import { createModel } from '@rematch/core';
import uniqueId from 'lodash.uniqueid';
const initialState = {
    toasts: [],
};
export const ToastModel = createModel()({
    state: initialState,
    reducers: {
        showToast: (state, toast) => {
            const newToast = { ...toast, id: `toast_${uniqueId()}` };
            return { ...state, toasts: [newToast, ...state.toasts] };
        },
        hideToast: (state, id) => {
            return { ...state, toasts: state.toasts.filter(toast => toast.id !== id) };
        },
    },
});
