import { Page } from '@const';
import { PartnerNameUrlEnum } from '@types';
export const matchChildUrl = (pathname, search) => {
    const compareUrl = [Page.Instruction.split('/')[1], Page.CreateSubscription, Page.SuccessParentAdd];
    const isNotAccessUrl = pathname.includes(PartnerNameUrlEnum.pingo) || pathname.includes(PartnerNameUrlEnum.leoni);
    if (pathname === '/' || isNotAccessUrl) {
        return Page.Child + search;
    }
    for (const url of compareUrl) {
        if (pathname.includes(url)) {
            if (pathname.includes('/child')) {
                return pathname + search;
            }
            return `/child${pathname + search}`;
        }
    }
    return pathname + search;
};
