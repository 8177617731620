import { SubscriptionStatusParentEnum } from '@models/subscription/types';
import { ResponseCodeEnum } from './types';
export const requestDefaultError = 'Возникли временные проблемы в работе сайта. Попробуйте позднее';
export const addChildDefaultError = 'Возникли проблемы при попытке осуществить связку номеров. Попробуйте позднее';
export const canceledStatuses = [
    SubscriptionStatusParentEnum.Canceled,
    SubscriptionStatusParentEnum.Failed,
    SubscriptionStatusParentEnum.Deleting,
];
export const subscriptionDefaultPrice = 149;
export const failParentStatuses = [
    SubscriptionStatusParentEnum.Failed,
    SubscriptionStatusParentEnum.Deleting,
    SubscriptionStatusParentEnum.Canceled,
];
export const activeParentStatuses = [SubscriptionStatusParentEnum.Active, SubscriptionStatusParentEnum.ChildAdd];
export const unavailableErrors = [
    ResponseCodeEnum.ContentForbidden,
    ResponseCodeEnum.ContentForbiddenForTP,
    ResponseCodeEnum.ForbiddenOtherContent,
];
