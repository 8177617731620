import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from 'classnames';
import { CheckMarkIcon, TimeSquareIcon, LockIcon, WarningIcon } from '@icons';
import classes from './Badge.module.scss';
import { useSelector } from 'react-redux';
import { isSafariSelector } from '@store/models/user/selectors';
const badgeIconConfig = {
    active: _jsx(CheckMarkIcon, { containerClasses: classes.svg }),
    negative: _jsx(LockIcon, { containerClasses: classes.svg }),
    inprocess: _jsx(TimeSquareIcon, { containerClasses: classes.svg }),
    warning: _jsx(WarningIcon, { containerClasses: classes.svg }),
};
const Badge = ({ variant, text = '', className, withIcon = true }) => {
    const isSafari = useSelector(isSafariSelector);
    const bageClasses = classNames(classes.badge, classes[variant], className, {
        [classes.withoutText]: !text,
        [classes.safari]: isSafari,
    });
    return (_jsxs("div", { className: bageClasses, children: [withIcon && _jsx("div", { className: classes.wrap, children: badgeIconConfig[variant] }), _jsx("div", { className: classNames('text', classes.text), children: text })] }));
};
export default Badge;
