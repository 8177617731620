export const getMetricNameConfirmClick = ({ isAnniversary, }) => {
    const metricConfig = [
        {
            check: isAnniversary,
            result: 'subscriptionRemainsAccept',
        },
        {
            check: !isAnniversary,
            result: 'subscriptionDisableButton',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
export const getToggleMetricNameShow = ({ isAnniversary, }) => {
    const metricConfig = [
        {
            check: isAnniversary,
            result: 'subscriptionRemainsShow',
        },
        {
            check: !isAnniversary,
            result: 'subscriptionDisableModal',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
