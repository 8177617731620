import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import classes from '@shared/Input/Input.module.scss';
import { validate } from '@utils/validators';
import { getUnmaskedValue, phoneMask } from '@utils/masks';
const maskConfig = {
    phone: phoneMask,
};
const typeConfig = {
    phone: 'tel',
};
const MaskedInput = ({ id, maskVariant, value = '', onChange, onBlur, onFocus, disabled, placeholder, inputRef }) => {
    const mask = maskConfig[maskVariant];
    const fieldType = typeConfig[maskVariant];
    const [innerValue, setInnerValue] = useState(value);
    useEffect(() => {
        // если поле очистили - очищаем innerValue
        if (!value.length) {
            setInnerValue('');
            return;
        }
        // если пришло валидное значение - подставляем его в innerValue
        // иначе используем текущее значение innerValue для корректой работы курсора
        validate(value, maskVariant) && setInnerValue(value);
    }, [value]);
    const handleChange = (ev) => {
        setInnerValue(ev.target.value); // внутри сохраняем значение с маской для корректной работы курсора
        onChange && onChange(getUnmaskedValue(ev.target.value, maskVariant)); // наверх передаем значение без маски
    };
    const handleFocus = () => {
        onFocus && onFocus();
    };
    const handleBlur = event => {
        const target = event.relatedTarget;
        if (!target?.classList?.contains(classes.clear)) {
            onBlur && onBlur(event);
        }
    };
    return (_jsx(InputMask, { id: id, value: innerValue, mask: mask, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur, disabled: disabled, className: classes.input, placeholder: placeholder, type: fieldType, children: _jsx("input", { ref: inputRef }) }));
};
export default MaskedInput;
