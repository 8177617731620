import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classes from './List.module.scss';
import classNames from 'classnames';
import { getMaskedPhone } from '@utils/masks';
import uniqueId from 'lodash.uniqueid';
import Input from '@shared/Input/Input';
import { PhoneFilledIcon } from '@icons';
import Slider from '@shared/Slider/Slider';
import { phoneValidator } from '@utils/validators';
import { triggerMetricsEvent } from '@metrics';
const List = ({ isValid = true, className, variants, onChange, loading, autocompleteValue, setAutocompleteValue, onScroll, ...props }) => {
    const autocompleteClasses = classNames(classes.wrapper, className);
    const isPhoneValid = phoneValidator(autocompleteValue);
    const handleChange = (value) => {
        setAutocompleteValue(value);
        onChange && onChange(value);
    };
    const handleVariantClick = (phone) => () => {
        triggerMetricsEvent({ element: 'slavesScroll' });
        setAutocompleteValue(phone);
        onChange && onChange(phone);
    };
    const slides = variants.map(({ phone }) => (_jsxs("button", { type: "button", className: classes.button, onMouseDown: handleVariantClick(phone), children: [_jsx(PhoneFilledIcon, { containerClasses: classes.icon }), _jsx("span", { className: classes.phone, children: getMaskedPhone(phone) })] }, uniqueId())));
    const autocompleteBlock = variants.length && !loading && !isPhoneValid ? (_jsx(Slider, { children: slides, className: classes.variants, slideClass: classes.slide })) : null;
    return (_jsx("div", { className: autocompleteClasses, children: _jsx(Input, { value: autocompleteValue, isValid: isValid, onChange: handleChange, autocompleteBlock: autocompleteBlock, loading: loading, mask: "phone", ...props }) }));
};
export default List;
