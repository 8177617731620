import { swagger } from '@store/api/defaultApi';
import { activePromoCode, validPromoCode } from './mock';
import { asyncDelay } from '@utils/delay';
export const prod = {
    validatePromoCode: (data) => swagger.validatePromoCode(data),
    applyPromoCode: (data) => swagger.activatePromoCode(data),
};
export const dev = {
    validatePromoCode: (data) => asyncDelay(() => {
        return new Promise((resolve, reject) => {
            resolve(validPromoCode);
        });
    }, 3000),
    applyPromoCode: (data) => asyncDelay(() => {
        return new Promise((resolve, reject) => {
            /* reject({ response: { data: { ErrorCode: 10107 } } }); */
            resolve(activePromoCode);
        });
    }, 3000),
};
