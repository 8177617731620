export const kionConfig = {
    parentNotAuth: {
        kionFilmCard_1: {
            ymID: 'pr_tap_1',
            GTMParams: {
                event: 'vntKion',
                eventCategory: 'kion',
                eventAction: 'card_click',
                eventLabel: 'kartochka_filma',
                userAuth: '0',
                eventContent: 'roditel',
                eventContext: '1',
                actionGroup: 'interactions',
            },
        },
        kionFilmCard_2: {
            ymID: 'pr_tap_2',
            GTMParams: {
                event: 'vntKion',
                eventCategory: 'kion',
                eventAction: 'card_click',
                eventLabel: 'kartochka_filma',
                userAuth: '0',
                eventContent: 'roditel',
                eventContext: '2',
                actionGroup: 'interactions',
            },
        },
        kionFilmCard_3: {
            ymID: 'pr_tap_3',
            GTMParams: {
                event: 'vntKion',
                eventCategory: 'kion',
                eventAction: 'card_click',
                eventLabel: 'kartochka_filma',
                userAuth: '0',
                eventContent: 'roditel',
                eventContext: '3',
                actionGroup: 'interactions',
            },
        },
        kionFilmCard_4: {
            ymID: 'pr_tap_4',
            GTMParams: {
                event: 'vntKion',
                eventCategory: 'kion',
                eventAction: 'card_click',
                eventLabel: 'kartochka_filma',
                userAuth: '0',
                eventContent: 'roditel',
                eventContext: '4',
                actionGroup: 'interactions',
            },
        },
        kionFilmCard_5: {
            ymID: 'pr_tap_5',
            GTMParams: {
                event: 'vntKion',
                eventCategory: 'kion',
                eventAction: 'card_click',
                eventLabel: 'kartochka_filma',
                userAuth: '0',
                eventContent: 'roditel',
                eventContext: '5',
                actionGroup: 'interactions',
            },
        },
    },
    childNotAuth: {
        kionFilmCard_1: {
            ymID: 'ch_tap_1',
            GTMParams: {
                event: 'vntKion',
                eventCategory: 'kion',
                eventAction: 'card_click',
                eventLabel: 'kartochka_filma',
                userAuth: '0',
                eventContent: 'rebenok',
                eventContext: '1',
                actionGroup: 'interactions',
            },
        },
        kionFilmCard_2: {
            ymID: 'ch_tap_2',
            GTMParams: {
                event: 'vntKion',
                eventCategory: 'kion',
                eventAction: 'card_click',
                eventLabel: 'kartochka_filma',
                userAuth: '0',
                eventContent: 'rebenok',
                eventContext: '2',
                actionGroup: 'interactions',
            },
        },
        kionFilmCard_3: {
            ymID: 'ch_tap_3',
            GTMParams: {
                event: 'vntKion',
                eventCategory: 'kion',
                eventAction: 'card_click',
                eventLabel: 'kartochka_filma',
                userAuth: '0',
                eventContent: 'rebenok',
                eventContext: '3',
                actionGroup: 'interactions',
            },
        },
        kionFilmCard_4: {
            ymID: 'ch_tap_4',
            GTMParams: {
                event: 'vntKion',
                eventCategory: 'kion',
                eventAction: 'card_click',
                eventLabel: 'kartochka_filma',
                userAuth: '0',
                eventContent: 'rebenok',
                eventContext: '4',
                actionGroup: 'interactions',
            },
        },
        kionFilmCard_5: {
            ymID: 'ch_tap_5',
            GTMParams: {
                event: 'vntKion',
                eventCategory: 'kion',
                eventAction: 'card_click',
                eventLabel: 'kartochka_filma',
                userAuth: '0',
                eventContent: 'rebenok',
                eventContext: '5',
                actionGroup: 'interactions',
            },
        },
    },
};
