export const moreCardsConfig = {
    parentNotAuth: {
        spamBlocking: {
            ymID: 'pr_15d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'zaschitnik',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        costSecurity: {
            ymID: 'pr_14d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'bezopasnost_rashodov',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        unlimitedMessengers: {
            ymID: 'pr_16d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'bazlimitnye_messengery',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        unlimitedSocial: {
            ymID: 'pr_17d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'bezlimitnye_soc_seti',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        alwaysInTouch: {
            ymID: 'pr_18d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'vsegda_na_svyazi',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        unlimitedLikee: {
            ymID: 'pr_19d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'likee',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
    },
    childNotAuth: {
        spamBlocking: {
            ymID: 'ch_15d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'zaschitnik',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        costSecurity: {
            ymID: 'ch_14d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'bezopasnost_rashodov',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        unlimitedMessengers: {
            ymID: 'ch_16d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'bazlimitnye_messengery',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        unlimitedSocial: {
            ymID: 'ch_17d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'bezlimitnye_soc_seti',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        unlimitedLikee: {
            ymID: 'ch_19d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '0',
                actionGroup: 'interactions',
                productName: 'bezlimit_na_likee',
            },
        },
        alwaysInTouch: {
            ymID: 'ch_18d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '0',
                actionGroup: 'interactions',
                productName: 'vsegda_na_svyazi',
            },
        },
    },
    childAuth: {
        spamBlocking: {
            ymID: 'ch_15d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                actionGroup: 'interactions',
                productName: 'zaschitnik',
            },
        },
        unlimitedLikee: {
            ymID: 'ch_19d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                actionGroup: 'interactions',
                productName: 'bezlimit_na_likee',
            },
        },
        alwaysInTouch: {
            ymID: 'ch_18d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                actionGroup: 'interactions',
                productName: 'vsegda_na_svyazi',
            },
        },
        costSecurity: {
            ymID: 'ch_14d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                actionGroup: 'interactions',
                productName: 'bezopasnost_rashodov',
            },
        },
        unlimitedMessengers: {
            ymID: 'ch_16d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                actionGroup: 'interactions',
                productName: 'bazlimitnye_messengery',
            },
        },
        unlimitedSocial: {
            ymID: 'ch_17d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                actionGroup: 'interactions',
                productName: 'bezlimitnye_soc_seti',
            },
        },
    },
};
