import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getActiveSubscriptionPeriod } from '@utils/getActiveSubscriptionPeriod';
import { formatDate } from '@utils/date';
export const getStatusText = ({ status, isTrial, remainingDays, price, nextTarifficationDate = '', }) => {
    const statusMap = {
        active: getActiveSubscriptionPeriod({ isTrialPeriod: !!isTrial, remainingDays, price, nextTarifficationDate }),
        suspended: (_jsxs(_Fragment, { children: ["\u041E\u043F\u043B\u0430\u0442\u0430 \u043D\u0435 \u0441\u043F\u0438\u0441\u0430\u043B\u0430\u0441\u044C. ", _jsx("br", {}), " \u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0435\u043C \u0441\u043F\u0438\u0441\u0430\u0442\u044C \u0441\u043D\u043E\u0432\u0430 ", formatDate({ date: nextTarifficationDate }), "."] })),
        canceled: '',
        adding: '',
        draft: '',
        childAdd: getActiveSubscriptionPeriod({ isTrialPeriod: !!isTrial, remainingDays, price, nextTarifficationDate }),
    };
    return status ? statusMap[status] : '';
};
