import { createModel } from '@rematch/core';
import { moreCardList } from '@configs/more';
import { errorConfig } from '@configs/errors';
const initialState = {
    openedDialog: null,
    moreEl: null,
    error: null,
    errorName: null,
};
export const DialogModel = createModel()({
    state: initialState,
    reducers: {
        openDialog: (state, openedDialog) => {
            return { ...state, openedDialog };
        },
        closeDialog: () => {
            return initialState;
        },
        setMoreElDialog: (state, moreEl) => {
            return { ...state, moreEl, openedDialog: 'more' };
        },
        setErrorDialog: (state, { error, errorName }) => {
            return { ...state, error, errorName: errorName || null, openedDialog: 'error' };
        },
    },
    effects: dispatch => ({
        openMoreElDialog: (elName, state) => {
            const version = state.userModel.version;
            const moreEl = moreCardList[version].find(el => el.name === elName);
            dispatch.dialogModel.setMoreElDialog(moreEl);
        },
        openErrorDialog: ({ name, role }, state) => {
            const getContent = errorConfig[role][name] || errorConfig[role].defaultError;
            if (getContent) {
                const ownerPhone = state.userModel.data.msisdn;
                const userPhone = state.subscriptionModel.newChildPhone;
                const codeExpireDate = state.subscriptionModel.subscription.nextTarifficationDate;
                dispatch.dialogModel.setErrorDialog({
                    error: getContent({ ownerPhone, userPhone, codeExpireDate }),
                    errorName: name,
                });
            }
        },
    }),
});
