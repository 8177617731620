import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classes from './ContentBlock.module.scss';
import { useSelector } from 'react-redux';
import { useWindowSize } from '@hooks/useWindowSize';
import { partnerFlowSelector } from '@store/models/subscription/selectors';
import BackButton from '@components/shared/BackButton/BackButton';
import classNames from 'classnames/bind';
import { useStickyBackButton } from '@hooks/useStickyBackButton';
const ContentBlock = ({ withBackButton, header, title, content, actions, status, subtitle }) => {
    const isPartnerFlow = useSelector(partnerFlowSelector);
    const { isDesktop } = useWindowSize();
    const isBackButtonShown = !isDesktop && withBackButton && !isPartnerFlow;
    const isBackButtonFixed = useStickyBackButton();
    return (_jsxs(_Fragment, { children: [isBackButtonShown && (_jsx("div", { className: classes.back, children: _jsx(BackButton, { className: classNames(classes.backButton, { [classes.fixed]: isBackButtonFixed }) }) })), _jsxs("div", { className: classes.block, children: [header, _jsxs("div", { className: classes.details, children: [_jsxs("div", { className: classes.titleWrap, children: [status, _jsxs("div", { children: [_jsx("h2", { className: classes.title, children: title }), subtitle && _jsx("div", { className: classes.subtitle, children: subtitle })] })] }), _jsx("div", { className: classes.content, children: content })] }), actions && _jsx("div", { className: classes.actions, children: actions })] })] }));
};
export default ContentBlock;
