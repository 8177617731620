import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classes from './InvitationButton.module.scss';
import { ChildIcon } from '@icons';
import Button from '@shared/Button/Button';
const InvitationButton = ({ variant, size, full }) => {
    const handleClick = () => {
        /* TODO сделать страницу принятия приглашения */
    };
    const icon = (_jsx("span", { className: classes.invitationIcon, children: _jsx(ChildIcon, {}) }));
    const text = (_jsxs("span", { className: classes.invitationText, children: [_jsx("span", { children: "\u041F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443" }), _jsx("span", { children: "\u043F\u043E \u043F\u0440\u0438\u0433\u043B\u0430\u0448\u0435\u043D\u0438\u044E \u0420\u0435\u0431\u0451\u043D\u043A\u0430" })] }));
    return _jsx(Button, { variant: variant, size: size, full: full, text: text, onClick: handleClick, icon: icon });
};
export default InvitationButton;
