export var ErrorName;
(function (ErrorName) {
    ErrorName["Unauthorized"] = "unauthorizedError";
    ErrorName["InternalServer"] = "internalServerError";
    ErrorName["InvalidOTP"] = "invalidOTP";
    ErrorName["ContentForbiddenForTP"] = "forbiddenTP";
    ErrorName["ForbiddenOtherContent"] = "forbiddenOther";
    ErrorName["ContentForbidden"] = "contentForbidden";
    ErrorName["ActiveSubscriptionParent"] = "activeParentError";
    ErrorName["ActiveSubscriptionChild"] = "activeChildError";
    ErrorName["PaymentError"] = "paymentError";
    ErrorName["Default"] = "error";
    ErrorName["Expired"] = "expired";
    ErrorName["ChildAndParentMatch"] = "selfConnection";
    ErrorName["PaymentToolMissed"] = "paymentToolMissed";
    ErrorName["CannotProcessPayment"] = "cannotProcessPayment";
})(ErrorName || (ErrorName = {}));
export var ResponseCodeEnum;
(function (ResponseCodeEnum) {
    ResponseCodeEnum[ResponseCodeEnum["InternalServerError"] = 10101] = "InternalServerError";
    ResponseCodeEnum[ResponseCodeEnum["BadRequest"] = 10102] = "BadRequest";
    ResponseCodeEnum[ResponseCodeEnum["Unauthorized"] = 10103] = "Unauthorized";
    ResponseCodeEnum[ResponseCodeEnum["NotFound"] = 10104] = "NotFound";
    ResponseCodeEnum[ResponseCodeEnum["InvalidMSISDN"] = 10105] = "InvalidMSISDN";
    ResponseCodeEnum[ResponseCodeEnum["InvalidOTP"] = 10106] = "InvalidOTP";
    ResponseCodeEnum[ResponseCodeEnum["ContentForbidden"] = 10107] = "ContentForbidden";
    ResponseCodeEnum["ContentBlockActivated"] = "ContentBlockActivated";
    ResponseCodeEnum["SubscriptionOnContentBlocked"] = "SubscriptionOnContentBlocked";
    ResponseCodeEnum[ResponseCodeEnum["ActiveSubscriptionParent"] = 10108] = "ActiveSubscriptionParent";
    ResponseCodeEnum["ActiveSubscriptionParentMH"] = "SubscriptionAlreadyExists";
    ResponseCodeEnum[ResponseCodeEnum["ActiveSubscriptionChild"] = 10109] = "ActiveSubscriptionChild";
    ResponseCodeEnum[ResponseCodeEnum["ForbiddenOtherContent"] = 10110] = "ForbiddenOtherContent";
    ResponseCodeEnum["ForbiddenOtherContentMH"] = "SubscriptionAlreadyExistsOnDifferentContent";
    ResponseCodeEnum[ResponseCodeEnum["ContentForbiddenForTP"] = 10111] = "ContentForbiddenForTP";
    ResponseCodeEnum["ContentForbiddenForTPMH"] = "ContentNotAllowedForThisTariffPlan";
    ResponseCodeEnum[ResponseCodeEnum["Expired"] = 10112] = "Expired";
    ResponseCodeEnum[ResponseCodeEnum["ChildAndParentMatch"] = 10113] = "ChildAndParentMatch";
    ResponseCodeEnum[ResponseCodeEnum["PaymentToolMissed"] = 10114] = "PaymentToolMissed";
    ResponseCodeEnum[ResponseCodeEnum["CannotProcessPayment"] = 10115] = "CannotProcessPayment";
})(ResponseCodeEnum || (ResponseCodeEnum = {}));
