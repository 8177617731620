import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ContentBlock from '../ContentBlock/ContentBlock';
import CustomLink from '@shared/Link/Link';
import Button from '@components/shared/Button/Button';
import { triggerMetricsEvent } from '@metrics';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import classes from './Auth.module.scss';
import { mtsJuniorRules } from '@const';
import { useSelector } from 'react-redux';
import { versionSelector } from '@store/models/user/selectors';
import { useEffect } from 'react';
const authConfig = {
    parent: {
        title: _jsx(_Fragment, { children: "\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u0443\u0439\u0442\u0435\u0441\u044C \u0441\u00A0\u043D\u043E\u043C\u0435\u0440\u0430\u00A0\u0420\u043E\u0434\u0438\u0442\u0435\u043B\u044F \u0434\u043B\u044F\u00A0\u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0435\u043D\u0438\u044F" }),
        content: _jsx(_Fragment, { children: "\u0412\u043E\u0439\u0434\u0438\u0442\u0435 \u0438\u043B\u0438 \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0439\u0442\u0435\u0441\u044C \u0441\u00A0\u043D\u043E\u043C\u0435\u0440\u0430 \u0420\u043E\u0434\u0438\u0442\u0435\u043B\u044F \u2014 \u044D\u0442\u043E \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E" }),
    },
    child: {
        title: (_jsxs(_Fragment, { children: ["\u0412\u043E\u0439\u0434\u0438 \u0441\u043E \u0441\u0432\u043E\u0435\u0433\u043E ", _jsx("br", { className: classes.childTitleBr }), " \u043D\u043E\u043C\u0435\u0440\u0430"] })),
        content: _jsx(_Fragment, { children: "\u0412\u043E\u0439\u0434\u0438 \u0438\u043B\u0438 \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0439\u0441\u044F \u0441\u00A0\u043D\u043E\u043C\u0435\u0440\u0430 \u0420\u0435\u0431\u0451\u043D\u043A\u0430 \u2014 \u044D\u0442\u043E \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E" }),
    },
};
const Auth = () => {
    const version = useSelector(versionSelector);
    const { userModel: { logIn }, metricsModel: { setAuthPageShowed }, } = useTypedDispatch();
    useEffect(() => {
        setAuthPageShowed(true);
        triggerMetricsEvent({ element: 'showAuthPage' });
        return () => {
            setAuthPageShowed(false);
        };
    }, []);
    const handleAuthClick = () => {
        triggerMetricsEvent({ element: 'authPageLoginButton' });
        logIn({ redirectType: 'login' });
    };
    const { title, content } = authConfig[version];
    const actions = (_jsxs(_Fragment, { children: [_jsx(Button, { size: "md", variant: "primary", text: "\u0412\u043E\u0439\u0442\u0438", onClick: handleAuthClick }), ' ', _jsxs("p", { className: classes.rules, children: ["\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0430\u044F, \u0441\u043E\u0433\u043B\u0430\u0448\u0430\u0435\u0442\u0435\u0441\u044C", ' ', _jsx(CustomLink, { href: mtsJuniorRules, type: "primary", inNewWindow: true, children: "\u0441\u00A0\u043F\u0440\u0430\u0432\u0438\u043B\u0430\u043C\u0438 \u041C\u0422\u0421 Junior" })] })] }));
    return _jsx(ContentBlock, { withBackButton: true, title: title, content: content, actions: actions });
};
export default Auth;
