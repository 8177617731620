import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Loader from '@components/shared/Loader/Loader';
import { useSelector } from 'react-redux';
import { checkAvailabilityStatusSelector, createStatusSelector, isRenewProcessSelector, partnerFlowSelector, requestCheckCreateSelector, } from '@store/models/subscription/selectors';
import { childThemeSelector, getUserDataSelector } from '@store/models/user/selectors';
import Button from '@components/shared/Button/Button';
import { useNavigate } from 'react-router';
import { Page } from '@const';
import { useEffect } from 'react';
import { triggerMetricsEvent } from '@metrics';
const CreateLoader = () => {
    const isChildFlow = useSelector(childThemeSelector);
    const createSubscriptionStatus = useSelector(createStatusSelector);
    const isPartnerFlow = useSelector(partnerFlowSelector);
    const { isConnection: isAvailabilityChecking } = useSelector(checkAvailabilityStatusSelector);
    const { isConnection: isCheckStatusRequest } = useSelector(requestCheckCreateSelector);
    const { isConnection: isUserDataRequest } = useSelector(getUserDataSelector);
    const isCreateFinished = createSubscriptionStatus !== 'inProgress';
    const isChildConnected = (isCheckStatusRequest || isCreateFinished) && isChildFlow;
    const isParentConnected = isCheckStatusRequest || isCreateFinished;
    const isRenewProcess = useSelector(isRenewProcessSelector);
    const navigate = useNavigate();
    const handelOkClick = () => {
        triggerMetricsEvent({ element: 'childConnectedScreenOkButton' });
        navigate(Page.Main);
    };
    useEffect(() => {
        if (isAvailabilityChecking) {
            triggerMetricsEvent({ element: 'availabilityCheckingScreen' });
        }
    }, [isAvailabilityChecking]);
    useEffect(() => {
        if (isChildConnected) {
            triggerMetricsEvent({ element: 'childConnectedScreen' });
        }
    }, [isChildConnected]);
    useEffect(() => {
        if (isParentConnected) {
            triggerMetricsEvent({ element: 'showConnectParent' });
        }
    }, [isParentConnected]);
    const loaderConfig = [
        {
            check: isUserDataRequest,
            result: {
                loaderTitle: 'Загрузка',
                loaderText: '',
                titleAlign: 'center',
            },
        },
        {
            check: isAvailabilityChecking,
            result: {
                loaderTitle: (_jsxs(_Fragment, { children: ["\u041F\u0440\u043E\u0432\u0435\u0440\u044F\u0435\u043C ", _jsx("br", {}), " \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E\u0441\u0442\u044C"] })),
                loaderText: '',
                titleAlign: 'center',
            },
        },
        {
            check: isRenewProcess,
            result: {
                loaderTitle: (_jsxs(_Fragment, { children: ["\u0412\u043E\u0437\u043E\u0431\u043D\u043E\u0432\u043B\u044F\u0435\u043C ", _jsx("br", {}), "\u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443 ", !isChildConnected && 'Родителю'] })),
                loaderText: '',
                titleAlign: 'center',
            },
        },
        {
            check: isChildConnected,
            result: {
                loaderTitle: 'Осталось чуть-чуть',
                loaderText: 'Настраиваем подписку МТС Junior, это может занять до 15 минут. Когда будет готово, мы пришлём SMS',
                titleAlign: 'left',
                action: !isPartnerFlow ? _jsx(Button, { size: "md", full: true, text: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E", variant: "primary", onClick: handelOkClick }) : _jsx(_Fragment, {}),
            },
        },
        {
            check: isParentConnected,
            result: {
                loaderTitle: 'Подключаем подписку',
                loaderText: 'Настраиваем подписку МТС Junior Родителю. Останется добавить Ребёнка.',
                titleAlign: 'left',
            },
        },
    ];
    const { loaderTitle = '', loaderText = '', titleAlign = 'center', action } = loaderConfig.find(({ check }) => check)?.result || {};
    return _jsx(Loader, { title: loaderTitle, text: loaderText, titleAlign: titleAlign, action: action });
};
export default CreateLoader;
