export const tariffConfig = {
    parentNotAuth: {
        yearTariffCardBuyButton: {
            ymID: 'pr_tarif_god_nea',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        monthTariffCardBuyButton: {
            ymID: 'pr_tarif_mes_nea',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
    },
    parentAuth: {
        yearTariffCardBuyButton: {
            ymID: 'pr_tarif_god_a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        monthTariffCardBuyButton: {
            ymID: 'pr_tarif_mes_a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageAbonentTrialYearTariffButton: {
            ymID: 'pr_mts_mes_trial_god',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'godovoi_tarif',
                eventContent: 'mesyac',
                eventContext: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageAbonentNoTrialYearTariffButton: {
            ymID: 'pr_mts_mes_notrial_god',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'godovoi_tarif',
                eventContent: 'mesyac',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        yearTariffAbonentOffer: {
            ymID: 'pr_mts_god_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'god',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        yearTariffNonAbonentOffer: {
            ymID: 'pr_neabon_god_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'god',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        yearTariffAbonentBuyButton: {
            ymID: 'pr_mts_god_podkluchit',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                eventContent: 'god',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        yearTariffNonAbonentBuyButton: {
            ymID: 'pr_neabon_god_vybratsposoboplaty',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vybrat_sposob_oplaty',
                eventContent: 'god',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageAbonentMonthTariffButton: {
            ymID: 'pr_mts_god_mes',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'mesyachnyi_tarif',
                eventContent: 'god',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageNonAbonentMonthTariffButton: {
            ymID: 'pr_neabon_god_mes',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'mesyachnyi_tarif',
                eventContent: 'god',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageYearTariffAbonentBackButton: {
            ymID: 'pr_mts_god_back',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'god',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageYearTariffNonAbonentBackButton: {
            ymID: 'pr_neabon_god_back',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'god',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageNonAbonentTrialYearTariffButton: {
            ymID: 'pr_neabon_mes_trial_god',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'godovoi_tarif',
                eventContent: 'mesyac',
                eventContext: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageNonAbonentNoTrialYearTariffButton: {
            ymID: 'pr_neabon_mes_notrial_god',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'godovoi_tarif',
                eventContent: 'mesyac',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        monthTariffAbonentTrialOffer: {
            ymID: 'pr_mts_mes_trial_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'mesyac',
                eventContext: 'trial',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        monthTariffAbonentTrialBuyButton: {
            ymID: 'pr_mts_mes_trial_podkluchit',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                eventContent: 'mesyac',
                eventContext: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        monthTariffAbonentNoTrialOffer: {
            ymID: 'pr_mts_mes_notrial_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'mesyac',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        monthTariffAbonentNoTrialBuyButton: {
            ymID: 'pr_mts_mes_notrial_podkluchit',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                eventContent: 'mesyac',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageMonthTariffAbonentTrialBackButton: {
            ymID: 'pr_mts_mes_trial_back',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'mesyac',
                eventContext: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageMonthTariffAbonentNoTrialBackButton: {
            ymID: 'pr_mts_mes_notrial_back',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'mesyac',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        monthTariffNonAbonentTrialOffer: {
            ymID: 'pr_neabon_mes_trial_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'mesyac',
                eventContext: 'trial',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        monthTariffNonAbonentTrialBuyButton: {
            ymID: 'pr_neabon_mes_trial_vybratsposoboplaty',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vybrat_sposob_oplaty',
                eventContent: 'mesyac',
                eventContext: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageMonthTariffNonAbonentTrialBackButton: {
            ymID: 'pr_neabon_mes_trial_back',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'mesyac',
                eventContext: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        monthTariffNonAbonentNoTrialOffer: {
            ymID: 'pr_neabon_mes_notrial_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'mesyac',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        monthTariffNonAbonentNoTrialBuyButton: {
            ymID: 'pr_neabon_mes_notrial_vybratsposoboplaty',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vybrat_sposob_oplaty',
                eventContent: 'mesyac',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageMonthTariffNonAbonentNoTrialBackButton: {
            ymID: 'pr_neabon_mes_notrial_back',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'mesyac',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
    },
};
