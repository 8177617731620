import { getQueryStatus } from '@store/utils';
import { createSelector } from 'reselect';
export const newPromoCodeSelector = (state) => state.promocodeModel.data;
export const promocodeErrorSelector = ({ promocodeModel: { error } }) => error?.errorCode || null;
export const previousPageSelector = ({ promocodeModel: { previousPage } }) => previousPage;
export const planChangedSelector = ({ promocodeModel: { isPlanChanged } }) => isPlanChanged;
export const promoCodeStageSelector = ({ promocodeModel: { promoCodeStage } }) => promoCodeStage;
// статусы запросов
const loadStatusSelector = ({ promocodeModel: { loadStatus } }) => loadStatus;
export const validateStatusSelector = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestValidate);
});
export const applyStatusSelector = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestApply);
});
