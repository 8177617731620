import { sortSlaves } from 'src/store/utils/sortSlaves';
const transformSlave = (slave) => {
    return {
        id: slave?.ID,
        phone: `${slave.MSISDN}`,
        name: slave.Description,
        picture: slave.Picture,
    };
};
export const transformUserInfo = (userInfo) => {
    const slaves = userInfo?.Slaves?.map((slave) => transformSlave(slave)) || [];
    const sortedSlaves = sortSlaves(slaves);
    return {
        guid: userInfo?.GUID?.length ? userInfo.GUID : null,
        msisdn: userInfo?.MSISDN || '',
        firstName: userInfo?.FirstName || '',
        lastName: userInfo?.LastName || '',
        middleName: userInfo?.MiddleName || '',
        picture: userInfo?.Picture,
        slaves: sortedSlaves,
        slaveInfo: userInfo?.SlaveInfo,
        isMtsAbonent: userInfo?.IsMTS,
    };
};
