const getCommonParentConfig = (userAuth) => ({
    logicLikeInsctructionAbout: {
        ymID: 'pr_logiclike_aboutservice',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'roditel',
            eventAction: 'screen_show',
            eventLabel: 'o_servise_logiclike',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    logicLikeInsctructionConnect: {
        ymID: 'pr_logiclike_kakpodkl',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_logiclike',
            eventAction: 'screen_show',
            eventLabel: 'kak_podkluchit_logiclike',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    logicLikeInsctructionConnectTopButton: {
        ymID: 'pr_logiclike_aboutservice_kakpodkl_top',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_logiclike',
            eventAction: 'button_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    logicLikeInsctructionConnectBottomButton: {
        ymID: 'pr_logiclike_aboutservice_kakpodkl_bottom',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_logiclike',
            eventAction: 'button_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'down',
            actionGroup: 'interactions',
        },
    },
    logicLikeInsctructionAboutTopButton: {
        ymID: 'pr_logiclike_kakpodkl_aboutservice',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_logiclike',
            eventAction: 'element_click',
            eventLabel: 'o_servise',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    logicLikeInsctructionCloseButton: {
        ymID: 'pr_logiclike_instruction_close',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_logiclike',
            eventAction: 'element_click',
            eventLabel: 'zakryt',
            userAuth,
            actionGroup: 'interactions',
        },
    },
    logicLikeInsctructionStartButton: {
        ymID: 'pr_logiclike_kakpodkl_start',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_logiclike',
            eventAction: 'element_click',
            eventLabel: 'nastroit_rebenku',
            userAuth,
            actionGroup: 'interactions',
        },
    },
});
const getCommonChildConfig = (userAuth) => ({
    logicLikeInsctructionConnect: {
        ymID: 'ch_logiclike_kakpodkl',
        GTMParams: {
            event: 'vntReb',
            eventCategory: 'rebenok',
            eventAction: 'screen_show',
            eventLabel: 'kak_podkluchit_logiclike',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    logicLikeInsctructionAboutTopButton: {
        ymID: 'ch_logiclike_kakpodkl_aboutservice',
        GTMParams: {
            event: 'vntReb',
            eventCategory: 'kak_podkluchit_logiclike',
            eventAction: 'button_click',
            eventLabel: 'o_servise',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    logicLikeInsctructionConnectBottomButton: {
        ymID: 'ch_logiclike_aboutservice_kakpodkl_bottom',
        GTMParams: {
            event: 'vntReb',
            eventCategory: 'kak_podkluchit_logiclike',
            eventAction: 'button_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'down',
            actionGroup: 'interactions',
        },
    },
});
export const logicLikeInsctructionPageConfig = {
    parentNotAuth: {
        ...getCommonParentConfig('0'),
    },
    parentAuth: {
        ...getCommonParentConfig('1'),
    },
    childNotAuth: {
        ...getCommonChildConfig('0'),
    },
    childAuth: {
        ...getCommonChildConfig('1'),
        logicLikeInsctructionPlayButton: {
            ymID: 'ch_logiclike_kakpodkl_play',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'kak_podkluchit_logiclike',
                eventAction: 'button_click',
                eventLabel: 'igrat',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
    },
};
