export const headerLoginConfig = {
    parentNotAuth: {
        headerLoginButton: {
            ymID: 'pr_2a',
            GTMParams: {
                event: 'vntLogin',
                eventCategory: 'avtorizaciya',
                eventAction: 'button_click',
                eventLabel: 'voiti',
                eventContent: 'roditel',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
    },
    childNotAuth: {
        headerLoginButton: {
            ymID: 'ch_2a',
            GTMParams: {
                event: 'vntLogin',
                eventCategory: 'avtorizaciya',
                eventAction: 'button_click',
                eventLabel: 'voiti',
                eventContent: 'rebenok',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        headerHaveSubscriptionButton: {
            ymID: '',
            GTMParams: {
                event: 'vntLogin',
                eventCategory: 'avtorizaciya',
                eventAction: 'button_click',
                eventLabel: 'u_menia_est_podpiska',
                eventContent: 'rebenok',
                actionGroup: 'interactions',
                userAuth: '0',
                buttonLocation: 'screen',
            },
        },
    },
};
