import { jsx as _jsx } from "react/jsx-runtime";
import classes from './Loading.module.scss';
import Spinner from '@shared/Spinner/Spinner';
import Loader from '@components/shared/Loader/Loader';
import { useSelector } from 'react-redux';
import { myMTSSelector } from '@store/models/subscription/selectors';
import { themeSelector } from '@store/models/user/selectors';
import classNames from 'classnames/bind';
const Loading = () => {
    const isMyMTSView = useSelector(myMTSSelector);
    const theme = useSelector(themeSelector);
    const spinnerColor = theme === 'dark' ? 'white' : 'black';
    /*
    Костыль спиннера для ММ.
    Объединяем 2 прелоадера, следующих друг за другом, в один (для ММ актуально, когда 2 проверки подряд).
    Подстроить наш прелоадер под прелоадер ММ.
    Чтобы минимизировать скачок между ними -> ощущение бесшовного перехода.
    */
    const myMTSSpinner = _jsx(Spinner, { className: classes.spinner, color: spinnerColor, size: "md", isAbsolutePos: false });
    const defaultLoader = _jsx(Loader, { titleAlign: "center" });
    const loader = isMyMTSView ? myMTSSpinner : defaultLoader;
    return (_jsx("div", { className: classNames(classes.container, {
            [classes.myMts]: isMyMTSView,
        }), children: loader }));
};
export default Loading;
