import { jsx as _jsx } from "react/jsx-runtime";
import Layout from '@components/shared/Layout/Layout';
import { GTMScreenName } from '@metrics';
import { useSelector } from 'react-redux';
import { authSelector, getUserDataSelector } from '@store/models/user/selectors';
import { childChangeAvailabilitySelector, membersSelector, } from '@store/models/subscription/selectors';
import { useNavigate } from 'react-router-dom';
import Loader from '@components/shared/Loader/Loader';
import { Page } from '@const';
import { useEffect } from 'react';
import Auth from '../Auth/Auth';
import { RoleEnum } from '@store/models/subscription/types';
import PhoneCard from '@components/shared/PhoneCard/PhoneCard';
import classes from './ChildInfo.module.scss';
import Button from '@components/shared/Button/Button';
import ContentBlock from '../ContentBlock/ContentBlock';
const ChildInfo = () => {
    const isAuth = useSelector(authSelector);
    const isChildChangeAvailable = useSelector(childChangeAvailabilitySelector);
    const { isConnection: isUserDataRequest } = useSelector(getUserDataSelector);
    const { child } = useSelector(membersSelector);
    const navigate = useNavigate();
    useEffect(() => {
        const isUserAuth = isAuth && !isUserDataRequest;
        const shouldRedirectToMain = isUserAuth && !isChildChangeAvailable;
        if (shouldRedirectToMain) {
            navigate(Page.Main);
        }
    }, [isAuth, isUserDataRequest, isChildChangeAvailable]);
    const isLoading = isUserDataRequest;
    const handleChangeClick = () => {
        navigate(Page.ChangeChild);
    };
    const title = 'Ребёнок в подписке';
    const subtitle = _jsx("p", { className: classes.subtitle, children: "\u041F\u043E\u043B\u044C\u0437\u0443\u0435\u0442\u0441\u044F \u0432\u0441\u0435\u043C\u0438 \u0441\u0435\u0440\u0432\u0438\u0441\u0430\u043C\u0438 \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438" });
    const content = (_jsx(PhoneCard, { role: RoleEnum.Child, name: child.name, msisdn: child.msisdn, picture: child.picture, className: classes.card }));
    const actions = _jsx(Button, { variant: "secondary", text: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u043D\u043E\u043C\u0435\u0440", size: "sm", full: true, onClick: handleChangeClick });
    return (_jsx(Layout, { pageName: GTMScreenName.addChild, withFooter: false, type: "parentFlow", withBackButton: !isLoading, withoutVersionButton: true, children: isLoading ? (_jsx(Loader, { title: "\u0417\u0430\u0433\u0440\u0443\u0437\u043A\u0430", titleAlign: "center" })) : isAuth ? (_jsx(ContentBlock, { title: title, subtitle: subtitle, content: content, actions: actions, withBackButton: !isLoading })) : (_jsx(Auth, {})) }));
};
export default ChildInfo;
