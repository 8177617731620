import { ResponseCodeEnum } from '@models/types';
import { CreateSubscriptionErrorName } from '@store/models/subscription/types';
export const transformError = (errorCode, role) => {
    const errorConfig = {
        owner: {
            [ResponseCodeEnum.ContentForbiddenForTPMH]: CreateSubscriptionErrorName.ContentForbiddenForTP,
            [ResponseCodeEnum.ContentForbiddenForTP]: CreateSubscriptionErrorName.ContentForbiddenForTP,
            [ResponseCodeEnum.ContentForbidden]: CreateSubscriptionErrorName.ContentBlockActivated,
            [ResponseCodeEnum.ContentBlockActivated]: CreateSubscriptionErrorName.ContentBlockActivated,
            [ResponseCodeEnum.SubscriptionOnContentBlocked]: CreateSubscriptionErrorName.ContentBlockActivated,
            [ResponseCodeEnum.ForbiddenOtherContent]: CreateSubscriptionErrorName.ForbiddenOtherContent,
            [ResponseCodeEnum.ForbiddenOtherContentMH]: CreateSubscriptionErrorName.ForbiddenOtherContent,
            [ResponseCodeEnum.CannotProcessPayment]: CreateSubscriptionErrorName.PaymentError,
        },
        user: {
            [ResponseCodeEnum.ContentForbiddenForTPMH]: CreateSubscriptionErrorName.ContentForbiddenForTPChild,
            [ResponseCodeEnum.ContentForbiddenForTP]: CreateSubscriptionErrorName.ContentForbiddenForTPChild,
            [ResponseCodeEnum.ContentForbidden]: CreateSubscriptionErrorName.ContentBlockActivatedChild,
            [ResponseCodeEnum.ContentBlockActivated]: CreateSubscriptionErrorName.ContentBlockActivatedChild,
            [ResponseCodeEnum.SubscriptionOnContentBlocked]: CreateSubscriptionErrorName.ContentBlockActivatedChild,
            [ResponseCodeEnum.ActiveSubscriptionParent]: CreateSubscriptionErrorName.SubscriptionAlreadyExistsParent,
            [ResponseCodeEnum.ActiveSubscriptionParentMH]: CreateSubscriptionErrorName.SubscriptionAlreadyExistsChild,
            [ResponseCodeEnum.ActiveSubscriptionChild]: CreateSubscriptionErrorName.SubscriptionAlreadyExistsChild,
        },
    };
    const defaultError = role === 'owner' ? CreateSubscriptionErrorName.DefaultError : CreateSubscriptionErrorName.DefaultChildError;
    return errorConfig[role][errorCode] || defaultError;
};
