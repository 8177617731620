import { TariffPlanEnum } from '@store/models/subscription/types';
export const getBackButtonMetricName = ({ isAuthPageShowed, isCreatingPage, isMTSAbonent, isFree, isSuccessAddPage, planName, instructionPageName, isPromocodePage, isAnniversary, }) => {
    const isYearPlan = planName === TariffPlanEnum.Year;
    const metricConfig = [
        {
            check: isAnniversary && isSuccessAddPage,
            result: 'continueSubscribeSuccessBack',
        },
        {
            check: isAnniversary && isCreatingPage && !isYearPlan,
            result: 'continueSubscribeMonthBack',
        },
        {
            check: isAnniversary && isCreatingPage && isYearPlan,
            result: 'continueSubscribeYearBack',
        },
        {
            check: isAnniversary && isCreatingPage && !isYearPlan && !isMTSAbonent,
            result: 'continueSubscribeMonthNonAbonentBack',
        },
        {
            check: isAnniversary && isCreatingPage && isYearPlan && !isMTSAbonent,
            result: 'continueSubscribeYearNonAbonentBack',
        },
        {
            check: Boolean(instructionPageName?.length),
            result: `${instructionPageName}InsctructionCloseButton`,
        },
        {
            check: isAuthPageShowed,
            result: 'authPageBackButton',
        },
        {
            check: isCreatingPage && isYearPlan && isMTSAbonent,
            result: 'createPageYearTariffAbonentBackButton',
        },
        {
            check: isCreatingPage && isYearPlan && !isMTSAbonent,
            result: 'createPageYearTariffNonAbonentBackButton',
        },
        {
            check: isCreatingPage && isMTSAbonent && isFree,
            result: 'createPageMonthTariffAbonentTrialBackButton',
        },
        {
            check: isCreatingPage && isMTSAbonent && !isFree,
            result: 'createPageMonthTariffAbonentNoTrialBackButton',
        },
        {
            check: isCreatingPage && !isMTSAbonent && isFree,
            result: 'createPageMonthTariffNonAbonentTrialBackButton',
        },
        {
            check: isCreatingPage && !isMTSAbonent && !isFree,
            result: 'createPageMonthTariffNonAbonentNoTrialBackButton',
        },
        {
            check: isSuccessAddPage,
            result: 'subscribeSuccessScreenBackButton',
        },
        {
            check: isPromocodePage,
            result: 'promocodeApplySuccessBackButton',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
