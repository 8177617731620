import { jsx as _jsx } from "react/jsx-runtime";
import Loader from '@components/shared/Loader/Loader';
import { useSelector } from 'react-redux';
import { updatePaymentTokenStatusSelector } from '@store/models/subscription/selectors';
import Button from '@components/shared/Button/Button';
import { useNavigate } from 'react-router';
import { Page } from '@const';
const UpdateLoader = () => {
    const { isConnection: isSubscriptionUpdating } = useSelector(updatePaymentTokenStatusSelector);
    const navigate = useNavigate();
    const handelOkClick = () => {
        navigate(Page.Main);
    };
    const loaderMap = {
        default: {
            loaderTitle: 'Загрузка',
            loaderText: '',
            titleAlign: 'center',
        },
        updating: {
            loaderTitle: 'Осталось чуть-чуть',
            loaderText: 'Настраиваем подписку МТС Junior, это может занять до 15 минут. Когда будет готово, мы пришлём SMS',
            titleAlign: 'left',
            action: _jsx(Button, { size: "md", full: true, text: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E", variant: "primary", onClick: handelOkClick }),
        },
    };
    const { loaderTitle = '', loaderText = '', titleAlign = 'center', action } = loaderMap[isSubscriptionUpdating ? 'updating' : 'default'];
    return _jsx(Loader, { title: loaderTitle, text: loaderText, titleAlign: titleAlign, action: action });
};
export default UpdateLoader;
