import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { versionSelector } from '@store/models/user/selectors';
import { useSelector } from 'react-redux';
import { modalDescriptionConfig } from '@configs/more';
import classes from './SliderElement.module.scss';
const SliderElement = ({ slide, moreEl }) => {
    const version = useSelector(versionSelector);
    const { alt: bageAlt, src: bageSrc } = moreEl.icon.mobile;
    const { title, text, subtitle } = slide;
    return (_jsxs("div", { className: classes.slide, children: [_jsx("img", { src: `images/${bageSrc.directory}/${bageSrc.fileName}`, alt: bageAlt, className: classes.img }), _jsx("div", { className: classes.title, children: title }), _jsx("div", { className: classes.subtitle, children: subtitle }), _jsx("div", { className: classes.textWrap, children: _jsx("p", { className: classes.text, children: text }) }), _jsx("div", { className: classes.description, children: modalDescriptionConfig[version] })] }));
};
export default SliderElement;
