import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classes from './Result.module.scss';
import Button from '@shared/Button/Button';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
const Result = ({ title, subtitle, text, buttonText, img, onClick, imgPosition = 'underTitle' }) => {
    const { dialogModel: { closeDialog }, } = useTypedDispatch();
    const handleCloseClick = () => {
        closeDialog();
        onClick && onClick();
    };
    const imgElement = _jsx("img", { className: classes.img, src: `/images/${img.directory}/${img.fileName}`, alt: img.fileName });
    return (_jsxs(_Fragment, { children: [imgPosition === 'aboveTitle' && imgElement, _jsx("div", { className: classes.title, children: title }), imgPosition === 'underTitle' && imgElement, subtitle && _jsx("div", { className: classes.subtitle, children: subtitle }), _jsx("p", { className: classes.text, children: text }), _jsx(Button, { variant: "primary", size: "md", text: buttonText, full: true, onClick: handleCloseClick, className: classes.button })] }));
};
export default Result;
