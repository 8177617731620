import { DialogModel } from '@models/dialog';
import { ToastModel } from '@models/toast';
import { FaqModel } from '@models/faq';
import { UserModel } from '@models/user';
import { PaymentWidgetModel } from '@models/paymentWidget';
import { SubscriptionModel } from '@models/subscription';
import { MetricsModel } from '@models/metrics';
import { PromoCodeModel } from './promocode';
import { RedirectModel } from './redirect';
import { SurveyWidgetModel } from './surveyWidget';
export const models = {
    dialogModel: DialogModel,
    toastModel: ToastModel,
    faqModel: FaqModel,
    userModel: UserModel,
    paymentWidgetModel: PaymentWidgetModel,
    subscriptionModel: SubscriptionModel,
    metricsModel: MetricsModel,
    promocodeModel: PromoCodeModel,
    redirectModel: RedirectModel,
    surveyWidgetModel: SurveyWidgetModel,
};
