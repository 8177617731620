import { createModel } from '@rematch/core';
const initialState = {
    successUrl: null,
    failedUrl: null,
    isChildAddNeeded: true,
};
export const RedirectModel = createModel()({
    state: initialState,
    reducers: {
        setParams: (state, params) => {
            return params;
        },
    },
    effects: dispatch => ({
        getRedirectParams() {
            const searchParams = new URLSearchParams(window.location.search);
            const successUrl = searchParams.get('success');
            const failedUrl = searchParams.get('failed');
            const isChildAddNeeded = searchParams.has('needChild');
            dispatch.redirectModel.setParams({ successUrl, failedUrl, isChildAddNeeded });
        },
    }),
});
