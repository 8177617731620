import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classes from '../SubscribeSettings.module.scss';
import { BankCardIcon, JuniorArrowIcon, SimCardIcon, InfoSquareIcon } from '@icons';
import classNames from 'classnames/bind';
import { getMaskedPhone } from '@utils/masks';
import { useSelector } from 'react-redux';
import { getSubscriptionStatuses } from '@utils/getSubscriptionStatuses';
import { getStatusText } from '../utils';
import Button from '@shared/Button/Button';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { PaymentTokenStatusEnum, PaymentTypeEnum } from '@models/subscription/types';
import Spinner from '@shared/Spinner/Spinner';
import { anniversarySelector, changeSubscriptionTariffPlanSelector, isPaymentChangeDisabledSelector, isProlongationAvailableSelector, isProlongationAvailableSelectorAnniversary, paymentStatusesSelector, subscriptionSelector, subscriptionStatusSelector, } from '@models/subscription/selectors';
import { childUserSelector, userInfoSelector } from '@models/user/selectors';
import { isChangeConnectSelector, isPaymentConnectSelector } from '@models/subscription/selectors';
import { triggerMetricsEvent } from '@metrics';
import { Page } from '@const';
import { useNavigate } from 'react-router';
import { pluralize } from '@utils/pluralize';
const Payment = () => {
    const subscription = useSelector(subscriptionSelector);
    const parent = useSelector(userInfoSelector);
    const subscriptionStatus = useSelector(subscriptionStatusSelector);
    const isPaymentChangeDisabled = useSelector(isPaymentChangeDisabledSelector);
    const { isTrialPeriod, isPaymentDisabled } = useSelector(paymentStatusesSelector);
    const isChild = useSelector(childUserSelector);
    const showPaymentTool = !isPaymentDisabled;
    const isProlongationAvailable = useSelector(isProlongationAvailableSelector);
    const isProlongationAnniversaryAvailable = useSelector(isProlongationAvailableSelectorAnniversary);
    const isAnniversary = useSelector(anniversarySelector);
    const navigate = useNavigate();
    const { subscriptionModel: { changePaymentTool }, dialogModel: { openDialog }, } = useTypedDispatch();
    const { price } = subscription;
    const { isSubscriptionSuspended, isSubscriptionProcess } = getSubscriptionStatuses(subscriptionStatus);
    const statusText = getStatusText({
        status: subscriptionStatus,
        isTrial: isTrialPeriod,
        remainingDays: subscription?.remainingDays,
        price,
        nextTarifficationDate: subscription?.nextTarifficationDate,
    });
    const paymentConnection = useSelector(isPaymentConnectSelector);
    const changeToolConnection = useSelector(isChangeConnectSelector);
    const isConnection = paymentConnection || changeToolConnection;
    const { isConnection: isTariffChanging } = useSelector(changeSubscriptionTariffPlanSelector);
    const phone = parent?.msisdn ? getMaskedPhone(parent.msisdn) : '';
    const isBankCard = subscription?.paymentType === PaymentTypeEnum.BankCard &&
        subscription?.paymentTokenStatus?.toLowerCase() === PaymentTokenStatusEnum.Active;
    const paymentTitle = isBankCard ? `Банковская карта ${subscription.bindingTitle}` : phone;
    const paymentIcon = isBankCard ? _jsx(BankCardIcon, {}) : _jsx(SimCardIcon, {});
    const paymentClasses = classNames(classes.payment, {
        [classes.error]: isSubscriptionSuspended,
        [classes.prolongation]: isProlongationAvailable,
    });
    const paymentLoaderClasses = classNames(classes.payment, classes.payment_loader);
    const handleChangeClick = (isPaymentTool) => {
        if (isPaymentChangeDisabled) {
            openDialog('paymentChangeDisabled');
            return;
        }
        changePaymentTool({});
        triggerMetricsEvent({ element: isPaymentTool ? 'paymentToolButton' : 'settingsPayButton' });
    };
    const handleProlongationClick = () => {
        triggerMetricsEvent({ element: 'specifyContinueSubscribeClick' });
        navigate(Page.UpdateSubscription);
    };
    const toolInfo = (_jsxs(_Fragment, { children: [paymentIcon, _jsxs("div", { className: classes.payment_info, children: [_jsx("small", { className: classes.payment_title, children: paymentTitle }), statusText && !isSubscriptionProcess && _jsx("span", { className: classes.payment_text, children: statusText })] })] }));
    const getPaymentTool = () => {
        const daysText = pluralize(subscription?.remainingDays, 'days');
        const leftTextUppercase = pluralize(subscription?.remainingDays, 'leftMUpper');
        if (isConnection) {
            return (_jsx("div", { className: paymentLoaderClasses, children: _jsx(Spinner, { color: "black", size: "md", isAbsolutePos: false }) }));
        }
        if (isSubscriptionProcess || isSubscriptionSuspended || isTariffChanging) {
            return _jsx("div", { className: paymentClasses, children: toolInfo });
        }
        if (isProlongationAnniversaryAvailable && isAnniversary) {
            return (_jsxs("button", { className: paymentClasses, onClick: handleProlongationClick, children: [_jsx(InfoSquareIcon, { containerClasses: classes.payment_info_square }), _jsx("span", { className: classes.payment_text, children: subscription?.remainingDays === 0 ?
                            'Продлите подписку, чтобы сохранить доступ к сервисам для себя и ребёнка'
                            :
                                `${leftTextUppercase} ${subscription?.remainingDays} ${daysText} подписки в подарок по акции «30 лет МТС».
              Продлите подписку, чтобы сохранить доступ к сервисам для себя и ребёнка` }), _jsx(JuniorArrowIcon, { containerClasses: classes.payment_arrow })] }));
        }
        if (isAnniversary && !isProlongationAnniversaryAvailable) {
            return null;
        }
        return (_jsxs("button", { className: paymentClasses, onClick: () => handleChangeClick(true), children: [toolInfo, _jsx(JuniorArrowIcon, { containerClasses: classes.payment_arrow, iconClasses: "settings" })] }));
    };
    return (_jsxs(_Fragment, { children: [showPaymentTool && getPaymentTool(), isSubscriptionSuspended && isChild && (_jsx(Button, { variant: "primary", size: "sm", text: "\u041E\u043F\u043B\u0430\u0442\u0438\u0442\u044C", full: true, className: classes.btn, onClick: () => handleChangeClick(), isLoading: isConnection }))] }));
};
export default Payment;
