import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { useSelector } from 'react-redux';
import { anniversarySelector, isChildOwnerSelector, isForisSubscriptionSelector, subscriptionDisableSelector, subscriptionSelector, } from '@models/subscription/selectors';
import classes from './SubscribeDisabling.module.scss';
import Button from '@shared/Button/Button';
import { triggerMetricsEvent } from '@metrics';
import { prevVersionSelector } from '@store/models/user/selectors';
import { RoleEnum } from '@store/models/subscription/types';
import { getMetricNameConfirmClick, getMetricNameLeaveClick, getToggleMetricNameShow, } from '@shared/Layout/Dialogs/SubscribeDisabling/config';
const imagesDir = '/images/subscription/disabling';
const config = {
    anniversary: {
        title: 'Уверены, что хотите отключить подписку?',
        text: 'Доступ к сервисам родителя и ребёнка прекратится сразу после отключения, а остаток доступных дней по подписке может сгореть',
        img: {
            alt: '',
            src: { directory: imagesDir, fileName: 'subscription-disabling-warning-default.png' },
        },
    },
    promocode: {
        title: 'Действует промокод',
        text: 'Ваш промокод может сгореть, а доступ к сервисам прекратится сразу после отключения подписки, даже если остались бесплатные или оплаченные дни',
        img: {
            alt: '',
            src: { directory: imagesDir, fileName: 'subscription-disabling-warning-promocode.png' },
        },
    },
    forisWithPromocode: {
        title: 'Вы точно хотите отключить подписку?',
        text: 'Доступ к сервисам родителя и ребёнка прекратится сразу после отключения, а оставшиеся дни сгорят',
        img: {
            alt: '',
            src: { directory: imagesDir, fileName: 'subscription-disabling-warning-foris-promocode.png' },
        },
    },
    foris: {
        title: 'Вы точно хотите отключить подписку?',
        text: 'Доступ к сервисам родителя и ребёнка прекратится сразу после отключения, а оставшиеся дни сгорят',
        img: {
            alt: '',
            src: { directory: imagesDir, fileName: 'subscription-disabling-warning-default.png' },
        }
    },
    default: {
        title: 'Отключение подписки',
        text: 'Доступ к сервисам прекратится сразу после отключения подписки, даже если остались бесплатные или оплаченные дни',
        img: {
            alt: '',
            src: { directory: imagesDir, fileName: 'subscription-disabling-warning-default.png' },
        },
    },
};
const getConfigName = ({ promocodeStatus, isMTSAnniversary, isForisSubscription }) => {
    const isPromocodeActive = promocodeStatus === 'active';
    const statusMap = [
        {
            check: isForisSubscription && isPromocodeActive,
            result: 'forisWithPromocode',
        },
        {
            check: isForisSubscription,
            result: 'foris',
        },
        {
            check: isMTSAnniversary,
            result: 'anniversary',
        },
        {
            check: isPromocodeActive,
            result: 'promocode',
        },
    ];
    return statusMap.find(({ check }) => check)?.result || 'default';
};
const SubscribeDisabling = () => {
    const { subscriptionModel: { disableSubscription }, dialogModel: { openDialog }, } = useTypedDispatch();
    const { isConnection } = useSelector(subscriptionDisableSelector);
    const isChildOwner = useSelector(isChildOwnerSelector);
    const isMTSAnniversary = useSelector(anniversarySelector);
    const isForisSubscription = useSelector(isForisSubscriptionSelector);
    const prevVersion = useSelector(prevVersionSelector);
    const { promocodeStatus } = useSelector(subscriptionSelector);
    const configName = getConfigName({ promocodeStatus, isMTSAnniversary, isForisSubscription });
    const { title, text, img } = config[configName];
    const handleConfirm = () => {
        const metricName = getMetricNameConfirmClick({ isAnniversary: isMTSAnniversary });
        disableSubscription({
            owner: isChildOwner ? RoleEnum.Child : RoleEnum.Parent,
            prevVersion: isChildOwner ? 'child' : prevVersion,
        });
        if (metricName) {
            triggerMetricsEvent({ element: metricName });
        }
    };
    const handleLeave = () => {
        const metricName = getMetricNameLeaveClick({ isAnniversary: isMTSAnniversary });
        openDialog('subscribeDisablingCancel');
        if (metricName) {
            triggerMetricsEvent({ element: metricName });
        }
    };
    useEffect(() => {
        const metricName = getToggleMetricNameShow({ isAnniversary: isMTSAnniversary });
        if (metricName) {
            triggerMetricsEvent({ element: metricName });
        }
    }, []);
    return (_jsxs("div", { className: classes.confirmation, children: [_jsx("div", { className: classes.title, children: title }), _jsx("img", { className: classes.img, src: `${img.src.directory}/${img.src.fileName}`, alt: img.alt }), _jsx("p", { className: classes.text, children: text }), _jsx(Button, { className: classes.button, variant: "primary", size: "md", text: "\u041E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443", full: true, disabled: isConnection, onClick: handleLeave }), _jsx(Button, { className: classes.button, variant: isChildOwner ? 'alwaysWhite' : 'negative', size: "md", text: "\u041E\u0442\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443", isLoading: isConnection, full: true, onClick: handleConfirm })] }));
};
export default SubscribeDisabling;
