export const backToSchoolConfig = {
    parentNotAuth: {
        b2sMainBannerButton: {
            ymID: 'pr_event_nea_1',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'uznat_bolshe',
                buttonLocation: 'banner',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
    },
};
