import { untypedWindow } from '@const';
import { goalsConfig } from './configs/goalsConfig';
import { store } from '@store';
import { SourceEnum } from '@store/models/subscription/types';
export const triggerGTMPageView = (screenName) => {
    const { isAuth, data: { guid }, } = store.getState().userModel;
    const { source } = store.getState().subscriptionModel;
    const isWebView = source !== SourceEnum.Landing;
    if (untypedWindow?.dataLayer) {
        untypedWindow.dataLayer.push({
            event: 'scrn',
            userId: guid,
            userAuth: isAuth ? '1' : '0',
            screenName,
            eventContent: null,
            eventContext: null,
            buttonLocation: null,
            actionGroup: 'non_interactions',
            productName: null,
            productId: null,
            touchPoint: isWebView ? 'webview' : 'web',
            currentTariff: null, //todo
        });
    }
};
export const triggerMetricsEvent = ({ element, productName }) => {
    const { subscriptionModel: { source }, metricsModel: { currentPage }, userModel: { version, isAuth, data: { guid }, }, } = store.getState();
    const isWebView = source !== SourceEnum.Landing;
    const isChildVersion = version === 'child';
    const namesConfig = {
        parentNotAuth: !isAuth && !isChildVersion,
        parentAuth: isAuth && !isChildVersion,
        childNotAuth: !isAuth && isChildVersion,
        childAuth: isAuth && isChildVersion,
    };
    const metricsConfig = Object.entries(namesConfig).find(([key, value]) => value);
    const metrics = metricsConfig?.[0] || null;
    if (!metrics || !element) {
        return;
    }
    const goals = goalsConfig[metrics];
    if (!(element in goals)) {
        return;
    }
    const { ymID, GTMParams } = goals[element];
    /*  if (untypedWindow?.ym) {
      untypedWindow.ym(ymCounterCode, 'reachGoal', ymID);
    } */
    if (untypedWindow?.dataLayer) {
        const params = {
            ...GTMParams,
            eventValue: GTMParams?.eventValue || null,
            userId: guid,
            screenName: currentPage,
            eventContent: GTMParams?.eventContent || null,
            eventContext: GTMParams?.eventContext || null,
            buttonLocation: GTMParams?.buttonLocation || null,
            productName: GTMParams?.productName || productName || null,
            productId: GTMParams?.productId || null,
            touchPoint: isWebView ? 'webview' : 'web',
            currentTariff: null, //todo
        };
        untypedWindow.dataLayer.push(params);
    }
};
