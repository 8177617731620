import { useEffect, useState } from 'react';
const getWindowSize = () => {
    const { innerWidth: width, innerHeight: height } = window;
    const isMobileSm = width < 600;
    const isMobileMd = width > 599 && width < 720;
    const isMobile = width < 720;
    const isTablet = width > 719 && width < 960;
    const isDesktop = width > 959;
    const isDesktopLgExtraLg = width > 1679;
    const isDesktopExtraSm = width > 959 && width < 1200;
    const isDesktopSm = width > 1199 && width < 1680;
    const isDesktopLg = width > 1599 && width < 1920;
    const isDesktopExtraLg = width > 1919;
    return {
        width,
        height,
        isMobileSm,
        isMobileMd,
        isTablet,
        isDesktopExtraSm,
        isDesktopSm,
        isDesktopLg,
        isDesktopExtraLg,
        isMobile,
        isDesktop,
        isDesktopLgExtraLg,
    };
};
export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        const handleResize = () => {
            setWindowSize(getWindowSize());
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
};
