import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classes from './PhoneCard.module.scss';
import Avatar from '@shared/Avatar/Avatar';
import { getMaskedPhone } from '@utils/masks';
import { RoleEnum } from '@store/models/subscription/types';
import classNames from 'classnames/bind';
const PhoneCard = ({ role, name, msisdn, picture, className }) => {
    const isChild = role === RoleEnum.Child;
    const icon = isChild ? 'violetAvatar' : 'grayAvatar';
    return (_jsxs("div", { className: classNames(classes.card, className), children: [_jsx(Avatar, { picture: picture, defaultIcon: icon, className: classes.avatar }), _jsxs("div", { className: classes.info, children: [name && _jsx("div", { className: classes.name, children: name }), _jsx("div", { className: classes.phone, children: getMaskedPhone(msisdn) })] })] }));
};
export default PhoneCard;
