import { getUrl } from '@store/utils/getUrl';
import axios from 'axios';
import { swagger } from 'src/store/api/defaultApi';
import storage from '@storage';
import { user } from '@store/api/user/mock';
import { asyncDelay } from '@utils/delay';
import { checkLocalMode } from '@utils/checkLocalMode';
const cookieValidateUrl = 'https://login.mts.ru/amserver/oauth2/sso/validate';
export const logIn = ({ theme, point, msisdn, redirectType, }) => {
    const currentUrl = window.location.href;
    const params = msisdn ? { msisdn, redirect_url: currentUrl } : { redirect_url: currentUrl };
    const loginUrl = getUrl('api/v2/redirect/websso/authorize', theme, params);
    if (redirectType) {
        storage.setItem('redirectType', redirectType);
    }
    if (point) {
        storage.setItem('cjPoint', point);
    }
    location.replace(loginUrl);
};
export const logout = (type, theme) => {
    const currentUrl = window.location.href;
    const logoutUrl = getUrl('api/v2/redirect/websso/logout', theme, { redirect_url: currentUrl });
    storage.setItem('redirectType', type);
    location.replace(logoutUrl);
};
export const checkAuth = async () => {
    const isLocal = checkLocalMode();
    if (isLocal) {
        return new Promise(resolve => {
            resolve(true);
        });
    }
    try {
        const { data } = await axios.get(cookieValidateUrl, {
            method: 'GET',
            withCredentials: true,
        });
        const isAuth = data?.isSessionCookieValid || data?.isPersistentCookieValid;
        return isAuth;
    }
    catch (error) {
        return false;
    }
};
export const getUserInfo = () => swagger.getUserInfoV2();
export const prod = {
    getUserInfo,
    checkAuth,
    logIn,
    logout,
};
export const dev = {
    getUserInfo: () => asyncDelay(() => {
        console.log('userInfo');
        return new Promise(resolve => {
            resolve(user);
        });
    }, 0),
    checkAuth,
    logIn,
    logout,
};
