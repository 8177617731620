import * as faq from './faq';
import * as user from './user';
import * as paymentWidget from './paymentWidget';
import * as subscription from './subscription';
import * as promocode from './promocode';
import { checkLocalMode } from '@utils/checkLocalMode';
const isLocal = checkLocalMode();
const configName = isLocal ? 'dev' : 'prod';
const api = {
    faq,
    user: user[configName],
    paymentWidget: paymentWidget[configName],
    subscription: subscription[configName],
    promocode: promocode[configName],
};
export default api;
