import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classes from './ChangePlanAccept.module.scss';
import Button from '@components/shared/Button/Button';
import { useSelector } from 'react-redux';
import { changeSubscriptionTariffPlanSelector, isForisSubscriptionSelector, tariffToChangeSelector, } from '@store/models/subscription/selectors';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { triggerMetricsEvent } from '@metrics';
import { useEffect } from 'react';
import { acceptButtonMetricsMap, leaveButtonMetricsMap, screenMetricsMap, getConfig, } from './config';
const ChangePlanAccept = () => {
    const choosenPlan = useSelector(tariffToChangeSelector);
    const isForisSubscription = useSelector(isForisSubscriptionSelector);
    const { title, text, img } = getConfig({ choosenPlan, isForisSubscription });
    const { dialogModel: { closeDialog }, subscriptionModel: { changeSubscriptionTariffPlan }, } = useTypedDispatch();
    const { isConnection: isTariffChanging } = useSelector(changeSubscriptionTariffPlanSelector);
    useEffect(() => {
        choosenPlan && triggerMetricsEvent({ element: screenMetricsMap[choosenPlan] });
    }, []);
    const handleClose = () => {
        choosenPlan && triggerMetricsEvent({ element: leaveButtonMetricsMap[choosenPlan] });
        closeDialog();
    };
    const handleChangeClick = (newPlanName) => () => {
        triggerMetricsEvent({ element: acceptButtonMetricsMap[newPlanName] });
        changeSubscriptionTariffPlan({ newPlanName, showSuccessChangeDialogs: true, closePreviousDialogBeforeWidget: true });
    };
    // проверка для ts, выбранный план устанавливаем перед открытием модалки
    if (!choosenPlan) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.title, children: title }), _jsx("img", { className: classes.img, src: `${img.src.directory}/${img.src.fileName}`, alt: img.alt }), _jsx("div", { className: classes.text, children: text }), _jsxs("div", { className: classes.actions, children: [_jsx(Button, { variant: "primary", size: "md", text: "\u0421\u043C\u0435\u043D\u0438\u0442\u044C \u043F\u043B\u0430\u043D", full: true, onClick: handleChangeClick(choosenPlan), isLoading: isTariffChanging }), _jsx(Button, { variant: "secondary", size: "md", text: "\u041E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u0442\u0435\u043A\u0443\u0449\u0438\u0439 \u043F\u043B\u0430\u043D", full: true, onClick: handleClose, disabled: isTariffChanging })] })] }));
};
export default ChangePlanAccept;
