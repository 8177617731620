import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classes from './DeletePromoCode.module.scss';
import SubscribeAddWrapper from '../../SubscribeAddWrapper/SubscribeAddWrapper';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { choosenTariffSelector } from '@models/subscription/selectors';
import Button from '@shared/Button/Button';
import { TariffPlanEnum } from '@store/models/subscription/types';
const contentMap = {
    [TariffPlanEnum.Month]: {
        planText: 'годовому',
        oldSubscriptionText: 'месячную',
        newSubscriptionText: 'годовую',
        newPlan: TariffPlanEnum.Year,
    },
    [TariffPlanEnum.Year]: {
        planText: 'месячному',
        oldSubscriptionText: 'годовую',
        newSubscriptionText: 'месячную',
        newPlan: TariffPlanEnum.Month,
    },
};
const DeletePromoCode = () => {
    const { dialogModel: { closeDialog }, subscriptionModel: { changeBucketTariffPlan }, } = useTypedDispatch();
    const choosenPlan = useSelector(choosenTariffSelector);
    const activePlanName = choosenPlan?.tariffPlan;
    const { planText, oldSubscriptionText, newSubscriptionText, newPlan } = contentMap[activePlanName];
    const handleLeavePlan = () => {
        closeDialog();
    };
    const handleChangePlan = () => {
        changeBucketTariffPlan(newPlan);
    };
    const title = 'Введеный промокод будет отменён';
    const content = _jsxs(_Fragment, { children: ["\u0423\u043A\u0430\u0437\u0430\u043D\u043D\u044B\u0439 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043D\u0435\u0432\u043E\u0437\u043C\u043E\u0436\u043D\u043E \u043F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C \u043A ", planText, " \u043F\u043B\u0430\u043D\u0443"] });
    const oldTariffButtonText = `Оставить ${oldSubscriptionText} подписку`;
    const newTariffButtonText = `Перейти на ${newSubscriptionText} подписку`;
    const action = (_jsxs(_Fragment, { children: [_jsx(Button, { size: "md", variant: "primary", text: oldTariffButtonText, onClick: handleLeavePlan, full: true }), _jsx(Button, { size: "md", full: true, text: newTariffButtonText, variant: "secondary", onClick: handleChangePlan, className: classes.newTariffButton })] }));
    return _jsx(SubscribeAddWrapper, { content: content, title: title, action: action });
};
export default DeletePromoCode;
