import { TariffPlanEnum } from '@store/models/subscription/types';
const imagesDir = '/images/subscription/disabling';
export const config = {
    month: {
        title: 'Вы переходите на месячный план подписки',
        img: {
            alt: '',
            src: { directory: imagesDir, fileName: 'subscription-disabling-warning-default.png' },
        },
        text: 'При переходе спишется 999 ₽, а текущий остаток периода исчезнет. В годовом плане вам станет доступен сервис Leoni',
    },
    year: {
        title: 'Вы переходите на годовой план подписки',
        img: {
            alt: '',
            src: { directory: imagesDir, fileName: 'subscription-disabling-warning-default.png' },
        },
        text: 'При переходе вы сможете воспользоваться остатком оплаченного периода. В месячном плане станет недоступен сервис Leoni',
    },
    yearForis: {
        title: 'План подписки изменится',
        text: 'Сменим вашу подписку на годовую, при этом оставшиеся дни сгорят. Первый год будет оплачен промокодом, для второго выберите комфортный способ оплаты',
        img: {
            alt: '',
            src: { directory: imagesDir, fileName: 'subscription-disabling-warning-default.png' },
        }
    },
};
export const getConfig = ({ choosenPlan, isForisSubscription }) => {
    const statusMap = [
        {
            check: choosenPlan === TariffPlanEnum.Year && isForisSubscription,
            result: 'yearForis',
        },
        {
            check: choosenPlan === TariffPlanEnum.Year,
            result: 'year',
        },
        {
            check: choosenPlan === TariffPlanEnum.Month,
            result: 'month',
        },
    ];
    return config[statusMap.find(({ check }) => check)?.result || 'month'];
};
export const screenMetricsMap = {
    [TariffPlanEnum.Month]: 'changePlanToMonthAcceptDialog',
    [TariffPlanEnum.Year]: 'changePlanToYearAcceptDialog',
};
export const acceptButtonMetricsMap = {
    [TariffPlanEnum.Month]: 'changePlanToMonthAcceptButton',
    [TariffPlanEnum.Year]: 'changePlanToYearAcceptButton',
};
export const leaveButtonMetricsMap = {
    [TariffPlanEnum.Month]: 'changePlanToMonthLeaveButton',
    [TariffPlanEnum.Year]: 'changePlanToYearLeaveButton',
};
