export const yearPlanConfig = {
    changePlanToYearMainPage: {
        ymID: 'pr_change_year_1a',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'roditel',
            eventAction: 'element_click',
            eventLabel: 'ezhegodno',
            userAuth: '1',
            actionGroup: 'interactions',
        },
    },
    changePlanToYearSettingsPage: {
        ymID: 'pr_change_year_1b',
        GTMParams: {
            event: 'vntPod',
            eventCategory: 'upravlyat_podpiskoi',
            eventAction: 'button_click',
            eventLabel: 'smenit_plan',
            eventContent: 'god',
            buttonLocation: 'popup',
            userAuth: '1',
            actionGroup: 'interactions',
        },
    },
    changePlanToYearAcceptDialog: {
        ymID: 'pr_change_year',
        GTMParams: {
            event: 'vntPod',
            eventCategory: 'podpiska',
            eventAction: 'popup_show',
            eventLabel: 'smenit_plan',
            eventContent: 'god',
            userAuth: '1',
            actionGroup: 'non_interactions',
        },
    },
    changePlanToYearAcceptButton: {
        ymID: 'pr_change_year_2a',
        GTMParams: {
            event: 'vntPod',
            eventCategory: 'podpiska',
            eventAction: 'button_click',
            eventLabel: 'smenit_plan',
            eventContent: 'god',
            buttonLocation: 'popup',
            userAuth: '1',
            actionGroup: 'interactions',
        },
    },
    changePlanToYearLeaveButton: {
        ymID: 'pr_change_year_2b',
        GTMParams: {
            event: 'vntPod',
            eventCategory: 'podpiska',
            eventAction: 'button_click',
            eventLabel: 'ostavit_tekuschii_plan',
            eventContent: 'god',
            buttonLocation: 'popup',
            userAuth: '1',
            actionGroup: 'interactions',
        },
    },
    changePlanToYearDialogClose: {
        ymID: 'pr_change_year_close',
        GTMParams: {
            event: 'vntPod',
            eventCategory: 'podpiska',
            eventAction: 'element_click',
            eventLabel: 'zakryt',
            eventContent: 'god',
            buttonLocation: 'popup',
            userAuth: '1',
            actionGroup: 'interactions',
        },
    },
};
