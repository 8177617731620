import { createSelector } from 'reselect';
import { authSelector } from './user/selectors';
import { subscriptionStatusSelector } from './subscription/selectors';
export const authUserSubscribeSelector = createSelector(authSelector, subscriptionStatusSelector, (isAuth, subscriptionStatus) => {
    return isAuth && subscriptionStatus === 'active';
});
//TODO временное решение, надо обсудить логику, по которой отображаются тексты на кнопках и описать ее тут в соответтсивии с новым CJM
const isTrialPossible = true;
export const showBuySelector = createSelector(authSelector, isAuth => {
    return isAuth && !isTrialPossible;
});
export const isConnectionSelector = ({ subscriptionModel, userModel, promocodeModel, dialogModel }) => {
    const subscriptionLoadStatus = Object.values(subscriptionModel.loadStatus);
    const userLoadStatus = Object.values(userModel.loadStatus);
    const promocodeLoadStatus = Object.values(promocodeModel.loadStatus);
    const isLoading = [...subscriptionLoadStatus, ...userLoadStatus, ...promocodeLoadStatus].some(status => status === 'loading');
    return isLoading;
};
export const isRepeatConnectionSelector = ({ subscriptionModel }) => {
    return [
        subscriptionModel.loadStatus.requestChangePaymentTool,
        subscriptionModel.loadStatus.requestChildAdd,
        subscriptionModel.loadStatus.requestCreate,
    ].includes('loading');
};
