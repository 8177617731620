import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classes from './PaymentChangeDisabled.module.scss';
import Button from '@shared/Button/Button';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { useSelector } from 'react-redux';
import { remainingDaysSelector } from '@models/subscription/selectors';
import { minPaymentChangeDays } from '@const';
import { pluralize } from '@utils/pluralize';
const PaymentChangeDisabled = () => {
    const remainingDays = useSelector(remainingDaysSelector);
    const { dialogModel: { closeDialog }, } = useTypedDispatch();
    const afterChangeDays = remainingDays - minPaymentChangeDays;
    const handleClick = () => {
        closeDialog();
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: classes.title, children: "\u0421\u043C\u0435\u043D\u0430 \u0441\u043F\u043E\u0441\u043E\u0431\u0430 \u043F\u0440\u043E\u0434\u043B\u0435\u043D\u0438\u044F" }), _jsxs("div", { className: classes.text, children: ["\u0421\u0442\u0430\u043D\u0435\u0442 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u043E\u0439 \u0447\u0435\u0440\u0435\u0437 ", afterChangeDays, " ", pluralize(afterChangeDays, 'days')] }), _jsx("div", { className: classes.actions, children: _jsx(Button, { onClick: handleClick, variant: 'secondary', size: 'md', text: '\u041F\u043E\u043D\u044F\u0442\u043D\u043E' }) })] }));
};
export { PaymentChangeDisabled };
