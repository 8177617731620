import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import SubscribeAddWrapper from '../SubscribeAddWrapper/SubscribeAddWrapper';
import { useSelector } from 'react-redux';
import { versionSelector } from '@store/models/user/selectors';
import classes from './Auth.module.scss';
import ChildDialogContainer from '../ChildDialogContainer/ChildDialogContainer';
import AgreementWithButton from '@shared/AgreementWithButton/AgreementWithButton';
import { triggerMetricsEvent } from '@metrics';
import { useEffect } from 'react';
const config = {
    parent: {
        title: _jsx(_Fragment, { children: "\u0410\u0432\u0442\u043E\u0440\u0438\u0437\u0443\u0439\u0442\u0435\u0441\u044C \u0441\u00A0\u043D\u043E\u043C\u0435\u0440\u0430 \u0420\u043E\u0434\u0438\u0442\u0435\u043B\u044F \u0434\u043B\u044F\u00A0\u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0435\u043D\u0438\u044F" }),
        content: _jsx(_Fragment, { children: "\u0412\u043E\u0439\u0434\u0438\u0442\u0435 \u0438\u043B\u0438 \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0439\u0442\u0435\u0441\u044C \u0441\u00A0\u043D\u043E\u043C\u0435\u0440\u0430 \u0420\u043E\u0434\u0438\u0442\u0435\u043B\u044F \u2014 \u044D\u0442\u043E \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E" }),
        component: props => _jsx(SubscribeAddWrapper, { ...props }),
    },
    child: {
        title: _jsx(_Fragment, { children: "\u0412\u043E\u0439\u0434\u0438 \u0441\u043E \u0441\u0432\u043E\u0435\u0433\u043E \u043D\u043E\u043C\u0435\u0440\u0430" }),
        content: _jsx(_Fragment, { children: "\u0412\u043E\u0439\u0434\u0438 \u0438\u043B\u0438 \u0437\u0430\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0438\u0440\u0443\u0439\u0441\u044F \u0441\u00A0\u043D\u043E\u043C\u0435\u0440\u0430 \u0420\u0435\u0431\u0451\u043D\u043A\u0430 \u2014 \u044D\u0442\u043E \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E" }),
        component: props => _jsx(ChildDialogContainer, { ...props }),
    },
};
const Auth = () => {
    const version = useSelector(versionSelector);
    const data = config[version];
    const { userModel: { logIn }, } = useTypedDispatch();
    useEffect(() => {
        triggerMetricsEvent({ element: 'showAuthModal' });
    }, []);
    const handleAuthClick = () => {
        triggerMetricsEvent({ element: 'authModalLoginButton' });
        logIn({ redirectType: 'login' });
    };
    const title = _jsx("p", { className: classes.title, children: data.title });
    const content = _jsx("p", { children: data.content });
    const action = _jsx(AgreementWithButton, { buttonText: "\u0412\u043E\u0439\u0442\u0438", onClick: handleAuthClick });
    return data.component({ title, content, action });
};
export default Auth;
