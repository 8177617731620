import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { planInfoMap } from '@const';
import { TariffPlanEnum } from '@store/models/subscription/types';
export const baseServices = [
    {
        alt: 'Pingo',
        src: { fileName: 'pingo.png', directory: 'partners/icons' },
    },
    {
        alt: 'LogicLike',
        src: { fileName: 'logicLike.png', directory: 'partners/icons' },
    },
    {
        alt: 'KION',
        src: { fileName: 'kion.png', directory: 'partners/icons' },
    },
    {
        alt: 'МТС Музыка',
        src: { fileName: 'music.png', directory: 'partners/icons' },
    },
    {
        alt: 'МТС Лончер Junior',
        src: { fileName: 'launcher.png', directory: 'partners/icons' },
    },
];
export const tariffPlanConfig = {
    [TariffPlanEnum.Month]: {
        period: 'На месяц',
        units: planInfoMap[TariffPlanEnum.Month].units,
        duration: planInfoMap[TariffPlanEnum.Month].duration,
        periodTitle: 'Ежемесячно',
        planTitle: 'Ежемесячный',
        price: 149,
        previousPrice: 1314,
        trialPeriod: 30,
        services: [...baseServices],
        addition: [
            {
                text: '30 дней бесплатно',
            },
            {
                text: `Выгода от ${planInfoMap[TariffPlanEnum.Month].benefit}`,
            },
            {
                text: 'Для любых операторов',
            },
        ],
        buttonText: 'Попробовать бесплатно',
    },
    [TariffPlanEnum.Year]: {
        period: 'На год',
        units: planInfoMap[TariffPlanEnum.Year].units,
        duration: planInfoMap[TariffPlanEnum.Year].duration,
        periodTitle: 'Ежегодно',
        planTitle: 'Годовой',
        price: 999,
        previousPrice: 5778,
        services: [
            ...baseServices,
            {
                alt: 'Leoni',
                src: { fileName: 'leoni.png', directory: 'partners/bages' },
            },
        ],
        addition: [
            {
                text: 'Leoni входит в подписку',
            },
            {
                text: `Выгода от ${planInfoMap[TariffPlanEnum.Year].benefit}`,
                info: (_jsxs(_Fragment, { children: ["999 \u20BD/\u0433\u043E\u0434 \u0432\u043C\u0435\u0441\u0442\u043E 1788 \u20BD ", _jsx("br", {}), " \u0437\u0430 12 \u043C\u0435\u0441\u044F\u0446\u0435\u0432 \u041C\u0422\u0421 Junior ", _jsx("br", {}), " \u0438 3990 \u20BD \u0437\u0430 Leoni"] })),
            },
            {
                text: 'Для любых операторов',
            },
        ],
        buttonText: 'Подключить',
    },
};
