import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import Result from '../../Result/Result';
import { triggerMetricsEvent } from '@metrics';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { ownerSelector } from '@store/models/subscription/selectors';
import { useNavigate } from 'react-router';
import { Page } from '@const';
const directory = 'result-dialog';
const contentMap = {
    parent: {
        img: { directory, fileName: 'result-dialog-subscription-add.png' },
        text: (_jsx(_Fragment, { children: "\u041F\u043E\u0437\u0434\u0440\u0430\u0432\u043B\u044F\u0435\u043C! \u0422\u0435\u043F\u0435\u0440\u044C \u0432\u0430\u0448\u0435\u043C\u0443 \u0420\u0435\u0431\u0451\u043D\u043A\u0443 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B \u0440\u0430\u0437\u0432\u0438\u0432\u0430\u044E\u0449\u0438\u0435 \u0438\u0433\u0440\u044B, \u0430 \u0442\u0430\u043A\u0436\u0435 \u0444\u0438\u043B\u044C\u043C\u044B \u0438 \u043C\u0443\u0437\u044B\u043A\u0430 \u043F\u043E \u0432\u043E\u0437\u0440\u0430\u0441\u0442\u0443. \u0410 \u0441 \u0433\u0435\u043E\u043B\u043E\u043A\u0430\u0446\u0438\u0435\u0439 \u0432\u044B \u0432\u0441\u0435\u0433\u0434\u0430 \u0431\u0443\u0434\u0435\u0442\u0435 \u0437\u043D\u0430\u0442\u044C, \u0433\u0434\u0435 \u0432\u0430\u0448 \u0420\u0435\u0431\u0451\u043D\u043E\u043A. \u0421\u043A\u043E\u0440\u043E \u043F\u0440\u0438\u0448\u043B\u0451\u043C SMS \u0441 \u0438\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u0435\u0439, \u043A\u0430\u043A \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u0441\u0435\u0440\u0432\u0438\u0441\u044B \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438" })),
    },
    child: {
        img: { directory, fileName: 'result-dialog-services.png' },
        text: (_jsx(_Fragment, { children: "\u041F\u043E\u0437\u0434\u0440\u0430\u0432\u043B\u044F\u0435\u043C! \u0422\u0435\u043F\u0435\u0440\u044C \u0442\u0435\u0431\u0435 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B \u0440\u0430\u0437\u0432\u0438\u0432\u0430\u044E\u0449\u0438\u0435 \u0438\u0433\u0440\u044B, \u0430 \u0442\u0430\u043A\u0436\u0435 \u0444\u0438\u043B\u044C\u043C\u044B \u0438 \u043C\u0443\u0437\u044B\u043A\u0430 \u043F\u043E\u00A0\u0432\u043E\u0437\u0440\u0430\u0441\u0442\u0443. \u0421\u043A\u043E\u0440\u043E \u043F\u0440\u0438\u0448\u043B\u0451\u043C SMS \u0441 \u0438\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u0435\u0439, \u043A\u0430\u043A \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u0441\u0435\u0440\u0432\u0438\u0441\u044B \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438" })),
    },
};
const Success = () => {
    const version = useSelector(ownerSelector);
    const navigate = useNavigate();
    const { subscriptionModel: { setAddChildStage, setCreateStatus }, } = useTypedDispatch();
    useEffect(() => {
        triggerMetricsEvent({ element: 'subscribeSuccessScreen' });
        return () => {
            setCreateStatus('inProgress');
            setAddChildStage('enterNumber');
        };
    }, []);
    const { img, text } = contentMap[version || 'parent'];
    const title = 'Подписка МТС Junior подключена';
    const handleServicesClick = () => {
        triggerMetricsEvent({ element: 'subscribeSuccessServicesButton' });
        navigate(Page.Main);
    };
    return _jsx(Result, { title: title, text: text, buttonText: "\u0421\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u0441\u0435\u0440\u0432\u0438\u0441\u044B", onClick: handleServicesClick, img: img });
};
export default Success;
