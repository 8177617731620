import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@shared/Button/Button';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import classes from './PromoCodeExists.module.scss';
import { useSelector } from 'react-redux';
import { promocodeExpirationDateSelector } from '@store/models/subscription/selectors';
import { formatDate } from '@utils/date';
const PromoCodeExists = () => {
    const promocodeExpirationDate = useSelector(promocodeExpirationDateSelector);
    const formatedDate = formatDate({
        date: promocodeExpirationDate || '',
        dateFormat: 'DD MMMM YYYY',
    });
    const { dialogModel: { closeDialog }, } = useTypedDispatch();
    const handleClose = () => {
        closeDialog();
    };
    return (_jsxs("div", { className: classes.container, children: [_jsx("div", { className: classes.title, children: "\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u0443\u0436\u0435 \u0434\u0435\u0439\u0441\u0442\u0432\u0443\u0435\u0442" }), _jsxs("p", { className: classes.text, children: ["\u0415\u0441\u043B\u0438 \u0432\u044B \u0440\u0430\u043D\u0435\u0435 \u0430\u043A\u0442\u0438\u0432\u0438\u0440\u043E\u0432\u0430\u043B\u0438 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434, \u0434\u043E\u0436\u0434\u0438\u0442\u0435\u0441\u044C, \u043F\u043E\u043A\u0430 \u0435\u0433\u043E \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u0437\u0430\u043A\u043E\u043D\u0447\u0438\u0442\u0441\u044F, \u0430\u00A0\u0437\u0430\u0442\u0435\u043C \u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u043E\u0432\u044B\u0439 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434. \u0412\u0432\u0435\u0441\u0442\u0438 \u043C\u043E\u0436\u043D\u043E \u0431\u0443\u0434\u0435\u0442 ", formatedDate] }), _jsx(Button, { className: classes.button, variant: "secondary", size: "md", text: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E", full: true, onClick: handleClose })] }));
};
export default PromoCodeExists;
