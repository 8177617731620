import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { getFooterSocialsList } from '@configs/footer';
import classes from './Socials.module.scss';
import ListItem from '@shared/ListItem/ListItem';
import Link from '@shared/Link/Link';
const socialsList = getFooterSocialsList(classes.icon);
const Socials = () => {
    return (_jsx("ul", { className: classes.list, children: socialsList.map(({ href, icon, name }) => (_jsx(ListItem, { type: "li", className: classes.item, children: _jsxs(Link, { href: href, inNewWindow: true, children: [name, icon] }) }, href))) }));
};
export default Socials;
