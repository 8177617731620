import { createSelector } from 'reselect';
import { getQueryStatus } from 'src/store/utils';
import { SubscriptionStatusParentEnum } from '../subscription/types';
import { canceledStatuses } from '../const';
export const authSelector = (state) => state.userModel.isAuth;
export const isProfileWidgetLoadedSelector = (state) => state.userModel.isProfileWidgetLoaded;
export const msisdnSelector = (state) => state.userModel.data.msisdn;
export const userInfoSelector = ({ userModel: { data: { msisdn, guid }, }, }) => ({ msisdn, guid });
export const getUserDataIsChild = ({ userModel: { isAuth, isChild } }) => ({ isAuth, isChild });
export const userNameSelector = ({ userModel: { data: { firstName, lastName, middleName }, }, }) => ({ firstName, lastName, middleName });
export const userNetworkStatusSelector = ({ userModel: { loadStatus } }) => loadStatus;
export const getUserDataSelector = createSelector(userNetworkStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestGetData);
});
export const slavesSelector = ({ userModel: { data: { slaves }, }, }) => slaves;
export const themeSelector = ({ userModel: { theme } }) => theme;
export const loadingErrorSelector = ({ userModel: { isLoadingError } }) => isLoadingError;
export const childThemeSelector = ({ userModel: { version } }) => version === 'child';
export const versionSelector = ({ userModel: { version } }) => version;
export const prevVersionSelector = ({ userModel: { prevVersion } }) => prevVersion;
export const childUserSelector = ({ userModel: { data }, subscriptionModel: { subscription } }) => {
    const parentStatus = subscription?.subscriptionStatusParent?.toLowerCase();
    const childStatus = subscription?.subscriptionStatusChild?.toLowerCase();
    const isChildOwnerActive = parentStatus === SubscriptionStatusParentEnum.Draft && !canceledStatuses.includes(childStatus);
    const isParentOwnerActive = parentStatus !== SubscriptionStatusParentEnum.Draft && !canceledStatuses.includes(parentStatus);
    const isChildFlow = data?.msisdn === subscription?.msisdnChild;
    const isOnlyChildFlowAvailable = isChildFlow && (isParentOwnerActive || isChildOwnerActive);
    return isOnlyChildFlowAvailable;
};
export const isChildAuthSelector = ({ userModel: { data }, subscriptionModel: { subscription } }) => {
    return data.msisdn === subscription.msisdnChild;
};
export const invitationShownSelector = ({ userModel }) => {
    return false;
};
export const isSafariSelector = ({ userModel: { isSafari } }) => isSafari;
export const plugSelector = ({ userModel: { plugType } }) => plugType;
export const abonentSelector = ({ userModel: { data: { isMtsAbonent }, }, }) => isMtsAbonent;
export const navigationHistorySelector = ({ userModel: { isNavigationHistoryEmpty } }) => isNavigationHistoryEmpty;
