import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import 'swiper/css';
import 'swiper/css/pagination';
import classes from './Slider.module.scss';
import uniqueId from 'lodash.uniqueid';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import classNames from 'classnames';
import { JuniorArrowIcon } from '@icons';
const Slider = ({ children, slideClass, className, loop = false, progressbar = false, navigation = false, navigationClass, navigationButtonClass, bullets = false, controlsClass, progressbarClass, progressbarWrapClass, onScroll, }) => {
    const isSingleSlide = children.length === 1;
    const paginationId = `pagination_${uniqueId()}`;
    const navigationNextId = `navigation_next_${uniqueId()}`;
    const navigationPrevId = `navigation_prev_${uniqueId()}`;
    const isProgressShown = progressbar || bullets;
    const pagination = bullets
        ? {
            clickable: true,
            renderBullet: (index, className) => {
                return `<button class='${classNames(className, classes.bullet)}'>${index + 1}</button>`;
            },
        }
        : {
            type: 'progressbar',
        };
    const nav = {
        nextEl: `#${navigationNextId}`,
        prevEl: `#${navigationPrevId}`,
    };
    const progressbarClasses = classNames(progressbarClass, { [classes.progressbar]: progressbar, [classes.bullets]: bullets });
    return (_jsxs(Swiper, { className: className, slidesPerView: "auto", loop: !isSingleSlide && loop, initialSlide: 0, observer: true, modules: [Pagination, Navigation], pagination: { ...pagination, el: `#${paginationId}` }, navigation: nav, mousewheel: true, onInit: swiper => {
            swiper.setProgress(0);
            swiper.pagination.update();
            swiper.on('slideChange', swiperChanged => {
                if (swiperChanged.activeIndex !== 0) {
                    onScroll && onScroll();
                }
            });
        }, children: [children.map((child, index) => (_jsx(SwiperSlide, { className: slideClass, onClick: ev => ev.stopPropagation(), children: child }, index))), !isSingleSlide && (_jsxs("div", { className: classNames(classes.controls, controlsClass), children: [_jsx("div", { className: classNames(classes.progressbarWrap, progressbarWrapClass, { [classes.progressbarShown]: isProgressShown }), children: _jsx("div", { id: paginationId, className: progressbarClasses }) }), navigation && (_jsxs("div", { className: classNames(classes.navigation, navigationClass), children: [_jsx("button", { id: navigationPrevId, className: classNames(classes.navButton, navigationButtonClass, classes.prev), children: _jsx(JuniorArrowIcon, {}) }), _jsx("button", { id: navigationNextId, className: classNames(classes.navButton, navigationButtonClass, classes.next), children: _jsx(JuniorArrowIcon, {}) })] }))] }))] }));
};
export default Slider;
