import { childMsisdn, parentMsisdn, subscription } from '@store/api/subscription/mock';
const slaves = [
    {
        MSISDN: childMsisdn,
        Description: 'slave 1',
    },
    { MSISDN: '78999999999' },
    { MSISDN: '71999999999' },
];
export const user = {
    data: {
        GUID: 'e5170b37-5d90-4b4f-b58f-8a02c58a2cd9',
        FirstName: '',
        MiddleName: '',
        LastName: '',
        MSISDN: parentMsisdn,
        IsMTS: true,
        Slaves: slaves,
        SlaveInfo: null,
        Subscription: subscription.data,
        //Subscription: null,
    },
};
