export const handleCloseSurvey = () => {
    const nextOpenSurveyModal = localStorage.getItem('nextOpenSurveyModal');
    if (!nextOpenSurveyModal) {
        const date = new Date();
        const dateOpenSurveyModal = new Date(date.setDate(date.getDate() + 1)).toISOString();
        localStorage.setItem('nextOpenSurveyModal', `${dateOpenSurveyModal}`);
    }
    else if (nextOpenSurveyModal) {
        localStorage.setItem('nextOpenSurveyModal', `${false}`);
    }
};
