export var PromoCodeErrorName;
(function (PromoCodeErrorName) {
    PromoCodeErrorName[PromoCodeErrorName["PromiseRedeemed"] = 10201] = "PromiseRedeemed";
    PromoCodeErrorName[PromoCodeErrorName["PromisNotFound"] = 10202] = "PromisNotFound";
    PromoCodeErrorName[PromoCodeErrorName["ProMISRedemptionQuantityIsOver"] = 10203] = "ProMISRedemptionQuantityIsOver";
    PromoCodeErrorName[PromoCodeErrorName["ProMISNotInvolved"] = 10204] = "ProMISNotInvolved";
    PromoCodeErrorName[PromoCodeErrorName["ProMISOutdated"] = 10205] = "ProMISOutdated";
    PromoCodeErrorName[PromoCodeErrorName["ProMISPromotionLimitExceeded"] = 10206] = "ProMISPromotionLimitExceeded";
    PromoCodeErrorName[PromoCodeErrorName["ProMISCounterLimitExceeded"] = 10207] = "ProMISCounterLimitExceeded";
    PromoCodeErrorName[PromoCodeErrorName["PromotionNotFound"] = 10208] = "PromotionNotFound";
    PromoCodeErrorName[PromoCodeErrorName["CommonPromoCodeNotFound"] = 10209] = "CommonPromoCodeNotFound";
    PromoCodeErrorName[PromoCodeErrorName["CustomValidateError"] = 2] = "CustomValidateError";
    PromoCodeErrorName[PromoCodeErrorName["CustomApplyError"] = 1] = "CustomApplyError";
})(PromoCodeErrorName || (PromoCodeErrorName = {}));
