export const delay = (fn, ms = 0) => {
    const timeout = window.setTimeout(() => {
        fn();
        window.clearTimeout(timeout);
    }, ms);
};
const asyncTimeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};
export const asyncDelay = async (fn, ms) => {
    await asyncTimeout(ms);
    return fn();
};
