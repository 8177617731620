import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import classes from './VersionButton.module.scss';
import { ChildVersionIcon, ParentVersionIcon } from '@icons';
import Button from '@shared/Button/Button';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { useSelector } from 'react-redux';
import { authSelector, childUserSelector, isSafariSelector, versionSelector } from '@store/models/user/selectors';
import classNames from 'classnames';
import { triggerMetricsEvent } from '@metrics';
import { useNavigate } from 'react-router';
import { Page } from '@const';
import { useWindowSize } from '@hooks/useWindowSize';
const versionConfig = {
    child: {
        icon: _jsx(ChildVersionIcon, {}),
        text: 'Показать версию для родителей',
        link: Page.Main,
    },
    parent: {
        icon: _jsx(ParentVersionIcon, {}),
        text: 'Показать версию для детей',
        link: Page.Child,
    },
};
const VersionButton = ({ className, full = false }) => {
    const versionName = useSelector(versionSelector);
    const isChildUser = useSelector(childUserSelector);
    const isSafari = useSelector(isSafariSelector);
    const isAuth = useSelector(authSelector);
    const version = versionConfig[versionName];
    const navigate = useNavigate();
    const { isDesktopExtraLg } = useWindowSize();
    const buttonSize = isDesktopExtraLg ? 'md' : 'sm';
    const buttonClasses = classNames(classes.button, className, {
        [classes.safari]: isSafari,
        [classes.child]: versionName === 'child',
        [classes.notAuth]: !isAuth,
    });
    const { userModel: { changeVersion }, } = useTypedDispatch();
    const handleClick = () => {
        triggerMetricsEvent({ element: 'versionButton' });
        changeVersion({});
        navigate(version.link);
    };
    const icon = _jsx("span", { className: classes.icon, children: version.icon });
    const text = _jsx("span", { className: classes.text, children: version.text });
    return (_jsx(_Fragment, { children: !isChildUser && (_jsx(Button, { variant: "secondary", size: buttonSize, full: full, text: text, onClick: handleClick, icon: icon, className: buttonClasses })) }));
};
export default VersionButton;
