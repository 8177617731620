import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Layout from '@components/shared/Layout/Layout';
import classes from './CreateSubscription.module.scss';
import { GTMScreenName, triggerMetricsEvent } from '@metrics';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { abonentSelector, authSelector, childThemeSelector, getUserDataSelector, isChildAuthSelector, msisdnSelector, } from '@store/models/user/selectors';
import { availableSubscriptionsSelector, checkAvailabilityStatusSelector, choosenTariffSelector, createStatusSelector, isRenewAvailableSelector, nextParentLinkSelector, requestCheckCreateSelector, requestCreateSelector, subscriptionSelector, subscriptionStatusesSelector, } from '@store/models/subscription/selectors';
import { PercentIcon, PromoCrossIcon } from '@components/icons';
import { useNavigate } from 'react-router-dom';
import CustomLink from '@shared/Link/Link';
import { Page, minYearDuration, mtsJuniorRules, planInfoMap } from '@const';
import { RoleEnum, TariffPlanEnum } from '@store/models/subscription/types';
import classNames from 'classnames';
import { pluralize } from '@utils/pluralize';
import { newPromoCodeSelector } from '@store/models/promocode/selectors';
import { getMaskedPhone } from '@utils/masks';
import Button from '@components/shared/Button/Button';
import { promoCodeButtonText } from '@configs/promocode';
import Auth from '../Auth/Auth';
import ContentBlock from '../ContentBlock/ContentBlock';
import CreateLoader from './CreateLoader/CreateLoader';
import { tariffPlanConfig } from '@configs/tariffPlans';
import { getBuyButtonMetricName, getToggleTariffButtonMetricName, getOfferShowMetricName, getPromocodeButtonMetricName, lsPaymentTextConfig, } from './configs';
import { checkChildUserCanceled } from '@store/models/subscription/utils/checkChildUserCanceled';
const getOfferText = ({ remainingDays, promocodeDays, price, isRenewAvailable, isFree, }) => {
    const isPromocodeDays = promocodeDays > 0;
    const calcDays = promocodeDays + remainingDays;
    const daysText = (_jsxs(_Fragment, { children: [remainingDays, " ", pluralize(remainingDays, 'days')] }));
    const leftText = pluralize(remainingDays, 'leftM');
    const config = [
        {
            check: isPromocodeDays,
            result: (_jsxs(_Fragment, { children: [calcDays, " ", pluralize(calcDays, 'days'), " \u0443\u0436\u0435 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B", _jsx("br", {}), "\u0414\u0430\u043B\u0435\u0435 \u2014 ", _jsx("span", { className: classes.offerPrice, children: price }), "."] })),
        },
        {
            check: isRenewAvailable,
            result: (_jsxs(_Fragment, { children: [leftText, " ", daysText, _jsx("br", {}), "\u0414\u0430\u043B\u0435\u0435 \u2014 ", _jsx("span", { className: classes.offerPrice, children: price }), "."] })),
        },
        {
            check: isFree,
            result: (_jsxs(_Fragment, { children: [daysText, " \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E, \u0434\u0430\u043B\u0435\u0435 \u2014 ", _jsx("span", { className: classes.offerPrice, children: price }), "."] })),
        },
    ];
    return config.find(({ check }) => check)?.result || _jsx(_Fragment, { children: price });
};
const CreateSubscription = () => {
    const isAuth = useSelector(authSelector);
    const { parent: { canceled: parentCanceled, childAdd, draft: parentDraft }, child: { canceled: childCanceled }, } = useSelector(subscriptionStatusesSelector);
    const availableSubscriptions = useSelector(availableSubscriptionsSelector);
    const createSubscriptionStatus = useSelector(createStatusSelector);
    const subscription = useSelector(subscriptionSelector);
    const promocode = useSelector(newPromoCodeSelector);
    const parentPhone = useSelector(msisdnSelector);
    const isMTSAbonent = useSelector(abonentSelector);
    const isChildFlow = useSelector(childThemeSelector);
    const choosenPlan = useSelector(choosenTariffSelector);
    const nextLink = useSelector(nextParentLinkSelector);
    const activePlanName = choosenPlan?.tariffPlan;
    const isFree = choosenPlan?.isTrial;
    const { isConnection: isAvailabilityChecking } = useSelector(checkAvailabilityStatusSelector);
    const { isConnection: isCreateRequest } = useSelector(requestCreateSelector);
    const { isConnection: isCheckStatusRequest } = useSelector(requestCheckCreateSelector);
    const { isConnection: isUserDataRequest } = useSelector(getUserDataSelector);
    const isChildUser = useSelector(isChildAuthSelector);
    const isRenewAvailable = useSelector(isRenewAvailableSelector);
    const remainingDays = choosenPlan?.remainingDays;
    const promocodeDays = promocode?.duration || 0;
    const isCreateFinished = createSubscriptionStatus !== 'inProgress';
    const role = isChildFlow ? RoleEnum.Child : RoleEnum.Parent;
    const isLoading = isUserDataRequest || isAvailabilityChecking || isCheckStatusRequest || isCreateFinished;
    const { subscriptionModel: { getTariffPlans, setTariffChoosen, createSubscription, setCreateStatus }, promocodeModel: { deletePromoCode, setPreviousPage }, } = useTypedDispatch();
    const navigate = useNavigate();
    const [offerMetricsState, setOfferMetricsState] = useState('notReady');
    const shouldMetricsSend = !isLoading && isAuth && availableSubscriptions && offerMetricsState === 'ready';
    useEffect(() => {
        const metricsName = getOfferShowMetricName({ isTrial: isFree, isAbonent: isMTSAbonent, planName: activePlanName });
        if (shouldMetricsSend && metricsName) {
            triggerMetricsEvent({ element: metricsName });
        }
    }, [activePlanName, shouldMetricsSend]);
    useEffect(() => {
        return () => {
            setCreateStatus('inProgress');
        };
    }, []);
    useEffect(() => {
        if (isAuth && !isUserDataRequest) {
            /*
            1. При загрузке проверяем:
               - если есть подписка и нет ребенка, то редиректим на добавление ребенка,
               - если есть ребенок, то редиректим на главную
            2. если подписки нет, то получаем список тарифных планов, доступных для подключения
          */
            const isParentOwnerActive = !parentDraft && !parentCanceled;
            const isChildOwnerActive = parentDraft && !childCanceled;
            //Если родитель active, а ребенок canceled, то для авторизованного под номером ребенка юзера даем возможность подключить моноподписку
            const isChildUserCanceled = checkChildUserCanceled({
                parentStatus: subscription.subscriptionStatusParent,
                childStatus: subscription.subscriptionStatusChild,
                isChildUser,
            });
            if (subscription.subscriptionId && (isChildOwnerActive || isParentOwnerActive) && !isChildUserCanceled && !isRenewAvailable) {
                const redirectLink = childAdd ? Page.AddChild : Page.Main;
                navigate(redirectLink);
                return;
            }
            getTariffPlans({ role, requestType: 'buy' });
            setOfferMetricsState('ready');
        }
    }, [isAuth, isUserDataRequest]);
    useEffect(() => {
        /*
          1. При окончании процесса подключения редиректим пользователя на страницы партнеров (если данные были ими переданы),
          либо на страницу добавления ребенка
          2. если ссылки для редиректа нет (возможно, например, при возникновении ошибок, которые у нас отображаются на этой странице),
          то ничего не делаем и остаемся здесь
        */
        if (createSubscriptionStatus !== 'inProgress') {
            const linkParams = createSubscriptionStatus === 'success' ? nextLink.success : nextLink.error;
            if (linkParams.href) {
                linkParams.type === 'external' ? (window.location.href = linkParams.href) : navigate(linkParams.href);
            }
        }
    }, [createSubscriptionStatus]);
    const price = activePlanName && `${choosenPlan?.price} ${tariffPlanConfig[activePlanName].units}/${tariffPlanConfig[activePlanName].duration}`;
    const buyButtonText = isMTSAbonent ? 'Подключить' : 'Выбрать способ оплаты';
    const handleToggleClick = (name) => () => {
        const metricName = getToggleTariffButtonMetricName({ isAbonent: isMTSAbonent, isTrial: isFree, planName: name });
        metricName && triggerMetricsEvent({ element: metricName });
        setTariffChoosen(name);
    };
    const handleBuyClick = () => {
        const metricsName = getBuyButtonMetricName({ isTrial: isFree, isAbonent: isMTSAbonent, planName: activePlanName });
        if (metricsName) {
            triggerMetricsEvent({ element: metricsName });
        }
        const role = isChildFlow ? RoleEnum.Child : RoleEnum.Parent;
        createSubscription({ contentID: choosenPlan.contentID, role, isRenewProcess: isRenewAvailable });
    };
    const handlePromoClick = () => {
        const metricName = getPromocodeButtonMetricName({ isAbonent: isMTSAbonent, isTrial: isFree, planName: activePlanName });
        metricName && triggerMetricsEvent({ element: metricName });
        setPreviousPage(Page.CreateSubscription);
        navigate(Page.Promocode);
    };
    const handlePromoDelete = () => {
        deletePromoCode();
        if (isRenewAvailable && subscription.tariffPlan) {
            setTariffChoosen(subscription.tariffPlan);
        }
    };
    const header = !isChildFlow && !isRenewAvailable && availableSubscriptions ? (_jsx("div", { className: classes.toggles, children: availableSubscriptions.map(({ price, tariffPlan }) => {
            const { period, units, previousPrice } = tariffPlanConfig[tariffPlan];
            const isYearButton = tariffPlan === TariffPlanEnum.Year;
            const isYearPromoApplied = Boolean(promocode && promocode.duration >= minYearDuration);
            const isMonthButton = tariffPlan === TariffPlanEnum.Month;
            const isMonthPromoApplied = Boolean(promocode && promocode.duration < minYearDuration);
            const isButtonDisabled = (isYearButton && isMonthPromoApplied) || (isMonthButton && isYearPromoApplied);
            const buttonClasses = classNames(classes.toggle, {
                [classes.active]: tariffPlan === activePlanName,
                [classes.disabled]: isButtonDisabled,
            });
            return (_jsxs("button", { type: "button", className: buttonClasses, onClick: handleToggleClick(tariffPlan), disabled: isButtonDisabled, children: [_jsxs("div", { className: classes.periodWrap, children: [_jsx("span", { className: classes.period, children: period }), previousPrice && _jsxs("span", { className: classes.previousPrice, children: [previousPrice, " \u20BD"] })] }), _jsxs("span", { className: classes.price, children: [price, " ", units] })] }, tariffPlan));
        }) })) : null;
    const planTitle = planInfoMap[activePlanName]?.title;
    const title = isRenewAvailable && !isChildFlow ? `МТС Junior на ${planTitle}` : 'Подписка МТС Junior';
    const content = (_jsxs(_Fragment, { children: [_jsxs("p", { className: classes.text, children: [getOfferText({ remainingDays, promocodeDays, price, isRenewAvailable, isFree }), " ", _jsx("br", {}), " \u0414\u043B\u044F \u043B\u044E\u0431\u044B\u0445 \u043E\u043F\u0435\u0440\u0430\u0442\u043E\u0440\u043E\u0432"] }), !isChildFlow && _jsxs("p", { className: classes.phone, children: ["\u0420\u043E\u0434\u0438\u0442\u0435\u043B\u044C: ", getMaskedPhone(parentPhone)] })] }));
    const actions = (_jsxs(_Fragment, { children: [_jsx(Button, { size: "md", variant: "primary", text: buyButtonText, onClick: handleBuyClick, isLoading: isCreateRequest }), !isChildFlow && (_jsx(_Fragment, { children: promocode ? (_jsxs("div", { className: classes.promoblock, children: [_jsx(PercentIcon, { containerClasses: classes.percent }), _jsxs("div", { className: classes.promoText, children: [promocode.duration, " \u0434\u043D\u0435\u0439 \u043F\u043E \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434\u0443"] }), _jsxs("button", { className: classes.deletePromo, onClick: handlePromoDelete, disabled: isCreateRequest, children: [_jsx(PromoCrossIcon, { containerClasses: classes.deletePromoSvg }), " \u0443\u0434\u0430\u043B\u0438\u0442\u044C \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434"] })] })) : (_jsx(Button, { size: "sm", full: true, text: promoCodeButtonText, variant: "secondary", onClick: handlePromoClick, disabled: isCreateRequest })) })), isMTSAbonent && (_jsx("p", { className: classes.rules, children: lsPaymentTextConfig[promocode ? 'promocode' : choosenPlan?.isTrial ? 'trial' : 'noTrial'] })), _jsxs("p", { className: classes.rules, children: ["\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0430\u044F, \u0441\u043E\u0433\u043B\u0430\u0448\u0430\u0435\u0442\u0435\u0441\u044C", ' ', _jsx(CustomLink, { href: mtsJuniorRules, type: "primary", inNewWindow: true, children: "\u0441\u00A0\u043F\u0440\u0430\u0432\u0438\u043B\u0430\u043C\u0438 \u041C\u0422\u0421 Junior" })] })] }));
    const contentBlock = availableSubscriptions && (_jsx(ContentBlock, { header: header, title: title, content: content, actions: actions, withBackButton: true }));
    return (_jsx(Layout, { pageName: GTMScreenName.createSubscriprion, withFooter: false, type: isChildFlow ? 'childFlow' : 'parentFlow', withBackButton: !isLoading, withoutVersionButton: true, children: isLoading ? _jsx(CreateLoader, {}) : isAuth ? contentBlock : _jsx(Auth, {}) }));
};
export default CreateSubscription;
