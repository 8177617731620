export const getToggleMetricNameClick = ({ isAnniversary, }) => {
    const metricConfig = [
        {
            check: isAnniversary,
            result: 'continueSubscribeSuccessAllService',
        },
        {
            check: !isAnniversary,
            result: 'subscribeSuccessServicesButton',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
export const getToggleMetricNameShow = ({ isAnniversary, }) => {
    const metricConfig = [
        {
            check: isAnniversary,
            result: 'continueSubscribeSuccessShow',
        },
        {
            check: !isAnniversary,
            result: 'subscribeSuccessScreen',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
