import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Result from '../Result/Result';
import Link from '@shared/Link/Link';
import { siteLink } from '@const';
import { useSelector } from 'react-redux';
import { childUserSelector } from '@store/models/user/selectors';
import { useEffect } from 'react';
import { triggerMetricsEvent } from '@metrics';
import { getMetricNameConfirmClick, getToggleMetricNameShow } from './config';
import { anniversarySelector } from '@models/subscription/selectors';
const contentMap = {
    parent: {
        title: 'Спасибо, что остаётесь с МТС Junior',
        text: (_jsxs(_Fragment, { children: ["\u041C\u044B \u043F\u0440\u043E\u0434\u043E\u043B\u0436\u0430\u0435\u043C \u0441\u043E\u0431\u0438\u0440\u0430\u0442\u044C \u043D\u043E\u0432\u0438\u043D\u043A\u0438 \u0432 \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0435. \u0417\u0430 \u0432\u0441\u0435\u043C\u0438 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u044F\u043C\u0438 \u0432\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0441\u043B\u0435\u0434\u0438\u0442\u044C \u043D\u0430 \u043D\u0430\u0448\u0435\u043C \u0441\u0430\u0439\u0442\u0435", ' ', _jsx(Link, { href: siteLink, type: 'primary', children: "junior.mts.ru" }), ' '] })),
    },
    child: {
        title: 'Спасибо, что остаёшься с МТС Junior',
        text: (_jsxs(_Fragment, { children: ["\u0410 \u043C\u044B \u0441\u043E\u0431\u0438\u0440\u0430\u0435\u043C \u043D\u043E\u0432\u0438\u043D\u043A\u0438 \u0432 \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0435. \u0417\u0430 \u0432\u0441\u0435\u043C\u0438 \u043E\u0431\u043D\u043E\u0432\u043B\u0435\u043D\u0438\u044F\u043C\u0438 \u043C\u043E\u0436\u0435\u0448\u044C \u0441\u043B\u0435\u0434\u0438\u0442\u044C \u043D\u0430 \u043D\u0430\u0448\u0435\u043C \u0441\u0430\u0439\u0442\u0435", ' ', _jsx(Link, { href: siteLink, type: 'primary', children: "junior.mts.ru" }), ' '] })),
    },
};
const SubscribeDisablingCancel = () => {
    const isChildUser = useSelector(childUserSelector);
    const version = isChildUser ? 'child' : 'parent';
    const isAnniversary = useSelector(anniversarySelector);
    const { title, text } = contentMap[version];
    const img = { directory: 'result-dialog', fileName: 'result-dialog-subscription-disabling-cancel.png' };
    useEffect(() => {
        const metricName = getToggleMetricNameShow({ isAnniversary });
        if (metricName) {
            triggerMetricsEvent({ element: metricName });
        }
    }, []);
    const handleOkClick = () => {
        const metricName = getMetricNameConfirmClick({ isAnniversary });
        if (metricName) {
            triggerMetricsEvent({ element: metricName });
        }
    };
    return _jsx(Result, { title: title, text: text, buttonText: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E", img: img, onClick: handleOkClick });
};
export default SubscribeDisablingCancel;
