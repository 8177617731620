export const gmdConfig = {
    parentNotAuth: {
        gmdLogin: {
            ymID: 'gmd_pr_2a',
            GTMParams: {
                event: 'vntLogin',
                eventCategory: 'avtorizaciya',
                eventAction: 'button_click',
                eventLabel: 'voiti',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        gmdMainBannerBuyFree: {
            ymID: 'gmd_pr_1a',
            GTMParams: {
                event: 'vntGmd',
                eventCategory: 'gmd',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                buttonLocation: 'up',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        gmdStickyButtonBuyFree: {
            ymID: 'gmd_pr_stickyA',
            GTMParams: {
                event: 'vntGmd',
                eventCategory: 'gmd',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        gmdBottomBannerBuyFree: {
            ymID: 'gmd_pr_1c',
            GTMParams: {
                event: 'vntGmd',
                eventCategory: 'gmd',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                buttonLocation: 'down',
                userAuth: '0',
            },
        },
        gmdActivationBuyFree: {
            ymID: 'gmd_pr_1b',
            GTMParams: {
                event: 'vntGmd',
                eventCategory: 'gmd',
                eventAction: 'link_click',
                eventLabel: 'podkluchite_podpisku',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
    },
    parentAuth: {
        gmdMainBannerBuyFree: {
            ymID: 'gmd_pr_1d',
            GTMParams: {
                event: 'vntGmd',
                eventCategory: 'gmd',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                buttonLocation: 'up',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        gmdStickyButtonBuyFree: {
            ymID: 'gmd_pr_stickyB',
            GTMParams: {
                event: 'vntGmd',
                eventCategory: 'gmd',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        gmdActivationBuyFree: {
            ymID: 'gmd_pr_1e',
            GTMParams: {
                event: 'vntGmd',
                eventCategory: 'gmd',
                eventAction: 'link_click',
                eventLabel: 'podkluchite_podpisku',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        gmdBottomBannerBuyFree: {
            ymID: 'gmd_pr_1g',
            GTMParams: {
                event: 'vntGmd',
                eventCategory: 'gmd',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                buttonLocation: 'down',
                userAuth: '1',
            },
        },
        gmdMainBannerBuy: {
            ymID: 'gmd_pr_12a',
            GTMParams: {
                event: 'vntGmd',
                eventCategory: 'gmd',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                buttonLocation: 'up',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        gmdStickyButtonBuy: {
            ymID: 'gmd_pr_stickyC',
            GTMParams: {
                event: 'vntGmd',
                eventCategory: 'gmd',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        gmdActivationBuy: {
            ymID: 'gmd_pr_12b',
            GTMParams: {
                event: 'vntGmd',
                eventCategory: 'gmd',
                eventAction: 'link_click',
                eventLabel: 'podkluchit',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        gmdBottomBannerBuy: {
            ymID: 'gmd_pr_12c',
            GTMParams: {
                event: 'vntGmd',
                eventCategory: 'gmd',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                actionGroup: 'interactions',
                buttonLocation: 'down',
                userAuth: '1',
            },
        },
    },
};
