import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import ProductLogo from '@shared/ProductLogo/ProductLogo';
import classes from './Plug.module.scss';
const contenMap = {
    needAuth: {
        text: (_jsxs(_Fragment, { children: ["\u0414\u043B\u044F \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u044F \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438 Junior ", _jsx("br", {}), " \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u0443\u0439\u0442\u0435\u0441\u044C \u043F\u043E\u0434 \u043D\u0443\u0436\u043D\u044B\u043C \u043D\u043E\u043C\u0435\u0440\u043E\u043C", _jsx("br", {}), " P\u043E\u0434\u0438\u0442\u0435\u043B\u044F \u0432 \u041C\u043E\u0439 \u041C\u0422\u0421"] })),
        buttonText: 'Авторизоваться',
    },
    onlyOneChild: { text: 'На данный момент нет возможности добавить второго Ребёнка', buttonText: 'Перейти на сайт подписки' },
};
const Plug = ({ type }) => {
    const { text } = contenMap[type];
    return (_jsxs("div", { className: classes.container, children: [_jsx("img", { className: classes.image, alt: "Sad man", src: "/images/plugs/plug.png" }), _jsx(ProductLogo, {}), _jsx("p", { className: classes.text, children: text })] }));
};
export default Plug;
