import { ChildPhoneValidateErrorName, RoleEnum } from '@store/models/subscription/types';
export const checkAvailabilityError = (data) => {
    const subscribed = Boolean(data.ActiveRole);
    const unavailable = !data.IsSubscriptionAvailable;
    if (subscribed) {
        const subscribedError = data.ActiveRole === RoleEnum.Parent ? ChildPhoneValidateErrorName.SubscribedParent : ChildPhoneValidateErrorName.SubscribedChild;
        throw subscribedError;
    }
    if (unavailable) {
        throw ChildPhoneValidateErrorName.ChildAddUnavailable;
    }
};
