import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Page } from '@const';
const defaultConfig = {
    parent: {
        img: 'result-dialog-subscription-add.png',
        content: (_jsx(_Fragment, { children: "\u041F\u043E\u0437\u0434\u0440\u0430\u0432\u043B\u044F\u0435\u043C! \u0422\u0435\u043F\u0435\u0440\u044C \u0432\u0430\u0448\u0435\u043C\u0443 \u0420\u0435\u0431\u0451\u043D\u043A\u0443 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B \u0440\u0430\u0437\u0432\u0438\u0432\u0430\u044E\u0449\u0438\u0435 \u0438\u0433\u0440\u044B, \u0430 \u0442\u0430\u043A\u0436\u0435 \u0444\u0438\u043B\u044C\u043C\u044B \u0438 \u043C\u0443\u0437\u044B\u043A\u0430 \u043F\u043E \u0432\u043E\u0437\u0440\u0430\u0441\u0442\u0443. \u0410 \u0441 \u0433\u0435\u043E\u043B\u043E\u043A\u0430\u0446\u0438\u0435\u0439 \u0432\u044B \u0432\u0441\u0435\u0433\u0434\u0430 \u0431\u0443\u0434\u0435\u0442\u0435 \u0437\u043D\u0430\u0442\u044C, \u0433\u0434\u0435 \u0432\u0430\u0448 \u0420\u0435\u0431\u0451\u043D\u043E\u043A. \u0421\u043A\u043E\u0440\u043E \u043F\u0440\u0438\u0448\u043B\u0451\u043C SMS \u0441 \u0438\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u0435\u0439, \u043A\u0430\u043A \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u0441\u0435\u0440\u0432\u0438\u0441\u044B \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438" })),
        layoutType: 'parentFlow',
        mainPage: Page.Main,
    },
    child: {
        img: 'result-dialog-services.png',
        content: (_jsx(_Fragment, { children: "\u041F\u043E\u0437\u0434\u0440\u0430\u0432\u043B\u044F\u0435\u043C! \u0422\u0435\u043F\u0435\u0440\u044C \u0442\u0435\u0431\u0435 \u0434\u043E\u0441\u0442\u0443\u043F\u043D\u044B \u0440\u0430\u0437\u0432\u0438\u0432\u0430\u044E\u0449\u0438\u0435 \u0438\u0433\u0440\u044B, \u0430 \u0442\u0430\u043A\u0436\u0435 \u0444\u0438\u043B\u044C\u043C\u044B \u0438 \u043C\u0443\u0437\u044B\u043A\u0430 \u043F\u043E\u00A0\u0432\u043E\u0437\u0440\u0430\u0441\u0442\u0443. \u0421\u043A\u043E\u0440\u043E \u043F\u0440\u0438\u0448\u043B\u0451\u043C SMS \u0441 \u0438\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u0435\u0439, \u043A\u0430\u043A \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u0441\u0435\u0440\u0432\u0438\u0441\u044B \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438" })),
        layoutType: 'childFlow',
        mainPage: Page.Child,
    },
};
const renewConfig = {
    parent: {
        ...defaultConfig.parent,
        content: (_jsx(_Fragment, { children: "\u0421 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0435\u043D\u0438\u0435\u043C! \u041C\u044B \u043E\u0447\u0435\u043D\u044C \u0440\u0430\u0434\u044B, \u0447\u0442\u043E \u0432\u044B \u0441\u043D\u043E\u0432\u0430 \u0441 \u043D\u0430\u043C\u0438. \u0412\u0441\u0435 \u043D\u0430\u0448\u0438 \u0441\u0435\u0440\u0432\u0438\u0441\u044B \u0443\u0436\u0435 \u0436\u0434\u0443\u0442 \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u043E\u0439. \u0427\u0442\u043E\u0431\u044B \u0431\u044B\u043B\u043E \u043B\u0435\u0433\u0447\u0435 \u0441\u043D\u043E\u0432\u0430 \u0438\u0445 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C, \u0441\u043A\u043E\u0440\u043E \u043F\u0440\u0438\u0448\u043B\u0451\u043C SMS \u0441\u00A0\u0438\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u0435\u0439" })),
    },
    child: {
        ...defaultConfig.child,
        content: (_jsx(_Fragment, { children: "\u0421 \u0432\u043E\u0437\u0432\u0440\u0430\u0449\u0435\u043D\u0438\u0435\u043C! \u041C\u044B \u043E\u0447\u0435\u043D\u044C \u0440\u0430\u0434\u044B, \u0447\u0442\u043E \u0442\u044B \u0441\u043D\u043E\u0432\u0430 \u0441 \u043D\u0430\u043C\u0438. \u0412\u0441\u0435 \u043D\u0430\u0448\u0438 \u0441\u0435\u0440\u0432\u0438\u0441\u044B \u0443\u0436\u0435 \u0436\u0434\u0443\u0442 \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u043E\u0439. \u0427\u0442\u043E\u0431\u044B \u0431\u044B\u043B\u043E \u043B\u0435\u0433\u0447\u0435 \u0441\u043D\u043E\u0432\u0430 \u0438\u0445 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C, \u0441\u043A\u043E\u0440\u043E \u043F\u0440\u0438\u0448\u043B\u0451\u043C SMS \u0441\u00A0\u0438\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u0435\u0439" })),
    },
};
const prolongationConfig = {
    ...defaultConfig.parent,
    content: _jsx(_Fragment, { children: "\u041F\u0440\u043E\u0434\u043B\u0438\u043B\u0438 \u0432\u0430\u043C \u0438 \u0432\u0430\u0448\u0435\u043C\u0443 \u0420\u0435\u0431\u0451\u043D\u043A\u0443 \u0434\u043E\u0441\u0442\u0443\u043F\u044B \u043A \u0438\u0433\u0440\u0430\u043C, \u043C\u0443\u0437\u044B\u043A\u0435 \u0438 \u043C\u0443\u043B\u044C\u0442\u0444\u0438\u043B\u044C\u043C\u0430\u043C" }),
};
export const getContentConfig = ({ isRenewProcess, isProlongationProcess, version, }) => {
    const config = [
        {
            check: isRenewProcess,
            result: {
                title: 'Подписка успешно возобновлена',
                ...renewConfig[version],
            },
        },
        {
            check: isProlongationProcess,
            result: {
                title: 'Подписка успешно продлена',
                ...prolongationConfig,
            },
        },
    ];
    return config.find(({ check }) => check)?.result || { title: 'Подписка МТС Junior подключена', ...defaultConfig[version] };
};
