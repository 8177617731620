import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { slavesSelector } from '@store/models/user/selectors';
import { childAddSelector, childChangeAvailabilitySelector, childPhoneValidateErrorSelector, newChildPhoneSelector, requestChildStatusSelector, } from '@store/models/subscription/selectors';
import { useLocation, useNavigate } from 'react-router-dom';
import { Page } from '@const';
import Button from '@components/shared/Button/Button';
import ContentBlock from '../../ContentBlock/ContentBlock';
import Autocomplete from '@components/shared/Autocomplete/Autocomplete';
import { phonePlaceholder } from '@configs/common';
import { getCheckAvailabilityText } from '@configs/subscription';
import { phoneValidator } from '@utils/validators';
import { triggerMetricsEvent } from '@metrics';
import { getTitle } from '../utils/getTitle';
const EnterPhone = () => {
    const isChildChangeAvailable = useSelector(childChangeAvailabilitySelector);
    const slaves = useSelector(slavesSelector);
    const childPhone = useSelector(newChildPhoneSelector);
    const availabilityError = useSelector(childPhoneValidateErrorSelector);
    const { isConnection: isStatusChecking } = useSelector(requestChildStatusSelector);
    const { isConnection: isChildAdding } = useSelector(childAddSelector);
    const initialValue = childPhone || (slaves.length === 1 ? slaves[0].phone : '');
    const [inputValue, setInputValue] = useState(initialValue);
    const isPhoneValid = phoneValidator(inputValue);
    const isInputValid = !availabilityError;
    const isConnectDisabled = !isPhoneValid || Boolean(availabilityError);
    const isConnection = isStatusChecking || isChildAdding;
    const { search } = useLocation();
    const isPingoFlow = search.includes('pingo');
    const { subscriptionModel: { checkChildStatus, clearChildPhoneValidateError }, } = useTypedDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        clearChildPhoneValidateError();
    }, []);
    useEffect(() => {
        if (!isPhoneValid && availabilityError) {
            clearChildPhoneValidateError();
        }
        return () => {
            clearChildPhoneValidateError();
        };
    }, [inputValue]);
    const handleInputChange = (value) => {
        setInputValue(value);
    };
    const handleConnectClick = () => {
        triggerMetricsEvent({ element: 'subscribeChildButton' });
        checkChildStatus(inputValue);
    };
    const handleLaterClick = () => {
        const mainPage = isPingoFlow ? Page.Pingo : Page.Main;
        navigate(mainPage);
    };
    const title = getTitle(isChildChangeAvailable);
    const laterButtonText = isChildChangeAvailable ? 'Изменить позже' : 'Добавить позже';
    const content = (_jsxs(_Fragment, { children: [_jsx("span", { children: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u043E\u043C\u0435\u0440 \u0420\u0435\u0431\u0451\u043D\u043A\u0430, \u043A\u0442\u043E \u0431\u0443\u0434\u0435\u0442 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u043E\u0439" }), _jsx(Autocomplete, { label: "\u041D\u043E\u043C\u0435\u0440 \u0420\u0435\u0431\u0451\u043D\u043A\u0430", defaultValue: initialValue, placeholder: phonePlaceholder, isValid: isInputValid, variants: slaves, onChange: handleInputChange, message: getCheckAvailabilityText(inputValue, availabilityError), disabled: isConnection })] }));
    const actions = (_jsxs(_Fragment, { children: [_jsx(Button, { size: "md", variant: "primary", text: "\u041F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C", onClick: handleConnectClick, isLoading: isConnection, disabled: isConnectDisabled }), _jsx(Button, { size: "md", variant: "transparent", text: laterButtonText, onClick: handleLaterClick, disabled: isConnection })] }));
    return _jsx(ContentBlock, { withBackButton: true, title: title, content: content, actions: actions });
};
export default EnterPhone;
