export const serviceCardsConfig = {
    parent: {
        servicePingo: {
            ymID: 'pr_8d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'gde_moi_deti',
                actionGroup: 'interactions',
            },
        },
        serviceLogicLike: {
            ymID: 'pr_9d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'logiclike',
                actionGroup: 'interactions',
            },
        },
        serviceKion: {
            ymID: 'pr_10d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'kion',
                actionGroup: 'interactions',
            },
        },
        serviceMusic: {
            ymID: 'pr_11d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'mts_music',
                actionGroup: 'interactions',
            },
        },
        serviceCostSecurity: {
            ymID: 'pr_14d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'bezopasnost_rashodov',
                actionGroup: 'interactions',
            },
        },
        serviceSpamBlocking: {
            ymID: 'pr_15d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'zaschitnik',
                actionGroup: 'interactions',
            },
        },
        serviceUnlimitedMessengers: {
            ymID: 'pr_16d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'bazlimitnye_messengery',
                actionGroup: 'interactions',
            },
        },
        serviceUnlimitedSocial: {
            ymID: 'pr_17d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'bezlimitnye_soc_seti',
                actionGroup: 'interactions',
            },
        },
        serviceLeoni: {
            ymID: 'pr_leoni_1d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'leoni',
                actionGroup: 'interactions',
            },
        },
    },
    child: {
        serviceLogicLike: {
            ymID: 'ch_9d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'logiclike',
                actionGroup: 'interactions',
            },
        },
        serviceKion: {
            ymID: 'ch_10d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'kion',
                actionGroup: 'interactions',
            },
        },
        serviceMusic: {
            ymID: 'ch_11d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'mts_music',
                actionGroup: 'interactions',
            },
        },
        serviceCostSecurity: {
            ymID: 'ch_14d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'bezopasnost_rashodov',
                actionGroup: 'interactions',
            },
        },
        serviceSpamBlocking: {
            ymID: 'ch_15d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'zaschitnik',
                actionGroup: 'interactions',
            },
        },
        serviceUnlimitedMessengers: {
            ymID: 'ch_16d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'bazlimitnye_messengery',
                actionGroup: 'interactions',
            },
        },
        serviceUnlimitedSocial: {
            ymID: 'ch_17d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'bezlimitnye_soc_seti',
                actionGroup: 'interactions',
            },
        },
        serviceUnlimitedLikee: {
            ymID: 'ch_19d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'bezlimit_na_likee',
                actionGroup: 'interactions',
            },
        },
        serviceAlwaysInTouch: {
            ymID: 'ch_18d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'podrobnee',
                userAuth: '1',
                productName: 'vsegda_na_svyazi',
                actionGroup: 'interactions',
            },
        },
    },
};
