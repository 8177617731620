export const getLoadReducers = () => {
    return {
        startRequest(state, type) {
            return { ...state, loadStatus: { ...state.loadStatus, [type]: 'loading' } };
        },
        stopRequest(state, type) {
            return { ...state, loadStatus: { ...state.loadStatus, [type]: 'pending' } };
        },
        /*setRequestStatus(state: State, { loading, type }: { loading: LoadStatusName; type: RequestType }): State {
          return { ...state, loadStatus: { ...state.loadStatus, [type]: loading } };
        },*/
    };
};
