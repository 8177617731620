import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense } from 'react';
import '../global.scss';
import { store } from 'src/store/store';
import { LazyMain } from '@pages/Main/LazyMain';
import dayjs from 'dayjs';
import * as localeRu from 'dayjs/locale/ru';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Page } from '@const';
import { LazyNotFound } from '@pages/NotFound/LazyNotFound';
import { LazyInstruction } from '@pages/Instruction/LazyInstruction';
import ScrollToTop from '@shared/ScrollToTop/ScrollToTop';
import { LazyPingo } from '@promo-pages/Pingo/LazyPingo';
import { LazyUserStory } from '@pages/promo/Pingo/UserStory/LazyUserStory';
import CreateSubscription from './pages/old-subscription/CreateSubscription/CreateSubscription';
import AddChild from './pages/old-subscription/AddChild/AddChild';
import SuccessAdd from './pages/old-subscription/SuccessAdd/SuccessAdd';
import { LazySettings } from '@pages/old-subscription/Settings/LazySettings';
import { LazyPromocode } from '@pages/old-subscription/Promocode/LazyPromocode';
import ChildInfo from './pages/old-subscription/ChildInfo/ChildInfo';
import UpdateSubscription from './pages/old-subscription/UpdateSubscription/UpdateSubscription';
import Loading from '@pages/Main/Loading/Loading';
import { useCheckSurvey } from '@hooks/useCheckSurvey';
store.dispatch.userModel.getAppData();
dayjs.locale(localeRu);
const App = () => {
    useCheckSurvey();
    return (_jsx(Suspense, { fallback: _jsx(Loading, {}), children: _jsx(ScrollToTop, { children: _jsxs(Routes, { children: [_jsx(Route, { index: true, element: _jsx(LazyMain, {}) }), _jsx(Route, { path: Page.Child, element: _jsx(LazyMain, {}) }), _jsx(Route, { path: Page.NotFound, element: _jsx(LazyNotFound, {}) }), _jsx(Route, { path: Page.Instruction, element: _jsx(LazyInstruction, {}) }), _jsx(Route, { path: Page.ChildInstruction, element: _jsx(LazyInstruction, {}) }), _jsx(Route, { path: Page.Pingo, element: _jsx(LazyPingo, {}) }), _jsx(Route, { path: Page.PingoStory, element: _jsx(LazyUserStory, {}) }), _jsx(Route, { path: Page.CreateSubscription, element: _jsx(CreateSubscription, {}) }), _jsx(Route, { path: Page.CreateChildSubscription, element: _jsx(CreateSubscription, {}) }), _jsx(Route, { path: Page.UpdateSubscription, element: _jsx(UpdateSubscription, {}) }), _jsx(Route, { path: Page.AddChild, element: _jsx(AddChild, {}) }), _jsx(Route, { path: Page.ChangeChild, element: _jsx(AddChild, { isChangeFlow: true }) }), _jsx(Route, { path: Page.ChildInfo, element: _jsx(ChildInfo, {}) }), _jsx(Route, { path: Page.SuccessChildAdd, element: _jsx(SuccessAdd, {}) }), _jsx(Route, { path: Page.SuccessParentAdd, element: _jsx(SuccessAdd, {}) }), _jsx(Route, { path: Page.SuccessUpdate, element: _jsx(SuccessAdd, { isProlongation: true }) }), _jsx(Route, { path: Page.ParentSettings, element: _jsx(LazySettings, {}) }), _jsx(Route, { path: Page.Promocode, element: _jsx(LazyPromocode, {}) }), _jsx(Route, { path: '*', element: _jsx(Navigate, { to: Page.NotFound, replace: true }) })] }) }) }));
};
export default App;
