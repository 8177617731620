import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Link from '@shared/Link/Link';
import { store } from '@store';
import { RoleEnum } from '@models/subscription/types';
import { getMaskedPhone } from '@utils/masks';
import { Page, tariffInfoLink } from '@const';
import { formatDate } from '@utils/date';
const handleChangePhone = () => {
    store.dispatch.userModel.logOut('changeUser');
    store.dispatch.dialogModel.closeDialog();
};
const handleChildChange = () => {
    if (!window.location.href.includes(Page.AddChild)) {
        window.location.href = Page.AddChild;
        return;
    }
    store.dispatch.dialogModel.closeDialog();
    store.dispatch.subscriptionModel.setAddChildStage('enterNumber');
};
const getDefaultError = ({ onRetryClick, isChildFlow }) => ({
    title: 'Что-то не так',
    description: isChildFlow
        ? 'Повтори попытку или позови родителей, чтобы они помогли обратиться в поддержку — специалисты помогут'
        : 'Повторите попытку или обратитесь в поддержку — специалисты вам помогут',
    primaryAction: {
        text: 'Повторить',
        onClick: () => {
            store.dispatch.dialogModel.closeDialog();
            onRetryClick && onRetryClick();
        },
    },
});
const parentErrors = {
    checkAvailabilityError: () => getDefaultError({
        onRetryClick: () => {
            store.dispatch.subscriptionModel.getTariffPlans({ role: RoleEnum.Parent, requestType: 'buy' });
        },
    }),
    subscriptionNotAnavailable: ({ ownerPhone }) => ({
        title: 'На номере Родителя подписка недоступна',
        description: (_jsxs(_Fragment, { children: ["\u041D\u0430 \u043D\u043E\u043C\u0435\u0440\u0435 ", _jsx("span", { className: "text-medium noWrap", children: getMaskedPhone(ownerPhone) }), " \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0430 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u0430. \u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0430\u0432\u0442\u043E\u0440\u0438\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u043F\u043E\u0434 \u0434\u0440\u0443\u0433\u0438\u043C \u043D\u043E\u043C\u0435\u0440\u043E\u043C \u0434\u043B\u044F \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u044F \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438"] })),
        primaryAction: {
            text: 'Войти под другим номером',
            onClick: handleChangePhone,
        },
    }),
    createError: () => getDefaultError({
        onRetryClick: () => {
            store.dispatch.subscriptionModel.createSubscription({ role: RoleEnum.Parent });
        },
    }),
    paymentError: () => ({
        title: 'Не удалось списать оплату',
        description: 'Пополните счёт или смените способ оплаты. Если остались вопросы, обратитесь в поддержку — специалисты вам помогут',
        primaryAction: {
            text: 'Сменить способ оплаты',
            onClick: () => {
                store.dispatch.subscriptionModel.changePaymentTool({});
            },
        },
    }),
    contentForbiddenForTP: ({ ownerPhone }) => ({
        title: 'Не удалось подключить подписку МТС Junior',
        description: (_jsxs(_Fragment, { children: ["\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0441\u043C\u0435\u043D\u0438\u0442\u044C", ' ', _jsx(Link, { href: tariffInfoLink, type: "primary", children: "\u0442\u0430\u0440\u0438\u0444" }), ' ', "\u043D\u0430 \u043D\u043E\u043C\u0435\u0440\u0435 ", _jsx("span", { className: "text-medium noWrap", children: getMaskedPhone(ownerPhone) }), " \u0438\u043B\u0438 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443 \u043D\u0430 \u0434\u0440\u0443\u0433\u043E\u043C \u043D\u043E\u043C\u0435\u0440\u0435"] })),
        primaryAction: {
            text: 'Войти под другим номером',
            onClick: handleChangePhone,
        },
    }),
    contentBlockActivated: ({ ownerPhone }) => ({
        title: 'Не удалось подключить подписку МТС Junior',
        description: (_jsxs(_Fragment, { children: ["\u041D\u0430 \u043D\u043E\u043C\u0435\u0440\u0435 ", _jsx("span", { className: "text-medium noWrap", children: getMaskedPhone(ownerPhone) }), " \u0435\u0441\u0442\u044C \u043E\u0433\u0440\u0430\u043D\u0438\u0447\u0435\u043D\u0438\u0435 \u0440\u0430\u0437\u0432\u043B\u0435\u043A\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0445 \u0441\u0435\u0440\u0432\u0438\u0441\u043E\u0432. \u041E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443 \u2014 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u044B \u0432\u0430\u043C \u043F\u043E\u043C\u043E\u0433\u0443\u0442"] })),
        primaryAction: {
            text: 'Войти под другим номером',
            onClick: handleChangePhone,
        },
    }),
    forbiddenOtherContent: ({ ownerPhone }) => ({
        title: 'Не удалось подключить подписку МТС Junior',
        description: (_jsxs(_Fragment, { children: ["\u041D\u0430 \u043D\u043E\u043C\u0435\u0440\u0435 ", _jsx("span", { className: "text-medium noWrap", children: getMaskedPhone(ownerPhone) }), " \u0435\u0441\u0442\u044C \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0430 \u043D\u0430 \u0432\u0437\u0430\u0438\u043C\u043E\u0438\u0441\u043A\u043B\u044E\u0447\u0430\u044E\u0449\u0438\u0439 \u043A\u043E\u043D\u0442\u0435\u043D\u0442. \u041E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443 \u2014 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u044B \u0432\u0430\u043C \u043F\u043E\u043C\u043E\u0433\u0443\u0442"] })),
        primaryAction: {
            text: 'Войти под другим номером',
            onClick: handleChangePhone,
        },
    }),
    defaultError: () => ({
        ...getDefaultError({}),
        primaryAction: {
            text: 'На главную страницу',
            onClick: () => {
                window.location.href = Page.Main;
            },
        },
    }),
    defaultChildError: () => ({
        title: 'Что-то пошло не так',
        description: 'Не удалось добавить Ребёнка, обратитесь в поддержку — специалисты вам помогут',
        secondaryAction: {
            text: 'Изменить номер Ребёнка',
            onClick: handleChildChange,
        },
    }),
    contentForbiddenForTPChild: ({ userPhone = '' }) => ({
        title: 'Не удалось подключить подписку МТС Junior Ребёнку',
        description: (_jsxs(_Fragment, { children: ["\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0441\u043C\u0435\u043D\u0438\u0442\u044C", ' ', _jsx(Link, { href: tariffInfoLink, type: "primary", children: "\u0442\u0430\u0440\u0438\u0444" }), ' ', "\u043D\u0430 \u043D\u043E\u043C\u0435\u0440\u0435 \u0420\u0435\u0431\u0451\u043D\u043A\u0430 ", _jsx("span", { className: "text-medium noWrap", children: getMaskedPhone(userPhone) }), " \u0438\u043B\u0438 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443 \u043D\u0430 \u0434\u0440\u0443\u0433\u043E\u0439 \u043D\u043E\u043C\u0435\u0440"] })),
        secondaryAction: {
            text: 'Изменить номер Ребёнка',
            onClick: handleChildChange,
        },
    }),
    contentBlockActivatedChild: ({ userPhone = '' }) => ({
        title: 'Не удалось подключить подписку МТС Junior Ребёнку',
        description: (_jsxs(_Fragment, { children: ["\u041D\u0430 \u043D\u043E\u043C\u0435\u0440\u0435 ", _jsx("span", { className: "text-medium noWrap", children: getMaskedPhone(userPhone) }), " \u0435\u0441\u0442\u044C \u043E\u0433\u0440\u0430\u043D\u0438\u0447\u0435\u043D\u0438\u0435 \u0440\u0430\u0437\u0432\u043B\u0435\u043A\u0430\u0442\u0435\u043B\u044C\u043D\u044B\u0445 \u0441\u0435\u0440\u0432\u0438\u0441\u043E\u0432. \u041E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443 \u2014 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u044B \u0432\u0430\u043C \u043F\u043E\u043C\u043E\u0433\u0443\u0442"] })),
        secondaryAction: {
            text: 'Изменить номер Ребёнка',
            onClick: handleChildChange,
        },
    }),
    subscriptionAlreadyExistsChild: ({ userPhone = '' }) => ({
        title: 'Подписка МТС Junior уже подключена Ребёнку',
        description: (_jsxs(_Fragment, { children: ["\u041F\u043E\u0434\u043F\u0438\u0441\u043A\u0430 \u0443\u0436\u0435 \u0430\u043A\u0442\u0438\u0432\u0438\u0440\u043E\u0432\u0430\u043D\u0430 \u043D\u0430\u00A0\u043D\u043E\u043C\u0435\u0440\u0435 ", _jsx("span", { className: "text-medium noWrap", children: getMaskedPhone(userPhone) }), ". \u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0443\u043A\u0430\u0437\u0430\u0442\u044C \u0434\u0440\u0443\u0433\u043E\u0439 \u043D\u043E\u043C\u0435\u0440 \u0434\u043B\u044F\u00A0\u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u044F \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438 \u0438\u043B\u0438 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u044C\u0441\u044F \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443 \u2014 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u044B \u0432\u0430\u043C \u043F\u043E\u043C\u043E\u0433\u0443\u0442"] })),
        secondaryAction: {
            text: 'Изменить номер Ребёнка',
            onClick: handleChildChange,
        },
    }),
    subscriptionAlreadyExistsParent: ({ userPhone = '' }) => ({
        title: 'Подписка МТС Junior уже подключена Родителю',
        description: (_jsxs(_Fragment, { children: ["\u041F\u043E\u0434\u043F\u0438\u0441\u043A\u0430 \u0443\u0436\u0435 \u0430\u043A\u0442\u0438\u0432\u0438\u0440\u043E\u0432\u0430\u043D\u0430 \u043D\u0430\u00A0\u043D\u043E\u043C\u0435\u0440\u0435 ", _jsx("span", { className: "text-medium noWrap", children: getMaskedPhone(userPhone) }), ". \u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u0443\u043A\u0430\u0437\u0430\u0442\u044C \u0434\u0440\u0443\u0433\u043E\u0439 \u043D\u043E\u043C\u0435\u0440 \u0434\u043B\u044F\u00A0\u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u044F \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438 \u0438\u043B\u0438 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u044C\u0441\u044F \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443 \u2014 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u044B \u0432\u0430\u043C \u043F\u043E\u043C\u043E\u0433\u0443\u0442"] })),
        secondaryAction: {
            text: 'Изменить номер Ребёнка',
            onClick: handleChildChange,
        },
    }),
    promocodeAlreadyApplied: ({ codeExpireDate }) => ({
        title: 'Промокод уже действует',
        description: (_jsxs(_Fragment, { children: ["\u0415\u0441\u043B\u0438 \u0432\u044B \u0440\u0430\u043D\u0435\u0435 \u0430\u043A\u0442\u0438\u0432\u0438\u0440\u043E\u0432\u0430\u043B\u0438 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434, \u0434\u043E\u0436\u0434\u0438\u0442\u0435\u0441\u044C, \u043F\u043E\u043A\u0430 \u0435\u0433\u043E \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u0437\u0430\u043A\u043E\u043D\u0447\u0438\u0442\u0441\u044F, \u0437\u0430\u0442\u0435\u043C \u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u043E\u0432\u044B\u0439 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434. \u0412\u0432\u0435\u0441\u0442\u0438 \u043C\u043E\u0436\u043D\u043E \u0431\u0443\u0434\u0435\u0442", ' ', formatDate({
                    date: codeExpireDate || '',
                    dateFormat: 'DD.MM.YYYY',
                })] })),
        primaryAction: {
            text: 'На Главную',
            onClick: () => {
                window.location.href = Page.Main;
            },
        },
        onClose: () => {
            window.location.href = Page.Main;
        },
    }),
};
const childErrors = {
    checkAvailabilityError: () => getDefaultError({
        onRetryClick: () => {
            store.dispatch.subscriptionModel.getTariffPlans({ role: RoleEnum.Parent, requestType: 'buy' });
        },
        isChildFlow: true,
    }),
    subscriptionNotAnavailable: ({ ownerPhone = '' }) => ({
        title: 'На номере Ребёнка подписка недоступна',
        description: (_jsx(_Fragment, { children: "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439 \u0432\u043E\u0439\u0442\u0438 \u0438 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443 \u043D\u0430 \u0434\u0440\u0443\u0433\u043E\u0439 \u043D\u043E\u043C\u0435\u0440 \u0438\u043B\u0438 \u043F\u043E\u0437\u043E\u0432\u0438 \u0440\u043E\u0434\u0438\u0442\u0435\u043B\u0435\u0439, \u0447\u0442\u043E\u0431\u044B \u043E\u043D\u0438 \u043F\u043E\u043C\u043E\u0433\u043B\u0438 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u044C\u0441\u044F \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443 \u2014 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u044B \u043F\u043E\u043C\u043E\u0433\u0443\u0442" })),
        primaryAction: {
            text: 'Войти под другим номером',
            onClick: handleChangePhone,
        },
    }),
    createError: () => getDefaultError({
        onRetryClick: () => {
            store.dispatch.subscriptionModel.createSubscription({ role: RoleEnum.Parent });
        },
        isChildFlow: true,
    }),
    paymentError: () => ({
        title: 'Не удалось списать оплату ',
        description: 'Если остались вопросы позови родителей, чтобы они помогли обратиться в поддержку — специалисты помогут',
        primaryAction: {
            text: 'Повторить',
            onClick: () => {
                store.dispatch.subscriptionModel.changePaymentTool({});
            },
        },
    }),
    contentForbiddenForTP: ({ ownerPhone }) => ({
        title: 'Не удалось подключить подписку МТС Junior',
        description: (_jsxs(_Fragment, { children: ["\u041F\u043E\u0437\u043E\u0432\u0438 \u0440\u043E\u0434\u0438\u0442\u0435\u043B\u0435\u0439, \u0447\u0442\u043E\u0431\u044B \u043E\u043D\u0438 \u043F\u043E\u043C\u043E\u0433\u043B\u0438 \u043F\u043E\u043F\u0440\u043E\u0431\u043E\u0432\u0430\u0442\u044C \u0441\u043C\u0435\u043D\u0438\u0442\u044C", ' ', _jsx(Link, { href: tariffInfoLink, type: "primary", children: "\u0442\u0430\u0440\u0438\u0444" }), ' ', "\u043D\u0430\u00A0\u043D\u043E\u043C\u0435\u0440\u0435 ", _jsx("span", { className: "text-medium noWrap", children: getMaskedPhone(ownerPhone) }), " \u0438\u043B\u0438 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443 \u043D\u0430\u00A0\u0434\u0440\u0443\u0433\u043E\u043C \u043D\u043E\u043C\u0435\u0440\u0435"] })),
        primaryAction: {
            text: 'Войти под другим номером',
            onClick: handleChangePhone,
        },
    }),
    contentBlockActivated: ({ ownerPhone }) => ({
        title: 'Не удалось подключить подписку МТС Junior',
        description: (_jsxs(_Fragment, { children: ["\u041D\u0430 \u043D\u043E\u043C\u0435\u0440\u0435 ", _jsx("span", { className: "text-medium noWrap", children: getMaskedPhone(ownerPhone) }), " \u0435\u0441\u0442\u044C \u043E\u0433\u0440\u0430\u043D\u0438\u0447\u0435\u043D\u0438\u0435. \u041F\u043E\u0437\u043E\u0432\u0438 \u0440\u043E\u0434\u0438\u0442\u0435\u043B\u0435\u0439, \u0447\u0442\u043E\u0431\u044B \u043E\u043D\u0438 \u043F\u043E\u043C\u043E\u0433\u043B\u0438 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u044C\u0441\u044F \u0432\u00A0\u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443 \u2014 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u044B \u043F\u043E\u043C\u043E\u0433\u0443\u0442"] })),
        primaryAction: {
            text: 'Войти под другим номером',
            onClick: handleChangePhone,
        },
    }),
    forbiddenOtherContent: ({ ownerPhone }) => ({
        title: 'Не удалось подключить подписку МТС Junior',
        description: (_jsxs(_Fragment, { children: ["\u041D\u0430 \u043D\u043E\u043C\u0435\u0440\u0435 ", _jsx("span", { className: "text-medium noWrap", children: getMaskedPhone(ownerPhone) }), " \u0435\u0441\u0442\u044C \u043E\u0433\u0440\u0430\u043D\u0438\u0447\u0435\u043D\u0438\u0435. \u041F\u043E\u0437\u043E\u0432\u0438 \u0440\u043E\u0434\u0438\u0442\u0435\u043B\u0435\u0439, \u0447\u0442\u043E\u0431\u044B \u043E\u043D\u0438 \u043F\u043E\u043C\u043E\u0433\u043B\u0438 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u044C\u0441\u044F \u0432\u00A0\u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443 \u2014 \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u044B \u043F\u043E\u043C\u043E\u0433\u0443\u0442"] })),
        primaryAction: {
            text: 'Войти под другим номером',
            onClick: handleChangePhone,
        },
    }),
    defaultError: () => ({
        ...getDefaultError({ isChildFlow: true }),
        primaryAction: {
            text: 'На главную страницу',
            onClick: () => {
                window.location.href = Page.Child;
            },
        },
    }),
};
export const errorConfig = {
    [RoleEnum.Parent]: parentErrors,
    [RoleEnum.Child]: childErrors,
};
export const getChangeTariffPlanError = (newPlanName) => ({
    ...getDefaultError({
        onRetryClick: () => {
            store.dispatch.subscriptionModel.changeSubscriptionTariffPlan({ newPlanName });
        },
    }),
});
