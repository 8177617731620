import { PaymentTypeEnum, SubscriptionStatusChildEnum, SubscriptionStatusParentEnum, } from '@models/subscription/types';
import { transformChildren } from '@store/utils/transformChildren';
import { defaultSubscription } from '@models/subscription';
import { PromocodeStatusEnum } from '@store/api/promocode/types';
import { subscriptionDefaultPrice } from '@store/models/const';
const getStatuses = (subscription) => {
    const parentStatus = subscription?.SubscriptionStatusParent?.toLowerCase();
    const childStatus = subscription?.SubscriptionStatusChild?.toLowerCase();
    return {
        parentDraft: parentStatus === 'draft',
        parentActive: parentStatus === 'active',
        parentAdding: parentStatus === 'adding' || parentStatus === 'creating' || parentStatus === 'updating',
        parentCanceled: ['canceled', 'deleting'].includes(parentStatus),
        parentFailed: parentStatus === 'failed',
        parentSuspended: parentStatus === 'suspended',
        childActive: childStatus === 'active',
        childAdding: childStatus === 'adding' || childStatus === 'creating' || childStatus === 'updating',
        childCanceled: ['canceled', 'deleting'].includes(childStatus),
        childSuspended: childStatus === 'suspended',
        childDraft: childStatus === 'draft',
        childFailed: childStatus === 'failed',
    };
};
const getParentSubscriptionStatus = (statuses) => {
    const { parentDraft, parentAdding, parentActive, parentCanceled, parentSuspended, parentFailed, childAdding, childActive, childCanceled, childDraft, childFailed, } = statuses;
    const statusMap = [
        {
            check: parentDraft,
            result: SubscriptionStatusParentEnum.Draft,
        },
        {
            check: parentSuspended,
            result: SubscriptionStatusParentEnum.Suspended,
        },
        {
            check: parentActive && (childDraft || childFailed || childCanceled),
            result: SubscriptionStatusParentEnum.ChildAdd,
        },
        {
            check: parentActive && childActive,
            result: SubscriptionStatusParentEnum.Active,
        },
        {
            check: parentFailed,
            result: SubscriptionStatusParentEnum.Failed,
        },
        {
            check: parentCanceled,
            result: SubscriptionStatusParentEnum.Canceled,
        },
        {
            check: parentAdding || childAdding,
            result: SubscriptionStatusParentEnum.Adding,
        },
    ];
    return statusMap.find(({ check }) => check)?.result;
};
const getChildSubscriptionStatus = (statuses) => {
    const { childAdding, childActive, childSuspended, childDraft, childCanceled, childFailed } = statuses;
    const statusMap = [
        {
            check: childDraft,
            result: SubscriptionStatusChildEnum.Draft,
        },
        {
            check: childSuspended,
            result: SubscriptionStatusChildEnum.Suspended,
        },
        {
            check: childActive,
            result: SubscriptionStatusChildEnum.Active,
        },
        {
            check: childFailed,
            result: SubscriptionStatusChildEnum.Failed,
        },
        {
            check: childCanceled,
            result: SubscriptionStatusChildEnum.Canceled,
        },
        {
            check: childAdding,
            result: SubscriptionStatusChildEnum.Adding,
        },
    ];
    return statusMap.find(({ check }) => check)?.result;
};
const getPromocodeStatus = (promocodeStatus) => {
    return promocodeStatus === PromocodeStatusEnum.Active || promocodeStatus === PromocodeStatusEnum.Unused ? 'active' : 'notActive';
};
export const transformKionStatus = (status) => {
    return status?.toLowerCase();
};
export const transformSubscription = (subscription, slaves) => {
    if (!subscription) {
        return defaultSubscription;
    }
    const origin = subscription?.SubscriptionOrigin;
    const statuses = getStatuses(subscription);
    const parentStatus = subscription?.SubscriptionStatusParent?.toLowerCase();
    const childStatus = subscription?.SubscriptionStatusChild?.toLowerCase();
    const kionStatus = transformKionStatus(subscription?.KionStatus);
    const parentHasChild = subscription?.MSISDNChild && subscription?.MSISDNParent && childStatus !== SubscriptionStatusChildEnum.Draft;
    const children = parentHasChild ? transformChildren([subscription?.MSISDNChild], slaves) : [];
    return {
        promoCode: '',
        remainingDays: subscription?.IsTrial ? subscription?.TrialPeriodDays : subscription?.PaidPeriodDays,
        isTrialAvailable: subscription?.IsTrial,
        subscriptionId: subscription?.ID,
        contentIDParent: subscription.ContentIDParent || '',
        contentIDChild: subscription.ContentIDChild || '',
        price: subscription?.Price ?? subscriptionDefaultPrice,
        subscriptionStatusParent: getParentSubscriptionStatus(statuses) || parentStatus,
        subscriptionStatusChild: getChildSubscriptionStatus(statuses) || childStatus,
        kionStatus,
        tarifficationStatus: subscription?.TarifficationStatus?.toLowerCase(),
        nextTarifficationDate: subscription?.NextTarifficationDate,
        bindingTitle: subscription?.EWalletBindingTitle,
        subscriptionDate: subscription?.SubscriptionDate,
        paymentTokenStatus: subscription?.PaymentTokenStatus?.toLowerCase(),
        isMTSSubscriber: subscription?.IsMTSSubscriberChild,
        children,
        paymentType: subscription?.PaymentType !== PaymentTypeEnum.Undefined ? subscription?.PaymentType : undefined,
        promocodeStatus: getPromocodeStatus(subscription.PromoCodeStatus),
        origin,
        msisdnParent: subscription?.MSISDNParent,
        msisdnChild: subscription?.MSISDNChild,
        tariffPlan: subscription?.TariffPlanType,
        promoCodePeriodDays: subscription?.PromoCodePeriodDays,
    };
};
