import { Fragment as _Fragment, jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import classes from './Button.module.scss';
import Spinner from '@shared/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { isSafariSelector } from '@store/models/user/selectors';
const spinnerConfig = {
    primary: 'white',
    secondary: 'black',
    negative: 'red',
    inverted: 'white',
    alwaysWhite: 'black',
    white: 'black',
    transparent: 'black',
    alwaysSecondary: 'black',
    black: 'white',
    alwaysBlack: 'white',
    violet: 'white',
};
const Button = ({ variant, size, text, disabled, full, className, onClick, icon, id, stopBubbling, isLoading, link, onOtherPage, }) => {
    const onlyIcon = icon && !text;
    const isSafari = useSelector(isSafariSelector);
    const btnClasses = classNames(classes.button, classes[size], classes[variant], className, {
        [classes.full]: full,
        [classes.onlyIcon]: onlyIcon,
        [classes.withIcon]: icon,
        [classes.loading]: isLoading,
        [classes.safari]: isSafari,
    });
    const handleClick = ev => {
        if (stopBubbling) {
            ev.stopPropagation();
        }
        if (link?.length) {
            onOtherPage ? window.open(link, '_blank') : (window.location.href = link);
        }
        onClick && !isLoading && onClick(ev);
    };
    const getContent = () => {
        if (onlyIcon) {
            return icon;
        }
        return (_jsxs(_Fragment, { children: [icon && icon, text] }));
    };
    return (_jsxs("button", { id: id, className: btnClasses, disabled: disabled, onClick: handleClick, children: [isLoading && _jsx(Spinner, { className: classes.spinner, color: spinnerConfig[variant], size: "md" }), getContent()] }));
};
export default Button;
