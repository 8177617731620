export const settingsPageConfig = {
    parentAuth: {
        settingsPage: {
            ymID: 'pr_settings',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'popup_show',
                eventLabel: 'upravlyat_podpiskoi',
                userAuth: '1',
                actionGroup: 'non_interactions',
            },
        },
    },
};
