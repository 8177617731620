/* tslint:disable */
/* eslint-disable */
/**
 * Swagger MTS Junior API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 10.0.5
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError } from '../base';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * activate promocode
         * @summary activate promocode
         * @param {LandingActivatePromoCodeData} body activate promocode data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePromoCode: async (body, options = {}) => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling activatePromoCode.');
            }
            const localVarPath = `/api/v2/authorized/promocode/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * add child to subscription
         * @summary add child to subscription
         * @param {LandingAddChildDataV2} body add child data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChildV2: async (body, options = {}) => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling addChildV2.');
            }
            const localVarPath = `/api/v2/authorized/subscription/child/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * cancel subscription
         * @summary cancel subscription
         * @param {LandingCancelSubscriptionDataV2} body cancel subscription data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscriptionV2: async (body, options = {}) => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling cancelSubscriptionV2.');
            }
            const localVarPath = `/api/v2/authorized/subscription/cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * create subscription or change tariff plan if exists
         * @summary create subscription or change tariff plan if exists
         * @param {LandingCreateSubscriptionDataV2} body create subscription data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionV2: async (body, options = {}) => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling createSubscriptionV2.');
            }
            const localVarPath = `/api/v2/authorized/subscription/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * get parent invite
         * @summary get parent invite
         * @param {string} id invite id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentInviteV2: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getParentInviteV2.');
            }
            const localVarPath = `/api/v2/unauthorized/invite/parent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * get subscription status child
         * @summary get subscription status child
         * @param {string} msisdn child msisdn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionStatusChildV2: async (msisdn, options = {}) => {
            // verify required parameter 'msisdn' is not null or undefined
            if (msisdn === null || msisdn === undefined) {
                throw new RequiredError('msisdn', 'Required parameter msisdn was null or undefined when calling getSubscriptionStatusChildV2.');
            }
            const localVarPath = `/api/v2/authorized/subscription/status/child`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (msisdn !== undefined) {
                localVarQueryParameter['msisdn'] = msisdn;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * get subscription by id
         * @summary get subscription by id
         * @param {string} id subscription id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionV2: async (id, options = {}) => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id', 'Required parameter id was null or undefined when calling getSubscriptionV2.');
            }
            const localVarPath = `/api/v2/authorized/subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * get tariff plans
         * @summary get tariff plans
         * @param {string} role role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffPlans: async (role, options = {}) => {
            // verify required parameter 'role' is not null or undefined
            if (role === null || role === undefined) {
                throw new RequiredError('role', 'Required parameter role was null or undefined when calling getTariffPlans.');
            }
            const localVarPath = `/api/v2/authorized/tariff-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * get user info
         * @summary get user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoV2: async (options = {}) => {
            const localVarPath = `/api/v2/authorized/userinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * send parent invite
         * @summary send parent invite
         * @param {string} msisdnParent parent msisdn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendParentInviteV2: async (msisdnParent, options = {}) => {
            // verify required parameter 'msisdnParent' is not null or undefined
            if (msisdnParent === null || msisdnParent === undefined) {
                throw new RequiredError('msisdnParent', 'Required parameter msisdnParent was null or undefined when calling sendParentInviteV2.');
            }
            const localVarPath = `/api/v2/authorized/invite/parent/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (msisdnParent !== undefined) {
                localVarQueryParameter['msisdnParent'] = msisdnParent;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * update payment tool
         * @summary update payment tool
         * @param {LandingUpdatePaymentToolDataV2} body subscription id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentToolV2: async (body, options = {}) => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling updatePaymentToolV2.');
            }
            const localVarPath = `/api/v2/authorized/subscription/paymenttool/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * validate promocode
         * @summary validate promocode
         * @param {LandingValidatePromoCodeData} body validate promocode data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePromoCode: async (body, options = {}) => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body', 'Required parameter body was null or undefined when calling validatePromoCode.');
            }
            const localVarPath = `/api/v2/authorized/promocode/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data = needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * websso authorize redirect
         * @summary websso authorize redirect
         * @param {string} [theme] my mts theme
         * @param {string} [msisdn] login hint
         * @param {string} [mymts_brws] my mts webview
         * @param {string} [source] sub source
         * @param {string} [slave_id] websso slave id
         * @param {string} [redirect_url] redirect url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webSSOAuthorizeV2: async (theme, msisdn, mymts_brws, source, slave_id, redirect_url, options = {}) => {
            const localVarPath = `/api/v2/redirect/websso/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (theme !== undefined) {
                localVarQueryParameter['theme'] = theme;
            }
            if (msisdn !== undefined) {
                localVarQueryParameter['msisdn'] = msisdn;
            }
            if (mymts_brws !== undefined) {
                localVarQueryParameter['mymts_brws'] = mymts_brws;
            }
            if (source !== undefined) {
                localVarQueryParameter['source'] = source;
            }
            if (slave_id !== undefined) {
                localVarQueryParameter['slave_id'] = slave_id;
            }
            if (redirect_url !== undefined) {
                localVarQueryParameter['redirect_url'] = redirect_url;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * websso callback
         * @summary websso callback
         * @param {string} code oauth2 code
         * @param {string} state oauth2 state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webSSOCallbackV2: async (code, state, options = {}) => {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code', 'Required parameter code was null or undefined when calling webSSOCallbackV2.');
            }
            // verify required parameter 'state' is not null or undefined
            if (state === null || state === undefined) {
                throw new RequiredError('state', 'Required parameter state was null or undefined when calling webSSOCallbackV2.');
            }
            const localVarPath = `/api/v2/redirect/websso/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }
            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * websso logout redirect
         * @summary websso logout redirect
         * @param {string} [redirect_url] redirect url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webSSOLogoutV2: async (redirect_url, options = {}) => {
            const localVarPath = `/api/v2/redirect/websso/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (redirect_url !== undefined) {
                localVarQueryParameter['redirect_url'] = redirect_url;
            }
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration) {
    return {
        /**
         * activate promocode
         * @summary activate promocode
         * @param {LandingActivatePromoCodeData} body activate promocode data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activatePromoCode(body, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).activatePromoCode(body, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * add child to subscription
         * @summary add child to subscription
         * @param {LandingAddChildDataV2} body add child data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addChildV2(body, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).addChildV2(body, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * cancel subscription
         * @summary cancel subscription
         * @param {LandingCancelSubscriptionDataV2} body cancel subscription data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelSubscriptionV2(body, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cancelSubscriptionV2(body, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * create subscription or change tariff plan if exists
         * @summary create subscription or change tariff plan if exists
         * @param {LandingCreateSubscriptionDataV2} body create subscription data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscriptionV2(body, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).createSubscriptionV2(body, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get parent invite
         * @summary get parent invite
         * @param {string} id invite id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParentInviteV2(id, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getParentInviteV2(id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get subscription status child
         * @summary get subscription status child
         * @param {string} msisdn child msisdn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionStatusChildV2(msisdn, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getSubscriptionStatusChildV2(msisdn, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get subscription by id
         * @summary get subscription by id
         * @param {string} id subscription id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionV2(id, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getSubscriptionV2(id, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get tariff plans
         * @summary get tariff plans
         * @param {string} role role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTariffPlans(role, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTariffPlans(role, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * get user info
         * @summary get user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfoV2(options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getUserInfoV2(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * send parent invite
         * @summary send parent invite
         * @param {string} msisdnParent parent msisdn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendParentInviteV2(msisdnParent, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).sendParentInviteV2(msisdnParent, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * update payment tool
         * @summary update payment tool
         * @param {LandingUpdatePaymentToolDataV2} body subscription id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePaymentToolV2(body, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).updatePaymentToolV2(body, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * validate promocode
         * @summary validate promocode
         * @param {LandingValidatePromoCodeData} body validate promocode data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validatePromoCode(body, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).validatePromoCode(body, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * websso authorize redirect
         * @summary websso authorize redirect
         * @param {string} [theme] my mts theme
         * @param {string} [msisdn] login hint
         * @param {string} [mymts_brws] my mts webview
         * @param {string} [source] sub source
         * @param {string} [slave_id] websso slave id
         * @param {string} [redirect_url] redirect url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webSSOAuthorizeV2(theme, msisdn, mymts_brws, source, slave_id, redirect_url, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).webSSOAuthorizeV2(theme, msisdn, mymts_brws, source, slave_id, redirect_url, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * websso callback
         * @summary websso callback
         * @param {string} code oauth2 code
         * @param {string} state oauth2 state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webSSOCallbackV2(code, state, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).webSSOCallbackV2(code, state, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * websso logout redirect
         * @summary websso logout redirect
         * @param {string} [redirect_url] redirect url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webSSOLogoutV2(redirect_url, options) {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).webSSOLogoutV2(redirect_url, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = { ...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url };
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         * activate promocode
         * @summary activate promocode
         * @param {LandingActivatePromoCodeData} body activate promocode data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activatePromoCode(body, options) {
            return DefaultApiFp(configuration).activatePromoCode(body, options).then((request) => request(axios, basePath));
        },
        /**
         * add child to subscription
         * @summary add child to subscription
         * @param {LandingAddChildDataV2} body add child data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addChildV2(body, options) {
            return DefaultApiFp(configuration).addChildV2(body, options).then((request) => request(axios, basePath));
        },
        /**
         * cancel subscription
         * @summary cancel subscription
         * @param {LandingCancelSubscriptionDataV2} body cancel subscription data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSubscriptionV2(body, options) {
            return DefaultApiFp(configuration).cancelSubscriptionV2(body, options).then((request) => request(axios, basePath));
        },
        /**
         * create subscription or change tariff plan if exists
         * @summary create subscription or change tariff plan if exists
         * @param {LandingCreateSubscriptionDataV2} body create subscription data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscriptionV2(body, options) {
            return DefaultApiFp(configuration).createSubscriptionV2(body, options).then((request) => request(axios, basePath));
        },
        /**
         * get parent invite
         * @summary get parent invite
         * @param {string} id invite id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParentInviteV2(id, options) {
            return DefaultApiFp(configuration).getParentInviteV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * get subscription status child
         * @summary get subscription status child
         * @param {string} msisdn child msisdn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionStatusChildV2(msisdn, options) {
            return DefaultApiFp(configuration).getSubscriptionStatusChildV2(msisdn, options).then((request) => request(axios, basePath));
        },
        /**
         * get subscription by id
         * @summary get subscription by id
         * @param {string} id subscription id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionV2(id, options) {
            return DefaultApiFp(configuration).getSubscriptionV2(id, options).then((request) => request(axios, basePath));
        },
        /**
         * get tariff plans
         * @summary get tariff plans
         * @param {string} role role
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTariffPlans(role, options) {
            return DefaultApiFp(configuration).getTariffPlans(role, options).then((request) => request(axios, basePath));
        },
        /**
         * get user info
         * @summary get user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfoV2(options) {
            return DefaultApiFp(configuration).getUserInfoV2(options).then((request) => request(axios, basePath));
        },
        /**
         * send parent invite
         * @summary send parent invite
         * @param {string} msisdnParent parent msisdn
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendParentInviteV2(msisdnParent, options) {
            return DefaultApiFp(configuration).sendParentInviteV2(msisdnParent, options).then((request) => request(axios, basePath));
        },
        /**
         * update payment tool
         * @summary update payment tool
         * @param {LandingUpdatePaymentToolDataV2} body subscription id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentToolV2(body, options) {
            return DefaultApiFp(configuration).updatePaymentToolV2(body, options).then((request) => request(axios, basePath));
        },
        /**
         * validate promocode
         * @summary validate promocode
         * @param {LandingValidatePromoCodeData} body validate promocode data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validatePromoCode(body, options) {
            return DefaultApiFp(configuration).validatePromoCode(body, options).then((request) => request(axios, basePath));
        },
        /**
         * websso authorize redirect
         * @summary websso authorize redirect
         * @param {string} [theme] my mts theme
         * @param {string} [msisdn] login hint
         * @param {string} [mymts_brws] my mts webview
         * @param {string} [source] sub source
         * @param {string} [slave_id] websso slave id
         * @param {string} [redirect_url] redirect url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webSSOAuthorizeV2(theme, msisdn, mymts_brws, source, slave_id, redirect_url, options) {
            return DefaultApiFp(configuration).webSSOAuthorizeV2(theme, msisdn, mymts_brws, source, slave_id, redirect_url, options).then((request) => request(axios, basePath));
        },
        /**
         * websso callback
         * @summary websso callback
         * @param {string} code oauth2 code
         * @param {string} state oauth2 state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webSSOCallbackV2(code, state, options) {
            return DefaultApiFp(configuration).webSSOCallbackV2(code, state, options).then((request) => request(axios, basePath));
        },
        /**
         * websso logout redirect
         * @summary websso logout redirect
         * @param {string} [redirect_url] redirect url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webSSOLogoutV2(redirect_url, options) {
            return DefaultApiFp(configuration).webSSOLogoutV2(redirect_url, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * activate promocode
     * @summary activate promocode
     * @param {LandingActivatePromoCodeData} body activate promocode data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    activatePromoCode(body, options) {
        return DefaultApiFp(this.configuration).activatePromoCode(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * add child to subscription
     * @summary add child to subscription
     * @param {LandingAddChildDataV2} body add child data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    addChildV2(body, options) {
        return DefaultApiFp(this.configuration).addChildV2(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * cancel subscription
     * @summary cancel subscription
     * @param {LandingCancelSubscriptionDataV2} body cancel subscription data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    cancelSubscriptionV2(body, options) {
        return DefaultApiFp(this.configuration).cancelSubscriptionV2(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * create subscription or change tariff plan if exists
     * @summary create subscription or change tariff plan if exists
     * @param {LandingCreateSubscriptionDataV2} body create subscription data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    createSubscriptionV2(body, options) {
        return DefaultApiFp(this.configuration).createSubscriptionV2(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * get parent invite
     * @summary get parent invite
     * @param {string} id invite id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getParentInviteV2(id, options) {
        return DefaultApiFp(this.configuration).getParentInviteV2(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * get subscription status child
     * @summary get subscription status child
     * @param {string} msisdn child msisdn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getSubscriptionStatusChildV2(msisdn, options) {
        return DefaultApiFp(this.configuration).getSubscriptionStatusChildV2(msisdn, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * get subscription by id
     * @summary get subscription by id
     * @param {string} id subscription id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getSubscriptionV2(id, options) {
        return DefaultApiFp(this.configuration).getSubscriptionV2(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * get tariff plans
     * @summary get tariff plans
     * @param {string} role role
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getTariffPlans(role, options) {
        return DefaultApiFp(this.configuration).getTariffPlans(role, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * get user info
     * @summary get user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    getUserInfoV2(options) {
        return DefaultApiFp(this.configuration).getUserInfoV2(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * send parent invite
     * @summary send parent invite
     * @param {string} msisdnParent parent msisdn
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    sendParentInviteV2(msisdnParent, options) {
        return DefaultApiFp(this.configuration).sendParentInviteV2(msisdnParent, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * update payment tool
     * @summary update payment tool
     * @param {LandingUpdatePaymentToolDataV2} body subscription id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    updatePaymentToolV2(body, options) {
        return DefaultApiFp(this.configuration).updatePaymentToolV2(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * validate promocode
     * @summary validate promocode
     * @param {LandingValidatePromoCodeData} body validate promocode data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    validatePromoCode(body, options) {
        return DefaultApiFp(this.configuration).validatePromoCode(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * websso authorize redirect
     * @summary websso authorize redirect
     * @param {string} [theme] my mts theme
     * @param {string} [msisdn] login hint
     * @param {string} [mymts_brws] my mts webview
     * @param {string} [source] sub source
     * @param {string} [slave_id] websso slave id
     * @param {string} [redirect_url] redirect url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    webSSOAuthorizeV2(theme, msisdn, mymts_brws, source, slave_id, redirect_url, options) {
        return DefaultApiFp(this.configuration).webSSOAuthorizeV2(theme, msisdn, mymts_brws, source, slave_id, redirect_url, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * websso callback
     * @summary websso callback
     * @param {string} code oauth2 code
     * @param {string} state oauth2 state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    webSSOCallbackV2(code, state, options) {
        return DefaultApiFp(this.configuration).webSSOCallbackV2(code, state, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * websso logout redirect
     * @summary websso logout redirect
     * @param {string} [redirect_url] redirect url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    webSSOLogoutV2(redirect_url, options) {
        return DefaultApiFp(this.configuration).webSSOLogoutV2(redirect_url, options).then((request) => request(this.axios, this.basePath));
    }
}
