import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
import classes from './Picture.module.scss';
const Picture = ({ src: { fileName, directory }, alt, className, containerClass }) => {
    const nameWithoutExtention = fileName.split('.')[0];
    const extention = fileName.split('.')[1];
    const isSvg = extention === 'svg';
    return (_jsx(_Fragment, { children: isSvg ? (_jsx("img", { className: className, src: `/images/${directory}/${fileName}`, alt: alt })) : (_jsxs("picture", { className: classNames(classes.image, containerClass), children: [_jsx("source", { srcSet: `/webp/${nameWithoutExtention}.webp`, type: "image/webp" }), _jsx("img", { className: className, src: `/images/${directory}/${fileName}`, alt: alt })] })) }));
};
export default Picture;
