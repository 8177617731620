import { addChild, childAvailable, invite, subscription, subscriptionCreateError, subscriptionCanceled, subscriptionCreated, subscriptionChangedTP, parentAvailableTPYear, } from './mock';
import { swagger } from '@store/api/defaultApi';
import { asyncDelay } from '@utils/delay';
import { untypedWindow } from '@const';
export const prod = {
    getChildStatus: (msisdn) => swagger.getSubscriptionStatusChildV2(msisdn),
    getTariffPlans: (role) => swagger.getTariffPlans(role),
    checkSubscriptionStatus: (id) => swagger.getSubscriptionV2(id),
    addChild: (data) => swagger.addChildV2(data),
    disableSubscription: () => swagger.cancelSubscriptionV2({}),
    createSubscription: (data) => {
        return swagger.createSubscriptionV2(data);
    },
    inviteParent: (parentMsisdn) => swagger.sendParentInviteV2(parentMsisdn),
    getInvitation: (id) => swagger.getParentInviteV2(id),
};
export const dev = {
    getChildStatus: (msisdn) => asyncDelay(() => {
        return new Promise((resolve, reject) => {
            /*    reject(childNotAvailable); */
            resolve(childAvailable);
        });
    }, 3000),
    getTariffPlans: () => {
        console.log('getTariffPlans');
        return asyncDelay(() => {
            return new Promise((resolve, reject) => {
                resolve(parentAvailableTPYear);
            });
        }, 0);
    },
    checkSubscriptionStatus: (id) => {
        console.log('status');
        return asyncDelay(() => {
            return new Promise(resolve => {
                resolve(untypedWindow.testTP ? subscriptionChangedTP : subscriptionCreated);
            });
        }, 5000);
    },
    addChild: (data) => asyncDelay(() => {
        return new Promise((resolve, reject) => {
            resolve(addChild);
            /* reject({
              response: {
                data: {
                  ErrorCode: 'SubscriptionOnContentBlocked',
                },
              },
            }); */
        });
    }, 3000),
    disableSubscription: () => new Promise((resolve, reject) => {
        resolve(subscriptionCanceled);
    }),
    createSubscription: (data) => {
        console.log('create');
        return asyncDelay(() => {
            return new Promise((resolve, reject) => {
                resolve(untypedWindow.testCreateError ? subscriptionCreateError : subscription);
            });
        }, 3000);
    },
    inviteParent: (parentMsisdn) => {
        console.log('inviteParent');
        return asyncDelay(() => {
            return new Promise((resolve, reject) => {
                resolve(subscription);
                /* reject({
                  response: {
                    data: {
                      ErrorCode: 10108,
                    },
                  },
                }); */
            });
        }, 3000);
    },
    getInvitation: (id) => new Promise(resolve => {
        resolve(invite);
    }),
};
