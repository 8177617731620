import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import classes from './SubscribeSettings.module.scss';
import Badge from '@shared/Badge/Badge';
import HelpService from '@shared/HelpService/HelpService';
import Button from '@shared/Button/Button';
import { getSubscriptionBadgeProps } from '@configs/subscription';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import Counter from './Counter/Counter';
import Payment from './Payment/Payment';
import { paymentStatusesSelector, subscriptionSelector, subscriptionStatusesSelector, subscriptionStatusSelector, } from '@models/subscription/selectors';
import { isChangeConnectSelector, isPaymentConnectSelector } from '@models/subscription/selectors';
import { isChildOwnerSelector } from '@models/subscription/selectors';
import { triggerMetricsEvent } from '@metrics';
const SubscribeSettings = () => {
    const subscription = useSelector(subscriptionSelector);
    const isPaymentConnection = useSelector(isPaymentConnectSelector);
    const isChangeToolConnection = useSelector(isChangeConnectSelector);
    const { isTrialPeriod, isPaymentDisabled } = useSelector(paymentStatusesSelector);
    const subscriptionStatus = useSelector(subscriptionStatusSelector);
    const isChildOwner = useSelector(isChildOwnerSelector);
    const { parent: { active: subscriptionActive, adding: subscriptionAdding, suspended: subscriptionSuspended, childAdd: subscriptionChildAdd }, child: { adding: subscriptionChildAdding }, } = useSelector(subscriptionStatusesSelector);
    const { dialogModel: { openDialog }, } = useTypedDispatch();
    const { price } = subscription;
    const badgeProps = subscriptionStatus ? getSubscriptionBadgeProps(subscriptionStatus) : null;
    const badgeVariant = badgeProps?.variant === 'warning' ? 'negative' : badgeProps?.variant;
    const isDisableButtonShowed = subscriptionActive || subscriptionAdding || subscriptionSuspended || subscriptionChildAdd || isChildOwner;
    const isFree = price === 0;
    const handleDisable = () => {
        openDialog('subscribeDisabling');
        triggerMetricsEvent({ element: subscriptionSuspended ? 'settingsErrorDisableButton' : 'settingsDisableButton' });
    };
    useEffect(() => {
        triggerMetricsEvent({ element: 'settingsModal' });
    }, []);
    return (_jsxs("div", { children: [badgeProps && badgeVariant && (_jsx(Badge, { variant: badgeVariant, text: badgeProps.text, className: classNames(classes.badge, classes[badgeVariant]), withIcon: false })), _jsx("div", { className: classes.title, children: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438 \u041C\u0422\u0421\u00A0Junior" }), !isFree && _jsx(Payment, {}), (isPaymentDisabled || isFree) && _jsx(Counter, { isTrialPeriod: isTrialPeriod, days: subscription?.remainingDays, isFree: isFree }), isDisableButtonShowed && (_jsx(Button, { variant: isChildOwner ? 'alwaysWhite' : 'negative', size: "md", text: "\u041E\u0442\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443", full: true, className: classes.btn, onClick: handleDisable, disabled: subscriptionAdding || subscriptionChildAdding || isPaymentConnection || isChangeToolConnection })), _jsx(HelpService, { className: classes.help })] }));
};
export default SubscribeSettings;
