import { createModel } from '@rematch/core';
import { GTMScreenName } from '@metrics';
const initialState = {
    /* isYMLoaded: false, */
    isGTMLoaded: false,
    isSuccessLogin: false,
    isUserDataChecked: false,
    isSuccessLoginSend: false,
    currentPage: GTMScreenName.main,
    isAuthPageShowed: false,
};
export const MetricsModel = createModel()({
    state: initialState,
    reducers: {
        /* setYMLoaded: state => {
          return { ...state, isYMLoaded: true };
        }, */
        setGTMLoaded: state => {
            return { ...state, isGTMLoaded: true };
        },
        setSuccessLogin: state => {
            return { ...state, isSuccessLogin: true };
        },
        setUserDataChecked: state => {
            return { ...state, isUserDataChecked: true };
        },
        setSuccessLoginSend: state => {
            return { ...state, isSuccessLoginSend: true };
        },
        setCurrentPage: (state, currentPage) => {
            return { ...state, currentPage };
        },
        setAuthPageShowed: (state, isAuthPageShowed) => {
            return { ...state, isAuthPageShowed };
        },
    },
});
