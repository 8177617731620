import { createModel } from '@rematch/core';
import { getLoadReducers } from 'src/store/utils';
import api from '@api';
import { transformUserInfo } from 'src/store/utils/transformUserInfo';
import { canceledStatuses, requestDefaultError } from '../const';
import storage from '@storage';
import Cookies from 'js-cookie';
import { requestWithRetry } from 'src/store/utils/requestWithRetry';
import { RoleEnum, SourceEnum, SubscriptionStatusChildEnum, SubscriptionStatusParentEnum } from '@models/subscription/types';
import { checkProdMode } from '@utils/checkProdMode';
import { transformSubscription } from '@store/utils/transformSubscription';
import { ErrorName } from '../types';
import { checkLocalMode } from '@utils/checkLocalMode';
import { triggerMetricsEvent } from '@metrics';
import { Page } from '@const';
import { matchChildUrl } from '@store/utils/matchChildUrl';
const isProd = checkProdMode();
const initialState = {
    isAuth: false,
    data: {
        guid: null,
        msisdn: '',
        firstName: '',
        lastName: '',
        middleName: '',
        slaves: [],
        isMtsAbonent: false,
    },
    loadStatus: {
        requestLogin: 'pending',
        requestLogout: 'pending',
        requestGetData: 'pending',
    },
    theme: 'light',
    isLoadingError: false,
    isChild: false,
    isSafari: false,
    plugType: null,
    version: 'parent',
    prevVersion: null,
    isNavigationHistoryEmpty: true,
    isProfileWidgetLoaded: false,
};
export const UserModel = createModel()({
    state: initialState,
    reducers: {
        ...getLoadReducers(),
        setUser: (state, user) => {
            return { ...state, data: user };
        },
        setUserPhone: (state, msisdn) => {
            return { ...state, data: { ...state.data, msisdn } };
        },
        setAuth: (state, isAuth) => {
            return { ...state, isAuth };
        },
        setTheme: (state, theme) => {
            return { ...state, theme };
        },
        setLoadingError: (state, isError) => {
            return { ...state, isLoadingError: isError };
        },
        setChild: (state, isChild) => {
            return { ...state, isChild };
        },
        setPlugType: (state, plugType) => {
            return { ...state, plugType };
        },
        setIsSafari: (state, isSafari) => {
            return { ...state, isSafari };
        },
        setVersion: (state, version) => {
            return { ...state, version };
        },
        setPrevVersion: (state, prevVersion) => {
            return { ...state, prevVersion };
        },
        setNavigationHistory: state => {
            return { ...state, isNavigationHistoryEmpty: false };
        },
        setProfileWidgetLoaded: state => {
            return { ...state, isProfileWidgetLoaded: true };
        },
    },
    effects: dispatch => ({
        logIn({ point, redirectType }, state) {
            dispatch.userModel.startRequest('requestGetData');
            dispatch.dialogModel.closeDialog();
            const { theme, data: { msisdn }, } = state.userModel;
            api.user.logIn({ theme, point, msisdn, redirectType });
        },
        logOut(type, state) {
            dispatch.userModel.startRequest('requestGetData');
            api.user.logout(type, state.userModel.theme);
        },
        changeVersion: ({}, rootState) => {
            const currentVersion = rootState.userModel.version;
            const newVersion = currentVersion !== 'child' ? 'child' : 'parent';
            const role = newVersion === 'child' ? RoleEnum.Child : RoleEnum.Parent;
            dispatch.userModel.setVersion(newVersion);
            const subscriptionParentStatus = rootState.subscriptionModel.subscription?.subscriptionStatusParent;
            const isParentDraft = subscriptionParentStatus === SubscriptionStatusParentEnum.Draft;
            const isParentSubscriptionActive = subscriptionParentStatus && !canceledStatuses.includes(subscriptionParentStatus) && !isParentDraft;
            const isAuth = rootState.userModel.isAuth;
            const checkTariffPlans = isAuth && !isParentSubscriptionActive;
            if (checkTariffPlans) {
                dispatch.subscriptionModel.getTariffPlans({ role });
            }
        },
        async checkAuth() {
            const isAuth = await api.user.checkAuth();
            dispatch.userModel.setAuth(isAuth);
            return isAuth;
        },
        showAuthCJ({ redirectType, isAuth }) {
            const authError = Cookies.get('master_error');
            const cjPoint = storage.getUnquotedItem('cjPoint');
            Cookies.remove('master_error');
            storage.clear();
            if (authError) {
                triggerMetricsEvent({ element: 'unsuccessLogin' });
                dispatch.toastModel.showToast({ variant: 'error', text: requestDefaultError });
                if (!isProd) {
                    console.log('master_error', authError);
                }
                return;
            }
            if (redirectType === 'changeUser') {
                storage.setItem('redirectType', 'login');
                dispatch.userModel.logIn({ point: cjPoint });
                return;
            }
            if (!isAuth) {
                return;
            }
        },
        checkTheme: () => {
            const theme = Cookies.get('theme');
            const cookieTheme = theme === 'dark' ? 'dark' : 'light';
            dispatch.userModel.setTheme(cookieTheme);
        },
        checkVersion: () => {
            const { pathname } = window.location;
            const isChild = pathname.includes(Page.Child);
            if (isChild) {
                dispatch.userModel.setVersion('child');
            }
            return isChild ? 'child' : 'parent';
        },
        checkIsSafari: () => {
            const appleExpression = /Apple/i;
            const safariExpression = /Safari/i;
            const MacOSExpression = /Mac OS/i;
            const isSafari = (appleExpression.test(navigator.vendor) && safariExpression.test(navigator.userAgent)) || MacOSExpression.test(navigator.userAgent);
            if (isSafari) {
                dispatch.userModel.setIsSafari(true);
            }
        },
        openCJPoint({ isSubscriptionAvailable, isAuth }) {
            const redirectType = storage.getUnquotedItem('redirectType');
            !isSubscriptionAvailable ? storage.clear() : dispatch.userModel.showAuthCJ({ redirectType, isAuth });
            return redirectType;
        },
        async getUserData() {
            try {
                const userInfo = await requestWithRetry({ requestFn: api.user.getUserInfo });
                const userInfoTransformed = transformUserInfo(userInfo);
                dispatch.userModel.setUser(userInfoTransformed);
                const isChildFlow = userInfo.MSISDN === userInfo.Subscription?.MSISDNChild;
                if (userInfo.MSISDN && isChildFlow) {
                    const { pathname, search } = window.location;
                    const newPath = matchChildUrl(pathname, search);
                    if (newPath && pathname !== newPath) {
                        window.location.replace(newPath);
                    }
                }
                const parentStatus = userInfo.Subscription?.SubscriptionStatusParent?.toLowerCase();
                const childStatus = userInfo.Subscription?.SubscriptionStatusChild?.toLowerCase();
                const subscriptionTransformed = transformSubscription(userInfo.Subscription, userInfoTransformed.slaves);
                const isSubscriptionAvailable = !userInfo.Subscription || canceledStatuses.includes(isChildFlow ? childStatus : parentStatus);
                const isChildCanceled = canceledStatuses.includes(childStatus);
                const isChildOwnerActive = parentStatus === SubscriptionStatusParentEnum.Draft && !isChildCanceled;
                const isParentOwnerActive = parentStatus !== SubscriptionStatusParentEnum.Draft && !canceledStatuses.includes(parentStatus);
                const isOnlyChildFlowAvailable = isChildFlow && (isParentOwnerActive || isChildOwnerActive);
                const slaveInfo = userInfo.SlaveInfo; // мастер под слейвом
                if (slaveInfo && !isSubscriptionAvailable) {
                    dispatch.userModel.setPlugType('needAuth');
                    return { isSubscriptionAvailable, isChildUser: isOnlyChildFlowAvailable };
                }
                const isParentOwnerActiveWithChild = isParentOwnerActive && childStatus !== SubscriptionStatusChildEnum.Draft && !isChildCanceled;
                const isKionCheckAvailable = !isSubscriptionAvailable && (isChildOwnerActive || isParentOwnerActiveWithChild);
                // если подписка активна или добавляется, то проверяем статус киона
                if (isKionCheckAvailable) {
                    dispatch.subscriptionModel.checkKionStatus({ id: userInfo.Subscription?.ID, currentStatus: subscriptionTransformed.kionStatus });
                }
                if (slaveInfo) {
                    dispatch.subscriptionModel.setNewChildPhone(slaveInfo.MSISDN);
                }
                if (isOnlyChildFlowAvailable) {
                    dispatch.userModel.setVersion('child');
                    dispatch.userModel.setChild(true);
                }
                dispatch.subscriptionModel.setSubscription(subscriptionTransformed);
                dispatch.subscriptionModel.setSubscriptionAvailability(isSubscriptionAvailable);
                return { isSubscriptionAvailable, isChildUser: isOnlyChildFlowAvailable };
            }
            catch (error) {
                if (error === ErrorName.Unauthorized) {
                    throw error;
                }
                dispatch.userModel.stopRequest('requestGetData');
                dispatch.userModel.setLoadingError(true);
                return { isSubscriptionAvailable: true, isChildUser: false };
            }
        },
        async getAppData() {
            const { search } = window.location;
            if (search.includes('auth')) {
                dispatch.userModel.logIn({});
                return;
            }
            dispatch.userModel.startRequest('requestGetData');
            const isLocal = checkLocalMode();
            dispatch.userModel.checkTheme();
            dispatch.redirectModel.getRedirectParams();
            const source = dispatch.subscriptionModel.getSource();
            const isWebView = source !== SourceEnum.Landing;
            dispatch.userModel.checkIsSafari();
            try {
                const isAuth = await dispatch.userModel.checkAuth();
                const isActiveSession = Cookies.get('session_id');
                if (!isWebView) {
                    const isReload = Boolean(Cookies.get('isReload'));
                    if (isReload) {
                        Cookies.remove('isReload');
                    }
                    if (isAuth && !isReload && !isLocal) {
                        Cookies.set('isReload', 'true');
                        dispatch.userModel.logIn({});
                        return;
                    }
                    if (!isAuth && isActiveSession && !isLocal) {
                        console.log('logout in getAppData');
                        dispatch.userModel.logOut('logout');
                        return;
                    }
                }
                let isSubscriptionAvailable = true;
                if (isAuth) {
                    const userData = await dispatch.userModel.getUserData();
                    isSubscriptionAvailable = userData.isSubscriptionAvailable;
                    const version = dispatch.userModel.checkVersion();
                    const isChildUser = userData.isChildUser;
                    const role = version === 'child' || isChildUser ? RoleEnum.Child : RoleEnum.Parent;
                    await dispatch.subscriptionModel.getTariffPlans({ role });
                }
                else {
                    dispatch.userModel.checkVersion();
                }
                dispatch.metricsModel.setUserDataChecked();
                /* const isInvitation = await dispatch.userModel.checkInvitation({}); */
                const redirectType = dispatch.userModel.openCJPoint({ isSubscriptionAvailable, isAuth }) || '';
                if (['login'].includes(redirectType) && isAuth) {
                    dispatch.metricsModel.setSuccessLogin();
                }
                if (redirectType !== 'changeUser') {
                    dispatch.userModel.stopRequest('requestGetData');
                }
            }
            catch (error) {
                if (error === ErrorName.Unauthorized) {
                    dispatch.userModel.logIn({ redirectType: 'updateSession' });
                    return;
                }
                if (!isProd) {
                    console.log('ошибка проверки авторизации');
                }
                dispatch.userModel.stopRequest('requestGetData');
                dispatch.userModel.setLoadingError(true);
                return;
            }
        },
    }),
});
