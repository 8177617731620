import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Layout from '@components/shared/Layout/Layout';
import { GTMScreenName, triggerMetricsEvent } from '@metrics';
import { useSelector } from 'react-redux';
import { authSelector, getUserDataSelector } from '@store/models/user/selectors';
import { addChildStageSelector, checkChildAddSelector, childAddAvailabilitySelector, childChangeAvailabilitySelector, nextChildLinkSelector, partnerFlowSelector, } from '@store/models/subscription/selectors';
import { useLocation, useNavigate } from 'react-router-dom';
import EnterPhone from './EnterPhone/EnterPhone';
import Loader from '@components/shared/Loader/Loader';
import Button from '@components/shared/Button/Button';
import { Page } from '@const';
import { useEffect } from 'react';
import Auth from '../Auth/Auth';
import EnterOTP from './EnterOTP/EnterOTP';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
const AddChild = ({ isChangeFlow }) => {
    const isAuth = useSelector(authSelector);
    const isChildAddAvailable = useSelector(childAddAvailabilitySelector);
    const addChildStage = useSelector(addChildStageSelector);
    const nextLink = useSelector(nextChildLinkSelector);
    const isPartnerFlow = useSelector(partnerFlowSelector);
    const isChildChangeAvailable = useSelector(childChangeAvailabilitySelector);
    const { isConnection: isChildAdding } = useSelector(checkChildAddSelector);
    const { isConnection: isUserDataRequest } = useSelector(getUserDataSelector);
    const showChildChangeFlow = isChangeFlow && isChildChangeAvailable;
    const isAddFinished = ['childAdded', 'error'].includes(addChildStage);
    const { subscriptionModel: { setAddChildStage, setNewChildPhone }, } = useTypedDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const isPingoFlow = search.includes('pingo');
    const nextLinkParams = addChildStage === 'childAdded' ? nextLink.success : nextLink.error;
    const isNextLinkExternal = nextLinkParams.type === 'external';
    useEffect(() => {
        return () => {
            setAddChildStage('enterNumber');
            setNewChildPhone('');
        };
    }, []);
    useEffect(() => {
        /*
          1. При окончании процесса подключения редиректим пользователя на страницы партнеров (если данные были ими переданы),
          либо на страницу добавления ребенка
          2. если ссылки для редиректа нет (возможно, например, при возникновении ошибок, которые у нас отображаются на этой странице),
          то ничего не делаем и остаемся здесь
        */
        if (isAddFinished && nextLinkParams.href) {
            isNextLinkExternal ? (window.location.href = nextLinkParams.href) : navigate(nextLinkParams.href);
        }
    }, [addChildStage]);
    useEffect(() => {
        const isUserAuth = isAuth && !isUserDataRequest;
        const isPageAvailable = isChildAddAvailable || isChildAdding || isAddFinished || showChildChangeFlow;
        const shouldRedirectToMain = isUserAuth && !isPageAvailable;
        if (shouldRedirectToMain) {
            navigate(Page.Main);
        }
    }, [isChildAddAvailable, isAuth, isUserDataRequest, isAddFinished, isChildAdding, showChildChangeFlow]);
    useEffect(() => {
        if (isChildAdding || isAddFinished) {
            triggerMetricsEvent({ element: 'addChildConnectedScreen' });
        }
    }, [isChildAdding || isAddFinished]);
    const handelOkClick = () => {
        triggerMetricsEvent({ element: 'addChildConnectedScreenOkButton' });
        const mainPage = isPingoFlow ? Page.Pingo : Page.Main;
        navigate(mainPage);
    };
    const contentMap = {
        enterNumber: _jsx(EnterPhone, {}),
        enterOTP: _jsx(EnterOTP, {}),
        error: _jsx(EnterPhone, {}),
        childAdded: null,
    };
    const loaderTitle = isUserDataRequest ? 'Загрузка' : 'Осталось чуть-чуть';
    const loaderText = isChildAdding || isAddFinished ? (_jsx(_Fragment, { children: _jsxs("div", { children: ["\u041D\u0430\u0441\u0442\u0440\u0430\u0438\u0432\u0430\u0435\u043C \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443 \u041C\u0422\u0421 Junior, \u044D\u0442\u043E\u00A0\u043C\u043E\u0436\u0435\u0442\u00A0\u0437\u0430\u043D\u044F\u0442\u044C\u00A0\u0434\u043E\u00A015\u00A0\u043C\u0438\u043D\u0443\u0442. ", _jsx("br", {}), " \u041A\u043E\u0433\u0434\u0430 \u0431\u0443\u0434\u0435\u0442 \u0433\u043E\u0442\u043E\u0432\u043E, \u043C\u044B \u043F\u0440\u0438\u0448\u043B\u0451\u043C SMS"] }) })) : ('');
    const action = !isPartnerFlow && !isUserDataRequest ? _jsx(Button, { size: "md", full: true, text: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E", variant: "primary", onClick: handelOkClick }) : _jsx(_Fragment, {});
    const titleAlign = isUserDataRequest ? 'center' : 'left';
    const isLoading = isUserDataRequest || isChildAdding || (isAddFinished && isNextLinkExternal);
    return (_jsx(Layout, { pageName: GTMScreenName.addChild, withFooter: false, type: "parentFlow", withBackButton: !isLoading, withoutVersionButton: true, children: isLoading ? (_jsx(Loader, { title: loaderTitle, text: loaderText, titleAlign: titleAlign, action: action })) : isAuth ? (contentMap[addChildStage]) : (_jsx(Auth, {})) }));
};
export default AddChild;
