export const transformAvailableSubscription = (data) => {
    const isTrial = data['is_trial'];
    const subscriptionDays = isTrial ? data['trial_period'] : data['period'];
    const remainingDays = data['remaining_days'];
    return {
        contentID: data['content_id'],
        isTrial,
        price: data['price'],
        remainingDays: !remainingDays ? subscriptionDays : remainingDays,
        tariffPlan: data['type'],
    };
};
