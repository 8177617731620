class Storage {
    getItem(key) {
        const item = localStorage.getItem(key);
        return item;
    }
    getUnquotedItem(key) {
        const item = localStorage.getItem(key);
        return item?.replace(/"/g, '');
    }
    setItem(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    removeItem(key) {
        localStorage.removeItem(key);
    }
    clear() {
        const nextOpenSurveyModal = localStorage.getItem('nextOpenSurveyModal');
        localStorage.clear();
        if (nextOpenSurveyModal) {
            localStorage.setItem('nextOpenSurveyModal', nextOpenSurveyModal);
        }
    }
}
export default new Storage();
