import { createModel } from '@rematch/core';
import { PromoCodeErrorName } from './types';
import { getLoadReducers } from 'src/store/utils';
import api from '@store/api';
import { ResponseCodeEnum } from '../types';
import { transformPromocode } from '@store/utils/transformPromoCode';
import { transformSubscription } from '@store/utils/transformSubscription';
import { canceledStatuses } from '../const';
import { TariffPlanEnum } from '../subscription/types';
import { Page } from '@const';
const initialState = {
    data: null,
    loadStatus: {
        requestApply: 'pending',
        requestValidate: 'pending',
    },
    error: null,
    previousPage: Page.Main,
    isPlanChanged: false,
    promoCodeStage: 'enterCode',
};
export const PromoCodeModel = createModel()({
    state: initialState,
    reducers: {
        ...getLoadReducers(),
        setPromocodeData(state, data) {
            return { ...state, data };
        },
        setError(state, error) {
            return { ...state, error };
        },
        setPreviousPage(state, previousPage) {
            return { ...state, previousPage };
        },
        clearError(state) {
            return { ...state, error: null };
        },
        deletePromoCode(state) {
            return { ...state, data: null, promoCodeStage: 'enterCode' };
        },
        setPlanChanged(state) {
            return { ...state, isPlanChanged: true };
        },
        setPromoCodeStage(state, stage) {
            return { ...state, promoCodeStage: stage };
        },
    },
    effects: dispatch => ({
        async validatePromoCode({ promocode }, rootState) {
            dispatch.promocodeModel.startRequest('requestValidate');
            const validatingCode = promocode || rootState.promocodeModel.error?.promocode;
            if (rootState.promocodeModel.error) {
                dispatch.promocodeModel.clearError();
            }
            if (rootState.promocodeModel.data) {
                dispatch.promocodeModel.setPromocodeData(null);
            }
            const { tariffChoosen, availableSubscriptions } = rootState.subscriptionModel;
            const chosenContent = availableSubscriptions && tariffChoosen && availableSubscriptions.find(({ tariffPlan }) => tariffPlan === tariffChoosen)?.contentID;
            const contentID = chosenContent || rootState.subscriptionModel.subscription.contentIDParent;
            try {
                if (!contentID || !validatingCode) {
                    throw PromoCodeErrorName.CustomValidateError;
                }
                const { data } = await api.promocode.validatePromoCode({ promocode, content_id: contentID });
                dispatch.promocodeModel.setPromocodeData(transformPromocode(data, validatingCode));
                dispatch.promocodeModel.stopRequest('requestValidate');
            }
            catch (error) {
                const errorCode = error?.response?.data?.ErrorCode || error;
                if (errorCode === ResponseCodeEnum.Unauthorized) {
                    dispatch.userModel.logIn({ redirectType: 'updateSession' });
                    return;
                }
                dispatch.promocodeModel.setError({ errorCode, promocode: validatingCode || '' });
                dispatch.promocodeModel.stopRequest('requestValidate');
            }
        },
        cancelPromoCodeEnter() {
            dispatch.promocodeModel.deletePromoCode();
        },
        async applyPromoCode({ withPlanUpdate }, rootState) {
            const { subscription: { subscriptionId, promocodeStatus, subscriptionStatusParent, subscriptionStatusChild }, } = rootState.subscriptionModel;
            const status = (subscriptionStatusParent === 'draft' ? subscriptionStatusChild : subscriptionStatusParent);
            const isSubscriptionActive = !canceledStatuses.includes(status);
            const isPromocodeActive = promocodeStatus === 'active' && isSubscriptionActive;
            const shouldSentRequest = subscriptionId && isSubscriptionActive && !isPromocodeActive;
            const newPlanName = rootState.subscriptionModel.tariffChoosen === TariffPlanEnum.Month ? TariffPlanEnum.Year : TariffPlanEnum.Month;
            const promocode = rootState.promocodeModel.data?.code;
            if (isPromocodeActive) {
                dispatch.dialogModel.openDialog('promoCodeExists');
                return;
            }
            if (withPlanUpdate) {
                dispatch.subscriptionModel.setTariffChoosen(newPlanName);
                dispatch.promocodeModel.setPlanChanged();
            }
            if (shouldSentRequest) {
                dispatch.promocodeModel.startRequest('requestApply');
                const requestData = {
                    PromoCode: promocode,
                    SubscriptionID: subscriptionId,
                };
                try {
                    if (!withPlanUpdate) {
                        const { data } = await api.promocode.applyPromoCode(requestData);
                        const slaves = rootState.userModel.data.slaves;
                        dispatch.subscriptionModel.setSubscription(transformSubscription(data, slaves));
                        dispatch.promocodeModel.stopRequest('requestApply');
                        dispatch.promocodeModel.setPromoCodeStage('successCodeApply');
                        dispatch.promocodeModel.setPromocodeData(null);
                        return;
                    }
                    if (withPlanUpdate) {
                        await dispatch.subscriptionModel.changeSubscriptionTariffPlan({ newPlanName, promocode });
                    }
                }
                catch (error) {
                    const errorCode = error?.response?.data?.ErrorCode;
                    if (errorCode === ResponseCodeEnum.Unauthorized) {
                        dispatch.userModel.logIn({ redirectType: 'updateSession' });
                        return;
                    }
                    const promocodeError = errorCode in PromoCodeErrorName ? errorCode : PromoCodeErrorName.CustomApplyError;
                    dispatch.promocodeModel.setError({ errorCode: promocodeError, promocode: promocode || '' });
                    dispatch.promocodeModel.stopRequest('requestApply');
                }
                return;
            }
            dispatch.promocodeModel.setPromoCodeStage('successCodeApply');
        },
    }),
});
