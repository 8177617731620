import { jsx as _jsx } from "react/jsx-runtime";
import { footerMenuList } from '@configs/footer';
import classes from './Menu.module.scss';
import ListItem from '@shared/ListItem/ListItem';
import Link from '@shared/Link/Link';
import { triggerMetricsEvent } from '@metrics';
const Menu = () => {
    const handleLinkClick = (name) => () => {
        triggerMetricsEvent({ element: name });
    };
    return (_jsx("ul", { className: classes.list, children: footerMenuList.map(({ href, text, metricsElementName }, index) => (_jsx(ListItem, { type: "li", className: classes.item, children: _jsx(Link, { type: "tertiary", href: href, inNewWindow: true, onClick: handleLinkClick(metricsElementName), children: text }) }, index))) }));
};
export default Menu;
