export const partnersCardsConfig = {
    parentNotAuth: {
        pingo: {
            ymID: 'pr_8a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'gde_moi_deti',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        logicLike: {
            ymID: 'pr_9a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'logiclike',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        kion: {
            ymID: 'pr_10a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'kion',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        music: {
            ymID: 'pr_11a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'mts_music',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        leoni: {
            ymID: 'pr_leoni_1a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'leoni',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        launcher: {
            ymID: 'pr_launcher_nea_1',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'loncher',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        launcherButton: {
            ymID: 'pr_launcher_nea_2',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'skachat_rebenku',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        skillBox: {
            ymID: 'pr_skillbox_nea',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'skillbox',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        skillBoxButton: {
            ymID: 'pr_skillbox_nea',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'uznat_bolshe',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        wowHow: {
            ymID: 'pr_wowhow_nea',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'wowhow',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        wowHowButton: {
            ymID: 'pr_wowhow_nea',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'izuchit_na_ozon',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
    },
    parentAuth: {
        pingo: {
            ymID: 'pr_8b',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'gde_moi_deti',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        pingoActiveButton: {
            ymID: 'pr_8c',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'nachat_besplatno',
                eventContent: 'gde_moi_deti',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        logicLike: {
            ymID: 'pr_9b',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'logiclike',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        logicLikeActiveButton: {
            ymID: 'pr_9c',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'nastroit_rebenku',
                eventContent: 'logiclike',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        kion: {
            ymID: 'pr_10b',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'kion',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        kionActiveButton: {
            ymID: 'pr_10c',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'nastroit_rebenku',
                eventContent: 'kion',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        music: {
            ymID: 'pr_11b',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'mts_music',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        musicActiveButton: {
            ymID: 'pr_11c',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'nastroit_rebenku',
                eventContent: 'mts_music',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        leoni: {
            ymID: 'pr_leoni_1b',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'card_click',
                eventLabel: 'leoni',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        leoniActiveButton: {
            ymID: 'pr_leoni_1c',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'nachat_besplatno',
                eventContent: 'leoni',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
    },
    childNotAuth: {
        logicLike: {
            ymID: 'ch_9a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'logiclike',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        kion: {
            ymID: 'ch_10a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'kion',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        music: {
            ymID: 'ch_11a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'mts_music',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        launcher: {
            ymID: 'ch_launcher_1',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'loncher',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        launcherButton: {
            ymID: 'ch_launcher_2',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'tvorit',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        roblox: {
            ymID: 'ch_roblox_1',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'roblox',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        robloxButton: {
            ymID: 'ch_roblox_2',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'uznat_bolshe',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        likee: {
            ymID: 'ch_likee_1',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'likee',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        likeeButton: {
            ymID: 'ch_likee_2',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'uznat_bolshe',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        wowHow: {
            ymID: 'ch_wowhow_1',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'wowhow',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        wowHowButton: {
            ymID: 'ch_wowhow_2',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'lumos',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
    },
    childAuth: {
        kion: {
            ymID: 'ch_10a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'kion',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        kionActive: {
            ymID: 'ch_10b',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'kion',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        kionButton: {
            ymID: 'ch_10c',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'smotret',
                eventContent: 'kion',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        kionActiveButton: {
            ymID: 'ch_10c',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'smotret',
                eventContent: 'kion',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        logicLike: {
            ymID: 'ch_9a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'logiclike',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        logicLikeActive: {
            ymID: 'ch_9b',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'logiclike',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        logicLikeButton: {
            ymID: 'ch_9c',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'igrat',
                eventContent: 'logiclike',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        logicLikeActiveButton: {
            ymID: 'ch_9c',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'igrat',
                eventContent: 'logiclike',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        music: {
            ymID: 'ch_11a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'mts_music',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        musicActive: {
            ymID: 'ch_11b',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'card_click',
                eventLabel: 'mts_music',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        musicButton: {
            ymID: 'ch_11c',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'slushat',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        musicActiveButton: {
            ymID: 'ch_11c',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'slushat',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
    },
};
