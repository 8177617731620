import { jsx as _jsx } from "react/jsx-runtime";
import Button from '@components/shared/Button/Button';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import Loader from '@components/shared/Loader/Loader';
import classes from './ChangePlanConnection.module.scss';
import { useEffect } from 'react';
import { triggerMetricsEvent } from '@metrics';
const ChangePlanConnection = () => {
    const { dialogModel: { closeDialog }, } = useTypedDispatch();
    useEffect(() => {
        triggerMetricsEvent({ element: 'changePlanConnection' });
    }, []);
    const handleClose = () => {
        triggerMetricsEvent({ element: 'changePlanConnectionOkButton' });
        closeDialog();
    };
    const loaderConfig = {
        loaderTitle: 'Осталось чуть-чуть',
        loaderText: 'Настраиваем подписку МТС Junior, это может занять до 15 минут. Когда будет готово, мы пришлём SMS',
        titleAlign: 'left',
        action: _jsx(Button, { size: "md", full: true, text: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E", variant: "primary", onClick: handleClose }),
    };
    const { loaderTitle, loaderText, titleAlign, action } = loaderConfig;
    return _jsx(Loader, { title: loaderTitle, text: loaderText, titleAlign: titleAlign, action: action, className: classes.loader });
};
export default ChangePlanConnection;
