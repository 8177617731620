import { Page } from '@const';
export const checkPage = () => {
    const { pathname } = window.location;
    return {
        isGMDPromoPage: pathname.includes(Page.Pingo),
        isChildAddedPage: pathname.includes(Page.SuccessChildAdd),
        isParentAddedPage: pathname.includes(Page.SuccessParentAdd),
        isChildAddPage: pathname.includes(Page.AddChild),
        isCreatingPage: pathname.includes(Page.CreateSubscription),
        isInstructionPage: pathname.includes('instruction'),
        isPromocodePage: pathname.includes(Page.Promocode),
        isUpdateSuccessPage: pathname.includes(Page.SuccessUpdate),
        isUpdatingPage: pathname.includes(Page.UpdateSubscription),
    };
};
