export var GTMScreenName;
(function (GTMScreenName) {
    GTMScreenName["main"] = "/";
    GTMScreenName["pingo"] = "/instruction/pingo";
    GTMScreenName["logicLike"] = "/instruction/logicLike";
    GTMScreenName["music"] = "/instruction/music";
    GTMScreenName["kion"] = "/instruction/kion";
    GTMScreenName["leoni"] = "/instruction/leoni";
    GTMScreenName["notFound"] = "/404";
    GTMScreenName["child"] = "/child";
    GTMScreenName["pingoPromo"] = "/gmd_promo";
    GTMScreenName["pingoPromoUserStory"] = "/gmd_promo/story";
    GTMScreenName["createSubscriprion"] = "/create-subscription";
    GTMScreenName["createChildSubscriprion"] = "/create-child-subscription";
    GTMScreenName["addChild"] = "/add-child";
    GTMScreenName["parentSettings"] = "/parent-settings";
    GTMScreenName["promocode"] = "/promocode";
    GTMScreenName["backToSchool"] = "/school";
})(GTMScreenName || (GTMScreenName = {}));
