import api from '@api';
import { asyncDelay } from '@utils/delay';
import { RoleEnum } from '@store/models/subscription/types';
const statusRetryDelay = 1000;
export const checkStatusWithRetry = async ({ id, currentStatus, delay = statusRetryDelay, type, retryCount, }) => {
    const { data } = await api.subscription.checkSubscriptionStatus(id);
    if (Boolean(data?.MasterHubError)) {
        throw data?.MasterHubError;
    }
    const statusMap = {
        [RoleEnum.Child]: data?.SubscriptionStatusChild?.toLowerCase(),
        [RoleEnum.Parent]: data?.SubscriptionStatusParent?.toLowerCase(),
        kion: data?.KionStatus?.toLowerCase(),
        paymentToken: data?.PaymentTokenStatusNew?.toLowerCase(),
    };
    if (statusMap[type] !== currentStatus || retryCount === 0) {
        return data;
    }
    const count = retryCount ? retryCount - 1 : undefined;
    const params = { id, currentStatus, delay, type, retryCount: count };
    return asyncDelay(() => checkStatusWithRetry(params), delay);
};
