export const phoneMask = '+7 999 999-99-99';
export const getMaskedPhone = (phone, startsWithEight) => {
    const matcher = /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/;
    const arr = phone.replace(/\D/g, '').match(matcher);
    if (!arr) {
        return '';
    }
    const startString = startsWithEight ? '8' : `+${arr[1]}`;
    return `${startString} ${arr[2]} ${arr[3]}-${arr[4]}-${arr[5]}`;
};
const getUnmaskedPhone = (phone) => {
    const parsedValue = parseInt(phone.replace(/[^0-9]/g, ''));
    return isNaN(parsedValue) ? '' : parsedValue.toString();
};
const unmaskConfig = {
    phone: value => getUnmaskedPhone(value),
};
export const getUnmaskedValue = (value, mask) => {
    return unmaskConfig[mask](value);
};
