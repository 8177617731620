import { getQueryStatus } from 'src/store/utils';
import { createSelector } from 'reselect';
import { SourceEnum, SubscriptionStatusChildEnum, SubscriptionStatusParentEnum, TarifficationStatusEnum, TariffPlanEnum, OriginEnum, ServiceStatusEnum, } from './types';
import { authSelector, childUserSelector, isChildAuthSelector, msisdnSelector, userInfoSelector, versionSelector, } from '@models/user/selectors';
import { isDateAfter } from '@utils/date';
import { minPaymentChangeDays, Page, trialDays } from '@const';
import { activeParentStatuses, canceledStatuses, subscriptionDefaultPrice } from '../const';
import { checkChildUserCanceled } from './utils/checkChildUserCanceled';
export const childPhoneValidateErrorSelector = ({ subscriptionModel: { childPhoneValidateError } }) => childPhoneValidateError;
export const checkOTPErrorSelector = ({ subscriptionModel: { checkOTPError } }) => checkOTPError;
export const subscriptionSelector = ({ subscriptionModel: { subscription } }) => subscription;
export const isForisSubscriptionSelector = ({ subscriptionModel: { subscription } }) => subscription.origin === OriginEnum.Foris60;
export const childrenSelector = ({ subscriptionModel: { subscription } }) => subscription.children;
export const membersSelector = ({ subscriptionModel: { subscription }, userModel: { data: parentInfo } }) => {
    const childInfo = subscription?.children?.find(el => el.msisdn === subscription?.msisdnChild);
    return {
        parent: {
            msisdn: parentInfo.msisdn,
            name: parentInfo.firstName,
            picture: parentInfo.picture,
        },
        child: {
            msisdn: childInfo?.msisdn || '',
            name: childInfo?.name || '',
            picture: childInfo?.picture,
        },
    };
};
export const tariffToChangeSelector = ({ subscriptionModel: { tariffToChange } }) => tariffToChange;
export const createStatusSelector = ({ subscriptionModel: { createStatus } }) => createStatus;
export const updateStatusSelector = ({ subscriptionModel: { updateStatus } }) => updateStatus;
export const nextParentLinkSelector = (state) => {
    /* Проверяем наличие урлов для редиректа, если есть, то отдаем их, если нет, то отдаем урл для добавления ребенка  */
    const { successUrl, failedUrl, isChildAddNeeded } = state.redirectModel;
    const { version } = state.userModel;
    const isChildFlow = version === 'child';
    const isRedirectNeeded = successUrl && !isChildAddNeeded;
    const internalSuccessUrl = isChildFlow ? Page.SuccessChildAdd : Page.AddChild;
    const links = {
        error: {
            href: failedUrl ? failedUrl : '',
            type: 'external',
        },
        success: {
            href: isRedirectNeeded ? successUrl : internalSuccessUrl,
            type: isRedirectNeeded ? 'external' : 'internal',
        },
    };
    return links;
};
export const nextChildLinkSelector = (state) => {
    /* Проверяем наличие урлов для редиректа, если есть, то отдаем их, если нет, то отдаем урл для добавления ребенка  */
    const { successUrl, failedUrl } = state.redirectModel;
    const links = {
        error: {
            href: failedUrl ? failedUrl : '',
            type: failedUrl ? 'external' : 'internal',
        },
        success: {
            href: successUrl ? successUrl : Page.SuccessParentAdd,
            type: successUrl ? 'external' : 'internal',
        },
    };
    return links;
};
export const subscriptionStatusesSelector = ({ subscriptionModel: { subscription } }) => {
    const parentAdding = [SubscriptionStatusParentEnum.Adding, SubscriptionStatusParentEnum.Creating, SubscriptionStatusParentEnum.Updating];
    const childAdding = [SubscriptionStatusChildEnum.Adding, SubscriptionStatusChildEnum.Creating, SubscriptionStatusChildEnum.Updating];
    return {
        parent: {
            adding: Boolean(subscription?.subscriptionStatusParent && parentAdding.includes(subscription.subscriptionStatusParent)),
            active: subscription?.subscriptionStatusParent === SubscriptionStatusParentEnum.Active,
            canceled: subscription?.subscriptionStatusParent === SubscriptionStatusParentEnum.Canceled,
            suspended: subscription?.subscriptionStatusParent === SubscriptionStatusParentEnum.Suspended,
            childAdd: subscription?.subscriptionStatusParent === SubscriptionStatusParentEnum.ChildAdd,
            draft: subscription?.subscriptionStatusParent === SubscriptionStatusParentEnum.Draft,
        },
        child: {
            adding: Boolean(subscription?.subscriptionStatusChild && childAdding.includes(subscription.subscriptionStatusChild)),
            active: subscription?.subscriptionStatusChild === SubscriptionStatusChildEnum.Active,
            canceled: subscription?.subscriptionStatusChild === SubscriptionStatusChildEnum.Canceled,
            suspended: subscription?.subscriptionStatusChild === SubscriptionStatusChildEnum.Suspended,
            draft: subscription?.subscriptionStatusChild === SubscriptionStatusChildEnum.Draft,
        },
    };
};
export const subscriptionStatusSelector = createSelector(subscriptionStatusesSelector, childUserSelector, (statuses, isChildAuth) => {
    const version = isChildAuth ? 'child' : 'parent';
    // выбираем активный статус из списка статусов на основании его значения, равного true, такое значение в объекте всегда только одно
    const activeStatus = Object.entries(statuses[version]).find(([key, value]) => value);
    const activeStatusName = activeStatus?.[0];
    return activeStatusName;
});
export const kionStatusSelector = createSelector(subscriptionStatusesSelector, subscriptionSelector, (statuses, subscription) => {
    const kionStatus = subscription?.kionStatus;
    const { child: { adding: isChildAdding, active: isChildActive, suspended: isChildSuspended }, parent: { adding: isParentAdding, draft: isParentDraft, suspended: isParentSuspended }, } = statuses;
    const isKionAdding = (isChildAdding || isChildActive || isParentAdding) && kionStatus === ServiceStatusEnum.Undefined;
    const isKionSuspended = (isParentDraft && isChildSuspended) || isParentSuspended;
    const transformedStatuses = {
        adding: isKionAdding,
        active: kionStatus === ServiceStatusEnum.Active,
        canceled: kionStatus === ServiceStatusEnum.Canceled || isKionSuspended,
    };
    const activeStatus = Object.entries(transformedStatuses).find(([key, value]) => value);
    const activeStatusName = activeStatus?.[0] || transformedStatuses.canceled;
    return activeStatusName;
});
export const availableSubscriptionsSelector = ({ subscriptionModel: { availableSubscriptions } }) => availableSubscriptions;
export const netariffSelector = ({ subscriptionModel: { subscription: { origin }, }, }) => origin === OriginEnum.Netariff;
// для 30летия МТС
export const anniversarySelector = ({ subscriptionModel: { subscription: { origin }, }, }) => origin === OriginEnum.MTSAnniversary;
export const isProlongationAvailableSelector = createSelector(anniversarySelector, subscriptionSelector, (isAnniversary, subscription) => {
    const isActive = !!subscription.subscriptionStatusParent && activeParentStatuses.includes(subscription.subscriptionStatusParent);
    const remainingDays = subscription.remainingDays || 0;
    return isActive && isAnniversary && remainingDays <= minPaymentChangeDays;
});
export const isProlongationAvailableSelectorAnniversary = createSelector(anniversarySelector, subscriptionSelector, (isAnniversary, subscription) => {
    const isActive = !!subscription.subscriptionStatusParent && activeParentStatuses.includes(subscription.subscriptionStatusParent);
    const remainingDays = subscription.remainingDays || 0;
    return isActive && isAnniversary && remainingDays <= 5;
});
export const isPaymentChangeDisabledSelector = createSelector(isForisSubscriptionSelector, subscriptionSelector, (isForis, subscription) => {
    const remainingDays = subscription.remainingDays || 0;
    return isForis && remainingDays > minPaymentChangeDays;
});
export const isRenewAvailableSelector = createSelector(subscriptionSelector, userInfoSelector, availableSubscriptionsSelector, versionSelector, (subscription, user, availableSubscriptions, version) => {
    const isLanding = subscription.origin === OriginEnum.Landing;
    const isChildUser = user.msisdn === subscription.msisdnChild;
    const isChildOwner = isChildUser && subscription.subscriptionStatusParent === SubscriptionStatusParentEnum.Draft;
    const isChildVersion = version === 'child';
    const isChildRenewAvailable = isChildOwner && subscription.subscriptionStatusChild === SubscriptionStatusChildEnum.Canceled;
    const isParentRenewAvailable = subscription.subscriptionStatusParent === SubscriptionStatusParentEnum.Canceled;
    const isRemainingDays = availableSubscriptions?.find(s => s.tariffPlan === subscription.tariffPlan)?.remainingDays;
    const isRenewAvailable = isChildVersion ? isChildRenewAvailable : isParentRenewAvailable;
    return Boolean(isRemainingDays) && isRenewAvailable && isLanding;
});
export const isRenewProcessSelector = ({ subscriptionModel: { isRenewProcess } }) => isRenewProcess;
export const showSubscriptionSelector = createSelector(authSelector, subscriptionSelector, subscriptionStatusesSelector, isChildAuthSelector, (isAuth, subscription, statusMap, isChildUser) => {
    const isChildOwner = isChildUser && statusMap.parent.draft;
    const isSubscription = isChildOwner ? Boolean(subscription?.subscriptionStatusChild) : Boolean(subscription.subscriptionStatusParent);
    if (!isSubscription) {
        return false;
    }
    const isChildCanceled = statusMap.child.canceled;
    const isChildUserCanceled = checkChildUserCanceled({
        parentStatus: subscription.subscriptionStatusParent,
        childStatus: subscription.subscriptionStatusChild,
        isChildUser,
    });
    const isParentCanceled = statusMap.parent.canceled;
    const isSubscriptionCanceled = isChildOwner ? isChildCanceled : isParentCanceled || isChildUserCanceled;
    return isAuth && !isSubscriptionCanceled;
});
export const isChildOwnerSelector = createSelector(childUserSelector, subscriptionStatusesSelector, (isChildAuth, statuses) => {
    return isChildAuth && statuses.parent.draft;
});
export const isParentOwnerSelector = createSelector(authSelector, msisdnSelector, subscriptionSelector, subscriptionStatusesSelector, (isAuth, msisdn, subscription, statuses) => {
    return isAuth && msisdn === subscription?.msisdnParent && !statuses.parent.draft;
});
export const prevOwnerSelector = ({ subscriptionModel: { prevSubscriptionOwner } }) => prevSubscriptionOwner;
export const ownerSelector = createSelector(isChildOwnerSelector, isParentOwnerSelector, (isChildOwner, isParentOwner) => {
    return isChildOwner ? 'child' : isParentOwner ? 'parent' : null;
});
export const paymentStatusesSelector = createSelector(subscriptionSelector, subscriptionStatusesSelector, ownerSelector, (subscription, statuses, owner) => {
    const isChildWasMono = statuses.parent.draft;
    const userRole = owner || (isChildWasMono ? 'child' : 'parent');
    const today = new Date().toISOString();
    const isPaymentOverdue = isDateAfter(today, subscription.nextTarifficationDate || '');
    const isTarrificationSuccessful = subscription.tarifficationStatus === TarifficationStatusEnum.Successful;
    const isTrialPeriod = Boolean(subscription?.isTrialAvailable);
    const isPaidPeriodLeft = statuses[userRole].canceled && isTarrificationSuccessful && !isPaymentOverdue;
    const isRemainingDays = subscription.remainingDays ? subscription.remainingDays > 0 : false;
    const isTrialLeft = statuses[userRole].canceled && isTrialPeriod && isRemainingDays;
    const isRemainingPaid = statuses[userRole].canceled && isTarrificationSuccessful && !isPaymentOverdue;
    const isPaymentDisabled = isTrialLeft || isPaidPeriodLeft;
    const subscriptionStatus = userRole === 'child' ? subscription.subscriptionStatusChild : subscription.subscriptionStatusParent;
    const isFree = !subscriptionStatus || isTrialLeft;
    return {
        isTrialPeriod,
        isPaidPeriodLeft,
        isTrialLeft,
        isRemainingPaid,
        isPaymentDisabled,
        isFree,
    };
});
export const tariffPlanSelector = ({ subscriptionModel: { subscription: { tariffPlan, subscriptionStatusParent, subscriptionStatusChild }, }, }) => {
    const status = (subscriptionStatusParent === 'draft' ? subscriptionStatusChild : subscriptionStatusParent);
    return status && !canceledStatuses.includes(status) ? tariffPlan : undefined;
};
const subscriptionDataSelector = ({ subscriptionModel: { tariffChoosen, availableSubscriptions, subscription, isSubscriptionAvailable }, }) => {
    return { tariffChoosen, availableSubscriptions, subscription, isSubscriptionAvailable };
};
export const choosenTariffSelector = createSelector(subscriptionDataSelector, isRenewAvailableSelector, ({ tariffChoosen, availableSubscriptions, subscription, isSubscriptionAvailable }, isRenewAvailable) => {
    if (!isSubscriptionAvailable && subscription?.tariffPlan && !isRenewAvailable) {
        const currentTariffPlan = {
            contentID: subscription.subscriptionStatusParent === 'draft' ? subscription.contentIDChild : subscription.contentIDParent,
            isTrial: Boolean(subscription.isTrialAvailable),
            price: subscription.price,
            remainingDays: subscription.remainingDays || 0,
            tariffPlan: subscription.tariffPlan || TariffPlanEnum.Month,
            isCurrentPlan: true,
        };
        return currentTariffPlan;
    }
    return availableSubscriptions?.find(({ tariffPlan }) => tariffChoosen === tariffPlan) || null;
});
export const newChildPhoneSelector = ({ subscriptionModel: { newChildPhone } }) => newChildPhone;
export const childAddAvailabilitySelector = ({ subscriptionModel: { subscription } }) => {
    return subscription.subscriptionStatusParent === SubscriptionStatusParentEnum.ChildAdd;
};
export const subscriptionPriceSelector = ({ subscriptionModel: { subscription, isSubscriptionAvailable } }) => {
    // если есть старая подписка не с месячным планом - в оффере отображаем по умолчанию цену месячного плана
    if (isSubscriptionAvailable && subscription?.tariffPlan !== TariffPlanEnum.Month) {
        return subscriptionDefaultPrice;
    }
    return subscription.price;
};
export const isSubscriptionActiveSelector = createSelector(subscriptionStatusesSelector, ({ parent: { active: isParentActive, childAdd: isChildAdd, adding: isParentAdding }, child: { active: isChildActive, adding: isChildAdding }, }) => {
    const isParentSubscription = isParentActive || isChildAdd || isParentAdding;
    const isChildSubscription = isChildActive || isChildAdding;
    return isParentSubscription || isChildSubscription;
});
export const remainingDaysSelector = ({ subscriptionModel: { subscription } }) => subscription.remainingDays || trialDays;
export const subscriptionAvailabilitySelector = ({ subscriptionModel: { isSubscriptionAvailable } }) => isSubscriptionAvailable;
export const resendOTPDateSelector = ({ subscriptionModel: { resendOTPDate } }) => resendOTPDate;
export const webViewSelector = ({ subscriptionModel: { source } }) => source !== SourceEnum.Landing;
export const partnerFlowSelector = ({ subscriptionModel: { source } }) => [SourceEnum.GMD, SourceEnum.Launcher, SourceEnum.LogicLike].includes(source);
export const myMTSSelector = ({ subscriptionModel: { source } }) => source === SourceEnum.MyMTS;
export const isPromocodeSelector = ({ subscriptionModel: { subscription: { promocodeStatus }, }, }) => promocodeStatus === 'active';
export const promocodeExpirationDateSelector = ({ subscriptionModel: { subscription: { nextTarifficationDate }, }, }) => nextTarifficationDate;
export const tariffPlansSelector = ({ subscriptionModel: { availableSubscriptions, subscription }, }) => {
    const currentTariffPlan = {
        contentID: subscription.subscriptionStatusParent === 'draft' ? subscription.contentIDChild : subscription.contentIDParent,
        isTrial: Boolean(subscription.isTrialAvailable),
        price: subscription.price,
        remainingDays: subscription.remainingDays || 0,
        tariffPlan: subscription.tariffPlan || TariffPlanEnum.Month,
        isCurrentPlan: true,
    };
    return availableSubscriptions
        ? [
            currentTariffPlan,
            ...availableSubscriptions.filter(subscription => {
                const isSameID = subscription.contentID === currentTariffPlan.contentID;
                const isSameName = subscription.tariffPlan === currentTariffPlan.tariffPlan;
                return !isSameID && !isSameName;
            }),
        ]
        : [currentTariffPlan];
};
export const availablePlanSelector = createSelector(availableSubscriptionsSelector, isRenewAvailableSelector, subscriptionSelector, (availableSubscriptions, isRenewAvailable, subscription) => {
    const availablePlan = isRenewAvailable
        ? availableSubscriptions?.find(s => s.tariffPlan === subscription.tariffPlan)
        : availableSubscriptions?.[0];
    return {
        subscriptionPrice: availablePlan?.price || subscriptionDefaultPrice,
        isFree: availablePlan?.isTrial ?? true,
        remainingDays: availablePlan?.remainingDays ?? trialDays,
        plan: availablePlan?.tariffPlan ?? TariffPlanEnum.Month,
    };
});
export const settingsDataSelector = createSelector(subscriptionSelector, subscriptionStatusesSelector, availableSubscriptionsSelector, (subscription, statuses, availableSubscriptions) => {
    const parentActive = statuses.parent.active || statuses.parent.adding || statuses.parent.childAdd;
    const monoChildActive = statuses.parent.draft && (statuses.child.active || statuses.child.adding);
    const isActive = parentActive || monoChildActive;
    const tariff = availableSubscriptions?.find(s => s.tariffPlan === subscription?.tariffPlan);
    const price = isActive ? subscription?.price : tariff?.price;
    const days = isActive ? subscription?.remainingDays : tariff?.remainingDays;
    return {
        subscriptionPrice: price ?? subscriptionDefaultPrice,
        remainingDays: days ?? trialDays,
    };
});
/* todo возобновление JNR-1954 */
/* export const renewDataSelector = createSelector(
  subscriptionSelector,
  availableSubscriptionsSelector,
  (subscription, availableSubscriptions): { subscriptionPrice: number; isFree: boolean; remainingDays: number } => {
    const renewPlan = availableSubscriptions?.find(s => s.tariffPlan === subscription?.tariffPlan);

    return {
      subscriptionPrice: renewPlan?.price || subscription.price,
      isFree: renewPlan?.isTrial ?? subscription?.isTrialAvailable ?? true,
      remainingDays: renewPlan?.remainingDays ?? subscription?.remainingDays ?? trialDays,
    };
  }
); */
export const isChildAddedSelector = createSelector(subscriptionStatusesSelector, ({ child }) => {
    return child.active || child.adding;
});
export const childChangeAvailabilitySelector = createSelector(subscriptionStatusesSelector, netariffSelector, anniversarySelector, isForisSubscriptionSelector, ({ child }, isNetariff, isMTSAnniversary, isForis) => {
    return (isNetariff || isMTSAnniversary || isForis) && child.active;
});
export const showLeoniSelector = createSelector(subscriptionStatusesSelector, netariffSelector, (statuses, isNetariff) => {
    return !(isNetariff && !statuses.parent.canceled);
});
// статусы запросов
const loadStatusSelector = ({ subscriptionModel: { loadStatus } }) => loadStatus;
export const checkAvailabilityStatusSelector = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestCheckAvailability);
});
export const subscriptionDisableSelector = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestDisable);
});
export const checkParentStatusSelector = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestParentStatus);
});
export const childAddSelector = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestChildAdd);
});
export const requestCreateSelector = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestCreate);
});
export const requestCheckCreateSelector = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestCheckCreate);
});
export const requestChangePaymentTool = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestChangePaymentTool);
});
export const requestChildStatusSelector = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestChildStatus);
});
export const updatePaymentTokenStatusSelector = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.updatePaymentTokenStatus);
});
export const changeSubscriptionTariffPlanSelector = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestChangeSubscriptionTariffPlan);
});
export const checkChangeSubscriptionTariffPlanSelector = createSelector(loadStatusSelector, networkStatus => {
    return getQueryStatus(networkStatus.requestCheckChangeSubscriptionTariffPlan);
});
export const isPaymentConnectSelector = createSelector(requestCreateSelector, checkParentStatusSelector, (sessionStatus, parentStatus) => {
    return sessionStatus.isConnection || parentStatus.isConnection;
});
export const isChangePlanConnectionSelector = createSelector(changeSubscriptionTariffPlanSelector, checkChangeSubscriptionTariffPlanSelector, checkAvailabilityStatusSelector, (changingStatus, checkChangeStatus, availabilityStatus) => {
    return changingStatus.isConnection || checkChangeStatus.isConnection || availabilityStatus.isConnection;
});
export const isChangeConnectSelector = createSelector(requestCreateSelector, requestChangePaymentTool, updatePaymentTokenStatusSelector, (sessionStatus, changeTool, updateToken) => {
    return sessionStatus.isConnection || updateToken.isConnection || changeTool.isConnection;
});
// Добавление ребенка
export const addChildStageSelector = ({ subscriptionModel: { addChildStage } }) => addChildStage;
export const checkChildAddSelector = ({ subscriptionModel: { loadStatus } }) => {
    return getQueryStatus(loadStatus.requestCheckChildAdd);
};
