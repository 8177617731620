const getCommonParentConfig = (userAuth) => ({
    leoniInsctructionAbout: {
        ymID: 'pr_leoni_aboutservice',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'roditel',
            eventAction: 'screen_show',
            eventLabel: 'o_servise_leoni',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    leoniInsctructionConnect: {
        ymID: 'pr_leoni_kakpodkl',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'roditel',
            eventAction: 'screen_show',
            eventLabel: 'kak_podkluchit_leoni',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    leoniInsctructionConnectTopButton: {
        ymID: 'pr_leoni_aboutservice_kakpodkl_top',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_leoni',
            eventAction: 'element_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    leoniInsctructionConnectBottomButton: {
        ymID: 'pr_leoni_aboutservice_kakpodkl_bottom',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_leoni',
            eventAction: 'element_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'down',
            actionGroup: 'interactions',
        },
    },
    leoniInsctructionAboutTopButton: {
        ymID: 'pr_leoni_kakpodkl_aboutservice',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'kak_podkluchit_leoni',
            eventAction: 'element_click',
            eventLabel: 'o_servise',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    leoniInsctructionCloseButton: {
        ymID: 'pr_leoni_instruction_close',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_leoni',
            eventAction: 'element_click',
            eventLabel: 'zakryt',
            userAuth,
            actionGroup: 'interactions',
        },
    },
});
export const leoniInsctructionPageConfig = {
    parentNotAuth: {
        ...getCommonParentConfig('0'),
    },
    parentAuth: {
        ...getCommonParentConfig('1'),
        leoniInsctructionStartButton: {
            ymID: 'pr_leoni_kakpodkl_start',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'kak_podkluchit_leoni',
                eventAction: 'kak_podkluchit_leoni',
                eventLabel: 'nachat',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        leoniInsctructionChangePlanButton: {
            ymID: 'pr_leoni_kakpodkl_change',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'kak_podkluchit_leoni',
                eventAction: 'button_click',
                eventLabel: 'smenit_plan_podpiski',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        leoniInsctructionConnectYearPlanButton: {
            ymID: 'pr_leoni_kakpodkl_activate',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'kak_podkluchit_leoni',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
    },
};
