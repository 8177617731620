import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { TariffPlanEnum } from '@store/models/subscription/types';
import { useSelector } from 'react-redux';
import { tariffPlanSelector } from '@store/models/subscription/selectors';
import Result from '../../Result/Result';
import { useEffect } from 'react';
import { triggerMetricsEvent } from '@metrics';
import { useNavigate } from 'react-router';
import { Page } from '@const';
const titleMap = {
    [TariffPlanEnum.Month]: 'месячный',
    [TariffPlanEnum.Year]: 'годовой',
};
const textMap = {
    [TariffPlanEnum.Month]: 'Вы сможете воспользоваться остатком оплаченного периода',
    [TariffPlanEnum.Year]: 'Теперь вам доступен сервис Leoni в годовой подписке МТС Junior',
};
const ChangePlanSuccess = () => {
    const currentPlan = useSelector(tariffPlanSelector);
    const navigate = useNavigate();
    useEffect(() => {
        triggerMetricsEvent({ element: 'changePlanSuccess' });
    }, []);
    const handleClose = () => {
        triggerMetricsEvent({ element: 'changePlanSuccessOkButton' });
        navigate(Page.Main);
    };
    const title = `Тарифный план успешно изменён на ${titleMap[currentPlan]}`;
    const text = _jsx(_Fragment, { children: textMap[currentPlan] });
    const img = { directory: 'result-dialog', fileName: 'result-dialog-subscription-add.png' };
    return _jsx(Result, { title: title, text: text, buttonText: "\u0421\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u0441\u0435\u0440\u0432\u0438\u0441\u044B", onClick: handleClose, img: img, imgPosition: "aboveTitle" });
};
export default ChangePlanSuccess;
