const variants = {
    days: ['день', 'дня', 'дней'],
    leftM: ['остался', 'осталось', 'осталось'],
    leftMUpper: ['Остался', 'Осталось', 'Осталось'],
    left: ['Оставшийся', 'Оставшиеся', 'Оставшиеся'],
    fire: ['сгорит', 'сгорят', 'сгорят'],
    leftF: ['осталась', 'осталось', 'осталось'],
    repeat: ['попытка', 'попытки', 'попыток'],
};
export const pluralize = (num, type) => {
    const variantsArr = variants[type];
    let count = num % 100;
    if (count >= 5 && count <= 20) {
        return variantsArr[2];
    }
    count = count % 10;
    if (count === 1) {
        return variantsArr[0];
    }
    if (count >= 2 && count <= 4) {
        return variantsArr[1];
    }
    return variantsArr[2];
};
