import { createModel } from '@rematch/core';
import api from '@api';
const initialState = {
    questions: [],
};
export const FaqModel = createModel()({
    state: initialState,
    reducers: {
        setQuestions: (state, questions) => {
            return { ...state, questions };
        },
    },
    effects: dispatch => ({
        async getQuestions() {
            try {
                const { data } = await api.faq.getQuestions();
                const questions = data.reduce((acc, section) => {
                    const sectionQuestions = section.articles.map(({ name, content }) => ({
                        question: name,
                        answer: content,
                    }));
                    return [...acc, ...sectionQuestions];
                }, []);
                dispatch.faqModel.setQuestions(questions);
            }
            catch (error) {
                dispatch.faqModel.setQuestions([]);
            }
        },
    }),
});
