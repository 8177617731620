import { jsx as _jsx } from "react/jsx-runtime";
import classes from './StickyButton.module.scss';
import Button from '@shared/Button/Button';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSubscribeButtonText } from '@configs/subscription';
import InvitationButton from '../InvitationButton/InvitationButton';
import { childThemeSelector, invitationShownSelector } from '@store/models/user/selectors';
import { availablePlanSelector, checkAvailabilityStatusSelector, isRenewAvailableSelector } from '@store/models/subscription/selectors';
const StickyButton = ({ onClick, config }) => {
    const [isVisible, setIsVisible] = useState(false);
    const isInvitation = useSelector(invitationShownSelector);
    const isChildVersion = useSelector(childThemeSelector);
    const { isConnection } = useSelector(checkAvailabilityStatusSelector);
    const { isFree } = useSelector(availablePlanSelector);
    const isRenewAvailable = useSelector(isRenewAvailableSelector);
    const { text: buttonText } = getSubscribeButtonText({ isChildVersion, isFree, isRenewAvailable });
    useEffect(() => {
        const handleScroll = () => {
            const shouldBeVisible = window.pageYOffset > window.innerHeight;
            setIsVisible(shouldBeVisible);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const defaultButtonProps = {
        variant: 'primary',
        size: 'lg',
        full: true,
        text: buttonText,
    };
    const buttonProps = config ? { ...defaultButtonProps, ...config } : { ...defaultButtonProps, onClick };
    const classesNames = classNames(classes.container, classes[buttonProps.variant], { [classes.visible]: isVisible });
    return (_jsx("div", { className: classesNames, children: isInvitation ? _jsx(InvitationButton, { ...buttonProps }) : _jsx(Button, { ...buttonProps, isLoading: isConnection && !isChildVersion }) }));
};
export default StickyButton;
