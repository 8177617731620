import { TariffPlanEnum } from '@store/models/subscription/types';
export const lsPaymentTextConfig = {
    trial: 'По окончании бесплатного периода оплату спишем с лицевого счета. Если не хватит средств, предложим оплатить банковской картой',
    noTrial: 'Оплату спишем с лицевого счета. Если не хватит средств, предложим оплатить банковской картой',
    promocode: 'По окончании  промопериода оплату спишем с лицевого счета. Если не хватит средств, предложим оплатить банковской картой',
};
export const getOfferShowMetricName = ({ isTrial, isAbonent, planName, anniversary, }) => {
    const isYearPlan = planName === TariffPlanEnum.Year;
    const metricConfig = [
        {
            check: isYearPlan && !!anniversary && isAbonent,
            result: 'continueSubscribeYearShow',
        },
        {
            check: !isYearPlan && !!anniversary && isAbonent,
            result: 'continueSubscribeMonthShow',
        },
        {
            check: !isYearPlan && !!anniversary && !isAbonent,
            result: 'continueSubscribeMonthNonAbonentShow',
        },
        {
            check: isYearPlan && !!anniversary && !isAbonent,
            result: 'continueSubscribeYearNonAbonentShow',
        },
        {
            check: isYearPlan && isAbonent,
            result: 'yearTariffAbonentOffer',
        },
        {
            check: isYearPlan && !isAbonent,
            result: 'yearTariffNonAbonentOffer',
        },
        {
            check: isTrial && isAbonent,
            result: 'monthTariffAbonentTrialOffer',
        },
        {
            check: !isTrial && isAbonent,
            result: 'monthTariffAbonentNoTrialOffer',
        },
        {
            check: isTrial && !isAbonent,
            result: 'monthTariffNonAbonentTrialOffer',
        },
        {
            check: !isTrial && !isAbonent,
            result: 'monthTariffNonAbonentNoTrialOffer',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
export const getBuyButtonMetricName = ({ isTrial, isAbonent, planName, anniversary, }) => {
    const isYearPlan = planName === TariffPlanEnum.Year;
    const metricConfig = [
        {
            check: isAbonent && isYearPlan && !!anniversary,
            result: 'continueSubscribeYearConnect',
        },
        {
            check: isAbonent && !isYearPlan && !!anniversary,
            result: 'continueSubscribeMonthConnect',
        },
        {
            check: !isAbonent && !isYearPlan && !!anniversary,
            result: 'continueSubscribeMonthNonAbonentConnect',
        },
        {
            check: !isAbonent && isYearPlan && !!anniversary,
            result: 'continueSubscribeYearNonAbonentConnect',
        },
        {
            check: isYearPlan && isAbonent,
            result: 'yearTariffAbonentBuyButton',
        },
        {
            check: isYearPlan && !isAbonent,
            result: 'yearTariffNonAbonentBuyButton',
        },
        {
            check: isTrial && isAbonent,
            result: 'monthTariffAbonentTrialBuyButton',
        },
        {
            check: !isTrial && isAbonent,
            result: 'monthTariffAbonentNoTrialBuyButton',
        },
        {
            check: isTrial && !isAbonent,
            result: 'monthTariffNonAbonentTrialBuyButton',
        },
        {
            check: !isTrial && !isAbonent,
            result: 'monthTariffNonAbonentNoTrialBuyButton',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
export const getToggleTariffButtonMetricName = ({ isAbonent, isTrial, planName, }) => {
    const isYearPlan = planName === TariffPlanEnum.Year;
    const metricConfig = [
        {
            check: isYearPlan && isTrial && isAbonent,
            result: 'createPageAbonentTrialYearTariffButton',
        },
        {
            check: isYearPlan && !isTrial && isAbonent,
            result: 'createPageAbonentNoTrialYearTariffButton',
        },
        {
            check: isYearPlan && isTrial && !isAbonent,
            result: 'createPageNonAbonentTrialYearTariffButton',
        },
        {
            check: isYearPlan && !isTrial && !isAbonent,
            result: 'createPageNonAbonentNoTrialYearTariffButton',
        },
        {
            check: isAbonent,
            result: 'createPageAbonentMonthTariffButton',
        },
        {
            check: !isAbonent,
            result: 'createPageNonAbonentMonthTariffButton',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
export const getPromocodeButtonMetricName = ({ isTrial, isAbonent, planName, }) => {
    const isYearPlan = planName === TariffPlanEnum.Year;
    const metricConfig = [
        {
            check: isYearPlan && isAbonent,
            result: 'yearTariffAbonentPromocodeButton',
        },
        {
            check: isYearPlan && !isAbonent,
            result: 'yearTariffNonAbonentPromocodeButton',
        },
        {
            check: isTrial && isAbonent,
            result: 'monthTariffAbonentTrialPromocodeButton',
        },
        {
            check: !isTrial && isAbonent,
            result: 'monthTariffAbonentNoTrialPromocodeButton',
        },
        {
            check: isTrial && !isAbonent,
            result: 'monthTariffNonAbonentTrialPromocodeButton',
        },
        {
            check: !isTrial && !isAbonent,
            result: 'monthTariffNonAbonentNoTrialPromocodeButton',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
