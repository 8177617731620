const getCommonParentConfig = (userAuth) => ({
    musicInsctructionAbout: {
        ymID: 'pr_music_aboutservice',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'roditel',
            eventAction: 'screen_show',
            eventLabel: 'o_servise_mts_music',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    musicInsctructionConnect: {
        ymID: 'pr_music_kakpodkl',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'roditel',
            eventAction: 'screen_show',
            eventLabel: 'kak_podkluchit_mts_music',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    musicInsctructionConnectTopButton: {
        ymID: 'pr_music_aboutservice_kakpodkl_top',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_mts_music',
            eventAction: 'element_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    musicInsctructionConnectBottomButton: {
        ymID: 'pr_music_aboutservice_kakpodkl_bottom',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_mts_music',
            eventAction: 'element_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'down',
            actionGroup: 'interactions',
        },
    },
    musicInsctructionAboutTopButton: {
        ymID: 'pr_music_kakpodkl_aboutservice',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'kak_podkluchit_mts_music',
            eventAction: 'element_click',
            eventLabel: 'o_servise',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    musicInsctructionCloseButton: {
        ymID: 'pr_music_instruction_close',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_mts_music',
            eventAction: 'element_click',
            eventLabel: 'zakryt',
            userAuth,
            actionGroup: 'interactions',
        },
    },
});
const getCommonChildConfig = (userAuth) => ({
    musicInsctructionConnect: {
        ymID: 'ch_music_kakpodkl',
        GTMParams: {
            event: 'vntReb',
            eventCategory: 'rebenok',
            eventAction: 'screen_show',
            eventLabel: 'kak_podkluchit_mts_music',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    musicInsctructionAboutTopButton: {
        ymID: 'ch_music_kakpodkl_aboutservice',
        GTMParams: {
            event: 'vntReb',
            eventCategory: 'kak_podkluchit_mts_music',
            eventAction: 'button_click',
            eventLabel: 'o_servise',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    musicInsctructionConnectBottomButton: {
        ymID: 'ch_music_aboutservice_kakpodkl_bottom',
        GTMParams: {
            event: 'vntReb',
            eventCategory: 'kak_podkluchit_mts_music',
            eventAction: 'button_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'down',
            actionGroup: 'interactions',
        },
    },
});
export const musicInsctructionPageConfig = {
    parentNotAuth: {
        ...getCommonParentConfig('0'),
    },
    parentAuth: {
        ...getCommonParentConfig('1'),
    },
    childNotAuth: {
        ...getCommonChildConfig('0'),
    },
    childAuth: {
        ...getCommonChildConfig('1'),
        musicInsctructionPlayButton: {
            ymID: 'ch_music_kakpodkl_play',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'kak_podkluchit_mts_music',
                eventAction: 'button_click',
                eventLabel: 'slushat',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
    },
};
