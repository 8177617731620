const fullNumberLength = 11;
export const phoneValidator = (value = '') => {
    return value.length === fullNumberLength;
};
export const requiredValidator = (value = '') => {
    return value.length > 0;
};
const validatorConfig = {
    phone: value => phoneValidator(value),
};
export const validate = (value, mask) => {
    return validatorConfig[mask](value);
};
