import { ErrorName, ResponseCodeEnum } from '@models/types';
import { asyncDelay } from '@utils/delay';
const maxRequestRetryCount = 3;
const requestRetryDelay = 10000;
export const requestWithRetry = async (args) => {
    const { requestFn, retryCount = 1, delayTime = requestRetryDelay, alwaysRetry = false } = args;
    if (retryCount > maxRequestRetryCount) {
        throw ErrorName.Default;
    }
    try {
        const { data } = await requestFn();
        return data;
    }
    catch (error) {
        const errorCode = error?.response?.data?.ErrorCode;
        const isInternalServerError = error?.response?.status === 500 && errorCode === ResponseCodeEnum.InternalServerError;
        const isServerError = isInternalServerError || (error?.response?.status || 0) > 500;
        if ((alwaysRetry || isServerError) && errorCode !== ResponseCodeEnum.Unauthorized && retryCount !== maxRequestRetryCount) {
            return asyncDelay(() => requestWithRetry({ ...args, retryCount: retryCount + 1 }), delayTime);
        }
        throw errorCode === ResponseCodeEnum.Unauthorized ? ErrorName.Unauthorized : ErrorName.Default;
    }
};
