import { createModel } from '@rematch/core';
const initialState = {
    sessionData: null,
    isOpen: false,
    onCloseSuccess: () => { },
};
export const PaymentWidgetModel = createModel()({
    state: initialState,
    reducers: {
        openWidget: (state, { onCloseSuccess, sessionData, onCloseError }) => {
            if (state.isOpen) {
                return state;
            }
            return { ...state, isOpen: true, onCloseSuccess, sessionData, onCloseError };
        },
        closeWidget: state => {
            if (!state.isOpen) {
                return state;
            }
            return initialState;
        },
    },
});
