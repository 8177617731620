import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import SubscribeAddWrapper from '../SubscribeAdd/SubscribeAddWrapper/SubscribeAddWrapper';
import { useSelector } from 'react-redux';
import HelpService from '@shared/HelpService/HelpService';
import Button from '@shared/Button/Button';
import { isRepeatConnectionSelector } from '@models/selectors';
import { dialogSelector } from '@store/models/dialog/selectors';
import { CreateSubscriptionErrorName } from '@store/models/subscription/types';
import { Page } from '@const';
import { useNavigate } from 'react-router-dom';
import { versionSelector } from '@store/models/user/selectors';
const helpService = _jsx(HelpService, {});
const Error = () => {
    const { error, errorName } = useSelector(dialogSelector);
    const isConnection = useSelector(isRepeatConnectionSelector);
    const navigate = useNavigate();
    const isCreatePage = window.location.href.includes(Page.CreateSubscription);
    const isUpdatePage = window.location.href.includes(Page.UpdateSubscription);
    const version = useSelector(versionSelector);
    useEffect(() => {
        return () => {
            // при закрытии редиректим на главную если была ошибка доступности
            if (errorName === CreateSubscriptionErrorName.SubscriptionNotAnavailable && (isCreatePage || isUpdatePage)) {
                navigate(version === 'child' ? Page.Child : Page.Main);
            }
            // удаляем промокод при закрытии
            error?.onClose && error.onClose();
        };
    }, []);
    const title = error?.title;
    const content = (_jsxs(_Fragment, { children: [error?.description && _jsx("p", { children: error.description }), helpService] }));
    const action = (_jsxs(_Fragment, { children: [error?.primaryAction && (_jsx(Button, { variant: "primary", size: "md", text: error.primaryAction.text, full: true, onClick: error.primaryAction.onClick, isLoading: isConnection })), error?.secondaryAction && (_jsx(Button, { variant: "secondary", size: "md", text: error.secondaryAction.text, full: true, onClick: error.secondaryAction.onClick, disabled: isConnection }))] }));
    return _jsx(SubscribeAddWrapper, { title: title, content: content, action: action });
};
export default Error;
