import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import classes from './EnterOTP.module.scss';
import ContentBlock from '../../ContentBlock/ContentBlock';
import { useSelector } from 'react-redux';
import { checkOTPErrorSelector, childAddSelector, childChangeAvailabilitySelector, newChildPhoneSelector, requestChildStatusSelector, resendOTPDateSelector, } from '@store/models/subscription/selectors';
import { getMaskedPhone } from '@utils/masks';
import Input from '@components/shared/Input/Input';
import { useEffect, useState } from 'react';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { pluralize } from '@utils/pluralize';
import classNames from 'classnames';
import Spinner from '@components/shared/Spinner/Spinner';
import Link from '@components/shared/Link/Link';
import dayjs from 'dayjs';
import { getTitle } from '../utils/getTitle';
const repeatMaxCount = 3;
const codeLength = 4;
const timeConfig = {
    seconds: {
        unit: 'сек',
        value: 30,
    },
    minutes: {
        unit: 'мин',
        value: 3,
    },
};
let timer;
const EnterOTP = () => {
    const childPhone = useSelector(newChildPhoneSelector);
    const nextOTPDate = useSelector(resendOTPDateSelector);
    const isCodeError = useSelector(checkOTPErrorSelector);
    const { isConnection: isStatusChecking } = useSelector(requestChildStatusSelector);
    const { isConnection: isAddConnection } = useSelector(childAddSelector);
    const isChildChangeAvailable = useSelector(childChangeAvailabilitySelector);
    const [code, setCode] = useState('');
    const [isCodeValid, setCodeValid] = useState(true);
    const [repeatCount, setRepeatCount] = useState(repeatMaxCount);
    const [resendTime, setResendTime] = useState(timeConfig.seconds.value);
    const maskedPhone = getMaskedPhone(childPhone);
    const isConnection = isStatusChecking || isAddConnection;
    const isCodeInputDisabled = isConnection || repeatCount === 0;
    const leftText = pluralize(repeatCount, 'leftF');
    const tryText = pluralize(repeatCount, 'repeat');
    const errorText = repeatCount > 0 ? `${leftText} ${repeatCount} ${tryText}.` : '';
    const isRepeatDisabled = resendTime > 0;
    const resendTimeUnit = timeConfig[repeatCount > 0 ? 'seconds' : 'minutes'].unit;
    const { dialogModel: { openDialog }, subscriptionModel: { addChild, setAddChildStage, checkChildStatus }, } = useTypedDispatch();
    const startCountdown = (delay = 1000) => {
        clearInterval(timer);
        timer = setInterval(() => {
            setResendTime(value => value - 1);
        }, delay);
    };
    useEffect(() => {
        startCountdown();
        return () => clearInterval(timer);
    }, []);
    useEffect(() => {
        // если nextOTPDate обновилась, то обновляем resendTime и запускаем счетчик времени
        if (nextOTPDate) {
            const newResendTime = nextOTPDate ? dayjs(nextOTPDate).diff(dayjs(), 'seconds') : timeConfig.seconds.value;
            setResendTime(newResendTime);
            startCountdown();
            // если попытки ввода кода закончились до этого, снова устанавливаем их
            if (repeatCount === 0) {
                setRepeatCount(repeatMaxCount);
            }
        }
    }, [nextOTPDate]);
    useEffect(() => {
        // если ошибка проверки кода, то уменьшаем число попыток ввода кода
        if (isCodeError) {
            const decrementCount = repeatCount - 1;
            const newCount = decrementCount >= 0 ? decrementCount : 0;
            setRepeatCount(newCount);
            // если попытки ввода кода закончились, то устанавливаем след. попытку sendOTP через 3 минуты
            if (newCount === 0) {
                setResendTime(timeConfig.minutes.value);
                startCountdown(60000);
            }
            // подсвечиваем, что код не валиден
            setCodeValid(false);
        }
    }, [isCodeError]);
    // если время до след. попытки sendOTP закончилось, то обнуляем timer
    useEffect(() => {
        if (resendTime === 0) {
            clearInterval(timer);
        }
    }, [resendTime]);
    const handleChangeCode = (value) => {
        const isDigits = /^\d+$/.test(value);
        if (value.length && !isDigits) {
            return;
        }
        if (value.length > codeLength) {
            return;
        }
        if (value.length === codeLength) {
            addChild({ childPhone, code: value });
        }
        setCode(value);
    };
    const handleRepeatClick = () => {
        setCodeValid(true);
        setCode('');
        setRepeatCount(repeatMaxCount);
        checkChildStatus(childPhone);
    };
    const handleCodeFailed = () => {
        openDialog('otpCodeFailed');
    };
    const handleChangePhone = () => {
        setAddChildStage('enterNumber');
    };
    const title = getTitle(isChildChangeAvailable);
    const content = (_jsxs(_Fragment, { children: [_jsx("div", { children: "\u041D\u0430 \u043D\u043E\u043C\u0435\u0440 \u0420\u0435\u0431\u0451\u043D\u043A\u0430 \u043C\u044B \u043E\u0442\u043F\u0440\u0430\u0432\u0438\u043B\u0438 SMS \u0441 \u043A\u043E\u0434\u043E\u043C \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0435\u043D\u0438\u044F, \u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u0435\u0433\u043E \u043D\u0438\u0436\u0435" }), _jsxs("div", { className: classes.inputContainer, children: [_jsx(Input, { label: "\u041D\u043E\u043C\u0435\u0440 \u0420\u0435\u0431\u0451\u043D\u043A\u0430", value: maskedPhone, isValid: true, disabled: true, mask: "phone", containerClasses: classes.phone }), _jsx(Input, { label: "\u041A\u043E\u0434 \u0438\u0437 SMS", placeholder: "\u041A\u043E\u0434", value: code, isValid: isCodeValid, onChange: handleChangeCode, containerClasses: classes.code, withoutIcons: true, disabled: isCodeInputDisabled, initialFocus: true }), _jsx("span", { className: classes.error, children: !isCodeValid && (_jsxs(_Fragment, { children: ["\u041D\u0435\u0432\u0435\u0440\u043D\u044B\u0439 \u043A\u043E\u0434. ", _jsx("span", { children: errorText })] })) })] })] }));
    const actions = (_jsxs("div", { className: classNames(classes.linkContainer, {
            [classes.loading]: isConnection,
        }), children: [isConnection && _jsx(Spinner, { color: "redMts", size: "lg", className: classes.spinner }), isRepeatDisabled ? (_jsx(Link, { href: "", type: "primary", preventDefault: true, disabled: true, children: `Отправить код повторно через ${resendTime} ${resendTimeUnit}.` })) : (_jsxs(_Fragment, { children: [_jsx(Link, { href: "", type: "primary", preventDefault: true, onClick: handleRepeatClick, children: "\u041E\u0442\u043F\u0440\u0430\u0432\u0438\u0442\u044C \u043A\u043E\u0434 \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E" }), _jsx(Link, { href: "", type: "primary", preventDefault: true, onClick: handleCodeFailed, disabled: isRepeatDisabled, children: "\u041D\u0435 \u043F\u0440\u0438\u0445\u043E\u0434\u0438\u0442 SMS \u0441 \u043A\u043E\u0434\u043E\u043C" })] })), _jsx(Link, { href: "", type: "primary", preventDefault: true, onClick: handleChangePhone, children: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u043D\u043E\u043C\u0435\u0440" })] }));
    return _jsx(ContentBlock, { title: title, content: content, actions: actions, withBackButton: true });
};
export default EnterOTP;
