export const bannerSalesConfig = {
    parentNotAuth: {
        mainBannerSalesButton: {
            ymID: 'pr_1a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                buttonLocation: 'banner',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        bottomBannerSalesButton: {
            ymID: 'pr_1c',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                buttonLocation: 'down',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        stickyButton: {
            ymID: '',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
    },
    parentAuth: {
        mainBannerSalesButton: {
            ymID: 'pr_1a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                buttonLocation: 'banner',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        bottomBannerSalesButton: {
            ymID: 'pr_1g',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                buttonLocation: 'down',
                actionGroup: 'interactions',
                userAuth: '1',
                eventContent: 'roditel',
            },
        },
        stickyButton: {
            ymID: '',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
    },
    childNotAuth: {
        mainBannerSalesButton: {
            ymID: 'ch_bannerverh_poprobovatbesplatno_nea',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                buttonLocation: 'up',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        bottomBannerSalesButton: {
            ymID: 'ch_bannerniz_poprobovatbesplatno_nea',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '0',
                buttonLocation: 'down',
            },
        },
        tryButton: {
            ymID: 'ch-pr_button_1a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        bottomTryButton: {
            ymID: 'ch-pr_button_1b',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        stickyButton: {
            ymID: 'ch_1d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'hochu_podpisku',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
    },
    childAuth: {
        mainBannerSalesButton: {
            ymID: 'ch_bannerverh_poprobovatbesplatno_a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '1',
                buttonLocation: 'up',
            },
        },
        bottomBannerSalesButton: {
            ymID: 'ch_bannerniz_poprobovatbesplatno_a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '1',
                buttonLocation: 'down',
            },
        },
        stickyButton: {
            ymID: 'ch_1d',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'hochu_podpisku',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
    },
};
