import { jsx as _jsx } from "react/jsx-runtime";
import IconContainer from '../IconContainer/IconContainer';
const circleBorder = 2;
const circleWidth = 36;
const circleCx = circleWidth / 2;
const circleRadius = circleCx - circleBorder / 2;
const circleLength = 2 * Math.PI * circleRadius;
const ProgressCircle = ({ containerClasses, onProgressEnd }) => {
    return (_jsx(IconContainer, { width: circleWidth, height: circleWidth, className: containerClasses, children: _jsx("circle", { cx: circleCx, cy: circleCx, r: circleRadius, strokeWidth: circleBorder, strokeDasharray: circleLength, strokeDashoffset: circleLength, fillOpacity: "0", onAnimationEnd: onProgressEnd }) }));
};
export default ProgressCircle;
