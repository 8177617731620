import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelector } from 'react-redux';
import { dialogSelector } from '@models/dialog/selectors';
import SliderElement from './SliderElement/SliderElement';
import { versionSelector } from '@store/models/user/selectors';
import uniqueId from 'lodash.uniqueid';
const More = () => {
    const { moreEl } = useSelector(dialogSelector);
    const version = useSelector(versionSelector);
    return _jsx(_Fragment, { children: moreEl?.slider && moreEl.slider[version].map(el => _jsx(SliderElement, { slide: el, moreEl: moreEl }, uniqueId())) });
};
export default More;
