import { swagger } from 'src/store/api/defaultApi';
import { asyncDelay } from '@utils/delay';
import { subscriptionCreating } from '../subscription/mock';
export const prod = {
    changePaymentTool: (data) => {
        return swagger.updatePaymentToolV2(data);
    },
};
export const dev = {
    changePaymentTool: (data) => {
        console.log('changeTool');
        return asyncDelay(() => {
            return new Promise((resolve, reject) => {
                resolve(subscriptionCreating);
            });
        }, 3000);
    },
};
