import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { useSelector } from 'react-redux';
import { navigationHistorySelector } from '@store/models/user/selectors';
const ScrollToTop = ({ children }) => {
    const { pathname, hash } = useLocation();
    const [currentPath, setCurrentPath] = useState(pathname);
    const isHistoryEmpty = useSelector(navigationHistorySelector);
    const { userModel: { setNavigationHistory }, } = useTypedDispatch();
    useEffect(() => {
        window.scrollTo(0, 0);
        if (isHistoryEmpty && currentPath !== pathname) {
            setNavigationHistory();
        }
    }, [pathname]);
    useEffect(() => {
        const id = hash.replace('#', '');
        if (id) {
            document.getElementById(id)?.scrollIntoView();
        }
    }, [hash]);
    return _jsx(_Fragment, { children: children });
};
export default ScrollToTop;
