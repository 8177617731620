import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import classes from './Avatar.module.scss';
import classNames from 'classnames';
import { AvatarChildIcon, AvatarGrayIcon, AvatarVioletIcon } from '@icons';
const defaultIconMap = {
    grayAvatar: className => _jsx(AvatarGrayIcon, { containerClasses: classNames(classes.avatar, className) }),
    violetAvatar: className => _jsx(AvatarVioletIcon, { containerClasses: classNames(classes.avatar, className) }),
    child: className => _jsx(AvatarChildIcon, { containerClasses: classNames(classes.avatar, className) }),
};
const Avatar = ({ className, picture, defaultIcon = 'child' }) => {
    const [loadError, setLoadError] = useState(false);
    const handleImageLoadError = () => {
        setLoadError(true);
    };
    return (_jsx(_Fragment, { children: picture && !loadError ? (_jsx("img", { className: classNames(classes.avatar, className), src: picture, alt: "\u0430\u0432\u0430\u0442\u0430\u0440", onError: handleImageLoadError })) : (defaultIconMap[defaultIcon](className)) }));
};
export default Avatar;
