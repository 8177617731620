import { useSelector } from 'react-redux';
import { changeSubscriptionTariffPlanSelector, subscriptionDisableSelector, subscriptionSelector, } from '@models/subscription/selectors';
import { getUserDataIsChild } from '@models/user/selectors';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { useLocation } from 'react-router-dom';
import { checkPage } from '@utils/checkPage';
import { dialogSelector } from '@models/dialog/selectors';
import { activeParentStatuses } from '@models/const';
export const useCheckSurvey = () => {
    const { pathname } = useLocation();
    const [timer, setTimer] = useState(0);
    const { openedDialog } = useSelector(dialogSelector);
    const { isConnection } = useSelector(changeSubscriptionTariffPlanSelector);
    const { isConnection: subscriptionDisable } = useSelector(subscriptionDisableSelector);
    const dispatch = useTypedDispatch();
    const userCurrentSub = useSelector(subscriptionSelector);
    const isActiveSubscriptions = activeParentStatuses?.includes(userCurrentSub?.subscriptionStatusParent);
    const { isAuth, isChild } = useSelector(getUserDataIsChild);
    const timerId = useRef(undefined);
    const successOpen = useRef(0);
    const diffDates = (daysCount) => {
        const currentDate = dayjs(new Date());
        return dayjs(currentDate).diff(userCurrentSub?.subscriptionDate, 'days', true) > daysCount;
    };
    const checkReopenSurveyModal = () => {
        const reopenSurveyModal = localStorage.getItem('nextOpenSurveyModal');
        if (reopenSurveyModal === null) {
            return true;
        }
        else {
            if (reopenSurveyModal === 'false') {
                return false;
            }
            const reopenDate = new Date(reopenSurveyModal);
            if (new Date() > reopenDate) {
                return true;
            }
        }
        return false;
    };
    const handleOpenSurveyModal = (withChild) => {
        successOpen.current = 1;
        dispatch.surveyWidgetModel.initSurveyWidget(withChild);
    };
    const checkContracts = () => {
        if (userCurrentSub.children.length !== 0 && diffDates(5) && timer >= 120 && successOpen.current === 0) {
            clearInterval(timerId.current);
            handleOpenSurveyModal(true);
            return;
        }
        if (userCurrentSub.children.length === 0 && diffDates(2) && timer >= 120 && successOpen.current === 0) {
            clearInterval(timerId.current);
            handleOpenSurveyModal(false);
            return;
        }
    };
    const checkForSurvey = () => {
        if (checkPage().isPromocodePage
            || checkPage().isChildAddPage
            || subscriptionDisable
            || isConnection
            || openedDialog
            || !userCurrentSub?.subscriptionId
            || !checkReopenSurveyModal()) {
            return false;
        }
        checkContracts();
    };
    useEffect(() => {
        if (timer >= 120) {
            clearInterval(timerId.current);
        }
        if (isAuth) {
            checkForSurvey();
        }
    }, [isAuth, timer, pathname, subscriptionDisable, isConnection, openedDialog]);
    useEffect(() => {
        if (isAuth && isActiveSubscriptions && !isChild && checkReopenSurveyModal()) {
            timerId.current = setInterval(() => {
                setTimer((prevState) => prevState + 10);
            }, 10000);
        }
        return () => clearInterval(timerId.current);
    }, [isAuth, isActiveSubscriptions, isChild]);
};
