const findSlaveByMsisdn = (msisdn, slaves) => {
    return slaves.find(slave => slave.phone === msisdn);
};
export const transformChildren = (children, slaves) => {
    return children.map(msisdn => {
        const slave = findSlaveByMsisdn(msisdn, slaves);
        return {
            msisdn,
            name: slave?.name || '',
            picture: slave?.picture || '',
        };
    });
};
