import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classes from './Counter.module.scss';
import { InfoSquareIcon } from '@icons';
import Button from '@shared/Button/Button';
import { getCounterProps } from '@configs/subscription';
import { pluralize } from '@utils/pluralize';
import { isPaymentConnectSelector } from '@models/subscription/selectors';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
const freeText = 'В рамках пилотного запуска подписка предоставляется с бесплатным периодом';
const Counter = ({ isTrialPeriod, days = 0, isFree = false }) => {
    const isConnection = useSelector(isPaymentConnectSelector);
    const daysText = pluralize(days, 'days');
    const leftText = pluralize(days, 'leftM');
    const handleRenew = () => { };
    const { periodText, buttonText } = getCounterProps(isTrialPeriod);
    const counterText = isFree ? freeText : `${leftText} ${days} ${daysText} ${periodText} периода`;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: classes.counter, children: [_jsx(InfoSquareIcon, { containerClasses: classNames(classes.counterIcon, 'active') }), ' ', _jsx("small", { className: classes.text, children: counterText })] }), !isFree && (_jsx(Button, { variant: "primary", size: "md", text: buttonText, full: true, className: classes.btn, onClick: handleRenew, isLoading: isConnection }))] }));
};
export default Counter;
