export const getMetricNameConfirmClick = ({ isAnniversary, }) => {
    const metricConfig = [
        {
            check: isAnniversary,
            result: 'subscriptionRemainsAccept',
        },
        {
            check: !isAnniversary,
            result: 'subscriptionDisabledModalOKButton',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
export const getToggleMetricNameShow = ({ isAnniversary, }) => {
    const metricConfig = [
        {
            check: isAnniversary,
            result: 'disabledSubscribeAgreeShow',
        },
        {
            check: !isAnniversary,
            result: 'subscriptionDisabledModal',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
