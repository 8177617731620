import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import uniqueId from 'lodash.uniqueid';
import classes from './Input.module.scss';
import classNames from 'classnames';
import Spinner from '../Spinner/Spinner';
import { FocusCrossIcon, InputErrorIcon, InputLockIcon } from '@icons';
import MaskedInput from './MaskedInput/MaskedInput';
const id = `field_${uniqueId()}`;
const Input = ({ label, value = '', disabled, placeholder, containerClasses, onChange, isValid, onBlur, onFocus, isAutocompleteActive, autocompleteBlock, message, loading, autocompleteIcon, excludeBlurClasses = [], mask, withoutIcons, initialFocus, }) => {
    const [isFocus, setFocus] = useState(false);
    const isEmpty = !value?.length;
    const inputRef = useRef(null);
    const blockClasses = classNames(classes.container, {
        [classes.disabled]: disabled || loading,
        [classes.error]: !isValid,
        [classes.withoutIcons]: withoutIcons,
    }, containerClasses);
    const wrapperClasses = classNames(classes.wrapper, {
        [classes.focus]: isFocus,
    });
    useEffect(() => {
        if (initialFocus) {
            inputRef.current?.focus();
        }
    }, []);
    useEffect(() => {
        if (isAutocompleteActive && !isFocus) {
            inputRef.current?.focus();
        }
    }, [isAutocompleteActive]);
    const handleMaskedInputChange = (value) => {
        onChange && onChange(value);
    };
    const handleInputChange = (ev) => {
        const newValue = ev.target.value;
        onChange && onChange(newValue);
    };
    const handleFocus = () => {
        setFocus(true);
        onFocus && onFocus();
    };
    const handleBlur = event => {
        const target = event.relatedTarget;
        const shouldFocus = isAutocompleteActive && excludeBlurClasses.some(el => target?.classList?.contains(el));
        if (!shouldFocus) {
            setFocus(false);
            onBlur && onBlur(event);
        }
        else {
            inputRef.current?.focus();
        }
    };
    const handleClearClick = () => {
        setFocus(false);
        onChange && onChange('');
    };
    const iconMap = {
        clear: (_jsx("button", { className: classes.clear, onMouseDown: handleClearClick, children: _jsx(FocusCrossIcon, { containerClasses: classes.clearIcon }) })),
        error: _jsx(InputErrorIcon, { containerClasses: classes.errorIcon }),
    };
    const input = (_jsx("input", { ref: inputRef, onChange: handleInputChange, value: value, onBlur: handleBlur, onFocus: handleFocus, disabled: disabled || loading, className: classes.input, placeholder: placeholder }));
    const maskedInputMap = {
        phone: (_jsx(MaskedInput, { id: id, placeholder: placeholder, onChange: handleMaskedInputChange, value: value, onBlur: handleBlur, onFocus: handleFocus, disabled: disabled || loading, maskVariant: "phone", inputRef: inputRef })),
    };
    return (_jsxs("div", { className: blockClasses, children: [label && (_jsxs("label", { className: classes.label, htmlFor: id, children: [label, !withoutIcons && (disabled || loading) && _jsx(InputLockIcon, {})] })), _jsxs("div", { className: wrapperClasses, children: [_jsxs("div", { className: classes.inputWrapper, children: [!withoutIcons && (_jsxs("div", { className: classes.icons, children: [isFocus && !isEmpty && iconMap.clear, !isValid && !isFocus && iconMap.error, !loading && autocompleteIcon] })), mask ? maskedInputMap[mask] : input, loading && _jsx(Spinner, { color: "black", size: "md", className: classes.spinner, isAbsolutePos: false })] }), autocompleteBlock] }), message && _jsx("p", { className: classes.desc, children: message })] }));
};
export default Input;
