import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PromoCodeErrorName } from '@store/models/promocode/types';
import { helpPhoneLink } from '@const';
import { store } from '@store';
export const promoCodeButtonText = 'Ввести промокод';
export const promoCodeLength = 9;
export const getValidationText = (isLoading, error, promocode) => {
    if (promocode && !error) {
        return `${promocode.duration} дней по промокоду`;
    }
    if (!isLoading && !error) {
        return null;
    }
    if (isLoading) {
        return 'Проверяем промокод ...';
    }
    const textMap = {
        [PromoCodeErrorName.ProMISCounterLimitExceeded]: _jsx(_Fragment, { children: "\u0414\u0430\u043D\u043D\u044B\u0439 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043D\u0435 \u043C\u043E\u0436\u0435\u0442 \u0431\u044B\u0442\u044C \u043F\u0440\u0438\u043C\u0435\u043D\u0451\u043D" }),
        [PromoCodeErrorName.ProMISNotInvolved]: _jsx(_Fragment, { children: "\u0414\u0430\u043D\u043D\u044B\u0439 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043D\u0435 \u043C\u043E\u0436\u0435\u0442 \u0431\u044B\u0442\u044C \u043F\u0440\u0438\u043C\u0435\u043D\u0435\u043D \u0432 \u0440\u0430\u043C\u043A\u0430\u0445 \u0430\u043A\u0446\u0438\u0438" }),
        [PromoCodeErrorName.ProMISOutdated]: _jsx(_Fragment, { children: "\u0418\u0441\u0442\u0435\u043A \u0441\u0440\u043E\u043A \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044F \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434\u0430" }),
        [PromoCodeErrorName.ProMISPromotionLimitExceeded]: _jsx(_Fragment, { children: "\u0418\u0441\u0442\u0451\u043A \u0441\u0440\u043E\u043A \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044F \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434\u0430" }),
        [PromoCodeErrorName.ProMISRedemptionQuantityIsOver]: _jsx(_Fragment, { children: "\u0414\u0430\u043D\u043D\u044B\u0439 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u0443\u0436\u0435 \u0431\u044B\u043B \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u043D" }),
        [PromoCodeErrorName.PromisNotFound]: _jsx(_Fragment, { children: "\u0414\u0430\u043D\u043D\u044B\u0439 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D" }),
        [PromoCodeErrorName.PromiseRedeemed]: _jsx(_Fragment, { children: "\u0423 \u0432\u0430\u0441 \u0443\u0436\u0435 \u043F\u0440\u0438\u043C\u0435\u043D\u0451\u043D \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434" }),
        [PromoCodeErrorName.PromotionNotFound]: _jsx(_Fragment, { children: "\u0414\u0430\u043D\u043D\u044B\u0439 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D" }),
        [PromoCodeErrorName.CustomApplyError]: _jsxs(_Fragment, { children: ["\u041D\u0435 \u0443\u0434\u0430\u043B\u043E\u0441\u044C \u043F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434. \u041F\u043E\u0432\u0442\u043E\u0440\u0438\u0442\u0435 \u043F\u043E\u043F\u044B\u0442\u043A\u0443 \u0438\u043B\u0438 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432\u00A0\u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443 ", helpPhoneLink] }),
        [PromoCodeErrorName.CustomValidateError]: (_jsxs(_Fragment, { children: ["\u041D\u0435 \u0443\u0434\u0430\u043B\u043E\u0441\u044C \u043F\u0440\u043E\u0432\u0435\u0440\u0438\u0442\u044C \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434. ", _jsx("br", {}), ' ', _jsx("button", { className: "link link-primary", onClick: () => {
                        store.dispatch.promocodeModel.validatePromoCode({});
                    }, children: "\u041F\u043E\u0432\u0442\u043E\u0440\u0438\u0442\u044C \u043F\u043E\u043F\u044B\u0442\u043A\u0443" })] })),
    };
    return error && error in textMap ? textMap[error] : textMap[PromoCodeErrorName.CustomValidateError];
};
export const promocodeRules = [
    _jsxs(_Fragment, { children: ["\u041F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u0434\u043E\u043B\u0436\u0435\u043D \u0441\u043E\u0441\u0442\u043E\u044F\u0442\u044C \u0438\u0437\u00A0", promoCodeLength, " \u043B\u0430\u0442\u0438\u043D\u0441\u043A\u0438\u0445 \u0441\u0438\u043C\u0432\u043E\u043B\u043E\u0432 \u0431\u0435\u0437 \u043B\u0438\u0448\u043D\u0438\u0445 \u043F\u0440\u043E\u0431\u0435\u043B\u043E\u0432 \u0438 \u0437\u043D\u0430\u043A\u043E\u0432, \u043F\u043E\u043B\u0435 \u0447\u0443\u0432\u0441\u0442\u0432\u0438\u0442\u0435\u043B\u044C\u043D\u043E \u043A\u00A0\u0440\u0435\u0433\u0438\u0441\u0442\u0440\u0443"] }),
    _jsx(_Fragment, { children: "\u041A\u0430\u0436\u0434\u044B\u0439 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434 \u043C\u043E\u0436\u043D\u043E \u0430\u043A\u0442\u0438\u0432\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0442\u043E\u043B\u044C\u043A\u043E \u043E\u0434\u0438\u043D \u0440\u0430\u0437 \u0441\u00A0\u043E\u0434\u043D\u043E\u0433\u043E \u043D\u043E\u043C\u0435\u0440\u0430 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430" }),
    _jsx(_Fragment, { children: "\u0415\u0441\u043B\u0438 \u0432\u044B \u0440\u0430\u043D\u0435\u0435 \u0430\u043A\u0442\u0438\u0432\u0438\u0440\u043E\u0432\u0430\u043B\u0438 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434, \u0434\u043E\u0436\u0434\u0438\u0442\u0435\u0441\u044C, \u043F\u043E\u043A\u0430 \u0435\u0433\u043E \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0435 \u0437\u0430\u043A\u043E\u043D\u0447\u0438\u0442\u0441\u044F, \u0430\u00A0\u0437\u0430\u0442\u0435\u043C \u0432\u0432\u0435\u0434\u0438\u0442\u0435 \u043D\u043E\u0432\u044B\u0439 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434" }),
    _jsx(_Fragment, { children: "\u0421\u0440\u043E\u043A \u0430\u043A\u0442\u0438\u0432\u0430\u0446\u0438\u0438 \u043F\u0440\u043E\u043C\u043E\u043A\u043E\u0434\u0430 \u043E\u0433\u0440\u0430\u043D\u0438\u0447\u0435\u043D \u0438\u00A0\u0443\u043A\u0430\u0437\u044B\u0432\u0430\u0435\u0442\u0441\u044F \u0432 \u043C\u043E\u043C\u0435\u043D\u0442 \u0435\u0433\u043E \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u044F" }),
];
