import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import { dialogSelector } from '@models/dialog/selectors';
import { useSelector } from 'react-redux';
import SubscribeSettings from './SubscribeSettings/SubscribeSettings';
import SubscribeDisabling from './SubscribeDisabling/SubscribeDisabling';
import classes from './Dialogs.module.scss';
import classNames from 'classnames';
import { CrossIcon } from '@icons';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import More from './More/More';
import Auth from './SubscribeAdd/Auth/Auth';
import { authSelector } from '@models/user/selectors';
import { anniversarySelector, tariffToChangeSelector, webViewSelector } from '@models/subscription/selectors';
import Success from './SubscribeAdd/Success/Success';
import { isConnectionSelector } from '@models/selectors';
import Error from './Error/Error';
import SubscribeDisablingSuccess from './SubscribeDisablingSuccess/SubscribeDisablingSuccess';
import PromoCodeExists from './SubscribeAdd/PromoCodes/PromoCodeExists/PromoCodeExists';
import { triggerMetricsEvent } from '@metrics';
import DeletePromoCode from './SubscribeAdd/PromoCodes/DeletePromoCode/DeletePromoCode';
import OTPCodeFailed from './SubscribeAdd/OTPCodeFailed/OTPCodeFailed';
import { checkPage } from '@utils/checkPage';
import SubscribeDisablingCancel from './SubscribeDisablingCancel/SubscribeDisablingCancel';
import ChangePlanAccept from './ChangePlan/Accept/ChangePlanAccept';
import ChangePlanConnection from './ChangePlan/Connection/ChangePlanConnection';
import ChangePlanSuccess from './ChangePlan/Success/ChangePlanSuccess';
import { useWindowSize } from '@hooks/useWindowSize';
import { TariffPlanEnum } from '@store/models/subscription/types';
import { PaymentChangeDisabled } from '@shared/Layout/Dialogs/PaymentChangeDisabled/PaymentChangeDisabled';
import Survey from './Survey/Survey';
import { handleCloseSurvey } from '@store/utils/handleCloseSurvey';
const dialogConfig = {
    subscribeSettings: _jsx(SubscribeSettings, {}),
    subscribeDisabling: _jsx(SubscribeDisabling, {}),
    subscribeDisablingSuccess: _jsx(SubscribeDisablingSuccess, {}),
    subscribeDisablingCancel: _jsx(SubscribeDisablingCancel, {}),
    more: _jsx(More, {}),
    auth: _jsx(Auth, {}),
    subscriptionAddSuccess: _jsx(Success, {}),
    error: _jsx(Error, {}),
    promoCodeExists: _jsx(PromoCodeExists, {}),
    deletePromoCode: _jsx(DeletePromoCode, {}),
    otpCodeFailed: _jsx(OTPCodeFailed, {}),
    changePlanAccept: _jsx(ChangePlanAccept, {}),
    changePlanConnection: _jsx(ChangePlanConnection, {}),
    changePlanSuccess: _jsx(ChangePlanSuccess, {}),
    paymentChangeDisabled: _jsx(PaymentChangeDisabled, {}),
    survey: _jsx(Survey, {}),
};
export const acceptChangeMetricsMap = {
    [TariffPlanEnum.Month]: 'changePlanToMonthDialogClose',
    [TariffPlanEnum.Year]: 'changePlanToYearDialogClose',
};
const closeButtonMetricMap = {
    subscribeDisablingCancel: (planToChange, isAnniversary) => isAnniversary ? 'subscriptionRemainsClose' : 'subscriptionSaveModalCloseButton',
    subscribeDisablingSuccess: (planToChange, isAnniversary) => isAnniversary ? 'disabledSubscribeAgreeClose' : 'subscriptionDisabledCloseButton',
    changePlanAccept: planToChange => (planToChange ? acceptChangeMetricsMap[planToChange] : ''),
    changePlanConnection: () => 'changePlanConnectionClose',
    changePlanSuccess: () => 'changePlanSuccessClose',
    subscribeDisabling: (planToChange, isAnniversary) => isAnniversary ? 'disabledSubscribeClose' : '',
};
const Dialogs = () => {
    const { openedDialog } = useSelector(dialogSelector);
    const isAuth = useSelector(authSelector);
    const isWebView = useSelector(webViewSelector);
    const isAnniversary = useSelector(anniversarySelector);
    const { isChildAddedPage, isParentAddedPage, isCreatingPage, isChildAddPage, isUpdateSuccessPage, isUpdatingPage } = checkPage();
    const { isMobileSm } = useWindowSize();
    const planToChange = useSelector(tariffToChangeSelector);
    const { dialogModel: { closeDialog }, } = useTypedDispatch();
    const isConnection = useSelector(isConnectionSelector);
    const dialogType = openedDialog && ['parentPhone', 'paymentMethod', 'enterPromoCode'].includes(openedDialog) && !isAuth ? 'auth' : openedDialog;
    const isSuccessPageOpened = isCreatingPage || isUpdatingPage || isChildAddPage || isChildAddedPage || isParentAddedPage || isUpdateSuccessPage;
    const shouldShowDialog = dialogType && !(isSuccessPageOpened && dialogType === 'subscriptionAddSuccess');
    const dialogClasses = classNames(classes.dialog, classes[openedDialog || ''], classes[dialogType || ''], {
        [classes.webView]: isWebView,
    });
    const handleClose = (ev) => {
        if (isConnection) {
            return;
        }
        const target = ev.target;
        if (target.classList.contains(classes.container) ||
            target.classList.contains(classes.close) ||
            target.classList.contains(classes.indicator)) {
            const closeMetric = dialogType && closeButtonMetricMap[dialogType]?.(planToChange, isAnniversary);
            if (openedDialog === 'survey') {
                handleCloseSurvey();
            }
            if (closeMetric) {
                triggerMetricsEvent({ element: closeMetric });
            }
            closeDialog();
        }
    };
    useEffect(() => {
        document.body.style.overflow = openedDialog && shouldShowDialog ? 'hidden' : '';
    }, [openedDialog]);
    const closeButton = (_jsx("button", { className: classes.close, children: _jsx(CrossIcon, { containerClasses: classes.svg }) }));
    return (_jsx(_Fragment, { children: shouldShowDialog && (_jsx("div", { className: classes.container, onClick: handleClose, children: _jsxs("div", { className: classes.dialogWrapper, children: [_jsx("button", { className: classes.indicator }), isMobileSm && closeButton, _jsxs("div", { className: dialogClasses, children: [!isMobileSm && _jsx("div", { className: classes.header, children: closeButton }), dialogConfig[dialogType]] })] }) })) }));
};
export default Dialogs;
