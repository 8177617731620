export const monthPlanConfig = {
    changePlanToMonthMainPage: {
        ymID: 'pr_change_month_1a',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'roditel',
            eventAction: 'element_click',
            eventLabel: 'ezhemesyachno',
            userAuth: '1',
            actionGroup: 'interactions',
        },
    },
    changePlanToMonthSettingsPage: {
        ymID: 'pr_change_month_1b',
        GTMParams: {
            event: 'vntPod',
            eventCategory: 'upravlyat_podpiskoi',
            eventAction: 'button_click',
            eventLabel: 'smenit_plan',
            eventContent: 'god',
            buttonLocation: 'popup',
            userAuth: '1',
            actionGroup: 'interactions',
        },
    },
    changePlanToMonthAcceptDialog: {
        ymID: 'pr_change_month',
        GTMParams: {
            event: 'vntPod',
            eventCategory: 'podpiska',
            eventAction: 'popup_show',
            eventLabel: 'smenit_plan',
            eventContent: 'mesyac',
            userAuth: '1',
            actionGroup: 'non_interactions',
        },
    },
    changePlanToMonthAcceptButton: {
        ymID: 'pr_change_month_2a',
        GTMParams: {
            event: 'vntPod',
            eventCategory: 'podpiska',
            eventAction: 'button_click',
            eventLabel: 'smenit_plan',
            eventContent: 'mesyac',
            buttonLocation: 'popup',
            userAuth: '1',
            actionGroup: 'interactions',
        },
    },
    changePlanToMonthLeaveButton: {
        ymID: 'pr_change_month_2b',
        GTMParams: {
            event: 'vntPod',
            eventCategory: 'podpiska',
            eventAction: 'button_click',
            eventLabel: 'ostavit_tekuschii_plan',
            eventContent: 'mesyac',
            buttonLocation: 'popup',
            userAuth: '1',
            actionGroup: 'interactions',
        },
    },
    changePlanToMonthDialogClose: {
        ymID: 'pr_change_month_close',
        GTMParams: {
            event: 'vntPod',
            eventCategory: 'podpiska',
            eventAction: 'element_click',
            eventLabel: 'zakryt',
            eventContent: 'mesyac',
            buttonLocation: 'popup',
            userAuth: '1',
            actionGroup: 'interactions',
        },
    },
};
