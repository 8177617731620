import { partnersCardsConfig } from './partnersCardsConfig';
import { moreCardsConfig } from './moreCardsConfig';
import { kionConfig } from './kionConfig';
import { headerLoginConfig } from './headerLoginConfig';
import { bannerSalesConfig } from './bannerSalesConfig';
import { serviceCardsConfig } from './serviceCardsConfig';
import { gmdConfig } from './gmdConfig';
import { monoSubscriptionConfig } from './monoSubscriptionConfig';
import { tariffConfig } from './tariffConfig';
import { backToSchoolConfig } from './backToSchoolConfig';
import { instructionsConfig } from './instruction-page/instructionsConfig';
import { settingsPageConfig } from './settings-page/settingsPageConfig';
import { changePlanConfig } from './change-plan/changePlanConfig';
import { promocodeConfig } from './promocode/promocodeConfig';
import { mtsAnniversaryConfig } from './mtsAnniversary/mtsAnniversaryConfig';
/* export const ymCounterCode = 88980668; */
const successLogin = {
    ymID: '',
    GTMParams: {
        event: 'vntLogin',
        eventCategory: 'avtorizaciya',
        eventAction: 'confirmed',
        eventLabel: 'uspeshnaya_avtorizaciya',
        actionGroup: 'conversions',
        userAuth: '1',
    },
};
export const goalsConfig = {
    parentNotAuth: {
        mainBanner: {
            ymID: 'pr_main',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_show',
                eventLabel: 'mts_junior',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        ...mtsAnniversaryConfig.parentNotAuth,
        ...partnersCardsConfig.parentNotAuth,
        ...moreCardsConfig.parentNotAuth,
        ...gmdConfig.parentNotAuth,
        faqMoreButton: {
            ymID: 'pr_12a',
            GTMParams: {
                event: 'vntMain',
                eventCategory: 'otvety_na_voprosy',
                eventAction: 'button_click',
                eventLabel: 'pokazat_esche',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        footerRules: {
            ymID: 'pr_13a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'link_click',
                eventLabel: 'pravila_mts_junior',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        footerProducts: {
            ymID: 'pr_14a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'link_click',
                eventLabel: 'vse_produkty_mts',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        footerSupport: {
            ymID: 'pr_15a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'link_click',
                eventLabel: 'podderzhka',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        versionButton: {
            ymID: 'pr-ch_swipe',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'pokazat_versiu_dlya_detei',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        kionCardsScroll: {
            ymID: 'pr_scrl_2',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'scroll',
                eventLabel: 'kion',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        moreCardsScroll: {
            ymID: 'pr_scrl_3',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'scroll',
                eventLabel: 'dostupno_bolshe_s_mts',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        additionalCardsScroll: {
            ymID: 'pr_poprobuy_vmeste_scrl_nea',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'scroll',
                eventLabel: 'poprobuite_vmeste',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        ...kionConfig.parentNotAuth,
        ...headerLoginConfig.parentNotAuth,
        ...bannerSalesConfig.parentNotAuth,
        connectSalesButton: {
            ymID: 'pr_1b',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                buttonLocation: 'up',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        inviteFromChildBanner: {
            ymID: 'pr_main_inv',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'popup_show',
                eventLabel: 'priglashenie_v_podpisku',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        inviteAcceptButton: {
            ymID: 'inv_1a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'prinyat_priglashenie',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        inviteRejectButton: {
            ymID: 'inv_1b',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'otklonit',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        inviteLoginButton: {
            ymID: 'pr_2c',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'voiti',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        showAuthPage: {
            ymID: 'pr_voitysnomeraroditelya_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'popup_show',
                eventLabel: 'avtorizaciya',
                userAuth: '0',
                actionGroup: 'non_interactions',
            },
        },
        ...tariffConfig.parentNotAuth,
        headerAuthPageLoginButton: {
            ymID: 'pr_voitysnomeraroditelya_head_voity',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'voiti',
                buttonLocation: 'popup',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        authPageBackButton: {
            ymID: 'pr_voitysnomeraroditelya_back',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                buttonLocation: 'popup',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        showAuthModal: {
            ymID: 'pr_voitysnomeraroditelya_popup_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'popup_show',
                eventLabel: 'avtorizaciya',
                userAuth: '0',
                actionGroup: 'non_interactions',
            },
        },
        authModalLoginButton: {
            ymID: 'pr_voitysnomeraroditelya_popup_voity',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'voiti',
                userAuth: '0',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
            },
        },
        ...backToSchoolConfig.parentNotAuth,
        ...instructionsConfig.parentNotAuth,
        ...promocodeConfig.parentNotAuth,
    },
    parentAuth: {
        ...gmdConfig.parentAuth,
        ...mtsAnniversaryConfig.parentAuth,
        settingsButton: {
            ymID: 'ctrl_1a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'upravlyat_podpiskoi',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        paymentToolButton: {
            ymID: 'ctrl_2a',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'upravlyat_podpiskoi',
                eventAction: 'element_cliсk',
                eventLabel: 'sposob_oplaty',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        settingsDisableButton: {
            ymID: 'ctrl_2b',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'upravlyat_podpiskoi',
                eventAction: 'button_click',
                eventLabel: 'otkluchit_podpisku',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        settingsErrorDisableButton: {
            ymID: 'ctrl_2f',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'otkluchit_podpisku',
                eventContent: 'oplata_ne_spisalas',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscriptionSaveButton: {
            ymID: 'ctrl_3a',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'ostavit_podpisku',
                actionGroup: 'interactions',
                userAuth: '1',
                buttonLocation: 'popup',
            },
        },
        subscriptionDisableButton: {
            ymID: 'ctrl_3b',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'otkluchit_podpisku',
                actionGroup: 'interactions',
                userAuth: '1',
                buttonLocation: 'popup',
            },
        },
        subscriptionDisabledModal: {
            ymID: 'ctrl_4a',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'confirmed',
                eventLabel: 'podpiska_otkluchena',
                actionGroup: 'conversions',
                userAuth: '1',
                buttonLocation: 'popup',
            },
        },
        settingsPayButton: {
            ymID: 'ctrl_2e',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'oplatit',
                eventContent: 'oplata_ne_spisalas',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscriptionCardsScroll: {
            ymID: 'pr_scrl_1',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'scroll',
                eventLabel: 'kartochki_podpiski',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        ...serviceCardsConfig.parent,
        ...partnersCardsConfig.parentAuth,
        ...bannerSalesConfig.parentAuth,
        successLogin,
        continueInvitationAccept: {
            ymID: 'pr_3c',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'prodolzhit',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        checkInvitationPayTrial: {
            ymID: 'pr_4d',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vybrat_sposob_oplaty',
                eventContent: 'trial',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        checkInvitationPay: {
            ymID: 'pr_4e',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vybrat_sposob_oplaty',
                eventContent: 'purchase',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        checkInvitationPayFree: {
            ymID: 'pr_4f',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                eventContent: 'trial',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        mainBannerSalesButton: {
            ymID: 'pr_1d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        connectSalesButton: {
            ymID: 'pr_1e',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        roleParentButton: {
            ymID: 'pr_3a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'roditel',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        checkPayTrial: {
            ymID: 'pr_4a',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vybrat_sposob_oplaty',
                eventContent: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        checkPay: {
            ymID: 'pr_4b',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vybrat_sposob_oplaty',
                eventContent: 'purchase',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        checkPayFree: {
            ymID: 'pr_4c',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                eventContent: 'purchase',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        checkPayFreeChangeParent: {
            ymID: 'pr_2c',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'karandash',
                eventContent: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        slavesScroll: {
            ymID: 'pr_scrl_3',
            GTMParams: {
                event: 'vntDob',
                eventCategory: 'dobavte_rebenka',
                eventAction: 'element_click',
                eventLabel: 'telefon',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscribeChildButton: {
            ymID: 'pr_5a',
            GTMParams: {
                event: 'vntDob',
                eventCategory: 'dobavte_rebenka',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscribeSuccessScreen: {
            ymID: 'pr_7a',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'confirmed',
                eventLabel: 'podpiska_podkluchena',
                buttonLocation: 'popup',
                actionGroup: 'conversions',
                userAuth: '1',
            },
        },
        subscribeSuccessScreenBackButton: {
            ymID: 'pr_7a_back',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscribeSuccessServicesButton: {
            ymID: 'pr_podkluchena_vseservisy',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'smotret_servisy',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        addChildButton: {
            ymID: 'pr_5b',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'dobavit_rebenka',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        roleChildButton: {
            ymID: 'pr_3b',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'rebenok',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        inviteAcceptButton: {
            ymID: 'inv_1c',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'prinyat_priglashenie',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        inviteRejectButton: {
            ymID: 'inv_1d',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'otklonit',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        closeConnectParent: {
            ymID: 'pr_close_4a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_click',
                eventLabel: 'zakryt',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        showConnectParent: {
            ymID: 'preloader_show_1a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'popup_show',
                eventLabel: 'podkluchaem_roditelya',
                buttonLocation: 'screen',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        ...tariffConfig.parentAuth,
        addChildConnectedScreen: {
            ymID: 'pr_ostaloschutchut_pokaz',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'popup_show',
                eventLabel: 'ostalos_chut_chut',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        addChildConnectedScreenOkButton: {
            ymID: 'pr_6a',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'ponyatno',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        availabilityCheckingScreen: {
            ymID: 'pr_proveraemdostupnost_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'popup_show',
                eventLabel: 'proveryaem_dostupnost_podpiski',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        ...instructionsConfig.parentAuth,
        ...settingsPageConfig.parentAuth,
        ...changePlanConfig.parentAuth,
        ...promocodeConfig.parentAuth,
    },
    childNotAuth: {
        mainBanner: {
            ymID: 'ch_main',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_show',
                eventLabel: 'mts_junior',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        ...partnersCardsConfig.childNotAuth,
        ...moreCardsConfig.childNotAuth,
        ...monoSubscriptionConfig.childNotAuth,
        versionButton: {
            ymID: 'ch-pr_swipe',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'pokazat_versiu_dlya_roditelei',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        kionCardsScroll: {
            ymID: 'ch_scrl_2',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'scroll',
                eventLabel: 'kion',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        moreCardsScroll: {
            ymID: 'ch_scrl_3',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'scroll',
                eventLabel: 'tebe_dostupno_bolshe_s_mts',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        additionalCardsScroll: {
            ymID: 'ch_scrl_4',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'scroll',
                eventLabel: 'poprobui_vmeste_s_podpiskoi',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        ...kionConfig.childNotAuth,
        ...headerLoginConfig.childNotAuth,
        ...bannerSalesConfig.childNotAuth,
        authPageLoginButton: {
            ymID: 'ch_voitysnomerarebenka_voity',
            GTMParams: {
                event: 'vntLogin',
                eventCategory: 'avtorizaciya',
                eventAction: 'button_click',
                eventLabel: 'voiti',
                eventContent: 'rebenok',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        headerAuthPageLoginButton: {
            ymID: 'ch_voitysnomerarebenka_head_voity',
            GTMParams: {
                event: 'vntLogin',
                eventCategory: 'avtorizaciya',
                eventAction: 'button_click',
                eventLabel: 'voiti',
                eventContent: 'rebenok',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        authPageBackButton: {
            ymID: 'ch_voitysnomerarebenka_back',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                buttonLocation: 'popup',
                userAuth: '0',
                actionGroup: 'interactions',
            },
        },
        showAuthPage: {
            ymID: 'ch_1f',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'popup_show',
                eventLabel: 'voiti',
                userAuth: '0',
                actionGroup: 'non_interactions',
            },
        },
        ...instructionsConfig.childNotAuth,
    },
    childAuth: {
        ...serviceCardsConfig.child,
        ...moreCardsConfig.childAuth,
        ...partnersCardsConfig.childAuth,
        ...monoSubscriptionConfig.childAuth,
        successLogin: {
            ymID: 'ch_3f',
            GTMParams: {
                event: 'vntLogin',
                eventCategory: 'avtorizaciya',
                eventAction: 'confirmed',
                eventLabel: 'uspeshnaya_avtorizaciya',
                eventContent: 'rebenok',
                actionGroup: 'conversions',
                userAuth: '1',
            },
        },
        unsuccessLogin: {
            ymID: 'ch_4f',
            GTMParams: {
                event: 'vntLogin',
                eventCategory: 'avtorizaciya',
                eventAction: 'rejected',
                eventLabel: 'neuspeshnaya_avtorizaciya',
                eventContent: 'rebenok',
                actionGroup: 'conversions',
                userAuth: '1',
            },
        },
        inviteParentButton: {
            ymID: 'ch_3a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'priglasit_roditelya',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        closeInvite: {
            ymID: 'ch_close_3a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'zakryt',
                actionGroup: 'interactions',
                buttonLocation: 'popup',
                userAuth: '1',
            },
        },
        closeSuccessInvite: {
            ymID: 'ch_close_4a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'zakryt',
                actionGroup: 'interactions',
                buttonLocation: 'popup',
                userAuth: '1',
            },
        },
        showInvite: {
            ymID: 'ch_5f',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'popup_show',
                eventLabel: 'priglasit_roditelya',
                actionGroup: 'non_interactions',
                buttonLocation: 'screen',
                userAuth: '1',
            },
        },
        successParentInvitation: {
            ymID: 'ch_12c',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'confirmed',
                eventLabel: 'uspeshnoe_priglashenie',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        downloadWallpapersButton: {
            ymID: 'ch_4a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'button_click',
                eventLabel: 'zabrat_sebe_oboi',
                actionGroup: 'interactions',
                buttonLocation: 'popup',
                userAuth: '1',
            },
        },
        ...bannerSalesConfig.childAuth,
        ...instructionsConfig.childAuth,
    },
};
