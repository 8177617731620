import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import classes from './Dropdown.module.scss';
import Input from '@shared/Input/Input';
import { useState } from 'react';
import classNames from 'classnames';
import { getMaskedPhone } from '@utils/masks';
import uniqueId from 'lodash.uniqueid';
import { phoneValidator } from '@utils/validators';
import { ArrowDownIcon } from '@icons';
const minStartSearchLength = 3;
const Dropdown = ({ isValid = true, className, variants, onChange, autocompleteValue, setAutocompleteValue, ...props }) => {
    const [isActive, setActive] = useState(false);
    const filteredVariants = variants.filter(variant => variant.phone.startsWith(autocompleteValue));
    const autocompleteClasses = classNames(classes.wrapper, className);
    const handleFocus = () => {
        if (autocompleteValue.length > 0) {
            setActive(true);
        }
    };
    const handleBlur = () => {
        setActive(false);
    };
    const handleChange = (value) => {
        setAutocompleteValue(value);
        if (!isActive) {
            setActive(value.length >= minStartSearchLength && !phoneValidator(value));
        }
        onChange && onChange(value);
    };
    const handleVariantClick = (phone) => () => {
        setAutocompleteValue(phone);
        setActive(false);
        onChange && onChange(phone);
    };
    const handleArrowClick = () => {
        setActive(!isActive);
    };
    const autocompleteBlock = filteredVariants.length ? (_jsx(_Fragment, { children: isActive && (_jsx("ul", { className: classes.variants, children: filteredVariants.map(({ name, phone }) => (_jsx("li", { className: classes.variantsItem, children: _jsxs("button", { type: "button", className: classes.button, onMouseDown: handleVariantClick(phone), children: [_jsx("span", { className: classes.name, children: name }), _jsx("span", { className: classes.phone, children: getMaskedPhone(phone) })] }) }, uniqueId()))) })) })) : null;
    const autocompleteIcon = filteredVariants.length ? (_jsx("button", { type: "button", className: classNames(classes.arrowButton, { [classes.opened]: isActive }), onMouseDown: handleArrowClick, children: _jsx(ArrowDownIcon, { iconClasses: classNames(classes.arrow, 'no-transition') }) })) : null;
    return (_jsx("div", { className: autocompleteClasses, children: _jsx(Input, { value: autocompleteValue, isValid: isValid, onBlur: handleBlur, onFocus: handleFocus, onChange: handleChange, autocompleteBlock: autocompleteBlock, isAutocompleteActive: isActive, autocompleteIcon: autocompleteIcon, excludeBlurClasses: [classes.arrowButton, classes.arrow], mask: "phone", ...props }) }));
};
export default Dropdown;
