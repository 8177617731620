export const getSubscriptionStatuses = (subscriptionStatus) => {
    const isSubscriptionActive = subscriptionStatus === 'active';
    const isSubscriptionCanceled = subscriptionStatus === 'canceled';
    const isSubscriptionSuspended = subscriptionStatus === 'suspended';
    const isSubscriptionProcess = subscriptionStatus === 'adding';
    return {
        isSubscriptionSuspended,
        isSubscriptionCanceled,
        isSubscriptionActive,
        isSubscriptionProcess,
    };
};
