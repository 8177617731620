const getCommonParentConfig = (userAuth) => ({
    pingoInsctructionAbout: {
        ymID: 'pr_gmd_aboutservice',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'roditel',
            eventAction: 'screen_show',
            eventLabel: 'o_servise_gde_moi_deti',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    pingoInsctructionConnect: {
        ymID: 'pr_gmd_kakpodkl',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_gde_moi_deti',
            eventAction: 'screen_show',
            eventLabel: 'kak_podkluchit_gde_moi_deti',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    pingoInsctructionConnectTopButton: {
        ymID: 'pr_gmd_aboutservice_kakpodkl_top',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_gde_moi_deti',
            eventAction: 'element_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    pingoInsctructionConnectBottomButton: {
        ymID: 'pr_gmd_aboutservice_kakpodkl_bottom',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_gde_moi_deti',
            eventAction: 'element_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'down',
            actionGroup: 'interactions',
        },
    },
    pingoInsctructionAboutTopButton: {
        ymID: 'pr_gmd_kakpodkl_aboutservice',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_gde_moi_deti',
            eventAction: 'element_click',
            eventLabel: 'o_servise',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    pingoInsctructionCloseButton: {
        ymID: 'pr_gmd_instruction_close',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_gde_moi_deti',
            eventAction: 'element_click',
            eventLabel: 'zakryt',
            userAuth,
            actionGroup: 'interactions',
        },
    },
    pingoInsctructionDownloadButton: {
        ymID: 'pr_gmd_kakpodkl_download',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_gde_moi_deti',
            eventAction: 'button_click',
            eventLabel: 'skachat_prilozhenie',
            userAuth,
            actionGroup: 'interactions',
        },
    },
});
export const pingoInsctructionPageConfig = {
    parentNotAuth: {
        ...getCommonParentConfig('0'),
    },
    parentAuth: {
        ...getCommonParentConfig('1'),
    },
};
