export var ShownSubscriptionStatusParentEnum;
(function (ShownSubscriptionStatusParentEnum) {
    ShownSubscriptionStatusParentEnum["Active"] = "active";
    ShownSubscriptionStatusParentEnum["Suspended"] = "suspended";
    ShownSubscriptionStatusParentEnum["Canceled"] = "canceled";
    ShownSubscriptionStatusParentEnum["Adding"] = "adding";
    ShownSubscriptionStatusParentEnum["ChildAdd"] = "childAdd";
    ShownSubscriptionStatusParentEnum["Draft"] = "draft";
})(ShownSubscriptionStatusParentEnum || (ShownSubscriptionStatusParentEnum = {}));
export var ShownSubscriptionStatusChildEnum;
(function (ShownSubscriptionStatusChildEnum) {
    ShownSubscriptionStatusChildEnum["Active"] = "active";
    ShownSubscriptionStatusChildEnum["Suspended"] = "suspended";
    ShownSubscriptionStatusChildEnum["Canceled"] = "canceled";
    ShownSubscriptionStatusChildEnum["Adding"] = "adding";
    ShownSubscriptionStatusChildEnum["Draft"] = "draft";
})(ShownSubscriptionStatusChildEnum || (ShownSubscriptionStatusChildEnum = {}));
export var SubscriptionStatusParentEnum;
(function (SubscriptionStatusParentEnum) {
    SubscriptionStatusParentEnum["Active"] = "active";
    SubscriptionStatusParentEnum["Suspended"] = "suspended";
    SubscriptionStatusParentEnum["Canceled"] = "canceled";
    SubscriptionStatusParentEnum["Adding"] = "adding";
    SubscriptionStatusParentEnum["Creating"] = "creating";
    SubscriptionStatusParentEnum["Updating"] = "updating";
    SubscriptionStatusParentEnum["Deleting"] = "deleting";
    SubscriptionStatusParentEnum["Failed"] = "failed";
    SubscriptionStatusParentEnum["ChildAdd"] = "childAdd";
    SubscriptionStatusParentEnum["Draft"] = "draft";
})(SubscriptionStatusParentEnum || (SubscriptionStatusParentEnum = {}));
export var SubscriptionStatusChildEnum;
(function (SubscriptionStatusChildEnum) {
    SubscriptionStatusChildEnum["Draft"] = "draft";
    SubscriptionStatusChildEnum["Active"] = "active";
    SubscriptionStatusChildEnum["Suspended"] = "suspended";
    SubscriptionStatusChildEnum["Canceled"] = "canceled";
    SubscriptionStatusChildEnum["Adding"] = "adding";
    SubscriptionStatusChildEnum["Creating"] = "creating";
    SubscriptionStatusChildEnum["Updating"] = "updating";
    SubscriptionStatusChildEnum["Deleting"] = "deleting";
    SubscriptionStatusChildEnum["Failed"] = "failed";
})(SubscriptionStatusChildEnum || (SubscriptionStatusChildEnum = {}));
export var RoleEnum;
(function (RoleEnum) {
    RoleEnum["Parent"] = "PARENT";
    RoleEnum["Child"] = "CHILD";
})(RoleEnum || (RoleEnum = {}));
export var ShownKionStatusEnum;
(function (ShownKionStatusEnum) {
    ShownKionStatusEnum["Adding"] = "adding";
    ShownKionStatusEnum["Active"] = "active";
    ShownKionStatusEnum["Canceled"] = "canceled";
})(ShownKionStatusEnum || (ShownKionStatusEnum = {}));
export var KionStatusEnum;
(function (KionStatusEnum) {
    KionStatusEnum["Undefined"] = "undefined";
    KionStatusEnum["Active"] = "active";
    KionStatusEnum["Canceled"] = "canceled";
})(KionStatusEnum || (KionStatusEnum = {}));
export var ServiceStatusEnum;
(function (ServiceStatusEnum) {
    ServiceStatusEnum["Active"] = "active";
    ServiceStatusEnum["Suspended"] = "suspended";
    ServiceStatusEnum["Canceled"] = "canceled";
    ServiceStatusEnum["Adding"] = "adding";
    ServiceStatusEnum["Deleting"] = "deleting";
    ServiceStatusEnum["Failed"] = "failed";
    ServiceStatusEnum["Undefined"] = "undefined";
})(ServiceStatusEnum || (ServiceStatusEnum = {}));
export var TarifficationStatusEnum;
(function (TarifficationStatusEnum) {
    TarifficationStatusEnum["Successful"] = "successful";
    TarifficationStatusEnum["Unsuccessful"] = "unsuccessful";
    TarifficationStatusEnum["Inprocess"] = "in_process";
    TarifficationStatusEnum["TrialPeriod"] = "trial_period";
    TarifficationStatusEnum["Undefined"] = "undefined";
})(TarifficationStatusEnum || (TarifficationStatusEnum = {}));
export var PaymentTokenStatusEnum;
(function (PaymentTokenStatusEnum) {
    PaymentTokenStatusEnum["Undefined"] = "undefined";
    PaymentTokenStatusEnum["Creating"] = "creating";
    PaymentTokenStatusEnum["Active"] = "active";
    PaymentTokenStatusEnum["Declined"] = "declined";
    PaymentTokenStatusEnum["Disabled"] = "disabled";
    PaymentTokenStatusEnum["Failed"] = "failed";
})(PaymentTokenStatusEnum || (PaymentTokenStatusEnum = {}));
export var SourceEnum;
(function (SourceEnum) {
    SourceEnum["Landing"] = "LANDING";
    SourceEnum["MyMTS"] = "MY_MTS";
    SourceEnum["LogicLike"] = "LOGIC_LIKE";
    SourceEnum["GMD"] = "FIND_MY_KIDS";
    SourceEnum["Launcher"] = "BABY_LAUNCHER";
})(SourceEnum || (SourceEnum = {}));
export var OriginEnum;
(function (OriginEnum) {
    OriginEnum["Landing"] = "LANDING";
    OriginEnum["PaySystemOne"] = "PAY_SYSTEM_1";
    OriginEnum["Foris60"] = "FORIS_60";
    OriginEnum["Netariff"] = "NETARIFF";
    OriginEnum["MTSAnniversary"] = "30TH_ANNIVERSARY";
})(OriginEnum || (OriginEnum = {}));
export var PaymentTypeEnum;
(function (PaymentTypeEnum) {
    PaymentTypeEnum["Msisdn"] = "MSISDN";
    PaymentTypeEnum["BankCard"] = "BANK_CARD";
    PaymentTypeEnum["Undefined"] = "UNDEFINED";
})(PaymentTypeEnum || (PaymentTypeEnum = {}));
export var TariffPlanEnum;
(function (TariffPlanEnum) {
    TariffPlanEnum["Month"] = "MONTHLY";
    TariffPlanEnum["Year"] = "ANNUAL";
})(TariffPlanEnum || (TariffPlanEnum = {}));
export var CreateSubscriptionErrorName;
(function (CreateSubscriptionErrorName) {
    CreateSubscriptionErrorName["checkAvailabilityError"] = "checkAvailabilityError";
    CreateSubscriptionErrorName["SubscriptionNotAnavailable"] = "subscriptionNotAnavailable";
    CreateSubscriptionErrorName["CreateError"] = "createError";
    CreateSubscriptionErrorName["PaymentError"] = "paymentError";
    CreateSubscriptionErrorName["CheckCreateError"] = "checkCreateError";
    CreateSubscriptionErrorName["ContentForbiddenForTP"] = "contentForbiddenForTP";
    CreateSubscriptionErrorName["ContentBlockActivated"] = "contentBlockActivated";
    CreateSubscriptionErrorName["ForbiddenOtherContent"] = "forbiddenOtherContent";
    CreateSubscriptionErrorName["ContentForbiddenForTPChild"] = "contentForbiddenForTPChild";
    CreateSubscriptionErrorName["ContentBlockActivatedChild"] = "contentBlockActivatedChild";
    CreateSubscriptionErrorName["SubscriptionAlreadyExistsChild"] = "subscriptionAlreadyExistsChild";
    CreateSubscriptionErrorName["SubscriptionAlreadyExistsParent"] = "subscriptionAlreadyExistsParent";
    CreateSubscriptionErrorName["DefaultError"] = "defaultError";
    CreateSubscriptionErrorName["DefaultChildError"] = "defaultChildError";
    CreateSubscriptionErrorName["PromocodeAlreadyApplied"] = "promocodeAlreadyApplied";
})(CreateSubscriptionErrorName || (CreateSubscriptionErrorName = {}));
export var ChildPhoneValidateErrorName;
(function (ChildPhoneValidateErrorName) {
    ChildPhoneValidateErrorName["NumbersMatch"] = "numbersMatch";
    ChildPhoneValidateErrorName["SubscribedParent"] = "subscribedParent";
    ChildPhoneValidateErrorName["SubscribedChild"] = "subscribedChild";
    ChildPhoneValidateErrorName["ChildAddUnavailable"] = "childAddUnavailable";
    ChildPhoneValidateErrorName["ChildPhoneValidateError"] = "childPhoneValidateError";
})(ChildPhoneValidateErrorName || (ChildPhoneValidateErrorName = {}));
