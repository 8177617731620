import { createModel } from '@rematch/core';
import { untypedWindow } from '@const';
import { handleCloseSurvey } from '@store/utils/handleCloseSurvey';
import Cookies from 'js-cookie';
import { getPlatform } from '@store/utils/getPlatform';
const withChildEvent = 'event_659d5a96-7d9c-47e8-9280-da7e128d530b';
const withoutChildEvent = 'event_54e867a4-64a5-4b5e-be67-7a189cfde186';
const appId = 'showcase_f0dc4947-d203-4f1c-ba10-e8059e6bbfa9';
const defaultParams = { url: null, isReady: false };
const initialState = {
    surveyWidgetState: 'pending',
    surveyParams: defaultParams,
};
export const SurveyWidgetModel = createModel()({
    state: initialState,
    reducers: {
        setSurveyWidgetLoaded: state => {
            return { ...state, surveyWidgetState: 'loaded' };
        },
        setSurveyWidgetReady: state => {
            return { ...state, surveyWidgetState: 'ready' };
        },
        setSurveyUrl: (state, url) => {
            return { ...state, surveyParams: { ...state.surveyParams, url } };
        },
        setSurveyReady: state => {
            return { ...state, surveyParams: { ...state.surveyParams, isReady: true } };
        },
        clearSurveyParams: state => {
            return { ...state, surveyParams: defaultParams };
        },
    },
    effects: dispatch => ({
        async initSurveyWidget(withChild, rootState) {
            const isLoaded = rootState.surveyWidgetModel.surveyWidgetState === 'loaded';
            if (!isLoaded) {
                return;
            }
            const appMetaData = {
                appId,
                token: Cookies.get('session_id') || '',
                msisdnMain: rootState.userModel.data.msisdn,
                msisdnSlave: rootState.subscriptionModel.subscription?.msisdnChild || '',
                operatingSystem: getPlatform(),
                operatingSystemVersion: null,
                appVersion: '13.0.0',
                url: window.location.href,
                isDarkMode: rootState.userModel.theme !== 'light',
            };
            const launchPoll = (url) => {
                dispatch.surveyWidgetModel.setSurveyUrl(url);
            };
            await untypedWindow.tNPS?.start(launchPoll, appMetaData);
            const regEvent = await untypedWindow.tNPS?.registerEvent(withChild ? withChildEvent : withoutChildEvent);
            if (regEvent) {
                dispatch.dialogModel.openDialog('survey');
            }
            // dispatch.surveyWidgetModel.setSurveyWidgetReady();
            window.addEventListener('message', (e) => {
                if (e.data === 'pollPlayerClose') {
                    handleCloseSurvey();
                    dispatch.dialogModel.closeDialog();
                }
                if (e.data === 'pollReady') {
                    dispatch.surveyWidgetModel.setSurveyReady();
                }
            });
        },
    }),
});
