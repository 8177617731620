import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Layout from '@components/shared/Layout/Layout';
import classes from './UpdateSubscription.module.scss';
import { GTMScreenName, triggerMetricsEvent } from '@metrics';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { abonentSelector, authSelector, getUserDataSelector, msisdnSelector } from '@store/models/user/selectors';
import { anniversarySelector, availableSubscriptionsSelector, checkAvailabilityStatusSelector, isProlongationAvailableSelector, isProlongationAvailableSelectorAnniversary, requestChangePaymentTool, subscriptionSelector, updatePaymentTokenStatusSelector, updateStatusSelector, } from '@store/models/subscription/selectors';
import { useNavigate } from 'react-router-dom';
import CustomLink from '@shared/Link/Link';
import { Page, mtsJuniorRules, planInfoMap } from '@const';
import { RoleEnum, TariffPlanEnum } from '@store/models/subscription/types';
import { pluralize } from '@utils/pluralize';
import { getMaskedPhone } from '@utils/masks';
import Button from '@components/shared/Button/Button';
import Auth from '../Auth/Auth';
import ContentBlock from '../ContentBlock/ContentBlock';
import Loader from './Loader/Loader';
import { tariffPlanConfig } from '@configs/tariffPlans';
import { getBuyButtonMetricName, getOfferShowMetricName, lsPaymentTextConfig } from '../CreateSubscription/configs';
const getOfferText = ({ activeRemainingDays, planRemainingDays, price, mtsAnniversary, isAbonent, isTrial, }) => {
    const days = activeRemainingDays + planRemainingDays;
    const leftText = pluralize(activeRemainingDays, 'leftMUpper');
    if (mtsAnniversary) {
        if (activeRemainingDays === 0 && isTrial) {
            return (_jsxs(_Fragment, { children: ["\u0414\u043B\u044F \u0432\u0430\u0441 \u0435\u0449\u0451 30 \u0434\u043D\u0435\u0439 \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E, \u0434\u0430\u043B\u0435\u0435 \u2014 ", _jsx("span", { className: classes.offerPrice, children: price }), ". \u0414\u043B\u044F \u043B\u044E\u0431\u044B\u0445 \u043E\u043F\u0435\u0440\u0430\u0442\u043E\u0440\u043E\u0432"] }));
        }
        if (activeRemainingDays === 0) {
            return (_jsxs(_Fragment, { children: [_jsx("span", { className: classes.offerPrice, children: price }), ". \u0414\u043B\u044F \u043B\u044E\u0431\u044B\u0445 \u043E\u043F\u0435\u0440\u0430\u0442\u043E\u0440\u043E\u0432"] }));
        }
        if (isAbonent && isTrial) {
            return (_jsxs(_Fragment, { children: ["\u0414\u043B\u044F \u0432\u0430\u0441 \u0435\u0449\u0451 30 \u0434\u043D\u0435\u0439 \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E, \u0434\u0430\u043B\u0435\u0435 \u2014 ", _jsx("span", { className: classes.offerPrice, children: price }), ". ", pluralize(activeRemainingDays, 'left'), " ", activeRemainingDays, " ", pluralize(activeRemainingDays, 'days'), " \u043F\u043E \u0430\u043A\u0446\u0438\u0438 \u00AB30 \u043B\u0435\u0442 \u041C\u0422\u0421\u00BB ", pluralize(activeRemainingDays, 'fire')] }));
        }
        if (!isAbonent && !isTrial) {
            return (_jsxs(_Fragment, { children: [_jsx("span", { className: classes.offerPrice, children: price }), ". ", pluralize(activeRemainingDays, 'left'), " ", activeRemainingDays, " ", pluralize(activeRemainingDays, 'days'), " \u043F\u043E \u0430\u043A\u0446\u0438\u0438 \u00AB30 \u043B\u0435\u0442 \u041C\u0422\u0421\u00BB ", pluralize(activeRemainingDays, 'fire'), ". \u0414\u043B\u044F \u043B\u044E\u0431\u044B\u0445 \u043E\u043F\u0435\u0440\u0430\u0442\u043E\u0440\u043E\u0432"] }));
        }
        if (isAbonent && !isTrial) {
            return (_jsxs(_Fragment, { children: [_jsx("span", { className: classes.offerPrice, children: price }), ". ", pluralize(activeRemainingDays, 'left'), " ", activeRemainingDays, " ", pluralize(activeRemainingDays, 'days'), " \u043F\u043E \u0430\u043A\u0446\u0438\u0438 \u00AB30 \u043B\u0435\u0442 \u041C\u0422\u0421\u00BB ", pluralize(activeRemainingDays, 'fire')] }));
        }
        if (!isAbonent) {
            return (_jsxs(_Fragment, { children: ["\u0414\u043B\u044F \u0432\u0430\u0441 \u0435\u0449\u0451 ", activeRemainingDays, " ", pluralize(activeRemainingDays, 'days'), " \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E, \u0434\u0430\u043B\u0435\u0435 \u2014 ", _jsx("span", { className: classes.offerPrice, children: price }), ". ", pluralize(activeRemainingDays, 'left'), " ", activeRemainingDays, " ", pluralize(activeRemainingDays, 'days'), " \u043F\u043E \u0430\u043A\u0446\u0438\u0438 \u00AB30 \u043B\u0435\u0442 \u041C\u0422\u0421\u00BB ", pluralize(activeRemainingDays, 'fire'), ". \u0414\u043B\u044F \u043B\u044E\u0431\u044B\u0445 \u043E\u043F\u0435\u0440\u0430\u0442\u043E\u0440\u043E\u0432"] }));
        }
        return (_jsxs(_Fragment, { children: ["\u0414\u043B\u044F \u0432\u0430\u0441 \u0435\u0449\u0451 ", activeRemainingDays, " ", pluralize(activeRemainingDays, 'days'), " \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E, \u0434\u0430\u043B\u0435\u0435 \u2014 ", _jsx("span", { className: classes.offerPrice, children: price }), ". ", pluralize(activeRemainingDays, 'left'), " ", activeRemainingDays, " ", pluralize(activeRemainingDays, 'days'), " \u043F\u043E \u0430\u043A\u0446\u0438\u0438 \u00AB30 \u043B\u0435\u0442 \u041C\u0422\u0421\u00BB ", pluralize(activeRemainingDays, 'fire')] }));
    }
    if (activeRemainingDays || planRemainingDays) {
        return (_jsxs(_Fragment, { children: [days, " ", pluralize(days, 'days'), " \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u043E, \u0434\u0430\u043B\u0435\u0435 \u2014 ", _jsx("span", { className: classes.offerPrice, children: price }), ". \u0414\u043B\u044F \u043B\u044E\u0431\u044B\u0445 \u043E\u043F\u0435\u0440\u0430\u0442\u043E\u0440\u043E\u0432"] }));
    }
    return (_jsxs(_Fragment, { children: [price, _jsx("br", {})] }));
};
const UpdateSubscription = () => {
    const isAuth = useSelector(authSelector);
    const isProlongationAvailable = useSelector(isProlongationAvailableSelector);
    const isProlongationAnniversaryAvailable = useSelector(isProlongationAvailableSelectorAnniversary);
    const availableSubscriptions = useSelector(availableSubscriptionsSelector);
    const mtsAnniversary = useSelector(anniversarySelector);
    const updateStatus = useSelector(updateStatusSelector);
    const subscription = useSelector(subscriptionSelector);
    const parentPhone = useSelector(msisdnSelector);
    const isMTSAbonent = useSelector(abonentSelector);
    const activePlanName = subscription.tariffPlan || TariffPlanEnum.Month;
    const availablePlanToUpdate = availableSubscriptions?.find(plan => plan.tariffPlan === activePlanName);
    const isTrialAvailable = availablePlanToUpdate?.isTrial ?? false;
    const price = availablePlanToUpdate?.price;
    const activeRemainingDays = subscription.remainingDays || 0;
    const planRemainingDays = availablePlanToUpdate?.remainingDays || 0;
    const { isConnection: isAvailabilityChecking } = useSelector(checkAvailabilityStatusSelector);
    const { isConnection: isPaymentToolUpdating } = useSelector(requestChangePaymentTool);
    const { isConnection: isSubscriptionUpdating } = useSelector(updatePaymentTokenStatusSelector);
    const { isConnection: isUserDataRequest } = useSelector(getUserDataSelector);
    const isLoading = isUserDataRequest || isAvailabilityChecking || isSubscriptionUpdating;
    const { subscriptionModel: { changePaymentTool, setUpdateStatus, getTariffPlans }, } = useTypedDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        return () => {
            setUpdateStatus('inProgress');
        };
    }, []);
    useEffect(() => {
        if (isAuth && !isUserDataRequest) {
            if (!isProlongationAvailable) {
                navigate(Page.Main);
                return;
            }
            getTariffPlans({ role: RoleEnum.Parent, requestType: 'buy' });
            setOfferMetricsState('ready');
        }
    }, [isAuth, isUserDataRequest]);
    useEffect(() => {
        if (updateStatus === 'success') {
            navigate(Page.SuccessUpdate);
        }
    }, [updateStatus]);
    const [offerMetricsState, setOfferMetricsState] = useState('notReady');
    const shouldMetricsSend = !isLoading && isAuth && availableSubscriptions && offerMetricsState === 'ready';
    useEffect(() => {
        const metricsName = getOfferShowMetricName({
            isTrial: isTrialAvailable,
            isAbonent: isMTSAbonent,
            planName: activePlanName,
            anniversary: mtsAnniversary,
        });
        if (shouldMetricsSend && metricsName) {
            triggerMetricsEvent({ element: metricsName });
        }
    }, [activePlanName, shouldMetricsSend]);
    const priceText = price ? `${price} ${tariffPlanConfig[activePlanName].units}/${tariffPlanConfig[activePlanName].duration}` : '';
    const buyButtonText = isMTSAbonent ? 'Подключить' : 'Выбрать способ оплаты';
    const handleBuyClick = () => {
        const metricsName = getBuyButtonMetricName({
            isTrial: isTrialAvailable,
            isAbonent: isMTSAbonent,
            planName: activePlanName,
            anniversary: mtsAnniversary,
        });
        if (metricsName) {
            triggerMetricsEvent({ element: metricsName });
        }
        changePaymentTool({ isProlongationProcess: true });
    };
    const title = isProlongationAnniversaryAvailable ? `Подписка МТС Junior` : `МТС Junior на ${planInfoMap[activePlanName].title}`;
    const content = (_jsxs(_Fragment, { children: [_jsx("p", { className: classes.text, children: getOfferText({
                    activeRemainingDays,
                    mtsAnniversary,
                    isAbonent: isMTSAbonent,
                    price: priceText,
                    planRemainingDays,
                    isTrial: isTrialAvailable,
                }) }), _jsxs("p", { className: classes.phone, children: ["\u0420\u043E\u0434\u0438\u0442\u0435\u043B\u044C: ", getMaskedPhone(parentPhone)] })] }));
    const actions = (_jsxs(_Fragment, { children: [_jsx(Button, { size: "md", variant: "primary", text: buyButtonText, onClick: handleBuyClick, isLoading: isPaymentToolUpdating }), isMTSAbonent && _jsx("p", { className: classes.rules, children: lsPaymentTextConfig[isTrialAvailable ? 'trial' : 'noTrial'] }), _jsxs("p", { className: classes.rules, children: ["\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0430\u044F, \u0441\u043E\u0433\u043B\u0430\u0448\u0430\u0435\u0442\u0435\u0441\u044C", ' ', _jsx(CustomLink, { href: mtsJuniorRules, type: "primary", inNewWindow: true, children: "\u0441 \u043F\u0440\u0430\u0432\u0438\u043B\u0430\u043C\u0438 \u041C\u0422\u0421 Junior" })] })] }));
    const contentBlock = availableSubscriptions && _jsx(ContentBlock, { title: title, content: content, actions: actions, withBackButton: true });
    return (_jsx(Layout, { pageName: GTMScreenName.createSubscriprion, withFooter: false, type: "parentFlow", withBackButton: !isLoading, withoutVersionButton: true, children: isLoading ? _jsx(Loader, {}) : isAuth ? contentBlock : _jsx(Auth, {}) }));
};
export default UpdateSubscription;
