import { useEffect, useState } from 'react';
import { useWindowSize } from './useWindowSize';
import { useSelector } from 'react-redux';
import { webViewSelector } from '@store/models/subscription/selectors';
const backButtonTop = 68;
export const useStickyBackButton = () => {
    const [isBackButtonFixed, setBackButtonFixed] = useState(false);
    const { isMobileSm } = useWindowSize();
    const isWebView = useSelector(webViewSelector);
    useEffect(() => {
        const handleScroll = () => {
            setBackButtonFixed(window.pageYOffset > backButtonTop);
        };
        if (isMobileSm) {
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            setBackButtonFixed(false);
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isMobileSm]);
    return (isWebView && isMobileSm) || isBackButtonFixed;
};
