import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { helpServicePhone } from './common';
import Link from '@shared/Link/Link';
import { store } from '@store';
import { pluralize } from '@utils/pluralize';
import { Page, planInfoMap } from '@const';
export const getSubscriptionBadgeProps = (notificationStatus) => {
    const bageMap = {
        active: {
            variant: 'active',
            text: 'Активна',
        },
        suspended: { variant: 'warning', text: 'Не активна' },
        canceled: { variant: 'negative', text: 'Не активна' },
        adding: { variant: 'inprocess', text: 'Подключение' },
        childAdd: { variant: 'active', text: 'Активна' },
        draft: { variant: 'negative', text: '' },
    };
    return bageMap[notificationStatus];
};
export const getCounterProps = (isTrialPeriod) => {
    const periodText = isTrialPeriod ? 'пробного' : 'оплаченного';
    const buttonText = 'Возобновить подписку';
    return {
        periodText,
        buttonText,
    };
};
export const getSubscriptionButtonText = ({ isFree, isRenewAvailable }) => {
    return isFree && !isRenewAvailable ? 'Попробовать бесплатно' : 'Подключить';
};
export const getSubscribeButtonText = ({ isChildVersion, isFree, isRenewAvailable, }) => {
    const text = getSubscriptionButtonText({ isFree, isRenewAvailable });
    return isChildVersion ? { text, link: Page.CreateChildSubscription } : { text, link: Page.CreateSubscription };
};
export const getSubscribeDescText = ({ isTrialPeriod, price, trialDays, isRenewAvailable, plan, }) => {
    const daysText = `${trialDays} ${pluralize(trialDays, 'days')}`;
    const { units, duration } = planInfoMap[plan];
    const priceText = `${price} ${units}/${duration}`;
    if (isRenewAvailable) {
        const leftText = pluralize(trialDays, 'leftM');
        return `${daysText} ${leftText}, далее — ${priceText}`;
    }
    return isTrialPeriod ? `${daysText} бесплатно, далее — ${priceText}` : `Подписка за ${priceText}`;
};
export const getCheckAvailabilityText = (childPhone, error) => {
    if (!error) {
        return null;
    }
    const textMap = {
        subscribedChild: (_jsxs(_Fragment, { children: ["\u041D\u0430 \u044D\u0442\u043E\u0442 \u043D\u043E\u043C\u0435\u0440 \u0443\u0436\u0435 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u043B\u0438 \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443 \u0420\u0435\u0431\u0451\u043D\u043A\u0443. \u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0434\u0440\u0443\u0433\u043E\u0439 \u043D\u043E\u043C\u0435\u0440 \u0438\u043B\u0438 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443:", ' ', _jsx(Link, { type: "primary", href: helpServicePhone.href, children: helpServicePhone.phone })] })),
        subscribedParent: (_jsxs(_Fragment, { children: ["\u041D\u0430 \u044D\u0442\u043E\u0442 \u043D\u043E\u043C\u0435\u0440 \u0443\u0436\u0435 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u043B\u0438 \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443 \u0420\u043E\u0434\u0438\u0442\u0435\u043B\u044E. \u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0434\u0440\u0443\u0433\u043E\u0439 \u043D\u043E\u043C\u0435\u0440 \u0438\u043B\u0438 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443:", ' ', _jsx(Link, { type: "primary", href: helpServicePhone.href, children: helpServicePhone.phone })] })),
        numbersMatch: (_jsxs(_Fragment, { children: ["\u041D\u043E\u043C\u0435\u0440 \u0420\u0435\u0431\u0451\u043D\u043A\u0430 \u0441\u043E\u0432\u043F\u0430\u0434\u0430\u0435\u0442 \u0441 \u043D\u043E\u043C\u0435\u0440\u043E\u043C \u0420\u043E\u0434\u0438\u0442\u0435\u043B\u044F. \u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0434\u0440\u0443\u0433\u043E\u0439 \u043D\u043E\u043C\u0435\u0440 \u0438\u043B\u0438 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443:", ' ', _jsx(Link, { type: "primary", href: helpServicePhone.href, children: helpServicePhone.phone })] })),
        childAddUnavailable: (_jsxs(_Fragment, { children: ["\u041D\u0430 \u044D\u0442\u043E\u043C \u043D\u043E\u043C\u0435\u0440\u0435 \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0430 \u043D\u0435\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u0430. \u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0443\u043A\u0430\u0437\u0430\u0442\u044C \u0434\u0440\u0443\u0433\u043E\u0439 \u043D\u043E\u043C\u0435\u0440 \u0438\u043B\u0438 \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u0435\u0441\u044C \u0432 \u043F\u043E\u0434\u0434\u0435\u0440\u0436\u043A\u0443:", ' ', _jsx(Link, { type: "primary", href: helpServicePhone.href, children: helpServicePhone.phone })] })),
        default: (_jsxs(_Fragment, { children: ["\u041D\u0435 \u0443\u0434\u0430\u043B\u043E\u0441\u044C \u043F\u0440\u043E\u0432\u0435\u0440\u0438\u0442\u044C \u043D\u043E\u043C\u0435\u0440 \u0420\u0435\u0431\u0451\u043D\u043A\u0430. ", _jsx("br", {}), _jsx(Link, { type: "primary", href: "", preventDefault: true, onClick: () => store.dispatch.subscriptionModel.checkChildStatus(childPhone), children: "\u041F\u0440\u043E\u0432\u0435\u0440\u0438\u0442\u044C \u043F\u043E\u0432\u0442\u043E\u0440\u043D\u043E" })] })),
    };
    return error && error in textMap ? textMap[error] : textMap['default'];
};
