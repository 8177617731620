import dayjs from 'dayjs';
const defaultDateFormatTemplate = 'DD MMMM';
export const formatDate = ({ date, dateFormat, postfix, }) => {
    const formatTemplate = dateFormat || defaultDateFormatTemplate;
    const formattedDate = dayjs(date).format(formatTemplate);
    const resultDate = postfix ? `${formattedDate} ${postfix}` : formattedDate;
    return resultDate.replace(/\s/g, '\u00a0');
};
export const isDateAfter = (date1, date2) => {
    return dayjs(date1).isAfter(dayjs(date2));
};
