const getCommonParentConfig = (userAuth) => ({
    kionInsctructionAbout: {
        ymID: 'pr_kion_aboutservice',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'roditel',
            eventAction: 'screen_show',
            eventLabel: 'o_servise_kion',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    kionInsctructionConnect: {
        ymID: 'pr_kion_kakpodkl',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'roditel',
            eventAction: 'screen_show',
            eventLabel: 'kak_podkluchit_kion',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    kionInsctructionConnectTopButton: {
        ymID: 'pr_kion_aboutservice_kakpodkl_top',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_kion',
            eventAction: 'element_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    kionInsctructionConnectBottomButton: {
        ymID: 'pr_kion_aboutservice_kakpodkl_bottom',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_kion',
            eventAction: 'element_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'down',
            actionGroup: 'interactions',
        },
    },
    kionInsctructionAboutTopButton: {
        ymID: 'pr_kion_kakpodkl_aboutservice',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'kak_podkluchit_kion',
            eventAction: 'element_click',
            eventLabel: 'o_servise',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    kionInsctructionCloseButton: {
        ymID: 'pr_kion_instruction_close',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'o_servise_kion',
            eventAction: 'element_click',
            eventLabel: 'zakryt',
            userAuth,
            actionGroup: 'interactions',
        },
    },
});
const getCommonChildConfig = (userAuth) => ({
    kionInsctructionConnect: {
        ymID: 'ch_kion_kakpodkl',
        GTMParams: {
            event: 'vntReb',
            eventCategory: 'rebenok',
            eventAction: 'screen_show',
            eventLabel: 'kak_podkluchit_kion',
            userAuth,
            actionGroup: 'non_interactions',
        },
    },
    kionInsctructionAboutTopButton: {
        ymID: 'ch_kion_kakpodkl_aboutservice',
        GTMParams: {
            event: 'vntReb',
            eventCategory: 'kak_podkluchit_kion',
            eventAction: 'button_click',
            eventLabel: 'o_servise',
            userAuth,
            buttonLocation: 'up',
            actionGroup: 'interactions',
        },
    },
    kionInsctructionConnectBottomButton: {
        ymID: 'ch_kion_aboutservice_kakpodkl_bottom',
        GTMParams: {
            event: 'vntReb',
            eventCategory: 'kak_podkluchit_kion',
            eventAction: 'button_click',
            eventLabel: 'kak_podkluchit',
            userAuth,
            buttonLocation: 'down',
            actionGroup: 'interactions',
        },
    },
});
export const kionInsctructionPageConfig = {
    parentNotAuth: {
        ...getCommonParentConfig('0'),
    },
    parentAuth: {
        ...getCommonParentConfig('1'),
    },
    childNotAuth: {
        ...getCommonChildConfig('0'),
    },
    childAuth: {
        ...getCommonChildConfig('1'),
        kionInsctructionConnectPlayButton: {
            ymID: 'ch_kion_kakpodkl_play',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'kak_podkluchit_kion',
                eventAction: 'button_click',
                eventLabel: 'smotret',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
    },
};
