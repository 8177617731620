import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import classNames from 'classnames';
const Link = ({ href, children, type, onClick, preventDefault, inNewWindow, className, disabled, withTextDecoration }) => {
    const target = inNewWindow ? { target: '_blank', rel: 'noreferrer' } : {};
    const classes = classNames(className, 'link', {
        'text-secondary': type === 'secondary',
        'text-tertiary': type === 'tertiary',
        'link-primary': type === 'primary',
        'with-text-decoration': withTextDecoration,
        disabled: disabled,
    });
    const handleClick = (ev) => {
        if (preventDefault) {
            ev.preventDefault();
        }
        onClick && onClick();
    };
    return (_jsx(_Fragment, { children: disabled ? (_jsx("div", { className: classes, children: children })) : (_jsx("a", { href: href, className: classes, onClick: handleClick, ...target, children: children })) }));
};
export default Link;
