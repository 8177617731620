import { kionInsctructionPageConfig } from './kion';
import { leoniInsctructionPageConfig } from './leoni';
import { logicLikeInsctructionPageConfig } from './logiclike';
import { musicInsctructionPageConfig } from './music';
import { pingoInsctructionPageConfig } from './pingo';
export const instructionsConfig = {
    parentNotAuth: {
        ...kionInsctructionPageConfig.parentNotAuth,
        ...leoniInsctructionPageConfig.parentNotAuth,
        ...logicLikeInsctructionPageConfig.parentNotAuth,
        ...musicInsctructionPageConfig.parentNotAuth,
        ...pingoInsctructionPageConfig.parentNotAuth,
    },
    parentAuth: {
        ...kionInsctructionPageConfig.parentAuth,
        ...leoniInsctructionPageConfig.parentAuth,
        ...logicLikeInsctructionPageConfig.parentAuth,
        ...musicInsctructionPageConfig.parentAuth,
        ...pingoInsctructionPageConfig.parentAuth,
    },
    childNotAuth: {
        ...kionInsctructionPageConfig.childNotAuth,
        ...logicLikeInsctructionPageConfig.childNotAuth,
        ...musicInsctructionPageConfig.childNotAuth,
    },
    childAuth: {
        ...kionInsctructionPageConfig.childAuth,
        ...logicLikeInsctructionPageConfig.childAuth,
        ...musicInsctructionPageConfig.childAuth,
    },
};
