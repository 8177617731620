import axios from 'axios';
const urlConfig = {
    base: 'https://cms.developers.mts.ru/',
    version: 'external-api/v2.2/',
    categoryCode: 'support',
    productCode: 'mts_junior',
    showArticleContent: 'true',
};
export const getQuestions = () => {
    const url = `${urlConfig.base}${urlConfig.version}sections?categoryCode=${urlConfig.categoryCode}&productCode=${urlConfig.productCode}&showArticleContent=${urlConfig.showArticleContent}`;
    return axios.get(url);
};
