import { monthPlanConfig } from './month';
import { yearPlanConfig } from './year';
export const changePlanConfig = {
    parentAuth: {
        ...monthPlanConfig,
        ...yearPlanConfig,
        changePlanConnection: {
            ymID: 'pr_change_loading',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'popup_show',
                eventLabel: 'ostalos_chut_chut',
                userAuth: '1',
                actionGroup: 'non_interactions',
            },
        },
        changePlanConnectionClose: {
            ymID: 'pr_change_loading_close',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'zakryt',
                buttonLocation: 'popup',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        changePlanConnectionOkButton: {
            ymID: 'pr_change_loading_1a',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'ponyatno',
                buttonLocation: 'popup',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        changePlanSuccess: {
            ymID: 'pr_success_change',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'podpiska',
                eventAction: 'confirmed',
                eventLabel: 'smena_podpiski',
                eventContent: 'god | mesyac',
                userAuth: '1',
                actionGroup: 'non_interactions',
            },
        },
        changePlanSuccessClose: {
            ymID: 'pr_success_change_close',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'smena_podpiski',
                eventAction: 'element_click',
                eventLabel: 'zakryt',
                buttonLocation: 'popup',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
        changePlanSuccessOkButton: {
            ymID: 'pr_success_change_1a',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'smena_podpiski',
                eventAction: 'button_click',
                eventLabel: 'smotret_servisy',
                buttonLocation: 'popup',
                userAuth: '1',
                actionGroup: 'interactions',
            },
        },
    },
};
