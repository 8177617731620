export const promocodeConfig = {
    parentNotAuth: {
        mainBannerPromocodeButton: {
            ymID: 'pr_promo_nea_1',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'vvesti_promokod',
                eventContent: 'roditel',
                buttonLocation: 'up',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
        bottomBannerPromocodeButton: {
            ymID: 'pr_promo_nea_2',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'vvesti_promokod',
                eventContent: 'roditel',
                buttonLocation: 'down',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
    },
    parentAuth: {
        subscriptionBannerPromocodeButton: {
            ymID: 'pr_promo_a_active',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'element_click',
                eventLabel: 'vvesti_promokod',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        promocodeEnterPage: {
            ymID: 'pr_promo_enter',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'vvesti_promokod',
                eventAction: 'popup_show',
                eventLabel: 'promokod',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        promocodeEnterPageApplyButton: {
            ymID: 'pr_promo_1a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'promokod',
                eventAction: 'button_click',
                eventLabel: 'primenit',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        promocodeEnterPageFAQButton: {
            ymID: 'pr_promo_2a',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'promokod',
                eventAction: 'element_click',
                eventLabel: 'pochemu_ne_rabotaet_promokod',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        promocodeApplySuccess: {
            ymID: 'pr_promo_success',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'promokod',
                eventAction: 'popup_show',
                eventLabel: 'uspeh',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        promocodeApplySuccessBackButton: {
            ymID: 'pr_promo_success_close',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'promokod',
                eventAction: 'button_click',
                eventLabel: 'zakryt',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        promocodeApplySuccessNextButton: {
            ymID: 'pr_promo_continue',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'promokod',
                eventAction: 'button_click',
                eventLabel: 'prodolzhit',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        bottomBannerPromocodeButton: {
            ymID: 'pr_promo_a_2',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'roditel',
                eventAction: 'button_click',
                eventLabel: 'vvesti_promokod',
                eventContent: 'roditel',
                buttonLocation: 'down',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        monthTariffAbonentTrialPromocodeButton: {
            ymID: 'pr_mts_mes_trial_promo',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vvesti_promokod',
                eventContent: 'mesyac',
                eventContext: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        monthTariffAbonentNoTrialPromocodeButton: {
            ymID: 'pr_mts_mes_notrial_promo',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vvesti_promokod',
                eventContent: 'mesyac',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        monthTariffNonAbonentTrialPromocodeButton: {
            ymID: 'pr_neabon_mes_trial_promo',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vvesti_promokod',
                eventContent: 'mesyac',
                eventContext: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        monthTariffNonAbonentNoTrialPromocodeButton: {
            ymID: 'pr_neabon_mes_notrial_promo',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vvesti_promokod',
                eventContent: 'mesyac',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        yearTariffAbonentPromocodeButton: {
            ymID: 'pr_mts_god_promo',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vvesti_promokod',
                eventContent: 'god',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        yearTariffNonAbonentPromocodeButton: {
            ymID: 'pr_neabon_god_promo',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vvesti_promokod',
                eventContent: 'god',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
    },
};
