import { jsx as _jsx } from "react/jsx-runtime";
import { authSelector, isProfileWidgetLoadedSelector, themeSelector, versionSelector } from '@models/user/selectors';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { supportLink, untypedWindow } from '@const';
import { checkProdMode } from '@utils/checkProdMode';
import { triggerMetricsEvent } from '@metrics';
import { checkPage } from '@utils/checkPage';
import { metricsDataSelector } from '@store/models/metrics/selectors';
const profileWidgetId = 'profile-widget';
export const ProfileWidget = () => {
    const isAuth = useSelector(authSelector);
    const version = useSelector(versionSelector);
    const { isAuthPageShowed } = useSelector(metricsDataSelector);
    const isProd = checkProdMode();
    const isChildVersion = version === 'child';
    const { isGMDPromoPage } = checkPage();
    const isProfileWidgetLoaded = useSelector(isProfileWidgetLoadedSelector);
    const { userModel: { logOut, logIn }, dialogModel: { openDialog }, } = useTypedDispatch();
    const theme = useSelector(themeSelector);
    useEffect(() => {
        let widget = null;
        if (isProfileWidgetLoaded && untypedWindow?.profileWidgetApp) {
            widget = new untypedWindow.profileWidgetApp({
                target: document.getElementById(profileWidgetId),
                props: {
                    env: isProd ? 'prod' : 'stage',
                    loginEndpoint: () => {
                        isChildVersion ? logIn({ redirectType: 'login' }) : openDialog('auth');
                        const loginButtonMetricName = isAuthPageShowed ? 'headerAuthPageLoginButton' : 'headerLoginButton';
                        const eventName = isGMDPromoPage ? 'gmdLogin' : loginButtonMetricName;
                        triggerMetricsEvent({ element: eventName });
                    },
                    logoutEndpoint: () => {
                        logOut('logout');
                    },
                    supportLink,
                    blockLoading: false,
                    theme,
                },
            });
            // widget.$on('profileUnauthorized', function (e: any) {
            //   // TODO: убрать, когда будет смена куки
            //   const isLocalhost = window.location.origin === 'http://localhost:3000';
            //
            //   if (isAuth && !isLocalhost) {
            //     console.log('logout in widget');
            //     logOut('logout');
            //   }
            // });
        }
        return () => {
            if (widget)
                widget.$destroy();
        };
    }, [isAuth, isChildVersion, isGMDPromoPage, isAuthPageShowed, isProfileWidgetLoaded]);
    return _jsx("div", { id: profileWidgetId });
};
