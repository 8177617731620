import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { paymentWidgetID, untypedWindow } from '@const';
import { checkProdMode } from '@utils/checkProdMode';
import { useSelector } from 'react-redux';
import { themeSelector } from '@models/user/selectors';
import { paymentWidgetPropsSelector } from '@models/paymentWidget/selectors';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
const isProd = checkProdMode();
const PaymentWidget = () => {
    const { sessionData, onCloseSuccess, isOpen, onCloseError } = useSelector(paymentWidgetPropsSelector);
    const theme = useSelector(themeSelector);
    const { paymentWidgetModel: { closeWidget }, } = useTypedDispatch();
    const getResultHandler = () => {
        let isSuccess = false;
        const handler = ({ eventName }) => {
            if (eventName === 'successPayment') {
                isSuccess = true;
                return;
            }
            if (eventName === 'close' && isSuccess) {
                onCloseSuccess();
                closeWidget();
                return;
            }
            if (eventName === 'close') {
                closeWidget();
                onCloseError && onCloseError();
            }
        };
        return handler;
    };
    useEffect(() => {
        if (untypedWindow?.MTSPay && isOpen && sessionData?.sessionId) {
            const params = {
                scenarioType: 'pay',
                sessionId: sessionData?.sessionId,
                tokenId: sessionData?.tokenId,
                environment: isProd ? 'PROD' : 'TEST',
                colorMode: theme,
                resultHandler: getResultHandler(),
            };
            const widget = new untypedWindow.MTSPay(params);
            widget.render(paymentWidgetID);
        }
    }, [isOpen, sessionData]);
    return _jsx("div", { id: paymentWidgetID });
};
export default PaymentWidget;
