import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Dialogs from './Dialogs/Dialogs';
import Header from './Header/Header';
import PaymentWidget from './PaymentWidget/PaymentWidget';
import Plug from './Plug/Plug';
import { triggerGTMPageView, triggerMetricsEvent } from '@metrics';
import { webpSupportCheck } from '@utils/webpSupportCheck';
import { isMetricsLoadedSelector, metricsDataSelector } from '@store/models/metrics/selectors';
import { partnerFlowSelector, webViewSelector } from '@store/models/subscription/selectors';
import { getUserDataSelector, loadingErrorSelector, plugSelector, themeSelector } from '@store/models/user/selectors';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './Layout.module.scss';
import Footer from './Footer/Footer';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { useWindowSize } from '@hooks/useWindowSize';
import BackButton from '../BackButton/BackButton';
const Layout = ({ children, withoutVersionButton, pageName, withFooter = true, withHeader = true, testID, className, backgroundImage, withStickyButton = true, stickyButtonConfig, type = 'default', withBackButton, }) => {
    const [isWebpSupported, setWebpSupported] = useState(true);
    const { isConnection } = useSelector(getUserDataSelector);
    const isLoadingError = useSelector(loadingErrorSelector);
    const isWebView = useSelector(webViewSelector);
    const isPartnerFlow = useSelector(partnerFlowSelector);
    const theme = useSelector(themeSelector);
    const plugType = useSelector(plugSelector);
    const isMetricsLoaded = useSelector(isMetricsLoadedSelector);
    const { isSuccessLogin, isGTMLoaded, isUserDataChecked, isSuccessLoginSend } = useSelector(metricsDataSelector);
    const { isDesktop } = useWindowSize();
    const { metricsModel: { setSuccessLoginSend, setCurrentPage }, } = useTypedDispatch();
    useEffect(() => {
        if (type === 'childFlow') {
            document.body.classList.add('childFlow');
            return;
        }
        document.body.classList.remove('childFlow');
    }, [type]);
    const containerClasses = classNames('grid-container', className, classes[type], {
        webp: isWebpSupported,
        loading: isConnection,
        error: isLoadingError,
        [classes.plug]: Boolean(plugType),
        [classes.webView]: isWebView,
    });
    useEffect(() => {
        if (isGTMLoaded && isUserDataChecked) {
            triggerGTMPageView(pageName);
        }
    }, [isGTMLoaded, isUserDataChecked, pageName]);
    useEffect(() => {
        if (isMetricsLoaded && isSuccessLogin && !isSuccessLoginSend) {
            setSuccessLoginSend();
            triggerMetricsEvent({ element: 'successLogin' });
        }
    }, [isMetricsLoaded, isSuccessLogin]);
    useEffect(() => {
        setCurrentPage(pageName);
    }, [pageName]);
    useEffect(() => {
        webpSupportCheck('lossless', isSupported => {
            setWebpSupported(isSupported);
        });
        if (theme === 'dark') {
            document.body.classList.add('dark');
        }
    }, []);
    const content = (_jsxs("div", { className: classes.contentWrap, children: [isDesktop && withBackButton && !isPartnerFlow && _jsx(BackButton, { className: classes.backButton }), children && _jsx("div", { className: classes.content, children: children })] }));
    return (_jsxs(_Fragment, { children: [backgroundImage, withHeader && !isWebView && !plugType && (_jsx(Header, { isLoading: isConnection, isStatic: isConnection || isLoadingError, withoutVersionButton: withoutVersionButton, stickyButtonConfig: stickyButtonConfig, withStickyButton: withStickyButton })), _jsxs("div", { className: containerClasses, "data-testid": testID, children: [plugType ? _jsx(Plug, { type: plugType }) : content, withFooter && !plugType && _jsx(Footer, {})] }), _jsx(Dialogs, {}), _jsx(PaymentWidget, {})] }));
};
export default Layout;
