import { createModel } from '@rematch/core';
import { ChildPhoneValidateErrorName, CreateSubscriptionErrorName, OriginEnum, PaymentTokenStatusEnum, PaymentTypeEnum, RoleEnum, ServiceStatusEnum, SourceEnum, SubscriptionStatusChildEnum, SubscriptionStatusParentEnum, TariffPlanEnum, TarifficationStatusEnum, } from './types';
import { getLoadReducers } from 'src/store/utils';
import api from '@api';
import { failParentStatuses, subscriptionDefaultPrice } from '@models/const';
import { ErrorName, ResponseCodeEnum } from '@models/types';
import { checkStatusWithRetry } from '@store/utils/checkStatusWithRetry';
import { transformKionStatus, transformSubscription } from '@store/utils/transformSubscription';
import Cookies from 'js-cookie';
import { transformAvailableSubscription } from './utils/transformAvailableSubscription';
import { transformError } from '@store/utils/transformError';
import { checkAvailabilityError } from './utils/checkAvailabilityError';
import { getChangeTariffPlanError } from '@configs/errors';
import { PromoCodeErrorName } from '../promocode/types';
const checkKionRetryCount = 5;
const originNewSubscription = [OriginEnum.PaySystemOne, OriginEnum.MTSAnniversary, OriginEnum.Foris60];
export const defaultSubscription = {
    subscriptionId: '',
    contentIDParent: '',
    contentIDChild: '',
    price: subscriptionDefaultPrice,
    children: [],
    promocodeStatus: 'notActive',
};
const initialState = {
    subscription: defaultSubscription,
    loadStatus: {
        requestCheckAvailability: 'pending',
        requestDisable: 'pending',
        requestParentStatus: 'pending',
        requestChildAdd: 'pending',
        requestSendOTP: 'pending',
        requestMultiStatus: 'pending',
        requestInviteParent: 'pending',
        requestRepeat: 'pending',
        requestCreate: 'pending',
        requestCheckCreate: 'pending',
        updatePaymentTokenStatus: 'pending',
        requestChangePaymentTool: 'pending',
        requestChildStatus: 'pending',
        requestCheckChildAdd: 'pending',
        requestChangeSubscriptionTariffPlan: 'pending',
        requestCheckChangeSubscriptionTariffPlan: 'pending',
    },
    childPhoneValidateError: null,
    checkOTPError: null,
    isLSPayAvailable: false,
    isSubscriptionAvailable: true,
    newChildPhone: '',
    inviteParentPhone: '',
    source: SourceEnum.Landing,
    childContentID: null,
    availableSubscriptions: null,
    tariffChoosen: TariffPlanEnum.Month,
    tariffToChange: null,
    createStatus: 'inProgress',
    updateStatus: 'inProgress',
    addChildStage: 'enterNumber',
    prevSubscriptionOwner: null,
    isRenewProcess: false,
};
export const SubscriptionModel = createModel()({
    state: initialState,
    reducers: {
        ...getLoadReducers(),
        setSubscription: (state, subscription) => {
            return {
                ...state,
                subscription,
            };
        },
        setKionStatus: (state, kionStatus) => {
            return {
                ...state,
                subscription: { ...state.subscription, kionStatus },
            };
        },
        setCreateStatus: (state, createStatus) => {
            return { ...state, createStatus };
        },
        setUpdateStatus: (state, updateStatus) => {
            return { ...state, updateStatus };
        },
        setTariffChoosen(state, tariffChoosen) {
            return { ...state, tariffChoosen };
        },
        setTariffToChange(state, tariffToChange) {
            return { ...state, tariffToChange };
        },
        setSubscriptionAdding: state => {
            return {
                ...state,
                subscription: {
                    ...defaultSubscription,
                    subscriptionStatusParent: SubscriptionStatusParentEnum.Adding,
                    subscriptionId: state.subscription.subscriptionId,
                },
                isChildAddAvailable: false,
            };
        },
        setSubscriptionID: (state, subscriptionId) => {
            return {
                ...state,
                subscription: { ...state.subscription, subscriptionId },
            };
        },
        setChildPhoneValidateError: (state, childPhoneValidateError) => {
            return { ...state, childPhoneValidateError };
        },
        clearChildPhoneValidateError: state => {
            return { ...state, childPhoneValidateError: null };
        },
        setCheckOTPError: (state, checkOTPError) => {
            return { ...state, checkOTPError };
        },
        clearCheckOTPError: state => {
            return { ...state, checkOTPError: null };
        },
        setNewChildPhone: (state, newChildPhone) => {
            return {
                ...state,
                newChildPhone,
            };
        },
        setSubscriptionAvailability: (state, isSubscriptionAvailable) => {
            return { ...state, isSubscriptionAvailable };
        },
        setNextOTPDate: (state, resendOTPDate) => {
            return { ...state, resendOTPDate };
        },
        setInviteParentPhone: (state, inviteParentPhone) => {
            return { ...state, inviteParentPhone };
        },
        setContentId: (state, contentID) => {
            return { ...state, contentID };
        },
        setSource: (state, source) => {
            return { ...state, source };
        },
        setLSPayAvailability: (state, isLSPayAvailable) => {
            return { ...state, isLSPayAvailable };
        },
        setChildContentID: (state, childContentID) => {
            return { ...state, childContentID };
        },
        setAvailableSubscriptions: (state, availableSubscriptions) => {
            return { ...state, availableSubscriptions };
        },
        setOTPData: (state, resendOTPDate) => {
            return { ...state, resendOTPDate, addChildStage: 'enterOTP', loadStatus: { ...state.loadStatus, requestSendOTP: 'pending' } };
        },
        setAddChildStage: (state, addChildStage) => {
            return { ...state, addChildStage };
        },
        clearChildStatus: state => {
            return {
                ...state,
                subscription: {
                    ...state.subscription,
                    subscriptionStatusChild: SubscriptionStatusChildEnum.Draft,
                    subscriptionStatusParent: SubscriptionStatusParentEnum.ChildAdd,
                    children: [],
                },
            };
        },
        setPrevSubscriptionOwner: (state, prevSubscriptionOwner) => {
            return { ...state, prevSubscriptionOwner };
        },
        setRenewProcess: (state, isRenewProcess) => {
            return { ...state, isRenewProcess };
        },
    },
    effects: dispatch => ({
        // смена ТП при покупке подписки
        changeBucketTariffPlan: (newPlanName) => {
            dispatch.dialogModel.closeDialog();
            dispatch.promocodeModel.deletePromoCode();
            dispatch.subscriptionModel.setTariffChoosen(newPlanName);
        },
        // смена ТП у активной подписки
        async changeSubscriptionTariffPlan({ newPlanName, promocode, showSuccessChangeDialogs, closePreviousDialogBeforeWidget, }, state) {
            dispatch.subscriptionModel.startRequest('requestChangeSubscriptionTariffPlan');
            const currentSubscription = state.subscriptionModel.subscription;
            const availableSubscription = state.subscriptionModel.availableSubscriptions?.find(({ tariffPlan }) => tariffPlan === newPlanName);
            const contentID = availableSubscription?.contentID;
            try {
                if (!contentID) {
                    throw new Error();
                }
                const newSubscriptionData = {
                    content_id: contentID,
                    source: state.subscriptionModel.source,
                    promocode,
                };
                const { data } = await api.subscription.createSubscription(newSubscriptionData);
                const tokenId = data.IDToken;
                if (!tokenId || !data?.PaymentSessionID) {
                    throw ErrorName.Default;
                }
                const widgetParams = {
                    onCloseSuccess: async () => {
                        try {
                            dispatch.subscriptionModel.startRequest('requestCheckChangeSubscriptionTariffPlan');
                            if (showSuccessChangeDialogs) {
                                dispatch.dialogModel.openDialog('changePlanConnection');
                            }
                            const updatedSubscription = await dispatch.subscriptionModel.checkOwnerSubscriptionStatus({
                                id: currentSubscription.subscriptionId,
                                type: RoleEnum.Parent,
                            });
                            if (updatedSubscription.TariffPlanType !== newPlanName) {
                                throw new Error();
                            }
                            const transformedSubscription = transformSubscription(updatedSubscription, state.userModel.data.slaves);
                            await dispatch.subscriptionModel.getTariffPlans({});
                            dispatch.subscriptionModel.setSubscription(transformedSubscription);
                            if (promocode) {
                                dispatch.promocodeModel.stopRequest('requestApply');
                                dispatch.promocodeModel.setPromoCodeStage('successCodeApply');
                                dispatch.promocodeModel.setPromocodeData(null);
                            }
                            if (showSuccessChangeDialogs) {
                                dispatch.dialogModel.openDialog('changePlanSuccess');
                            }
                        }
                        catch (error) {
                            dispatch.dialogModel.setErrorDialog({ error: getChangeTariffPlanError(newPlanName) });
                            if (promocode) {
                                dispatch.promocodeModel.setError({ errorCode: PromoCodeErrorName.CustomApplyError, promocode });
                                dispatch.promocodeModel.stopRequest('requestApply');
                            }
                        }
                        finally {
                            dispatch.subscriptionModel.stopRequest('requestCheckChangeSubscriptionTariffPlan');
                        }
                    },
                    onCloseError: () => {
                        dispatch.promocodeModel.stopRequest('requestApply');
                    },
                    sessionData: {
                        sessionId: data.PaymentSessionID,
                        tokenId,
                    },
                };
                dispatch.paymentWidgetModel.openWidget(widgetParams);
                if (closePreviousDialogBeforeWidget) {
                    dispatch.dialogModel.closeDialog();
                }
            }
            catch (error) {
                dispatch.dialogModel.setErrorDialog({ error: getChangeTariffPlanError(newPlanName) });
                dispatch.promocodeModel.stopRequest('requestApply');
            }
            finally {
                dispatch.subscriptionModel.stopRequest('requestChangeSubscriptionTariffPlan');
            }
        },
        getSource: () => {
            const source = Cookies.get('source') || SourceEnum.Landing;
            dispatch.subscriptionModel.setSource(source);
            return source;
        },
        async disableSubscription({ owner, prevVersion }, rootState) {
            dispatch.subscriptionModel.startRequest('requestDisable');
            try {
                const { data } = await api.subscription.disableSubscription();
                dispatch.subscriptionModel.setSubscriptionAvailability(true);
                const role = prevVersion === 'child' ? RoleEnum.Child : RoleEnum.Parent;
                await dispatch.subscriptionModel.getTariffPlans({ role });
                const transformedSubscription = transformSubscription(data, rootState.userModel.data.slaves);
                dispatch.subscriptionModel.setPrevSubscriptionOwner(owner);
                dispatch.subscriptionModel.setSubscription(transformedSubscription);
                dispatch.dialogModel.openDialog('subscribeDisablingSuccess');
            }
            catch (error) {
                if (error?.response?.data?.ErrorCode === ResponseCodeEnum.Unauthorized) {
                    dispatch.userModel.logIn({ point: 'subscribeDisabling' });
                    return;
                }
                const role = rootState.userModel.isChild ? RoleEnum.Child : RoleEnum.Parent;
                dispatch.dialogModel.openErrorDialog({ name: CreateSubscriptionErrorName.DefaultError, role });
            }
            finally {
                dispatch.subscriptionModel.stopRequest('requestDisable');
            }
        },
        async getTariffPlans({ role = RoleEnum.Parent, requestType = 'main' }, rootState) {
            const availableSubscriptions = rootState.subscriptionModel.availableSubscriptions;
            const isBuyRequest = requestType === 'buy';
            if (isBuyRequest && availableSubscriptions && availableSubscriptions.length > 0) {
                return;
            }
            dispatch.subscriptionModel.startRequest('requestCheckAvailability');
            try {
                const { data: tariffPlans } = await api.subscription.getTariffPlans(role);
                if (tariffPlans.values.length === 0) {
                    dispatch.subscriptionModel.setAvailableSubscriptions(null);
                    throw new Error();
                }
                const transformedAvailableSubscriptions = tariffPlans.values.map(transformAvailableSubscription);
                dispatch.subscriptionModel.setAvailableSubscriptions(transformedAvailableSubscriptions);
                const { isSubscriptionAvailable, subscription } = rootState.subscriptionModel;
                // если есть подписка - устанавливаем выбранный план из нее
                if (!isSubscriptionAvailable && subscription.tariffPlan) {
                    dispatch.subscriptionModel.setTariffChoosen(subscription.tariffPlan);
                    return;
                }
                // если пришел всего один доступный тарифный план, то делаем его планом по умолчанию
                if (isSubscriptionAvailable && transformedAvailableSubscriptions.length === 1) {
                    dispatch.subscriptionModel.setTariffChoosen(transformedAvailableSubscriptions[0].tariffPlan);
                    return;
                }
                if (subscription.tariffPlan) {
                    dispatch.subscriptionModel.setTariffChoosen(subscription.tariffPlan);
                }
            }
            catch (error) {
                const errorCode = error?.response?.data?.ErrorCode;
                if (errorCode === ResponseCodeEnum.Unauthorized) {
                    dispatch.userModel.logIn({ point: null, redirectType: 'buy' });
                    return;
                }
                const errorName = errorCode
                    ? CreateSubscriptionErrorName.checkAvailabilityError
                    : CreateSubscriptionErrorName.SubscriptionNotAnavailable;
                if (isBuyRequest) {
                    dispatch.dialogModel.openErrorDialog({ name: errorName, role });
                }
            }
            finally {
                dispatch.subscriptionModel.stopRequest('requestCheckAvailability');
            }
        },
        async checkOwnerSubscriptionStatus({ id, withUpdate = true, type }, rootState) {
            const checkSubscription = async (currentStatus) => {
                const currentSubscriptionState = await checkStatusWithRetry({ id, currentStatus, type });
                if (withUpdate) {
                    const subscriptionAddingTransformed = transformSubscription(currentSubscriptionState, rootState.userModel.data.slaves);
                    dispatch.subscriptionModel.setSubscription(subscriptionAddingTransformed);
                }
                const subscriptionStatus = type === RoleEnum.Parent
                    ? currentSubscriptionState?.SubscriptionStatusParent?.toLowerCase()
                    : currentSubscriptionState?.SubscriptionStatusChild?.toLowerCase();
                const tarifficationStatus = currentSubscriptionState?.TarifficationStatus.toLowerCase();
                if (subscriptionStatus === SubscriptionStatusParentEnum.Active) {
                    if (type === RoleEnum.Child) {
                        dispatch.subscriptionModel.checkKionStatus({ id, currentStatus: currentSubscriptionState?.KionStatus });
                    }
                    return currentSubscriptionState;
                }
                if (failParentStatuses.includes(subscriptionStatus)) {
                    throw CreateSubscriptionErrorName.DefaultError;
                }
                if (tarifficationStatus === TarifficationStatusEnum.Unsuccessful || subscriptionStatus === SubscriptionStatusParentEnum.Suspended) {
                    throw ResponseCodeEnum.CannotProcessPayment;
                }
                // если статус не стал Active и не перешел в один из негативных, запускаем проверку еще раз
                return await checkSubscription(subscriptionStatus);
            };
            // дергаем метод проверки статуса подписки в ожидании пока подписка перейдет из статуса Draft в Adding
            return await checkSubscription(SubscriptionStatusParentEnum.Draft);
        },
        async checkChildSubscriptionStatus(id) {
            let currentSubscriptionState = await checkStatusWithRetry({
                id,
                currentStatus: SubscriptionStatusChildEnum.Adding,
                type: RoleEnum.Child,
            });
            const isChildActive = currentSubscriptionState?.SubscriptionStatusChild?.toLowerCase() === SubscriptionStatusChildEnum.Active;
            if (isChildActive) {
                dispatch.subscriptionModel.checkKionStatus({ id, currentStatus: currentSubscriptionState?.KionStatus });
                return currentSubscriptionState;
            }
            throw { failedState: currentSubscriptionState };
        },
        async checkKionStatus({ id, currentStatus, errorRetryCount = checkKionRetryCount }, rootState) {
            if (!currentStatus || currentStatus?.toLowerCase() === ServiceStatusEnum.Active || errorRetryCount === 0) {
                return;
            }
            try {
                const updatedSubscription = await checkStatusWithRetry({
                    id,
                    currentStatus,
                    type: 'kion',
                    retryCount: checkKionRetryCount,
                });
                const transformedKionStatus = transformKionStatus(updatedSubscription?.KionStatus);
                dispatch.subscriptionModel.setKionStatus(transformedKionStatus);
            }
            catch (error) {
                dispatch.subscriptionModel.checkKionStatus({ id, currentStatus, errorRetryCount: errorRetryCount - 1 });
            }
        },
        async checkIsChildAdded(id, rootState) {
            dispatch.subscriptionModel.startRequest('requestCheckChildAdd');
            try {
                const currentSubscription = await dispatch.subscriptionModel.checkChildSubscriptionStatus(id);
                const currentSubscriptionTransformed = transformSubscription(currentSubscription, rootState.userModel.data.slaves);
                dispatch.subscriptionModel.setSubscription(currentSubscriptionTransformed);
                dispatch.dialogModel.openDialog('subscriptionAddSuccess');
                dispatch.subscriptionModel.setAddChildStage('childAdded');
            }
            catch (error) {
                const errorCode = error.response?.data?.ErrorCode || error;
                if (errorCode === ResponseCodeEnum.Unauthorized) {
                    dispatch.userModel.logIn({ redirectType: 'updateSession' });
                    return;
                }
                const errorName = transformError(errorCode, 'user');
                dispatch.dialogModel.openErrorDialog({ name: errorName, role: RoleEnum.Parent });
                dispatch.subscriptionModel.setAddChildStage('error');
                if (error.failedState) {
                    const failedSubscriptionTransformed = transformSubscription(error.failedState, rootState.userModel.data.slaves);
                    dispatch.subscriptionModel.setSubscription(failedSubscriptionTransformed);
                    return;
                }
                dispatch.subscriptionModel.clearChildStatus();
            }
            finally {
                dispatch.subscriptionModel.stopRequest('requestCheckChildAdd');
            }
        },
        async addChild({ childPhone, code }, rootState) {
            dispatch.subscriptionModel.startRequest('requestChildAdd');
            dispatch.subscriptionModel.clearCheckOTPError();
            try {
                const { subscription: { subscriptionId }, source, } = rootState.subscriptionModel;
                const { data } = await api.subscription.addChild({
                    Code: code,
                    ID: subscriptionId,
                    MSISDN: childPhone,
                    Source: source,
                });
                dispatch.subscriptionModel.checkIsChildAdded(data.ID);
                const subscriptionActiveTransformed = transformSubscription(data, rootState.userModel.data.slaves);
                dispatch.subscriptionModel.setSubscription(subscriptionActiveTransformed);
            }
            catch (error) {
                const errorCode = error.response?.data?.ErrorCode;
                if (errorCode === ResponseCodeEnum.Unauthorized) {
                    dispatch.userModel.logIn({ redirectType: 'updateSession' });
                    return;
                }
                const isOTPInvalid = errorCode === ResponseCodeEnum.InvalidOTP;
                if (!isOTPInvalid) {
                    const errorName = errorCode ? transformError(errorCode, 'user') : error;
                    dispatch.dialogModel.openErrorDialog({ name: errorName, role: RoleEnum.Parent });
                    dispatch.subscriptionModel.setAddChildStage('error');
                    return;
                }
                dispatch.subscriptionModel.setCheckOTPError('invalidOTP');
            }
            finally {
                dispatch.subscriptionModel.stopRequest('requestChildAdd');
            }
        },
        async checkChildStatus(msisdn, rootState) {
            /*
             1. проверяем, доступно ли подключение ребенку: IsSubscriptionAvailable = true && !ActiveRole
             2. если доступно и нет OTPResendDate, то сразу запускаем подключение ребенку
             3. если доступно и есть OTPResendDate, то открываем страницу ввода OTP
             4. если подключение недоступно, то отдаем ошибку
           */
            dispatch.subscriptionModel.startRequest('requestChildStatus');
            dispatch.subscriptionModel.setNewChildPhone(msisdn);
            try {
                if (rootState.userModel.data.msisdn === msisdn) {
                    throw ChildPhoneValidateErrorName.NumbersMatch;
                }
                const { data } = await api.subscription.getChildStatus(msisdn);
                checkAvailabilityError(data);
                if (data.OTPResendDate) {
                    dispatch.subscriptionModel.setOTPData(data.OTPResendDate);
                    return;
                }
                dispatch.subscriptionModel.addChild({ childPhone: msisdn });
            }
            catch (error) {
                // Здесь могут быть как ошибки, приходящие с бэка со статусом 500, так и ошибки в результате обработки данных на фронте
                const errorCode = error.response ? error.response.data.ErrorCode : error;
                if (errorCode === ResponseCodeEnum.Unauthorized) {
                    dispatch.userModel.logIn({ redirectType: 'updateSession' });
                    return;
                }
                const availabilityError = error.response
                    ? ChildPhoneValidateErrorName.ChildPhoneValidateError
                    : errorCode;
                dispatch.subscriptionModel.setChildPhoneValidateError(availabilityError);
            }
            finally {
                dispatch.subscriptionModel.stopRequest('requestChildStatus');
            }
        },
        async checkSubscriptionCreate({ id, role }) {
            dispatch.subscriptionModel.startRequest('requestCheckCreate');
            try {
                await dispatch.subscriptionModel.checkOwnerSubscriptionStatus({ id, type: role });
                if (role === RoleEnum.Child) {
                    dispatch.dialogModel.openDialog('subscriptionAddSuccess');
                }
                dispatch.subscriptionModel.setSubscriptionAvailability(false);
                await dispatch.subscriptionModel.getTariffPlans({ role });
                dispatch.subscriptionModel.setCreateStatus('success');
            }
            catch (error) {
                const errorCode = error.response?.data?.ErrorCode || error;
                if (errorCode === ResponseCodeEnum.Unauthorized) {
                    dispatch.userModel.logIn({ redirectType: 'updateSession' });
                    return;
                }
                const errorName = transformError(errorCode, 'owner');
                dispatch.dialogModel.openErrorDialog({ name: errorName, role });
            }
            finally {
                dispatch.subscriptionModel.stopRequest('requestCheckCreate');
            }
        },
        async createSubscription({ contentID, role, isRenewProcess }, rootState) {
            /*
              1. выясняем, нужно ли поднимать виджет для оплаты подписки, пока считаем, что виджет нужен, если подписка когда-то была
              и при этом у нее не осталось триального периода, ждем более точное ТЗ
              2. отправляем на бэк запрос на создание подписки
              3. если нужен виджет, то поднимаем его (будет новое апи, пока не понятно какое, может быть можно будет данные для виджета перенести
              в эту модель)
              4. Меняем статус запроса о создании подписки на pending и начинаем проверять подписку на подключение
            */
            dispatch.subscriptionModel.startRequest('requestCreate');
            const source = rootState.subscriptionModel.source;
            const childPhone = rootState.subscriptionModel.subscription.msisdnChild || '';
            const choosenContent = contentID ||
                rootState.subscriptionModel.availableSubscriptions?.find(({ tariffPlan }) => rootState.subscriptionModel.tariffChoosen === tariffPlan)?.contentID;
            try {
                if (isRenewProcess) {
                    dispatch.subscriptionModel.setRenewProcess(true);
                    // запоминаем номер ребенка, который был ранее в подписке, т.к. после возобновления подписки родителя не сохранится номер ребенка
                    dispatch.subscriptionModel.setNewChildPhone(childPhone);
                }
                const requestData = {
                    content_id: choosenContent,
                    source: source,
                    promocode: rootState.promocodeModel.data?.code,
                };
                const { data } = await api.subscription.createSubscription(requestData);
                /*
                    комментарий бэка:
                    если надо поднять виджет, тебе метод create ответит подпиской с payment token status = creating && payment type = undefined
                    если же удалось провести оплату по лс на бэке, ответит payment token status = active && payment type = msisdn
                  */
                const isWidgetNeeded = data.PaymentTokenStatus?.toLowerCase() === PaymentTokenStatusEnum.Creating && data.PaymentType === PaymentTypeEnum.Undefined;
                if (isWidgetNeeded) {
                    const tokenId = data.IDToken;
                    if (!tokenId || !data?.PaymentSessionID) {
                        throw ErrorName.Default;
                    }
                    const widgetParams = {
                        onCloseSuccess: () => {
                            dispatch.subscriptionModel.checkSubscriptionCreate({ id: data.ID, role });
                            dispatch.subscriptionModel.stopRequest('requestCreate');
                        },
                        onCloseError: () => {
                            dispatch.subscriptionModel.stopRequest('requestCreate');
                        },
                        sessionData: {
                            sessionId: data.PaymentSessionID,
                            tokenId,
                        },
                    };
                    dispatch.paymentWidgetModel.openWidget(widgetParams);
                }
                if (!isWidgetNeeded) {
                    dispatch.subscriptionModel.checkSubscriptionCreate({ id: data.ID, role });
                    dispatch.subscriptionModel.stopRequest('requestCreate');
                }
            }
            catch (error) {
                const errorCode = error?.response?.data?.ErrorCode;
                if (errorCode === ResponseCodeEnum.Unauthorized) {
                    dispatch.userModel.logIn({ point: null, redirectType: 'buy' });
                    return;
                }
                dispatch.dialogModel.openErrorDialog({ name: CreateSubscriptionErrorName.CreateError, role });
                dispatch.subscriptionModel.stopRequest('requestCreate');
            }
        },
        async changePaymentTool({ isProlongationProcess }, rootState) {
            /*
              1. отправляем запрос на бэк для получения токена для виджета оплаты
              2. определяем sessionId для поднятия виджета:
                 - для PS1, 30TH_ANNIVERSATY и FORIS_60 это новая подписка, поэтому поле PaymentSessionID,
                 - для остальных систем старая обновляющаяся подписка и поле PaymentSessionIDNew;
              3. открываем виджет оплаты
            */
            dispatch.subscriptionModel.startRequest('requestChangePaymentTool');
            const subscription = rootState.subscriptionModel.subscription;
            const isSubscriptionActive = !rootState.subscriptionModel.isSubscriptionAvailable;
            const { subscriptionId, origin, subscriptionStatusParent } = subscription;
            const { source } = rootState.subscriptionModel;
            const isNewSubscription = originNewSubscription.includes(origin);
            const isChildOwner = subscriptionStatusParent === 'draft' && isSubscriptionActive;
            try {
                const { data } = await api.paymentWidget.changePaymentTool({ ID: subscriptionId, Source: source });
                const tokenId = data.IDToken;
                const sessionId = isNewSubscription ? data.PaymentSessionID : data.PaymentSessionIDNew;
                if (!sessionId || !tokenId) {
                    throw ErrorName.Default;
                }
                const widgetParams = {
                    onCloseSuccess: () => {
                        dispatch.subscriptionModel.updateSubscription({
                            subscriptionId: data.ID,
                            role: isChildOwner ? RoleEnum.Child : RoleEnum.Parent,
                            isProlongationProcess,
                        });
                        isChildOwner ? dispatch.dialogModel.openDialog('subscribeSettings') : dispatch.dialogModel.closeDialog();
                    },
                    sessionData: {
                        sessionId,
                        tokenId,
                    },
                };
                dispatch.dialogModel.closeDialog();
                dispatch.paymentWidgetModel.openWidget(widgetParams);
            }
            catch (error) {
                if (error?.response?.data?.ErrorCode === ResponseCodeEnum.Unauthorized) {
                    dispatch.userModel.logIn({});
                    return;
                }
                const role = isChildOwner ? RoleEnum.Child : RoleEnum.Parent;
                dispatch.dialogModel.openErrorDialog({ name: CreateSubscriptionErrorName.DefaultError, role });
                if (isProlongationProcess) {
                    dispatch.subscriptionModel.setUpdateStatus('error');
                }
            }
            finally {
                dispatch.subscriptionModel.stopRequest('requestChangePaymentTool');
            }
        },
        async checkPaymentTokenStatus(subscriptionId) {
            //успешный сценарий такой creating -> active -> undefined (переехал с PaymentTokenStatusNew в основные поля)
            let subscription = await checkStatusWithRetry({
                id: subscriptionId,
                currentStatus: PaymentTokenStatusEnum.Creating,
                type: 'paymentToken',
            });
            if (subscription.PaymentTokenStatusNew?.toLowerCase() === PaymentTokenStatusEnum.Active) {
                subscription = await checkStatusWithRetry({
                    id: subscriptionId,
                    currentStatus: PaymentTokenStatusEnum.Active,
                    type: 'paymentToken',
                });
            }
            if (subscription.PaymentTokenStatusNew?.toLowerCase() !== PaymentTokenStatusEnum.Undefined) {
                throw ErrorName.Default;
            }
            return subscription;
        },
        async updateSubscription({ subscriptionId, role, isProlongationProcess }, rootState) {
            dispatch.subscriptionModel.startRequest('updatePaymentTokenStatus');
            const subscription = rootState.subscriptionModel.subscription;
            const { origin } = subscription;
            try {
                // если PS1, 30TH_ANNIVERSARY или FORIS_60 , то проверяем обновление всей подписки, иначе только обновление
                // платежного
                // токена
                const result = originNewSubscription.includes(origin)
                    ? await dispatch.subscriptionModel.checkOwnerSubscriptionStatus({ id: subscriptionId, type: role })
                    : await dispatch.subscriptionModel.checkPaymentTokenStatus(subscriptionId);
                const transforemedResult = transformSubscription(result, rootState.userModel.data.slaves);
                dispatch.subscriptionModel.setSubscription(transforemedResult);
                if (isProlongationProcess) {
                    dispatch.subscriptionModel.setUpdateStatus('success');
                }
            }
            catch (error) {
                if (error?.response?.data?.ErrorCode === ResponseCodeEnum.Unauthorized) {
                    dispatch.userModel.logIn({});
                    return;
                }
                const role = rootState.userModel.isChild ? RoleEnum.Child : RoleEnum.Parent;
                dispatch.dialogModel.openErrorDialog({ name: CreateSubscriptionErrorName.DefaultError, role });
                if (isProlongationProcess) {
                    dispatch.subscriptionModel.setUpdateStatus('error');
                }
            }
            finally {
                dispatch.subscriptionModel.stopRequest('updatePaymentTokenStatus');
            }
        },
    }),
});
