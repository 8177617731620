export const getMetricNameLeaveClick = ({ isAnniversary, }) => {
    const metricConfig = [
        {
            check: isAnniversary,
            result: 'disabledSubscribeCancel',
        },
        {
            check: !isAnniversary,
            result: 'subscriptionSaveButton',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
export const getMetricNameConfirmClick = ({ isAnniversary, }) => {
    const metricConfig = [
        {
            check: isAnniversary,
            result: 'disabledSubscribeAgree',
        },
        {
            check: !isAnniversary,
            result: 'subscriptionDisableButton',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
export const getToggleMetricNameShow = ({ isAnniversary, }) => {
    const metricConfig = [
        {
            check: isAnniversary,
            result: 'disabledSubscribeShow',
        },
        {
            check: !isAnniversary,
            result: 'subscriptionSaveModal',
        },
    ];
    return metricConfig.find(({ check }) => check)?.result || null;
};
