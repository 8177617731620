import { jsx as _jsx } from "react/jsx-runtime";
import Layout from '@components/shared/Layout/Layout';
import classes from './SuccessAdd.module.scss';
import ContentBlock from '../ContentBlock/ContentBlock';
import Button from '@components/shared/Button/Button';
import { GTMScreenName, triggerMetricsEvent } from '@metrics';
import { useWindowSize } from '@hooks/useWindowSize';
import { useLocation, useNavigate } from 'react-router';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { useEffect } from 'react';
import { getInstructionUrl } from '@utils/getInstructionUrl';
import { useSelector } from 'react-redux';
import Picture from '@components/shared/Picture/Picture';
import { anniversarySelector, ownerSelector, isRenewProcessSelector } from '@store/models/subscription/selectors';
import { getToggleMetricNameClick, getToggleMetricNameShow } from '@pages/old-subscription/UpdateSubscription/configs';
import { getContentConfig } from './configs';
const imgDirectory = 'result-dialog';
const SuccessAdd = ({ isProlongation = false }) => {
    const version = useSelector(ownerSelector);
    const mtsAnniversary = useSelector(anniversarySelector);
    const { isDesktop } = useWindowSize();
    const navigate = useNavigate();
    const { search } = useLocation();
    const isPingoFlow = search.includes('pingo');
    const isRenewProcess = useSelector(isRenewProcessSelector);
    const { title, content, img, layoutType, mainPage } = getContentConfig({
        version: version || 'parent',
        isRenewProcess,
        isProlongationProcess: isProlongation,
    });
    const { dialogModel: { closeDialog }, subscriptionModel: { setRenewProcess }, } = useTypedDispatch();
    useEffect(() => {
        triggerMetricsEvent({ element: 'subscribeSuccessScreen' });
        closeDialog();
        return () => {
            // завершаем флоу возобновления
            setRenewProcess(false);
        };
    }, []);
    const handleClose = () => {
        const metricName = getToggleMetricNameClick({ isAnniversary: mtsAnniversary });
        if (metricName) {
            triggerMetricsEvent({ element: metricName });
        }
        closeDialog();
        const url = isPingoFlow ? getInstructionUrl('pingo', false) : mainPage;
        navigate({
            pathname: url,
            search: isPingoFlow ? 'pingo' : '',
        });
    };
    const header = _jsx(Picture, { className: classes.img, alt: img, src: { directory: imgDirectory, fileName: img } });
    const actions = _jsx(Button, { variant: "primary", size: "md", text: "\u0421\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u0441\u0435\u0440\u0432\u0438\u0441\u044B", full: true, onClick: handleClose, className: classes.button });
    useEffect(() => {
        const metricName = getToggleMetricNameShow({ isAnniversary: mtsAnniversary });
        if (metricName) {
            triggerMetricsEvent({ element: metricName });
        }
        closeDialog();
    }, []);
    return (_jsx(Layout, { pageName: GTMScreenName.addChild, withFooter: false, type: layoutType, withBackButton: isDesktop, withoutVersionButton: true, children: _jsx(ContentBlock, { title: title, content: content, actions: actions, header: header, withBackButton: !isDesktop }) }));
};
export default SuccessAdd;
