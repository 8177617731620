import { subscription } from '../subscription/mock';
import { PromocodeStatusEnum } from './types';
export const validPromoCode = {
    data: {
        DurationDays: 365,
        PromotionDescription: 'good promocode',
        PromotionName: 'the best action',
    },
};
export const activePromoCode = {
    data: {
        ...subscription.data,
        PromoCodeStatus: PromocodeStatusEnum.Active,
    },
};
