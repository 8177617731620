import { jsx as _jsx } from "react/jsx-runtime";
import { BackArrowIcon } from '@components/icons';
import Button from '../Button/Button';
import classes from './BackButton.module.scss';
import { useWindowSize } from '@hooks/useWindowSize';
import { useSelector } from 'react-redux';
import { abonentSelector, childThemeSelector, isSafariSelector, navigationHistorySelector } from '@store/models/user/selectors';
import { Page, partnerUrlNameMap } from '@const';
import { anniversarySelector, availablePlanSelector, choosenTariffSelector } from '@store/models/subscription/selectors';
import { checkPage } from '@utils/checkPage';
import { useLocation, useNavigate, useParams } from 'react-router';
import { metricsDataSelector } from '@store/models/metrics/selectors';
import { getBackButtonMetricName } from './config';
import { triggerMetricsEvent } from '@metrics';
import classNames from 'classnames/bind';
const BackButton = ({ className }) => {
    const { isMobileSm, isDesktopLgExtraLg } = useWindowSize();
    const isHistoryEmpty = useSelector(navigationHistorySelector);
    const isChildFlow = useSelector(childThemeSelector);
    const isMTSAbonent = useSelector(abonentSelector);
    const { isCreatingPage, isChildAddedPage, isChildAddPage, isParentAddedPage, isInstructionPage, isPromocodePage, isUpdatingPage, isUpdateSuccessPage, } = checkPage();
    const mtsAnniversary = useSelector(anniversarySelector);
    const { search } = useLocation();
    const isPingoFlow = search.includes('pingo');
    const navigate = useNavigate();
    const { isAuthPageShowed } = useSelector(metricsDataSelector);
    const { partner } = useParams();
    const partnerName = partner && partnerUrlNameMap[partner];
    const isSafari = useSelector(isSafariSelector);
    const isSuccessAddPage = isChildAddedPage || isParentAddedPage || isUpdateSuccessPage;
    const showLinkToMain = isHistoryEmpty || isCreatingPage || isChildAddPage || isSuccessAddPage || (isPingoFlow && isInstructionPage);
    const choosenPlan = useSelector(choosenTariffSelector);
    const availablePlan = useSelector(availablePlanSelector);
    const isFree = choosenPlan ? choosenPlan.isTrial : availablePlan.isFree;
    const mainLinkConfig = [
        {
            check: isPingoFlow && !isInstructionPage,
            result: Page.Pingo,
        },
        {
            check: isChildFlow,
            result: Page.Child,
        },
    ];
    const handleBackClick = () => {
        const metricsName = getBackButtonMetricName({
            isAuthPageShowed,
            isCreatingPage: isCreatingPage || isUpdatingPage,
            isMTSAbonent,
            isFree,
            isSuccessAddPage,
            instructionPageName: partnerName,
            planName: choosenPlan?.tariffPlan,
            isPromocodePage,
            isAnniversary: mtsAnniversary,
        });
        if (metricsName) {
            triggerMetricsEvent({ element: metricsName });
        }
        const mainLink = mainLinkConfig.find(({ check }) => check)?.result || Page.Main;
        const to = showLinkToMain ? mainLink : -1;
        navigate(to);
    };
    const backButtonSize = isDesktopLgExtraLg ? 'md' : 'sm';
    const backButtonText = showLinkToMain ? 'На главную' : 'Назад';
    return (_jsx(Button, { className: classNames(classes.backButton, className), size: backButtonSize, variant: "white", text: !isMobileSm ? backButtonText : '', icon: _jsx(BackArrowIcon, { containerClasses: classNames(classes.backIcon, { [classes.notSafari]: !isSafari }) }), onClick: handleBackClick }));
};
export default BackButton;
