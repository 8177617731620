import { OriginEnum, PaymentTokenStatusEnum, ServiceStatusEnum, SubscriptionStatusChildEnum, SubscriptionStatusParentEnum, TariffPlanEnum, TarifficationStatusEnum, } from '@models/subscription/types';
import dayjs from 'dayjs';
import { PromocodeStatusEnum } from '../promocode/types';
import { ResponseCodeEnum } from '@models/types';
export const parentMsisdn = '79429458662';
export const childMsisdn = '79999999999';
const nextTarifficationDate = `${dayjs().add(29, 'day')}`;
const tarifficationDate = `${dayjs()}`;
const resendDate = `${dayjs().add(180, 'second').format()}`;
export const childAvailable = {
    data: {
        ActiveRole: null,
        IsSubscriptionAvailable: true,
        OTPResendDate: resendDate,
    },
};
export const childNotAvailable = {
    response: {
        data: {
            ErrorCode: 10108,
        },
    },
};
export const parentAvailableTPMonth = {
    data: {
        values: [
            {
                content_id: '1679211e-c946-4666-a3ed-fe522f7177e5',
                is_trial: true,
                period: 30,
                price: 149,
                trial_period: 30,
                type: TariffPlanEnum.Month,
            },
        ],
    },
};
export const parentAvailableTPYear = {
    data: {
        values: [
            {
                content_id: '1679211e-c946-4666-a3ed-fe522f7177e6',
                is_trial: false,
                period: 365,
                price: 999,
                trial_period: 0,
                type: TariffPlanEnum.Year,
            },
        ],
    },
};
export const parentAvailableTPBoth = {
    data: {
        values: [
            {
                content_id: '1679211e-c946-4666-a3ed-fe522f7177e5',
                is_trial: true,
                period: 30,
                price: 149,
                trial_period: 30,
                type: TariffPlanEnum.Month,
                remaining_days: 20,
            },
            {
                content_id: '1679211e-c946-4666-a3ed-fe522f7177e6',
                is_trial: false,
                period: 365,
                price: 999,
                trial_period: 0,
                type: TariffPlanEnum.Year,
                remaining_days: 200,
            },
        ],
    },
};
export const parentNotAvailable = {
    data: {
        ErrorCode: ResponseCodeEnum.ContentForbiddenForTP,
        ErrorMessage: 'string',
    },
};
export const subscriptionCreateError = {
    data: {
        ErrorCode: ResponseCodeEnum.InternalServerError,
        ErrorMessage: 'string',
    },
};
export const subscription = {
    data: {
        EWalletBindingTitle: '',
        ID: '1',
        IsMTSSubscriberChild: true,
        IsMTSSubscriberParent: true,
        IsTrial: true,
        KionStatus: ServiceStatusEnum.Active,
        MasterHubIDChild: '',
        MasterHubIDParent: '',
        MSISDNChild: childMsisdn,
        MSISDNParent: parentMsisdn,
        NextTarifficationDate: nextTarifficationDate,
        Price: 149,
        SubscriptionStatusChild: SubscriptionStatusChildEnum.Active,
        SubscriptionStatusParent: SubscriptionStatusParentEnum.Active,
        PaymentTokenStatus: PaymentTokenStatusEnum.Undefined,
        PromoCodeStatus: PromocodeStatusEnum.Undefined,
        TarifficationDate: tarifficationDate,
        TarifficationStatus: TarifficationStatusEnum.Successful,
        TrialPeriodDays: 5,
        PaidPeriodDays: 0,
        SubscriptionDate: '2023-12-01T11:30:34.298Z',
        SubscriptionOrigin: OriginEnum.Landing,
        TariffPlanType: TariffPlanEnum.Month,
    },
};
export const subscriptionChangedTP = {
    data: {
        EWalletBindingTitle: '',
        ID: '1',
        IsMTSSubscriberChild: true,
        IsMTSSubscriberParent: true,
        IsTrial: true,
        KionStatus: ServiceStatusEnum.Active,
        MasterHubIDChild: '',
        MasterHubIDParent: '',
        MSISDNChild: childMsisdn,
        MSISDNParent: parentMsisdn,
        NextTarifficationDate: nextTarifficationDate,
        Price: 149,
        SubscriptionStatusChild: SubscriptionStatusChildEnum.Canceled,
        SubscriptionStatusParent: SubscriptionStatusParentEnum.Active,
        PaymentTokenStatus: PaymentTokenStatusEnum.Undefined,
        PromoCodeStatus: PromocodeStatusEnum.Undefined,
        TarifficationDate: tarifficationDate,
        TarifficationStatus: TarifficationStatusEnum.Successful,
        TrialPeriodDays: 30,
        PaidPeriodDays: 0,
        SubscriptionDate: '',
        SubscriptionOrigin: OriginEnum.Landing,
        TariffPlanType: TariffPlanEnum.Year,
    },
};
export const subscriptionCanceled = {
    data: {
        ...subscription.data,
        SubscriptionStatusParent: SubscriptionStatusParentEnum.Canceled,
    },
};
export const subscriptionCreating = {
    data: {
        ...subscription.data,
        SubscriptionStatusChild: SubscriptionStatusChildEnum.Draft,
        SubscriptionStatusParent: SubscriptionStatusParentEnum.Draft,
    },
};
export const subscriptionCreated = {
    data: {
        ...subscription.data,
        SubscriptionStatusParent: SubscriptionStatusParentEnum.Active,
        SubscriptionStatusChild: SubscriptionStatusChildEnum.Draft,
        TarifficationStatus: TarifficationStatusEnum.Successful,
        PaymentTokenStatusNew: 'Undefined',
        PromoCodeStatus: PromocodeStatusEnum.Active,
    },
};
export const invite = {
    data: {
        ID: '',
        MSISDNChild: '79161234567',
        MSISDNParent: parentMsisdn,
    },
};
export const addChild = {
    data: {
        ...subscription.data,
        SubscriptionStatusParent: SubscriptionStatusParentEnum.Active,
        SubscriptionStatusChild: SubscriptionStatusChildEnum.Adding,
    },
    /*data: {
      ErrorCode: 10106,
      ErrorMessage: 'string',
    },*/
};
export const createSubscriptionError = {
    data: {
        ErrorCode: 10114,
        ErrorMessage: 'string',
    },
};
