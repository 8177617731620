export const monoSubscriptionConfig = {
    childNotAuth: {
        connectSalesButton: {
            ymID: 'ch_kakpodkluchit_poprobovatbesplatno_nea',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'link_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '0',
            },
        },
    },
    childAuth: {
        connectSalesButton: {
            ymID: 'ch_kakpodkluchit_poprobovatbesplatno_a',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'link_click',
                eventLabel: 'poprobovat_besplatno',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        availabilityCheckingScreen: {
            ymID: 'ch_proveraemdostupnost_pokaz',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'popup_show',
                eventLabel: 'proveryaem_dostupnost_podpiski',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        headerLogo: {
            ymID: 'ch_logo',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'mts_junior',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        monthTariffAbonentTrialOffer: {
            ymID: 'ch_mts_mes_trial_pokaz',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'mesyac',
                eventContext: 'trial',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        monthTariffAbonentTrialBuyButton: {
            ymID: 'ch_mts_mes_trial_podkluchit',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                eventContent: 'mesyac',
                eventContext: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageMonthTariffAbonentTrialBackButton: {
            ymID: 'ch_mts_mes_trial_back',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'mesyac',
                eventContext: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        monthTariffAbonentNoTrialOffer: {
            ymID: 'ch_mts_mes_notrial_pokaz',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'mesyac',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        monthTariffAbonentNoTrialBuyButton: {
            ymID: 'ch_mts_mes_notrial_podkluchit',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                eventContent: 'mesyac',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageMonthTariffAbonentNoTrialBackButton: {
            ymID: 'ch_mts_mes_notrial_back',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'mesyac',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        monthTariffNonAbonentTrialOffer: {
            ymID: 'ch_neabon_mes_trial_pokaz',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'mesyac',
                eventContext: 'trial',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        monthTariffNonAbonentTrialBuyButton: {
            ymID: 'ch_neabon_mes_trial_podkluchit',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vybrat_sposob_oplaty',
                eventContent: 'mesyac',
                eventContext: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageMonthTariffNonAbonentTrialBackButton: {
            ymID: 'ch_neabon_mes_trial_back',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'mesyac',
                eventContext: 'trial',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        monthTariffNonAbonentNoTrialOffer: {
            ymID: 'ch_neabon_mes_notrial_pokaz',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'mesyac',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        monthTariffNonAbonentNoTrialBuyButton: {
            ymID: 'ch_neabon_mes_notrial_podkluchit',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'vybrat_sposob_oplaty',
                eventContent: 'mesyac',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        createPageMonthTariffNonAbonentNoTrialBackButton: {
            ymID: 'ch_neabon_mes_notrial_back',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'mesyac',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        childConnectedScreen: {
            ymID: 'ch_ostaloschutchut_pokaz',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'popup_show',
                eventLabel: 'ostalos_chut_chut',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        childConnectedScreenOkButton: {
            ymID: 'ch_ostaloschutchut_ponyatno',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'ponyatno',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscribeSuccessScreen: {
            ymID: 'ch_podkluchena_pokaz',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'confirmed',
                eventLabel: 'podpiska_podkluchena',
                buttonLocation: 'popup',
                actionGroup: 'conversions',
                userAuth: '1',
            },
        },
        subscribeSuccessScreenBackButton: {
            ymID: 'ch_podkluchena_back',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscribeSuccessServicesButton: {
            ymID: 'ch_podkluchena_vseservisy',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'smotret_servisy',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        settingsButton: {
            ymID: 'ch_upravlyat',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'upravlyat_podpiskoi',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        settingsModal: {
            ymID: 'ch_nastroika_pokaz',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'popup_show',
                eventLabel: 'nastroika_podpiski',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        paymentToolButton: {
            ymID: 'ch_nastroika_karta',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'element_click',
                eventLabel: 'sposob_oplaty',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        settingsDisableButton: {
            ymID: 'ch_nastroika_otkluchit',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'otkluchit_podpisku',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscriptionDisableModal: {
            ymID: 'ch_pochemuotkluchaesh_pokaz',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'popup_show',
                eventLabel: 'ostavit_podpisku',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        subscriptionSaveButton: {
            ymID: 'ch_pochemuotkluchaesh_ostavit',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'ostavit_podpisku',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscriptionSaveModal: {
            ymID: 'ch_podpiska_ostalas_pokaz',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'popup_show',
                eventLabel: 'podpiska_ostalas',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        subscriptionSaveModalOkButton: {
            ymID: 'ch_podpiska_ostalas_ponyatno',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'ponyatno',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscriptionSaveModalCloseButton: {
            ymID: 'ch_podpiska_ostalas_close',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'zakryt',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscriptionDisableButton: {
            ymID: 'ch_pochemuotkluchaesh_otkluchit',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'otkluchit_podpisku',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscriptionDisabledModal: {
            ymID: 'ch_podpiska_otkluchena_pokaz',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'rebenok',
                eventAction: 'popup_show',
                eventLabel: 'podpiska_otkluchena',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        subscriptionDisabledModalOKButton: {
            ymID: 'ch_podpiska_otkluchena_ponyatno',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'button_click',
                eventLabel: 'ponyatno',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscriptionDisabledCloseButton: {
            ymID: 'ch_podpiska_otkluchena_close',
            GTMParams: {
                event: 'vntReb',
                eventCategory: 'podpiska',
                eventAction: 'element_click',
                eventLabel: 'zakryt',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
    },
};
