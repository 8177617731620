import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Result from '../Result/Result';
import Link from '@shared/Link/Link';
import { siteLink } from '@const';
import { triggerMetricsEvent } from '@metrics';
import { abonentSelector } from '@store/models/user/selectors';
import { useTypedDispatch } from '@hooks/useTypedDispatch';
import { anniversarySelector, prevOwnerSelector } from '@store/models/subscription/selectors';
import { RoleEnum } from '@store/models/subscription/types';
import { getToggleMetricNameShow, getMetricNameConfirmClick } from './config';
const contentMap = {
    parent: isAbonent => ({
        text: (_jsxs(_Fragment, { children: ["\u0412\u044B \u0432\u0441\u0435\u0433\u0434\u0430 \u043C\u043E\u0436\u0435\u0442\u0435 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443 \u0437\u0430\u043D\u043E\u0432\u043E \u043D\u0430 \u043D\u0430\u0448\u0435\u043C \u0441\u0430\u0439\u0442\u0435", ' ', _jsx(Link, { href: siteLink, type: "primary", children: "junior.mts.ru" }), ' ', isAbonent && (_jsxs(_Fragment, { children: ["\u0438\u043B\u0438 \u0432 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0438", ' ', _jsx(Link, { href: "https://mts.ru/app", type: "primary", children: "\u041C\u043E\u0439 \u041C\u0422\u0421" })] }))] })),
    }),
    child: isAbonent => ({
        text: (_jsxs(_Fragment, { children: ["\u0422\u044B \u0432\u0441\u0435\u0433\u0434\u0430 \u043C\u043E\u0436\u0435\u0448\u044C \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u044C \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443 \u0437\u0430\u043D\u043E\u0432\u043E \u043D\u0430 \u043D\u0430\u0448\u0435\u043C \u0441\u0430\u0439\u0442\u0435", ' ', _jsx(Link, { href: siteLink, type: "primary", children: "junior.mts.ru" }), ' ', isAbonent && (_jsxs(_Fragment, { children: ["\u0438\u043B\u0438 \u0432 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0438", ' ', _jsx(Link, { href: "https://mts.ru/app", type: "primary", children: "\u041C\u043E\u0439 \u041C\u0422\u0421" })] }))] })),
    }),
};
const SubscribeDisablingSuccess = () => {
    const isAnniversary = useSelector(anniversarySelector);
    const isAbonent = useSelector(abonentSelector);
    const isChildUser = useSelector(prevOwnerSelector) === RoleEnum.Child;
    const version = isChildUser ? 'child' : 'parent';
    const { text } = contentMap[version](isAbonent);
    const { subscriptionModel: { setPrevSubscriptionOwner }, } = useTypedDispatch();
    useEffect(() => {
        const metricName = getToggleMetricNameShow({ isAnniversary });
        if (metricName) {
            triggerMetricsEvent({ element: metricName });
        }
        return () => {
            setPrevSubscriptionOwner(null);
        };
    }, []);
    const img = { directory: 'result-dialog', fileName: 'result-dialog-subscription-disabled.png' };
    const handleOkClick = () => {
        const metricName = getMetricNameConfirmClick({ isAnniversary });
        if (metricName) {
            triggerMetricsEvent({ element: metricName });
        }
    };
    return (_jsx(Result, { title: "\u041F\u043E\u0434\u043F\u0438\u0441\u043A\u0430 \u043E\u0442\u043A\u043B\u044E\u0447\u0435\u043D\u0430", text: text, buttonText: "\u041F\u043E\u043D\u044F\u0442\u043D\u043E", onClick: handleOkClick, img: img }));
};
export default SubscribeDisablingSuccess;
