import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { pluralize } from '@utils/pluralize';
import { formatDate } from '@utils/date';
import { TariffPlanEnum } from '@store/models/subscription/types';
export const getActiveSubscriptionPeriod = ({ isSuspended, isTrialPeriod, remainingDays = 0, price, nextTarifficationDate = '', withoutChild = false, isNetariff = false, currentPlan, isProlongationAvailable, isAnniversary, }) => {
    if (!price && !(remainingDays || nextTarifficationDate)) {
        return '';
    }
    const nextDate = formatDate({ date: nextTarifficationDate });
    const daysText = pluralize(remainingDays, 'days');
    const leftText = pluralize(remainingDays, 'leftM');
    const leftTextUppercase = pluralize(remainingDays, 'leftMUpper');
    if (isAnniversary && remainingDays <= 5) {
        if (remainingDays === 0) {
            return _jsx(_Fragment, { children: "\u041F\u0440\u043E\u0434\u043B\u0438\u0442\u0435 \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443, \u0447\u0442\u043E\u0431\u044B \u0441\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C \u0434\u043E\u0441\u0442\u0443\u043F \u043A \u0441\u0435\u0440\u0432\u0438\u0441\u0430\u043C \u0434\u043B\u044F \u0441\u0435\u0431\u044F \u0438 \u0440\u0435\u0431\u0451\u043D\u043A\u0430" });
        }
        return (_jsxs(_Fragment, { children: [leftTextUppercase, " ", remainingDays, " ", daysText, " \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438 \u0432 \u043F\u043E\u0434\u0430\u0440\u043E\u043A \u043F\u043E \u0430\u043A\u0446\u0438\u0438 \u00AB30 \u043B\u0435\u0442 \u041C\u0422\u0421\u00BB. \u041F\u0440\u043E\u0434\u043B\u0438\u0442\u0435 \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0443, \u0447\u0442\u043E\u0431\u044B \u0441\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C \u0434\u043E\u0441\u0442\u0443\u043F \u043A \u0441\u0435\u0440\u0432\u0438\u0441\u0430\u043C \u0434\u043B\u044F \u0441\u0435\u0431\u044F \u0438 \u0440\u0435\u0431\u0451\u043D\u043A\u0430"] }));
    }
    if (isProlongationAvailable) {
        const prolongationText = `${leftText} ${remainingDays} ${daysText} подписки.`;
        return (_jsxs(_Fragment, { children: [remainingDays > 0 && prolongationText, " \u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u0441\u043F\u043E\u0441\u043E\u0431 \u043F\u0440\u043E\u0434\u043B\u0435\u043D\u0438\u044F \u0434\u043E ", nextDate, ", \u0447\u0442\u043E\u0431\u044B \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0430 \u043E\u0441\u0442\u0430\u0432\u0430\u043B\u0430\u0441\u044C \u0430\u043A\u0442\u0438\u0432\u043D\u043E\u0439"] }));
    }
    if (isNetariff) {
        return (_jsxs(_Fragment, { children: ["\u0412\u0445\u043E\u0434\u0438\u0442 \u0432 \u0442\u0430\u0440\u0438\u0444 ", _jsx("span", { className: "text-medium", children: "\u041C\u0422\u0421 \u041A\u0430\u043A \u0445\u043E\u0447\u0435\u0448\u044C" })] }));
    }
    if (isSuspended) {
        const withoutChildText = withoutChild && '. Добавить ребенка в подписку можно после оплаты';
        return (_jsxs(_Fragment, { children: ["\u0412\u043E\u0437\u043D\u0438\u043A\u043B\u0438 \u043F\u0440\u043E\u0431\u043B\u0435\u043C\u044B \u0441 \u043E\u043F\u043B\u0430\u0442\u043E\u0439. ", _jsx("br", { className: "br-first" }), "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0435\u043C \u0441\u043F\u0438\u0441\u0430\u0442\u044C \u0441\u043D\u043E\u0432\u0430 ", _jsx("br", { className: "br-second" }), " ", nextDate, withoutChildText] }));
    }
    if (isTrialPeriod && remainingDays >= 7) {
        return (_jsxs(_Fragment, { children: [leftText, " ", remainingDays, " ", daysText, " \u043F\u0440\u043E\u0431\u043D\u043E\u0433\u043E \u043F\u0435\u0440\u0438\u043E\u0434\u0430, \u0434\u0430\u043B\u0435\u0435\u00A0", price, "\u00A0\u20BD"] }));
    }
    const isYearPlan = currentPlan === TariffPlanEnum.Year;
    const dateFormat = isYearPlan ? 'DD MMMM YYYY' : null;
    const postfix = isYearPlan ? 'года' : null;
    return (_jsxs(_Fragment, { children: [formatDate({
                date: nextTarifficationDate,
                dateFormat,
                postfix,
            }), ' ', "\u0441\u043F\u0438\u0448\u0435\u0442\u0441\u044F ", price, "\u00A0\u20BD"] }));
};
export const getAnniversaryPeriodFor = (remainingDays) => {
    const days = remainingDays || 0;
    const daysText = pluralize(days, 'days');
    const leftText = pluralize(days, 'leftM');
    if (days <= 5) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [leftText, " ", days, " ", daysText, " \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438 \u0432 \u043F\u043E\u0434\u0430\u0440\u043E\u043A \u043F\u043E \u0430\u043A\u0446\u0438\u0438 \u00AB30 \u043B\u0435\u0442 \u041C\u0422\u0421\u00BB"] }));
};
export const getAnniversaryPeriod = (remainingDays) => {
    const days = remainingDays || 0;
    const daysText = pluralize(days, 'days');
    const leftText = pluralize(days, 'leftM');
    return (_jsxs(_Fragment, { children: [leftText, " ", days, " ", daysText, " \u043F\u043E\u0434\u043F\u0438\u0441\u043A\u0438 \u0432 \u043F\u043E\u0434\u0430\u0440\u043E\u043A \u043F\u043E \u0430\u043A\u0446\u0438\u0438 \u00AB30 \u043B\u0435\u0442 \u041C\u0422\u0421\u00BB"] }));
};
