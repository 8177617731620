const getCommonParentConfig = (userAuth) => ({
    mainBannerClick: {
        ymID: 'pr_event_30letmts',
        GTMParams: {
            event: 'vntRod',
            eventCategory: 'junior_mts30',
            eventAction: 'banner_click',
            eventLabel: 'podarki_v_chest_30_letiya_mts',
            actionGroup: 'interactions',
            productName: 'mts_30',
            userAuth,
        },
    },
});
export const mtsAnniversaryConfig = {
    parentAuth: {
        ...getCommonParentConfig('1'),
        specifyContinueSubscribeClick: {
            ymID: 'pr_prolong_sposobprodleniya',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'element_click',
                eventLabel: 'ukazhite_sposob_prodleniya',
                actionGroup: 'interactions',
                userAuth: '1'
            },
        },
        continueSubscribeClick: {
            ymID: 'pr_prolong_prodlit',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'button_click',
                eventLabel: 'prodlit_podpisku',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        continueSubscribeMonthShow: {
            ymID: 'pr_mts_mes_prolong_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'mesyac',
                eventContext: 'prodlenie_podpiski',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        continueSubscribeMonthBack: {
            ymID: 'pr_mts_mes_prolong_back',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'mesyac',
                eventContext: 'prodlenie_podpiski',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        continueSubscribeMonthConnect: {
            ymID: 'pr_mts_mes_prolong_podkluchit',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                eventContent: 'mesyac',
                eventContext: 'prodlenie_podpiski',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        continueSubscribeYearShow: {
            ymID: 'pr_mts_god_prolong_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'god',
                eventContext: 'prodlenie_podpiski',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        continueSubscribeYearBack: {
            ymID: 'pr_mts_god_prolong_back',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'god',
                eventContext: 'prodlenie_podpiski',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        continueSubscribeYearConnect: {
            ymID: 'pr_mts_god_prolong_podkluchit',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'button_click',
                eventLabel: 'podkluchit',
                eventContent: 'god',
                eventContext: 'prodlenie_podpiski',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        continueSubscribeMonthNonAbonentShow: {
            ymID: 'pr_neabon_mes_prolong_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'mesyac',
                eventContext: 'prodlenie_podpiski',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        continueSubscribeMonthNonAbonentBack: {
            ymID: 'pr_neabon_mes_prolong_back',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'mesyac',
                eventContext: 'prodlenie_podpiski',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        continueSubscribeMonthNonAbonentConnect: {
            ymID: 'pr_neabon_mes_prolong_vybratsposoboplaty',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'button_click',
                eventLabel: 'vybrat_sposob_oplaty',
                eventContent: 'mesyac',
                eventContext: 'prodlenie_podpiski',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        continueSubscribeYearNonAbonentShow: {
            ymID: 'pr_neabon_god_prolong_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'popup_show',
                eventLabel: 'podpiska',
                eventContent: 'god',
                eventContext: 'prodlenie_podpiski',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        continueSubscribeYearNonAbonentBack: {
            ymID: 'pr_neabon_god_prolong_back',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'god',
                eventContext: 'prodlenie_podpiski',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        continueSubscribeYearNonAbonentConnect: {
            ymID: 'pr_neabon_god_prolong_vybratsposoboplaty',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'button_click',
                eventLabel: 'vybrat_sposob_oplaty',
                eventContent: 'god',
                eventContext: 'prodlenie_podpiski',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        continueSubscribeSuccessShow: {
            ymID: 'pr_prolong_prodlena_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'confirmed',
                eventLabel: 'podpiska_prodlena',
                buttonLocation: 'popup',
                actionGroup: 'conversions',
                userAuth: '1',
            },
        },
        continueSubscribeSuccessBack: {
            ymID: 'pr_prolong_prodlena_back',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'element_click',
                eventLabel: 'nazad',
                eventContent: 'podpiska_prodlena',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        continueSubscribeSuccessAllService: {
            ymID: 'pr_prolong_prodlena_vseservisy',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'button_click',
                eventLabel: 'smotret_servisy',
                eventContent: 'podpiska_prodlena',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        disabledSubscribeShow: {
            ymID: 'pr_otkluchit_30let_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'popup_show',
                eventLabel: 'otkluchit_podpisku',
                buttonLocation: 'popup',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        disabledSubscribeClose: {
            ymID: 'pr_otkluchit_30let_close',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'element_click',
                eventLabel: 'zakryt',
                eventContent: 'otkluchit_podpisku',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        disabledSubscribeCancel: {
            ymID: 'pr_otkluchit_30let_ostavit',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'button_click',
                eventLabel: 'ostavit_podpisku',
                eventContent: 'otkluchit_podpisku',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscriptionRemainsShow: {
            ymID: 'pr_podpiska_ostalas_pokaz',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'popup_show',
                eventLabel: 'podpiska_ostalas',
                buttonLocation: 'popup',
                actionGroup: 'non_interactions',
                userAuth: '1',
            },
        },
        subscriptionRemainsAccept: {
            ymID: 'pr_podpiska_ostalas_ponyatno',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'button_click',
                eventLabel: 'ponyatno',
                eventContent: 'podpiska_ostalas',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        subscriptionRemainsClose: {
            ymID: 'pr_podpiska_ostalas_close',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'element_click',
                eventLabel: 'zakryt',
                eventContent: 'podpiska_ostalas',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        disabledSubscribeAgree: {
            ymID: 'pr_otkluchit_30let_otkluchit',
            GTMParams: {
                event: 'vntRod',
                eventCategory: 'junior_mts30',
                eventAction: 'button_click',
                eventLabel: 'otkluchit_podpisku',
                eventContent: 'otkluchit_podpisku',
                buttonLocation: 'popup',
                actionGroup: 'interactions',
                userAuth: '1',
            },
        },
        disabledSubscribeAgreeShow: {
            ymID: 'pr_podpiska_otkluchena_pokaz',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'junior_mts30',
                eventAction: 'confirmed',
                eventLabel: 'podpiska_otkluchena',
                buttonLocation: 'popup',
                actionGroup: 'conversions',
                userAuth: '1',
            },
        },
        disabledSubscribeAgreeAccept: {
            ymID: 'pr_podpiska_otkluchena_ponyatno',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'junior_mts30',
                eventAction: 'button_click',
                eventLabel: 'ponyatno',
                eventContent: 'podpiska_otkluchena',
                buttonLocation: 'popup',
                actionGroup: 'conversions',
                userAuth: '1',
            },
        },
        disabledSubscribeAgreeClose: {
            ymID: 'pr_podpiska_otkluchena_close',
            GTMParams: {
                event: 'vntPod',
                eventCategory: 'junior_mts30',
                eventAction: 'element_click',
                eventLabel: 'zakryt',
                eventContent: 'podpiska_otkluchena',
                buttonLocation: 'popup',
                actionGroup: 'conversions',
                userAuth: '1',
            },
        },
    },
    parentNotAuth: {
        ...getCommonParentConfig('0')
    },
};
