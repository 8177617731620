import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { useWindowSize } from 'src/hooks/useWindowSize';
import Dropdown from './Dropdown/Dropdown';
import List from './List/List';
const Autocomplete = ({ defaultValue = '', ...props }) => {
    const [value, setValue] = useState(defaultValue);
    const { isMobileSm } = useWindowSize();
    const autocompleteProps = { autocompleteValue: value, setAutocompleteValue: setValue, ...props };
    return isMobileSm ? _jsx(List, { ...autocompleteProps }) : _jsx(Dropdown, { ...autocompleteProps });
};
export default Autocomplete;
